import GenericCreateForm from '../common/create'

import BookingFormLayout, { transformValues } from './form'
import { BookingTitleWithUser } from './titles'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<BookingTitleWithUser />}
      formLayoutComponent={<BookingFormLayout />}
      transformValues={transformValues}
    />
  )
}
