import { alpha } from '@material-ui/core/styles'
import { Datagrid, Filter, List, useGetIdentity } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import { getUserFullName } from '../../components/UserFullNameField'
import { OPS_USER_ROLES_NAMES, SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { darkTheme } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { CreateButton, EditButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'
import OrganisationField from '../organisations/field'

import { useCreateOpsUser, useEditOpsUser } from './form'
import { DeleteOpsUserButton } from './buttons'
import config from './config'

const OpsUsersFilters = (props) => {
  return (
    <Filter variant="outlined" {...props}>
      <AdvancedSearchFilter alwaysOn />
    </Filter>
  )
}

const opsUserRowStyle = (record, currentUserId) => {
  const isCurrentUser = record.id === currentUserId
  return {
    backgroundColor: isCurrentUser ? alpha(darkTheme.palette.secondary.main, 0.1) : 'none',
  }
}

export const OpsUsersListLayout = ({ hasEdit, hasDelete, ...props }) => {
  const { identity } = useGetIdentity()
  const currentUserId = identity?.id
  const translate = useTranslate()

  const listClasses = useListStyles()

  const [openEditPopup, editPopupDialog] = useEditOpsUser()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          alignItems="flex-start"
          itemStyle={(record) => opsUserRowStyle(record, currentUserId)}
          icon={(record) => getUserFullName(record, { showInitials: true })}
          title={(record) => getUserFullName(record)}
          body={(record) => (
            <>
              <AdvancedTextField record={record} source="email" />
              <br />
              <AdvancedTextField record={record} source="role" map={OPS_USER_ROLES_NAMES} />
            </>
          )}
        />
      }
      regularListLayout={
        <Datagrid
          {...props}
          classes={{ row: listClasses.row }}
          rowStyle={(record) => opsUserRowStyle(record, currentUserId)}
        >
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="role" map={OPS_USER_ROLES_NAMES} />
          <OrganisationField
            sortable={false}
            source="restricted_to_organisation_id"
            emptyText={translate('resources.ops-users.allOrganisations')}
          />
          <DateTooltipField source="created_on" addTime />
          {hasEdit && <EditButton onClick={openEditPopup} />}
          {hasDelete && <DeleteOpsUserButton condition={(record) => record.id !== currentUserId} />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  )
}

export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateOpsUser()
  const [hasDelete] = useResourcePermissions(props.resource, SYSTEM_PERMISSION_DELETE)

  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<OpsUsersFilters />}
        actions={
          <ListActions>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <OpsUsersListLayout hasEdit={props.hasEdit} hasDelete={hasDelete} />
      </List>
      {createPopupDialog}
    </>
  )
}
