import { FunctionField } from 'react-admin'
import get from 'lodash/get'

export const getVehicleName = (record) => `${get(record, 'brand', '')} ${get(record, 'model', '')}`

const VehicleNameField = (props) => (
  <FunctionField label="resources.vehicles.fields.name" render={getVehicleName} {...props} />
)

VehicleNameField.defaultProps = {
  addLabel: true,
}

export default VehicleNameField
