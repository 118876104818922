import { useEffect, useRef } from 'react'
import { CREATE, required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import { FORM_TYPE_FILTER } from '../common/forms'
import organisationsConfig from '../organisations/config'
import hubsConfig from '../hubs/config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'

const VehicleReferenceInput = ({ source, linkedToHub, formType, disabled, filter, ...props }) => {
  const { change } = useForm()
  const { values } = useFormState()
  const organisationId = values[organisationsConfig.options.referenceKey]
  const hubId = values[hubsConfig.options.referenceKey]

  const isFirstRender = useRef(true)
  useEffect(() => {
    // Skip the first render to prevent resetting the value when organisationId & hubId are undefined
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    // Reset the value when organisation or hub changes
    if (formType === CREATE) {
      change(source, null)
    }
  }, [organisationId, hubId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdvancedReferenceInput
      {...props}
      source={source}
      formType={formType}
      getOptionLabel={config.options.getName}
      disabled={
        formType === FORM_TYPE_FILTER ? false : (linkedToHub ? !Boolean(hubId) : !Boolean(organisationId)) || disabled
      }
      filter={{
        ...config.options.defaultFilterValues,
        organisation_id: organisationId,
        hub_id: linkedToHub ? hubId : null,
        ...filter,
      }}
    />
  )
}

VehicleReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  linkedToHub: true,
  validate: required(),
  disabled: false,
}

export default VehicleReferenceInput
