import { ReferenceField } from 'react-admin'

import damageBlueprintsConfig from '../damageBlueprints/config'
import Blueprint from '../../components/Blueprint'

const MarkerField = (props) => (
  <ReferenceField {...props}>
    <ReferencedMarkerField report={props.record} {...props} />
  </ReferenceField>
)

MarkerField.defaultProps = {
  source: damageBlueprintsConfig.options.referenceKey,
  reference: damageBlueprintsConfig.name,
  addLabel: false,
  link: false,
}

const ReferencedMarkerField = ({ record = {}, ...props }) => (
  <Blueprint blueprint={record} reports={[props.report]} {...props} />
)

export default MarkerField
