import classnames from 'classnames'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AutocompleteInput, Labeled, ReferenceInput, SelectInput, useGetList, useResourceContext } from 'react-admin'

import { COMMON_INPUT_WIDTH } from '../config/theme'
import { FORM_TYPE_FILTER, useHelperText } from '../layouts/common/forms'

const useStyles = makeStyles({
  input: {
    width: (props) => props.width ?? COMMON_INPUT_WIDTH,
  },
  linearProgress: {
    marginTop: (props) => (props.isFormTypeFilter ? 6 : 16),
    marginBottom: (props) => (props.isFormTypeFilter ? 6 : 16),
  },
})

const AdvancedReferenceInput = ({
  excludedIds = [],
  formType,
  getOptionLabel,
  reference,
  sort,
  width,
  perPage = 10,
  emptyText,
  ...props
}) => {
  const { data, total } = useGetList(reference, { pagination: { page: 1, perPage } }, sort, props.filter)
  const helperText = useHelperText(props.source)
  const resource = useResourceContext()

  const isFormTypeFilter = formType === FORM_TYPE_FILTER
  const classes = useStyles({ isFormTypeFilter, width })

  const label = props.label ?? `resources.${resource}.fields.${props.source}`
  const commonParentInputProps = {
    ...props,
    className: classes.input,
    emptyText,
    emptyValue: emptyText,
    initialValue: emptyText,
    label,
  }
  const commonChildInputProps = {
    optionText: getOptionLabel,
    helperText: isFormTypeFilter ? false : props.helperText ?? helperText,
  }

  if (total === undefined) {
    const linearProgress = <LinearProgress classes={{ root: classnames(classes.input, classes.linearProgress) }} />
    return label ? <Labeled label={label}>{linearProgress}</Labeled> : linearProgress
  }

  return total > perPage ? (
    <ReferenceInput
      {...commonParentInputProps}
      reference={reference}
      perPage={perPage}
      sort={sort}
      allowEmpty={false}
      enableGetChoices={(filters) => filters && filters.search_designation?.length >= 1}
      filterToQuery={(searchText) => ({ search_designation: searchText, excluded_ids: excludedIds })}
    >
      <AutocompleteInput {...commonChildInputProps} resettable />
    </ReferenceInput>
  ) : (
    <SelectInput {...commonParentInputProps} {...commonChildInputProps} choices={Object.values(data)} />
  )
}

export default AdvancedReferenceInput
