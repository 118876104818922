import { FORM_TYPE_FILTER } from '../common/forms'

import config from './config'
import HubReferenceInput from './input'

const HubsFilter = (props) => <HubReferenceInput {...props} formType={FORM_TYPE_FILTER} validate={null} width={227} />

HubsFilter.defaultProps = { source: config.options.referenceKey }

export default HubsFilter
