import Icon from '@material-ui/icons/LocalOffer'

import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'

export default {
  name: 'vouchers',
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'voucher_id',
    defaultSort: { field: 'created_on', order: 'DESC' },
    references: [],
    getName: (record) => {
      return `${record?.name} (${record?.code})`
    },
  },
  icon: Icon,
}
