import { isEmpty } from 'lodash'
import { IconButton, Tooltip } from '@material-ui/core'
import ResetIcon from '@material-ui/icons/Replay'
import { makeStyles } from '@material-ui/styles'
import { useCallback, useState } from 'react'
import { SelectInput, TextInput, required, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import { BOOKING_BILLING_TYPE_AUTO, BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPE_PRO } from '../config/bookings'

const useStyles = makeStyles({
  resetButton: {
    width: 26,
    height: 26,
    marginLeft: 6,
    marginTop: 15,
    marginRight: -8,
  },
  resetIcon: {
    width: 20,
    height: 20,
  },
})

const OPTION_CODE_OTHER = 'OTHER'

const BookingJustificationInput = ({ bookingsJustifications, record, ...props }) => {
  const { change } = useForm()
  const classes = useStyles()
  const translate = useTranslate()

  const hasBookingsJustifications = !isEmpty(bookingsJustifications)

  const [optionCode, setOptionCode] = useState(
    Boolean(record.justification) && hasBookingsJustifications
      ? bookingsJustifications.find((j) => j.code === record.justification)?.code ?? OPTION_CODE_OTHER
      : null,
  )

  const onSelectInputChange = useCallback(
    ({ target: { value } }) => {
      setOptionCode(value)
      if (value === OPTION_CODE_OTHER) {
        change(props.source, null)
      }
    },
    [change, props.source],
  )

  const resetInput = useCallback(() => {
    change(props.source, null)
    setOptionCode(null)
  }, [change, props.source])

  const {
    values: { billing_settings: billingSettings, billing_type: billingType },
  } = useFormState()
  const isBillingTypeFree = billingType === BOOKING_BILLING_TYPE_FREE
  const shouldDisplayJustificationInput =
    [BOOKING_BILLING_TYPE_PRO, BOOKING_BILLING_TYPE_AUTO].includes(billingType) ||
    (isBillingTypeFree && billingSettings?.free_billing_type_requires_justification)

  if (!shouldDisplayJustificationInput || !Boolean(billingSettings)) {
    return null
  }

  const requiredErrorMessage = isBillingTypeFree
    ? 'resources.bookings.forms.validation.justification.requiredForFreeBookings'
    : 'resources.bookings.forms.validation.justification.requiredForProAutoBookings'
  const commonProps = { ...props, validate: required(requiredErrorMessage) }

  const bookingsJustificationsChoices = bookingsJustifications
    .sort(
      (a, b) => (a.code === OPTION_CODE_OTHER) - (b.code === OPTION_CODE_OTHER), // put 'OTHER' option at the end
    )
    .map(({ code, label }) => ({ id: code, name: label }))

  return hasBookingsJustifications && optionCode !== OPTION_CODE_OTHER ? (
    <SelectInput
      {...commonProps}
      choices={bookingsJustificationsChoices}
      onChange={onSelectInputChange}
      value={optionCode}
    />
  ) : (
    <>
      <TextInput {...commonProps} />
      {hasBookingsJustifications && optionCode === OPTION_CODE_OTHER && (
        <Tooltip title={translate('mymove.goBackToList')}>
          <IconButton className={classes.resetButton} onClick={resetInput}>
            <ResetIcon className={classes.resetIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default BookingJustificationInput
