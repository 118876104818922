import { CardContent, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import createDecorator from 'final-form-calculate'
import { get, isEmpty, pick } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  ArrayInput,
  BooleanInput,
  CREATE,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useCreateController,
  useDataProvider,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useTranslate } from 'ra-core'

import { useApi } from '../../api/apiProvider'
import AddressInputMain from '../../components/AddressInputMain'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedTextInput from '../../components/AdvancedTextInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import DefaultBillingTypeSelectInput from '../../components/DefaultBillingTypeSelectInput'
import DefinitionList from '../../components/DefinitionList'
import { ADDRESS_FIELD_COUNTRY_CODE } from '../../config/addresses'
import {
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPE_PAID,
  BOOKING_BILLING_TYPES,
  BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET,
  BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD,
} from '../../config/bookings'
import { OPS_USER_ROLE_OWNER } from '../../config/permissions'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import {
  USER_NFC_CARD_TYPES,
  USER_ROLE_USER,
  USER_ROLES,
  USER_SMS_LANGUAGE_EN,
  USER_SMS_LANGUAGES,
  USERS_PERMISSIONS,
} from '../../config/users'
import { hasBillingType, hasMobilityBudgetBillingType, parsePhoneNumber } from '../../utils'
import { useSmallScreen } from '../../utils/theme'
import { validateAddress, validateEmail, validatePhoneNumber, validateUuid, validateVAT } from '../../utils/validators'
import { ACCOUNT_BASE_PATH } from '../account/config'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'

import config from './config'

const useStyles = makeStyles({
  trustedInputContainer: {
    width: 'calc(100% - 320px) !important',
  },
})

const userRoleChoices = Object.entries(USER_ROLES).map(([k, v]) => ({ id: k, name: v }))
const smsLanguageChoices = Object.entries(USER_SMS_LANGUAGES).map(([k, v]) => ({ id: k, name: v }))
const nfcTypesChoices = Object.entries(USER_NFC_CARD_TYPES).map(([k, v]) => ({ id: k, name: v }))

const shouldDisplayAccountAttributesSection = (formData) => {
  const currentUserRestrictedToOrganisationId = formData.identity.restricted_to_organisation_id
  return !Boolean(currentUserRestrictedToOrganisationId)
}

const shouldDisplayCompanyLegalInfoSection = (formData) => {
  const accountHasBillingTypePaid = hasBillingType(formData.account.billing_types, BOOKING_BILLING_TYPE_PAID)
  const userHasBillingOverride = formData.account_billing_override
  const userHasBillingTypePaidInOverride = hasBillingType(
    formData.billing_types_override_data?.split('/'),
    BOOKING_BILLING_TYPE_PAID,
  )
  return (
    (!userHasBillingOverride && accountHasBillingTypePaid) ||
    (userHasBillingOverride && userHasBillingTypePaidInOverride)
  )
}

const shouldDisplayMobilityBudgetSection = (formData) => {
  const accountHasMobilityBudget = hasMobilityBudgetBillingType(formData.account.billing_types)
  const userHasBillingOverride = formData.account_billing_override
  const userHasMobilityBudgetInOverride = hasMobilityBudgetBillingType(formData.billing_types_override_data?.split('/'))
  return (
    formData.identity.role === OPS_USER_ROLE_OWNER &&
    ((!userHasBillingOverride && accountHasMobilityBudget) || userHasMobilityBudgetInOverride)
  )
}

export const transformValues = (values) => {
  const {
    account,
    account_billing_override,
    billing_types_override_data,
    has_company_legal_info,
    has_mobility_budget_override,
    identity,
    nfc_cards,
    send_sms,
    sms_locale,
    ...restValues
  } = values
  return restValues
    ? {
        ...restValues,
        phone_number: restValues.phone_number || null,
        send_sms: restValues.phone_number ? send_sms : false,
        sms_locale: restValues.phone_number && send_sms ? sms_locale : null,
        billing_types_override:
          shouldDisplayAccountAttributesSection(values) &&
          billing_types_override_data &&
          billing_types_override_data !== 'null'
            ? billing_types_override_data.split('/')
            : null,
        requires_payment_override: shouldDisplayAccountAttributesSection(values)
          ? restValues.requires_payment_override
          : null,
        default_billing_type_override:
          shouldDisplayAccountAttributesSection(values) && restValues.default_billing_type_override !== 'null'
            ? restValues.default_billing_type_override
            : null,
        free_billing_type_requires_justification_override: shouldDisplayAccountAttributesSection(values)
          ? restValues.free_billing_type_requires_justification_override
          : null,
        company_legal_info:
          shouldDisplayCompanyLegalInfoSection(values) && has_company_legal_info ? restValues.company_legal_info : null,
        mbrella_id: shouldDisplayMobilityBudgetSection(values) ? restValues.mbrella_id : null,
        skipr_id: shouldDisplayMobilityBudgetSection(values) ? restValues.skipr_id : null,
        nfc_cards: nfc_cards
          ? nfc_cards.map((card) => ({ ...card, card_id: card.card_id.replace(/[^a-zA-Z0-9]/g, '') }))
          : [],
      }
    : restValues
}

const createFormDecorator = (account) => {
  const updater = (value, field, values) => {
    if (values.account_billing_override) {
      const shouldResetToAccount = field === 'account_billing_override' && account
      const shouldResetToFirstChoice = field === 'has_mobility_budget_override'
      let hasMobilityBudgetOverride
      let requiresPaymentOverride
      let billingTypesOverrideData
      let defaultBillingTypeOverride
      let freeBillingTypeRequiresJustificationOverride

      if (shouldResetToAccount) {
        hasMobilityBudgetOverride = hasMobilityBudgetBillingType(account.billing_types)
        requiresPaymentOverride = account.requires_payment
        const billingTypesOverride = Object.keys(BOOKING_BILLING_TYPES).filter((type) =>
          account.billing_types.includes(type),
        )
        billingTypesOverrideData = billingTypesOverride.join('/')
        defaultBillingTypeOverride = account.default_billing_type
        freeBillingTypeRequiresJustificationOverride = account.free_billing_type_requires_justification
      } else {
        hasMobilityBudgetOverride = values.has_mobility_budget_override
        requiresPaymentOverride = values.requires_payment_override !== false
        const billingTypesOverrideChoices = hasMobilityBudgetOverride
          ? BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET
          : BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD
        billingTypesOverrideData = shouldResetToFirstChoice
          ? billingTypesOverrideChoices[0].id
          : values.billing_types_override_data
        const billingTypesOverride = billingTypesOverrideData.split('/')
        defaultBillingTypeOverride =
          shouldResetToFirstChoice || !billingTypesOverride.includes(values.default_billing_type_override)
            ? billingTypesOverride[0]
            : values.default_billing_type_override
        freeBillingTypeRequiresJustificationOverride =
          values.free_billing_type_requires_justification_override !== false
      }

      return {
        has_mobility_budget_override: hasMobilityBudgetOverride,
        requires_payment_override: requiresPaymentOverride,
        billing_types_override_data: billingTypesOverrideData,
        default_billing_type_override: defaultBillingTypeOverride,
        free_billing_type_requires_justification_override: freeBillingTypeRequiresJustificationOverride,
      }
    } else {
      return {
        requires_payment_override: null,
        billing_types_override_data: 'null',
        default_billing_type_override: 'null',
        free_billing_type_requires_justification_override: null,
      }
    }
  }

  return createDecorator(
    {
      field: 'account_billing_override',
      updates: updater,
    },
    {
      field: 'has_mobility_budget_override',
      updates: updater,
    },
    {
      field: 'billing_types_override_data',
      updates: updater,
    },
  )
}

const SendSmsSwitch = () => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const { change } = useForm()
  const { values } = useFormState()

  useEffect(() => {
    if (!Boolean(values.phone_number)) {
      change('send_sms', false)
      change('sms_locale', null)
    }
  }, [Boolean(values.phone_number)]) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggle = (event) => {
    change('send_sms', event.target.checked)
    if (event.target.checked) {
      change('sms_locale', USER_SMS_LANGUAGE_EN)
    } else {
      change('sms_locale', null)
    }
  }

  return (
    <AdvancedBooleanInput
      source="send_sms"
      disabled={!Boolean(values.phone_number)}
      helperText={Boolean(values.phone_number) ? null : undefined}
      className={isSmallScreen ? null : values.send_sms ? commonClasses.commonInput : commonClasses.doubleInput}
      options={{ onChange: onToggle }}
    />
  )
}

const UserFormLayout = ({ type = UPDATE, onClose, ...props }) => {
  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  const [userDetails, setUserDetails] = useState({})
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()
  const translate = useTranslate()

  useEffect(() => fetchAccount(), [fetchAccount])

  useEffect(() => {
    if (!Boolean(props.record.id)) return
    const timeout = setTimeout(() => {
      dataProvider
        .getOne('users/details', { id: props.record.id })
        .then(({ data }) => {
          if (data) setUserDetails(data)
        })
        .catch(() => onClose && onClose())
    }, 50)
    return () => clearTimeout(timeout)
  }, [props.record.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const title = `resources.users.forms.${type.toLowerCase()}.title`
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = {
    ...pick(formProps.record, [
      'id',
      'first_name',
      'last_name',
      'email',
      'monitoring_email',
      'phone_number',
      'address',
      'is_trusted',
    ]),
    ...pick(userDetails, [
      'role',
      'requires_payment_override',
      'billing_types_override',
      'default_billing_type_override',
      'free_billing_type_requires_justification_override',
      'company_legal_info',
      'mbrella_id',
      'skipr_id',
    ]),
  }
  formProps.record.nfc_cards = get(userDetails, 'nfc_cards', [])

  const initialValues = useMemo(() => {
    let values = {}
    // We inject account & identity info in the form because we need it in "shouldDisplay" functions (also used in transformValues func)
    values.account = account
    values.identity = identity
    if (type === CREATE) {
      values.role = USER_ROLE_USER
      values.send_sms = false
      values.account_billing_override = false
      values.requires_payment_override = null
      values.billing_types_override_data = 'null'
      values.default_billing_type_override = null
      values.free_billing_type_requires_justification_override = null
      values.has_company_legal_info = false
      values.company_legal_info = null
      values.monitoring_email = null
    } else {
      values.account_billing_override = formProps.record.billing_types_override !== null
      values.billing_types_override_data = formProps.record.billing_types_override
        ? Object.keys(BOOKING_BILLING_TYPES)
            .filter((type) => formProps.record.billing_types_override.includes(type))
            .join('/')
        : 'null'
      values.has_mobility_budget_override = hasMobilityBudgetBillingType(values.billing_types_override_data.split('/'))
      values.has_company_legal_info = formProps.record.company_legal_info !== null
    }
    return values
  }, [account?.id, identity?.id, userDetails?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const formDecorator = useMemo(() => createFormDecorator(account), [account?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return account && identity && (type === CREATE || (type === UPDATE && !isEmpty(userDetails))) ? (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        decorators={[formDecorator]}
        variant="outlined"
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <TextInput source="first_name" validate={required()} />
        <TextInput source="last_name" validate={required()} />
        <TextInput source="email" validate={validateEmail()} type="email" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const countryCode = formData.address?.[ADDRESS_FIELD_COUNTRY_CODE]
            return (
              <TextInput
                {...rest}
                type="tel"
                source="phone_number"
                parse={(value) => parsePhoneNumber(value, countryCode) || value}
                validate={validatePhoneNumber({ countryCode })}
                helperText={
                  formData.phone_number?.charAt(0) === '+' ? null : 'resources.users.forms.helperTexts.phone_number'
                }
              />
            )
          }}
        </FormDataConsumer>
        <AddressInputMain source="address" validate={validateAddress(false)} />
        <FormDivider />
        <SelectInput source="role" choices={userRoleChoices} optionText="name" optionValue="id" validate={required()} />
        <AdvancedSelectInput source="is_trusted" formClassName={isSmallScreen ? null : classes.trustedInputContainer} />
        <DefinitionList items={USERS_PERMISSIONS} />

        {type === CREATE && [
          <FormDivider key="divider" />,
          <SendSmsSwitch key="send_sms" />,
          <FormDataConsumer key="sms_locale">
            {({ formData, ...rest }) =>
              Boolean(formData.send_sms) && (
                <SelectInput
                  {...rest}
                  source="sms_locale"
                  choices={smsLanguageChoices}
                  optionText="name"
                  optionValue="id"
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>,
        ]}

        <FormDivider condition={(formData) => shouldDisplayAccountAttributesSection(formData)} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) && (
              <BooleanInput
                {...rest}
                source="account_billing_override"
                className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
                helperText={translate('resources.users.forms.helperTexts.account_billing_override', {
                  billingTypes: account.billing_types
                    .map((t) => translate(BOOKING_BILLING_TYPES[t]) + (t === account.default_billing_type ? '*' : ''))
                    .join('/'),
                  paymentMethodRequired: translate(`ra.boolean.${account.requires_payment}`),
                })}
              />
            )
          }
        </FormDataConsumer>
        <FormDivider
          condition={(formData) => shouldDisplayAccountAttributesSection(formData) && formData.account_billing_override}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) &&
            formData.account_billing_override && <AdvancedSelectInput {...rest} source="has_mobility_budget_override" />
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) &&
            formData.account_billing_override && <AdvancedSelectInput {...rest} source="requires_payment_override" />
          }
        </FormDataConsumer>
        <FormDivider
          condition={(formData) => shouldDisplayAccountAttributesSection(formData) && formData.account_billing_override}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) &&
            formData.account_billing_override && (
              <RadioButtonGroupInput
                {...rest}
                row={false}
                source="billing_types_override_data"
                validate={required()}
                helperText={false}
                choices={
                  formData.has_mobility_budget_override
                    ? BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET
                    : BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD
                }
                style={{ width: COMMON_INPUT_WIDTH, marginTop: -3 }}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) &&
            formData.account_billing_override && (
              <DefaultBillingTypeSelectInput
                {...rest}
                source="default_billing_type_override"
                billingTypesData={formData.billing_types_override_data}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) &&
            formData.account_billing_override &&
            hasBillingType(formData.billing_types_override_data.split('/'), BOOKING_BILLING_TYPE_FREE) && (
              <AdvancedSelectInput {...rest} source="free_billing_type_requires_justification_override" />
            )
          }
        </FormDataConsumer>
        <FormDivider condition={(formData) => shouldDisplayCompanyLegalInfoSection(formData)} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayCompanyLegalInfoSection(formData) && (
              <AdvancedBooleanInput
                {...rest}
                source="has_company_legal_info"
                className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
              />
            )
          }
        </FormDataConsumer>
        <FormDivider
          condition={(formData) => shouldDisplayCompanyLegalInfoSection(formData) && formData.has_company_legal_info}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayCompanyLegalInfoSection(formData) &&
            formData.has_company_legal_info && (
              <TextInput {...rest} source="company_legal_info.name" validate={required()} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayCompanyLegalInfoSection(formData) &&
            formData.has_company_legal_info && (
              <TextInput {...rest} source="company_legal_info.vat_number" validate={validateVAT()} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayCompanyLegalInfoSection(formData) &&
            formData.has_company_legal_info && (
              <AddressInputMain {...rest} source="company_legal_info.address" validate={validateAddress()} />
            )
          }
        </FormDataConsumer>
        <FormDivider condition={(formData) => shouldDisplayMobilityBudgetSection(formData)} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayMobilityBudgetSection(formData) && (
              <AdvancedTextInput
                {...rest}
                source="mbrella_id"
                validate={validateUuid(translate('resources.users.fields.mbrella_id'))}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayMobilityBudgetSection(formData) && (
              <AdvancedTextInput
                {...rest}
                source="skipr_id"
                validate={validateUuid(translate('resources.users.fields.skipr_id'))}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider condition={(formData) => shouldDisplayAccountAttributesSection(formData)} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            shouldDisplayAccountAttributesSection(formData) && (
              <AdvancedTextInput {...rest} source="monitoring_email" validate={validateEmail(false)} type="email" />
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <ArrayInput
          source="nfc_cards"
          label="resources.users.fields.nfc_cards.name"
          className={commonClasses.noMarginTop}
        >
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
            classes={{
              index: commonClasses.displayNone,
              form: commonClasses.flexForm,
              line: classnames(commonClasses.marginTopOnFirstChild, commonClasses.noMarginBottomOnPenultimateChild),
            }}
          >
            <SelectInput
              source="nfc_type"
              label="resources.users.fields.nfc_cards.nfc_type"
              choices={nfcTypesChoices}
              optionText="name"
              optionValue="id"
              validate={required()}
            />
            <TextInput source="card_id" label="resources.users.fields.nfc_cards.card_id" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </>
  ) : null
}

const UserFormLayoutController = ({ basePath = '/' + config.name, resource = config.name, initialValues, onClose }) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
  }

  return <UserFormLayout {...controllerProps} />
}

export const EditUserFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <UserFormLayoutController {...props} /> : <div />

export const CreateUserFormLayoutController = (props) => <UserFormLayoutController {...props} />

export const useCreateUser = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = () => {
    // We pick nothing here to avoid errors with user name filter
    setCreatePopupState({ isOpen: true, values: {} })
  }
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateUserFormLayoutController initialValues={createPopupState.values} onClose={handleCreatePopupClose} />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditUser = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditUserFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default UserFormLayout
