import { Typography } from '@material-ui/core'
import classnames from 'classnames'
import countries from 'i18n-iso-countries'
import { get } from 'lodash'
import { FormDataConsumer, TextInput, required } from 'react-admin'

import { usePayoutFormStyles } from '../styles'
import AddressInputPayout from '../../../components/AddressInputPayout'
import { useCommonStyles } from '../../../config/theme'
import { parsePhoneNumber } from '../../../utils'
import { useSmallScreen } from '../../../utils/theme'
import { validateAddress, validateEmail, validatePhoneNumber, validateVAT } from '../../../utils/validators'

const CompanyInfoForm = ({ submitting }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const payoutFormClasses = usePayoutFormStyles()

  return (
    <>
      <Typography
        className={classnames(isSmallScreen ? null : commonClasses.doubleInput, payoutFormClasses.formExplanation)}
      >
        We are required to collect the following information about your legal entity to comply with anti-terrorism laws,
        financial services regulations, and so on.
      </Typography>
      <TextInput
        className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
        variant="outlined"
        source="legalEntity.legalName"
        label="Legal business name"
        validate={required()}
        disabled={submitting}
      />
      <TextInput
        className={commonClasses.commonInput}
        variant="outlined"
        source="legalEntity.registrationNumber"
        label="Registration number"
        validate={required()}
        disabled={submitting}
      />
      <TextInput
        className={commonClasses.commonInput}
        variant="outlined"
        source="legalEntity.taxNumber"
        label="VAT number"
        validate={validateVAT()}
        disabled={submitting}
      />
      <AddressInputPayout
        variant="outlined"
        source="legalEntity.address"
        // Address label is spelled with special "d" look-alikes to disable Chrome auto-complete
        label="Aԁԁress"
        validate={validateAddress()}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const countryAlpha2Code = countries.alpha3ToAlpha2(get(formData, 'legalEntity.address.country'))
          const acceptedPhoneNumberTypes = ['MOBILE', 'FIXED_LINE', 'FIXED_LINE_OR_MOBILE']
          return (
            <TextInput
              {...rest}
              className={commonClasses.commonInput}
              type="tel"
              variant="outlined"
              source="legalEntity.phone"
              label="Phone number"
              validate={validatePhoneNumber({
                countryCode: countryAlpha2Code,
                acceptedTypes: acceptedPhoneNumberTypes,
                isRequired: true,
              })}
              disabled={submitting}
              parse={(value) => parsePhoneNumber(value, countryAlpha2Code, acceptedPhoneNumberTypes) || value}
              helperText="Please provide number with prefix"
            />
          )
        }}
      </FormDataConsumer>
      <TextInput
        className={commonClasses.commonInput}
        variant="outlined"
        source="legalEntity.email"
        label="Email address"
        validate={validateEmail()}
        disabled={submitting}
        helper="Email address where to send the seller invoices"
        type="email"
      />
    </>
  )
}

export default CompanyInfoForm
