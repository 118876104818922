import { IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import { useEffect } from 'react'
import { required, TextInput, useGetOne } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useTranslate } from 'ra-core'

import organisationsConfig from '../organisations/config'
import usersConfig from '../users/config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPE_PERSONAL } from '../../config/vouchers'
import { getRandomString } from '../../utils'

import config from './config'

export const VoucherCodeInput = ({ source = 'code', disabled, ...props }) => {
  const { change } = useForm()
  const commonClasses = useCommonStyles()
  const translate = useTranslate()

  return (
    <TextInput
      {...props}
      source={source}
      disabled={disabled}
      onChange={(event) => change(source, event.target.value.replace(' ', '').toUpperCase())}
      options={{
        InputProps: {
          endAdornment: disabled ? null : (
            <InputAdornment position="end">
              <Tooltip title={translate('resources.vouchers.forms.tooltips.generateNewCode')}>
                <IconButton
                  className={commonClasses.contrastColor}
                  onClick={() => {
                    change(source, getRandomString())
                  }}
                  size="small"
                  onMouseDown={(event) => {
                    event.preventDefault()
                  }}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        },
      }}
    />
  )
}

const VoucherReferenceInput = ({ source, ...props }) => {
  const { change } = useForm()
  const { values } = useFormState()

  const organisationId = values[organisationsConfig.options.referenceKey]
  const userId = values[usersConfig.options.referenceKey]
  const voucherId = values[config.options.referenceKey]
  const { data: voucher } = useGetOne(config.name, voucherId, { enabled: Boolean(voucherId) })

  // We reset the voucher only when the type is personal and the newly selected org is different from the one linked to the voucher
  useEffect(() => {
    if (!voucher) return
    if (voucher.type === VOUCHER_TYPE_PERSONAL && organisationId !== voucher.organisation_id) {
      change(source, null)
    }
  }, [change, source, organisationId]) // eslint-disable-line react-hooks/exhaustive-deps

  // We reset the voucher only when the type is personal and the newly selected user is different from the one linked to the voucher
  useEffect(() => {
    if (!voucher) return
    if (voucher.type === VOUCHER_TYPE_PERSONAL && Boolean(userId) && userId !== voucher.user_id) {
      change(source, null)
    }
  }, [change, source, userId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdvancedReferenceInput
      {...props}
      source={source}
      getOptionLabel={config.options.getName}
      filter={{ active: true, organisation_id: organisationId ?? null, user_id: userId ?? null }}
    />
  )
}

VoucherReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  validate: required(),
}

export default VoucherReferenceInput
