import { useTranslate } from 'ra-core'

import { Title } from '../common/show'
import GenericCreateForm from '../common/create'

import BookingTransactionFormLayout, { transformValues } from './form'
import config from './config'

export default (props) => {
  const translate = useTranslate()
  return (
    <GenericCreateForm
      {...props}
      titleComponent={
        <Title resourceConfig={config}>{translate('resources.booking-transactions.forms.create.pageTitle')}</Title>
      }
      formLayoutComponent={<BookingTransactionFormLayout />}
      transformValues={transformValues}
    />
  )
}
