import { ShowReference } from '../layouts/common/show'

import UnitField from './UnitField'

const VehicleMileageField = (props) => (
  <ShowReference reference="vehicles/details" source="id" {...props}>
    {(vehicleDetailsProps) => <UnitField record={vehicleDetailsProps.record} source="mileage" />}
  </ShowReference>
)

VehicleMileageField.defaultProps = {
  addLabel: true,
  label: 'resources.vehicles.fields.mileage',
}

export default VehicleMileageField
