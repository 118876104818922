import classnames from 'classnames'
import ArrowIcon from '@material-ui/icons/ArrowForward'

import { useCommonStyles } from '../config/theme'

import DateTooltipField from './DateTooltipField'

const PeriodField = ({ startedOnSource, endedOnSource, label, addTime, record, ...props }) => {
  const classes = useCommonStyles()

  return (
    <span className={classnames(classes.textWithSmallIcons, props.className)}>
      <DateTooltipField {...props} record={record} source={startedOnSource} addTime={addTime} />
      <ArrowIcon />
      <DateTooltipField {...props} record={record} source={endedOnSource} addTime={addTime} />
    </span>
  )
}

PeriodField.defaultProps = {
  addLabel: true,
  startedOnSource: 'startedOn',
  endedOnSource: 'endedOn',
  label: 'resources.common.fields.period',
}

export default PeriodField
