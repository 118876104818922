import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import { get, pick } from 'lodash'
import { useRef, useState } from 'react'
import {
  CREATE,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { useCommonStyles } from '../../config/theme'
import {
  VOUCHER_DISCOUNT_TYPE_MINUTE,
  VOUCHER_DISCOUNT_TYPE_PERCENTAGE,
  VOUCHER_DISCOUNT_TYPES,
  VOUCHER_TYPE_CAMPAIGN,
  VOUCHER_TYPE_PERSONAL,
  VOUCHER_TYPES,
} from '../../config/vouchers'
import { getRoundedNow, parseDateAsDateTime } from '../../utils/dates'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import OrganisationReferenceInput from '../organisations/input'
import UserReferenceInput from '../users/input'

import config from './config'
import { VoucherCodeInput } from './input'

const voucherTypeChoices = Object.entries(VOUCHER_TYPES).map(([k, v]) => ({ id: k, name: v }))
const voucherDiscountTypeChoices = Object.entries(VOUCHER_DISCOUNT_TYPES).map(([k, v]) => ({ id: k, name: v }))

const SEVEN_DAYS_HOURS = 24 * 7

const discountValueConfig = {
  [VOUCHER_DISCOUNT_TYPE_PERCENTAGE]: {
    minValue: 1,
    maxValue: 100,
    unit: '%',
    validate: [
      required(),
      number('mymove.validation.number.invalid'),
      minValue(1, 'resources.vouchers.forms.validation.discount_value.notInPercentageRange'),
      maxValue(100, 'resources.vouchers.forms.validation.discount_value.notInPercentageRange'),
    ],
  },
  [VOUCHER_DISCOUNT_TYPE_MINUTE]: {
    minValue: 1,
    maxValue: SEVEN_DAYS_HOURS,
    unit: 'mymove.units.time.hours',
    validate: [
      required(),
      number('mymove.validation.number.invalid'),
      minValue(1, 'resources.vouchers.forms.validation.discount_value.minTime'),
      maxValue(SEVEN_DAYS_HOURS, 'resources.vouchers.forms.validation.discount_value.maxTime'),
    ],
  },
}

const validate = (values) => {
  const errors = {}
  // discount_value
  const validateDiscountValue = discountValueConfig[values.discount_type].validate
  for (const validator of validateDiscountValue) {
    const err = validator(values.discount_value)
    if (err) {
      errors.discount_value = err
      break
    }
  }
  return errors
}

export const transformValues = (values) => {
  return {
    ...values,
    user_id: values.type === VOUCHER_TYPE_PERSONAL ? values.user_id : null,
    organisation_id: values.type === VOUCHER_TYPE_PERSONAL ? values.organisation_id : null,
    discount_value:
      values.discount_type === VOUCHER_DISCOUNT_TYPE_MINUTE ? values.discount_value * 60 : values.discount_value,
  }
}

const VoucherFormLayout = ({ type = UPDATE, onClose, ...props }) => {
  const { hasSingleOrganisation, singleOrganisationId } = useCurrentAccountSelectors()

  const now = useRef(getRoundedNow())
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const translate = useTranslate()

  const title = `resources.vouchers.forms.${type.toLowerCase()}.title`

  const { identity } = useGetIdentity()
  if (!identity) return null
  const currentUserRestrictedToOrganisationId = identity.restricted_to_organisation_id

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'code',
    'name',
    'type',
    'discount_type',
    'discount_value',
    'start_date',
    'end_date',
    'user_id',
    'organisation_id',
    'number_of_uses',
  ])

  let initialValues = {}
  if (type === CREATE) {
    initialValues.start_date = now.current
    initialValues.type = currentUserRestrictedToOrganisationId ? VOUCHER_TYPE_PERSONAL : VOUCHER_TYPE_CAMPAIGN
    initialValues.organisation_id =
      currentUserRestrictedToOrganisationId || (hasSingleOrganisation ? singleOrganisationId : null)
    initialValues.discount_type = VOUCHER_DISCOUNT_TYPE_PERCENTAGE
  } else {
    formProps.record.discount_value =
      formProps.record.discount_type === VOUCHER_DISCOUNT_TYPE_MINUTE
        ? formProps.record.discount_value / 60
        : formProps.record.discount_value
  }

  const hasBeenUsed = formProps.record.number_of_uses > 0

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        validate={validate}
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <VoucherCodeInput validate={required()} disabled={type === UPDATE && hasBeenUsed} />

        <TextInput source="name" validate={required()} />

        <FormDivider />

        <SelectInput
          source="type"
          choices={voucherTypeChoices}
          optionText="name"
          optionValue="id"
          validate={required()}
          disabled={!!currentUserRestrictedToOrganisationId || type === UPDATE}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === VOUCHER_TYPE_PERSONAL && <UserReferenceInput {...rest} disabled={type === UPDATE} />
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === VOUCHER_TYPE_PERSONAL && (
              <OrganisationReferenceInput
                {...rest}
                disabled={!!currentUserRestrictedToOrganisationId || type === UPDATE}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <SelectInput
          source="discount_type"
          choices={voucherDiscountTypeChoices}
          optionText="name"
          optionValue="id"
          validate={required()}
          disabled={type === UPDATE && hasBeenUsed}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const config = discountValueConfig[formData.discount_type]
            return (
              <NumberInput
                {...rest}
                source="discount_value"
                disabled={type === UPDATE && hasBeenUsed}
                required
                min={config.minValue}
                max={config.maxValue}
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {translate(config.unit, get(formData, 'discount_value') || 1)}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )
          }}
        </FormDataConsumer>

        <FormDivider />

        <AdvancedDateTimeInput
          source="start_date"
          validate={required()}
          disabled={
            type === UPDATE &&
            (hasBeenUsed ||
              parseDateAsDateTime(formProps.record.start_date) < parseDateAsDateTime(Date.now()).minus({ minutes: 5 }))
          }
          required
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AdvancedDateTimeInput
              {...rest}
              source="end_date"
              minDate={formData.start_date ? parseDateAsDateTime(formData.start_date) : undefined}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </>
  )
}

const VoucherFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
  }

  return <VoucherFormLayout {...controllerProps} />
}

export const EditVoucherFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <VoucherFormLayoutController {...props} /> : <div />

export const CreateVoucherFormLayoutController = (props) => <VoucherFormLayoutController {...props} />

export const useCreateVoucher = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = () => {
    setCreatePopupState({ isOpen: true, values: {} })
  }
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateVoucherFormLayoutController initialValues={createPopupState.values} onClose={handleCreatePopupClose} />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditVoucher = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditVoucherFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default VoucherFormLayout
