import { Typography } from '@material-ui/core'
import jsonExport from 'jsonexport/dist'
import { Datagrid, downloadCSV, Filter, List, ReferenceField, useGetIdentity } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import UserFullNameField, { getUserFullName } from '../../components/UserFullNameField'
import VehicleIconField from '../../components/VehicleIconField'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { OPS_USER_ROLE_LEASING_COMPANY, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { formatDateTimeForExport } from '../../utils/dates'
import { useCurrentAccountSelectors } from '../account/hooks'
import { EditButton } from '../common/buttons'
import { GenericListLayout, useListStyles } from '../common/list'
import HubField from '../hubs/field'
import HubsFilter from '../hubs/filter'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'
import usersConfig from '../users/config'
import vehiclesConfig from '../vehicles/config'
import VehicleField from '../vehicles/field'
import VehiclesFilter from '../vehicles/filter'

import config from './config'
import MarkerField from './field'
import { DamageReportsStatusFilter } from './filter'
import { useEditDamageReport } from './form'

const DamageReportsFilters = (props) => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const listClasses = useListStyles()
  return (
    <Filter variant="outlined" classes={{ form: listClasses.filterForm }} {...props}>
      {!hasSingleOrganisation && <OrganisationsFilter alwaysOn />}
      {((!hasSingleOrganisation && props.filterValues.organisation_id) || (hasSingleOrganisation && !hasSingleHub)) && (
        <HubsFilter alwaysOn />
      )}
      <VehiclesFilter alwaysOn />
      <DamageReportsStatusFilter alwaysOn />
    </Filter>
  )
}

const damageReportsExporter = (records, fetchRelatedRecords) => {
  let damageReports = []
  fetchRelatedRecords(records, 'organisation_id', 'organisations')
    .then((organisations) => {
      damageReports = records.map((record) =>
        record.organisation_id && organisations[record.organisation_id]
          ? {
              ...record,
              organisation_name: organisations[record.organisation_id].name,
            }
          : record,
      )
    })
    .then(() =>
      fetchRelatedRecords(records, 'hub_id', 'hubs').then((hubs) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.hub_id && hubs[damageReport.hub_id]
            ? { ...damageReport, hub_name: hubs[damageReport.hub_id].name }
            : damageReport,
        )
      }),
    )
    .then(() =>
      fetchRelatedRecords(records, 'vehicle_id', 'vehicles').then((vehicles) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.vehicle_id && vehicles[damageReport.vehicle_id]
            ? {
                ...damageReport,
                vehicle_plate: vehicles[damageReport.vehicle_id].designation,
              }
            : damageReport,
        )
      }),
    )
    .then(() =>
      fetchRelatedRecords(records, 'reporter_id', 'users').then((users) => {
        damageReports = damageReports.map((damageReport) =>
          damageReport.reporter_id && users[damageReport.reporter_id]
            ? {
                ...damageReport,
                reporter_user: `${users[damageReport.reporter_id].first_name} ${
                  users[damageReport.reporter_id].last_name
                }`,
              }
            : damageReport,
        )
      }),
    )
    .then(() => {
      const damageReportsForExport = damageReports.map((damageReport) => {
        // Remove non-used fields
        const { organisation_id, hub_id, vehicle_id, reporter_id, created_on, updated_on, ...damageReportForExport } =
          damageReport
        // Add new fields (date formatting & header change)
        damageReportForExport.creation_date = formatDateTimeForExport(created_on)
        damageReportForExport.last_update_date = formatDateTimeForExport(updated_on)
        return damageReportForExport
      })
      jsonExport(
        damageReportsForExport,
        {
          headers: ['id', 'organisation_name', 'hub_name', 'vehicle_plate', 'reporter_user'], // order fields in the export
        },
        (err, csv) => {
          downloadCSV(csv, 'damage_reports')
        },
      )
    })
}

export const DamageReportsListLayout = ({ excluded = [], hasEdit, ...props }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const [openEditPopup, editPopupDialog] = useEditDamageReport()
  const translate = useTranslate()

  const commonClasses = useCommonStyles()
  const listClasses = useListStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          alignItems="flex-start"
          linkType="show"
          icon={
            <ReferenceValue reference="vehicles" source="vehicle_id" target={(v) => <VehicleIconField record={v} />} />
          }
          title={(record) => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <ReferenceValue record={record} reference="organisations" source="organisation_id" target="name" />
              </Typography>
              {isAllowed(excluded, 'vehicles') && (
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                  {' • '}
                  <ReferenceValue
                    record={record}
                    reference="vehicles"
                    source="vehicle_id"
                    target={vehiclesConfig.options.getName}
                  />
                </Typography>
              )}
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
              <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" fallback="n/a" />
              {hasReadForUsers && (
                <>
                  <br />
                  <StaticText>{'resources.damage-reports.fields.reporter_id'} </StaticText>
                  <ReferenceValue
                    record={record}
                    reference="users"
                    source="reporter_id"
                    target={(u) => getUserFullName(u)}
                  />
                </>
              )}
              <br />
              <StaticText>{'resources.damage-reports.fields.category'}: </StaticText>
              <AdvancedTextField
                record={record}
                source="category"
                fallback="resources.damage-reports.enums.category.none"
                map={DAMAGES_CATEGORIES}
              />
              <br />
              <StaticText>{'resources.damage-reports.fields.status'}: </StaticText>
              <AdvancedTextField record={record} source="status" map={DAMAGES_STATUSES} />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show">
          {isAllowed(excluded, 'organisations') && !hasSingleOrganisation && <OrganisationField sortable={false} />}
          {isAllowed(excluded, 'hubs') && !hasSingleHub && <HubField sortable={false} />}
          {isAllowed(excluded, 'vehicles') && <VehicleField />}
          <AdvancedTextField
            source="category"
            fallback="resources.damage-reports.enums.category.none"
            map={DAMAGES_CATEGORIES}
          />
          <MarkerField containerWidth={200} markerSize={16} />
          <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
          {hasReadForUsers && (
            <ReferenceField source="reporter_id" reference="users" link="show">
              <UserFullNameField />
            </ReferenceField>
          )}
          <DateTooltipField source="created_on" addTime />
          {hasEdit && <EditButton onClick={openEditPopup} />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  )
}

export default (props) => {
  const { identity } = useGetIdentity()
  const translate = useTranslate()
  return (
    <List
      title={translate('resources.damage-reports.name', 2)}
      sort={config.options.defaultSort}
      filters={<DamageReportsFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={identity?.role === OPS_USER_ROLE_LEASING_COMPANY ? false : damageReportsExporter}
      {...props}
    >
      <DamageReportsListLayout hasEdit={props.hasEdit} />
    </List>
  )
}
