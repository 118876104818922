import { get, isEmpty } from 'lodash'
import { useEffect } from 'react'
import { required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import { useCurrentAccountSelectors } from '../account/hooks'
import organisationsConfig from '../organisations/config'
import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'
import { useGetHubsListForOrganisation } from './hooks'

const HubReferenceInput = ({ source, organisationSource, disabled, ...props }) => {
  const { hasSingleOrganisation } = useCurrentAccountSelectors()
  const { change } = useForm()
  const { values } = useFormState()
  const hubId = get(values, source)
  const organisationId = get(values, organisationSource)
  const { ids: hubsIds, total: numberOfHubs } = useGetHubsListForOrganisation(organisationId)

  useEffect(() => {
    if (!disabled) {
      // Auto-fill with first hub if only one
      if (!!numberOfHubs && numberOfHubs === 1) {
        change(source, hubsIds[0])
        return
      }
      // Reset hub when org is reset or when hub doesn't belong to newly selected org
      if (!Boolean(organisationId) || (!isEmpty(hubsIds) && Boolean(hubId) && !hubsIds.includes(hubId))) {
        change(source, null)
        return
      }
    }
  }, [change, disabled, JSON.stringify(hubsIds), source]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdvancedReferenceInput
      source={source}
      disabled={(!hasSingleOrganisation && !Boolean(organisationId)) || disabled}
      filter={{ organisation_id: organisationId }}
      {...props}
    />
  )
}

HubReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  organisationSource: organisationsConfig.options.referenceKey,
  validate: required(),
  disabled: false,
}

export default HubReferenceInput
