import classnames from 'classnames'
import { Datagrid, Filter, List, NumberField, ReferenceField, SimpleShowLayout } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import UserFullNameField from '../../components/UserFullNameField'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPES } from '../../config/vouchers'
import { isVoucherExpired } from '../../domain/vouchers'
import { CreateButton, EditButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'
import { useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'

import { useCreateVoucher, useEditVoucher } from './form'
import { VoucherTypeFilter } from './filter'
import { DiscountField } from './field'
import config from './config'

const VouchersFilters = (props) => (
  <Filter variant="outlined" {...props}>
    <VoucherTypeFilter alwaysOn />
  </Filter>
)

const itemStyle = (record) => {
  const isExpired = isVoucherExpired(record)
  return {
    opacity: isExpired ? 0.4 : 1,
  }
}

const VoucherPanel = (props) => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()

  return (
    <>
      <SimpleShowLayout {...props} className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
        <NumberField source="number_of_entries" />
        <NumberField source="number_of_uses" />
        <ReferenceField source="user_id" reference="users" link="show" emptyText="n/a">
          <UserFullNameField />
        </ReferenceField>
        <OrganisationField emptyText="n/a" />
      </SimpleShowLayout>
    </>
  )
}

export const VouchersListLayout = ({ hasEdit, ...props }) => {
  const listClasses = useListStyles()

  const [openEditPopup, editPopupDialog] = useEditVoucher()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          linkType={null}
          itemStyle={itemStyle}
          icon={<config.icon />}
          title={config.options.getName}
          body={(record) => (
            <>
              <AdvancedTextField record={record} source="type" map={VOUCHER_TYPES} />
              {' • '}
              <DiscountField record={record} />
              <br />
              <PeriodField record={record} startedOnSource="start_date" endedOnSource="end_date" addTime />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid
          {...props}
          classes={{ row: listClasses.row }}
          rowClick="expand"
          rowStyle={itemStyle}
          expand={<VoucherPanel />}
        >
          <AdvancedTextField source="code" />
          <AdvancedTextField source="name" />
          <AdvancedTextField source="type" map={VOUCHER_TYPES} />
          <DiscountField />
          <DateTooltipField source="start_date" addTime />
          <DateTooltipField source="end_date" addTime />
          <DateTooltipField source="created_on" addTime />
          {hasEdit && <EditButton onClick={openEditPopup} />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  )
}
export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateVoucher()
  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<VouchersFilters />}
        filterDefaultValues={config.options.defaultFilterValues}
        actions={
          <ListActions>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <VouchersListLayout hasEdit={props.hasEdit} />
      </List>
      {createPopupDialog}
    </>
  )
}
