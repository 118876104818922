import { CardContent } from '@material-ui/core'
import { NumberField, ReferenceField, Show, SimpleShowLayout } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import UserFullNameField from '../../components/UserFullNameField'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPES } from '../../config/vouchers'
import { CardTitle } from '../common'
import { Title, useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'
import { BookingsListLayout } from '../bookings/list'
import bookingsConfig from '../bookings/config'
import { ListReference } from '../common/list'

import { DiscountField } from './field'
import config from './config'

export const VoucherTitle = (props) => (
  <Title resourceConfig={config} {...props}>
    {props.details || config.options.getName}
  </Title>
)

export const VoucherShowLayout = (props) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.vouchers.show.titles.main" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedTextField source="code" />
        <AdvancedTextField source="name" />
        <DiscountField />
        <DateTooltipField source="created_on" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedTextField source="type" map={VOUCHER_TYPES} />
        <ReferenceField source="user_id" reference="users" link="show" emptyText="n/a">
          <UserFullNameField />
        </ReferenceField>
        <OrganisationField emptyText="n/a" />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <DateTooltipField source="start_date" addTime />
        <DateTooltipField source="end_date" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <NumberField source="number_of_entries" />
        <NumberField source="number_of_uses" />
      </SimpleShowLayout>
    </>
  )
}

const VoucherAside = (props) => {
  return props.record ? (
    <ListReference
      reference="bookings"
      target={config.options.referenceKey}
      sort={bookingsConfig.options.defaultSort}
      filter={bookingsConfig.options.defaultFilterValues}
      showPagination
      {...props}
    >
      <BookingsListLayout />
    </ListReference>
  ) : null
}

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show {...props} classes={{ main: showClasses.main }} title={<VoucherTitle />} aside={<VoucherAside />}>
      <VoucherShowLayout />
    </Show>
  )
}
