import { useMediaQuery } from '@material-ui/core'

import { getHexFromHSL } from '.'

export const useSmallScreen = () => !useMediaQuery((theme) => theme.breakpoints.up('md'), { noSsr: true })

export const isDarkMode = () => matchMedia('(prefers-color-scheme: dark)').matches

export const getRandomColorFromString = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = Math.abs(hash % 360)
  return getHexFromHSL(h / 360, 0.5, 0.5)
}
