import { TextField, CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import classnames from 'classnames'
import throttle from 'lodash/throttle'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { InputHelperText, useInput } from 'react-admin'
import { useForm } from 'react-final-form'

import {
  PLACE_DETAILS_FIELD_PLACE_ID,
  PLACE_DETAILS_FIELD_FORMATTED_ADDRESS,
  PLACE_DETAILS_FIELD_ADDRESS_COMPONENTS,
  PLACE_DETAILS_FIELD_GEOMETRY,
  PLACE_DETAILS_FIELDS,
  ADDRESS_FIELD_COUNTRY,
  ADDRESS_FIELD_COUNTRY_CODE,
  ADDRESS_FIELD_LOCALITY,
  ADDRESS_FIELD_POSTAL_TOWN,
  ADDRESS_MAIN_FIELDS,
} from '../config/addresses'
import { useCommonStyles } from '../config/theme'
import { useSmallScreen } from '../utils/theme'

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    marginLeft: 5,
    marginRight: 2,
    marginTop: 2,
    width: 19,
    height: 19,
  },
  locationOnIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const placeToAddressObject = (place, excludedFields, transformAddressObject) => {
  const address = {
    [PLACE_DETAILS_FIELD_PLACE_ID]: place[PLACE_DETAILS_FIELD_PLACE_ID],
    [PLACE_DETAILS_FIELD_FORMATTED_ADDRESS]: place[PLACE_DETAILS_FIELD_FORMATTED_ADDRESS],
  }
  ADDRESS_MAIN_FIELDS.filter((field) => !excludedFields.includes(field)).forEach((field) => {
    const fieldData = place[PLACE_DETAILS_FIELD_ADDRESS_COMPONENTS].find((c) => !!c?.types.includes(field))
    address[field] = fieldData?.long_name
    // We also store country code (alpha-2)
    if (field === ADDRESS_FIELD_COUNTRY) {
      address[ADDRESS_FIELD_COUNTRY_CODE] = fieldData?.short_name
    }
    // If no locality is found through Google API, we use a fallback on postal town
    if (field === ADDRESS_FIELD_LOCALITY && !Boolean(address[field])) {
      address[field] = place[PLACE_DETAILS_FIELD_ADDRESS_COMPONENTS].find(
        (c) => !!c?.types.includes(ADDRESS_FIELD_POSTAL_TOWN),
      )?.long_name
    }
  })
  const newAddressObject = transformAddressObject(address)
  return newAddressObject
}

let autocompleteService = null
let placesService = null

const AddressInputBase = ({
  label,
  getOptionLabel,
  disabled,
  helperText,
  latLngSources,
  excludedFields = [],
  transformAddressObject = (object) => object,
  ...props
}) => {
  const {
    meta: { submitting, error, touched },
    input: { onChange, value, ...inputProps },
    isRequired,
  } = useInput(props)
  const hasError = Boolean(error) && touched

  const [highlightedOption, setHighlightedOption] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const sessionToken = useRef()
  const { change } = useForm()

  if (window.google?.maps) {
    if (!autocompleteService) {
      autocompleteService = new window.google.maps.places.AutocompleteService()
    }
    if (!placesService) {
      placesService = new window.google.maps.places.PlacesService(document.getElementById('google-maps-root'))
    }
    if (!sessionToken.current) {
      sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
    }
  }

  const getPlacePredictions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  const getPlaceDetails = useMemo(
    () => (request, callback) => {
      placesService.getDetails(request, callback)
    },
    [],
  )

  const changeLatLngFields = useMemo(
    () => (location) => {
      if (!latLngSources) return
      if (location) {
        const latitude = location.lat()
        const longitude = location.lng()
        change(latLngSources[0], latitude)
        change(latLngSources[1], longitude)
      } else {
        change(latLngSources[0], null)
        change(latLngSources[1], null)
      }
    },
    [change, latLngSources],
  )

  const onChangeHandler = useCallback(
    (event, newValue) => {
      if (newValue) {
        getPlaceDetails(
          {
            placeId: newValue[PLACE_DETAILS_FIELD_PLACE_ID],
            fields: PLACE_DETAILS_FIELDS,
            sessionToken: sessionToken.current,
          },
          (place) => {
            onChange(placeToAddressObject(place, excludedFields, transformAddressObject))
            changeLatLngFields(place[PLACE_DETAILS_FIELD_GEOMETRY].location)
          },
        )
      } else {
        onChange(null)
        changeLatLngFields()
      }
    },
    [getPlaceDetails, onChange, excludedFields, transformAddressObject, changeLatLngFields],
  )

  const onInputChangeHandler = useCallback(
    (event, newInputValue) => {
      setInputValue(newInputValue)
    },
    [setInputValue],
  )

  const onHighlightChangeHandler = useCallback(
    (event, option) => {
      setHighlightedOption(option)
    },
    [setHighlightedOption],
  )

  const renderInput = (params) => {
    const textFieldProps = {
      label,
      variant: 'outlined',
      required: isRequired,
      error: hasError,
      helperText: helperText ?? <InputHelperText touched={touched} error={error} />,
    }
    return (
      <TextField
        {...params}
        {...textFieldProps}
        InputProps={{
          ...params.InputProps,
          startAdornment: <SearchIcon className={classnames(commonClasses.contrastColor, classes.searchIcon)} />,
          endAdornment: (
            <>
              {loading ? <CircularProgress size={20} style={{ marginLeft: 10 }} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          onKeyDown: (e) => {
            // Prevent submitting the whole form on pressing enter with
            // an empty input / half-filled input
            if (e.key === 'Enter' && !highlightedOption) {
              e.preventDefault()
              e.stopPropagation()
            }
          },
        }}
      />
    )
  }

  const renderOption = (option) => {
    const matches = option.structured_formatting?.main_text_matched_substrings
    const parts = parse(
      option.structured_formatting?.main_text,
      matches?.map((match) => [match.offset, match.offset + match.length]) ?? [],
    )
    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={classes.locationOnIcon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting?.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    let active = true
    if (!autocompleteService || !placesService || !sessionToken.current) {
      return
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return
    }

    setLoading(true)
    getPlacePredictions(
      {
        input: inputValue,
        sessionToken: sessionToken.current,
        types: ['geocode'],
      },
      (results) => {
        if (!active) return
        let newOptions = []
        if (value) {
          newOptions = [value]
        }
        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setLoading(false)
        setOptions(newOptions)
      },
    )
    return () => (active = false)
  }, [value, inputValue, getPlacePredictions])

  return (
    <Autocomplete
      {...inputProps}
      className={classnames(isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput, props.classes?.root)}
      size="small"
      noOptionsText={translate('mymove.enterAnAddress')}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) =>
        option[PLACE_DETAILS_FIELD_PLACE_ID] === value[PLACE_DETAILS_FIELD_PLACE_ID]
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={submitting || disabled}
      value={value ? value : null}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      onHighlightChange={onHighlightChangeHandler}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default AddressInputBase
