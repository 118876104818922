import GenericCreateForm from '../common/create'

import VehicleFormLayout, { transformValues } from './form'
import { VehicleTitle } from './show'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<VehicleTitle />}
      formLayoutComponent={<VehicleFormLayout />}
      transformValues={transformValues}
    />
  )
}
