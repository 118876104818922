import countries from 'i18n-iso-countries'

import {
  PLACE_DETAILS_FIELD_FORMATTED_ADDRESS,
  ADDRESS_FIELD_COUNTRY,
  ADDRESS_FIELD_COUNTRY_CODE,
  ADDRESS_FIELD_POSTAL_CODE,
  ADDRESS_FIELD_LOCALITY,
  ADDRESS_FIELD_ROUTE,
  ADDRESS_FIELD_STREET_NUMBER,
} from '../config/addresses'
import { renameKeysInObject } from '../utils'

import AddressInputBase from './AddressInputBase'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const AddressInputPayout = (props) => {
  const getOptionLabel = (option) => {
    const { description, street, houseNumber, zipcode, city, country } = option
    if (description) {
      return description
    } else if (option[PLACE_DETAILS_FIELD_FORMATTED_ADDRESS]) {
      return option[PLACE_DETAILS_FIELD_FORMATTED_ADDRESS]
    } else if (street && houseNumber && zipcode && city && country) {
      return `${street} ${houseNumber}, ${zipcode} ${city}, ${countries.getName(country, 'en')}`
    } else if (city && country) {
      return `${city}, ${countries.getName(country, 'en')}`
    }
    return ''
  }

  const transformAddressObject = (object) => {
    // Rename address object keys & adapt values to match backend expected keys
    const newObject = renameKeysInObject(object, [
      [ADDRESS_FIELD_COUNTRY_CODE, ADDRESS_FIELD_COUNTRY],
      [ADDRESS_FIELD_POSTAL_CODE, 'zipcode'],
      [ADDRESS_FIELD_LOCALITY, 'city'],
      [ADDRESS_FIELD_ROUTE, 'street'],
      [ADDRESS_FIELD_STREET_NUMBER, 'houseNumber'],
    ])
    newObject[ADDRESS_FIELD_COUNTRY] = countries.alpha2ToAlpha3(newObject[ADDRESS_FIELD_COUNTRY])
    return newObject
  }

  return <AddressInputBase getOptionLabel={getOptionLabel} transformAddressObject={transformAddressObject} {...props} />
}

export default AddressInputPayout
