import { SelectInput } from 'react-admin'

import { DAMAGES_STATUSES } from '../../config/damages'

const statusChoices = Object.entries(DAMAGES_STATUSES).map(([k, v]) => ({ value: k, name: v }))

export const DamageReportsStatusFilter = (props) => (
  <SelectInput {...props} choices={statusChoices} optionText="name" optionValue="value" allowEmpty />
)

DamageReportsStatusFilter.defaultProps = {
  source: 'status',
}
