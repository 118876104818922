import { useResourceContext, useTranslate } from 'react-admin'

import NonInput from '../../components/NonInput'

import { GenericCardTitle } from './show'

export const FORM_TYPE_FILTER = 'FILTER'

export const NonInputTitle = ({ text, condition }) => (
  <NonInput condition={condition}>
    <GenericCardTitle text={text} />
  </NonInput>
)

export const useHelperText = (source) => {
  const translate = useTranslate()
  const resource = useResourceContext()

  const helperTextKey = `resources.${resource}.forms.helperTexts.${source}`
  const helperText = translate(helperTextKey)

  // Check if the translated text is the same as the key (which happens when no translation is found)
  return helperText !== helperTextKey ? helperText : null
}
