// We use this component to avoid props errors (by sanitizing props) when adding non-input elements in forms

import { memo } from 'react'
import { FormDataConsumer } from 'react-admin'

const NonInput = memo(({ children, condition }) => (
  <FormDataConsumer>{({ formData }) => (!condition || condition(formData) ? children : null)}</FormDataConsumer>
))

export default NonInput
