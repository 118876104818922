import { ReferenceInput } from 'react-admin'
import { Field } from 'react-final-form'

import damageBlueprintsConfig from '../damageBlueprints/config'
import Blueprint from '../../components/Blueprint'

const ReferencedMarkerInput = (props) => {
  const { report, choices, onNewMarker } = props
  const blueprint = choices.find((record) => record.id === report.blueprint_id)
  return blueprint ? (
    <Blueprint
      blueprint={blueprint}
      reports={report && report.marker_x_ratio ? [report] : []}
      onNewMarker={onNewMarker}
      {...props}
    />
  ) : null
}

const MarkerInput = (props) => (
  <>
    <ReferenceInput {...props}>
      <ReferencedMarkerInput {...props} />
    </ReferenceInput>
    <Field name="marker_x_ratio" render={() => null} />
    <Field name="marker_y_ratio" render={() => null} />
  </>
)

MarkerInput.defaultProps = {
  blueprint_id: null,
  reference: damageBlueprintsConfig.name,
  source: damageBlueprintsConfig.options.referenceKey,
  editable: true,
}

export default MarkerInput
