import UserTitle from '../../components/UserTitle'
import GenericEditForm from '../common/edit'

import UserFormLayout, { transformValues } from './form'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<UserTitle />}
      formLayoutComponent={<UserFormLayout />}
      transformValues={transformValues}
    />
  )
}
