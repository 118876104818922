import { CRUD_DELETE_SUCCESS, refreshView } from 'react-admin'
import { eventChannel, buffers } from 'redux-saga'
import { cancel, delay, fork, put, race, take, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import pick from 'lodash/pick'

import { updateKeyboard } from '../reducers/keyboard'
import { configs as resources } from '../layouts'

import { POLL_REFRESH } from './actions'

const RESOURCES_WITH_BADGE = Object.entries(resources)
  .filter(([k, v]) => !!v.options?.badge)
  .map(([k, v]) => v.name)

function* throttle(delayLength, subscribe, worker, ...args) {
  const chan = eventChannel(subscribe, buffers.sliding(1))
  while (true) {
    const item = yield take(chan)
    yield fork(worker, item, ...args)
    yield delay(delayLength)
  }
}

const keyboardListener = (emit) => {
  const onKeyDown = (event) => {
    emit(updateKeyboard(pick(event, ['key', 'ctrlKey', 'shiftKey', 'altKey', 'metaKey'])))
  }
  const onKeyUp = () => {
    emit(updateKeyboard({}))
  }
  window.addEventListener('keydown', onKeyDown)
  window.addEventListener('keyup', onKeyUp)
  return () => {
    window.removeEventListener('keydown', onKeyDown)
    window.removeEventListener('keyup', onKeyUp)
  }
}

function* dispatchAction(action) {
  yield put(action)
}

function* watchKeyboard() {
  yield throttle(250, keyboardListener, dispatchAction)
}

function* incrementalPolling() {
  let time = 250
  while (true) {
    yield put(refreshView())
    yield delay(time)
    time *= 2
  }
}

function* onPollRefresh({ payload: maxTime }) {
  const pollTask = yield fork(incrementalPolling)
  yield race([take(LOCATION_CHANGE), delay(maxTime)])
  yield cancel(pollTask)
}

function* onCrudDeleteSuccess({ payload, meta }) {
  // Refresh menu badge counts that could have changed after a deletion
  if (RESOURCES_WITH_BADGE.includes(meta.resource)) {
    yield put(refreshView())
  }
}

export default function* root() {
  yield fork(watchKeyboard)
  yield takeLatest(POLL_REFRESH, onPollRefresh)
  yield takeLatest(CRUD_DELETE_SUCCESS, onCrudDeleteSuccess)
}
