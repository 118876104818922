import { get, isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'

import { USER_PAYMENT_METHOD_STATUSES } from '../config/users'
import { useShowStyles } from '../layouts/common/show'

import StaticText from './StaticText'

const UserPaymentMethodsField = (props) => {
  const translate = useTranslate()
  const showClasses = useShowStyles()
  const paymentMethods = get(props.record, 'payment_methods', [])

  if (isEmpty(paymentMethods)) {
    return <StaticText>resources.users.fields.payment_methods.missing</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {paymentMethods.map((paymentMethod) => {
        const {
          id,
          brand,
          last_four: lastFour,
          expiry_month: expiryMonth,
          expiry_year: expiryYear,
          status,
        } = paymentMethod
        return (
          <li key={id} className={showClasses.unorderedListItem}>
            <p>
              {brand.toUpperCase()} •••• {lastFour} ({expiryMonth}/{expiryYear})
            </p>
            <p>
              {translate('resources.users.fields.status')}: {translate(USER_PAYMENT_METHOD_STATUSES[status])}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

UserPaymentMethodsField.defaultProps = {
  addLabel: true,
  label: 'resources.users.fields.payment_methods.name',
}

export default UserPaymentMethodsField
