import React, { cloneElement, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useSaveContext } from 'react-admin'
import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import { SaveRowButton } from '@react-admin/ra-editable-datagrid'

/**
This is a simplified copy past from RA RowForm to remove the cancel button and override the actionColumn class

This component is meant to be removed after the migration to RA 4
 */
const RowForm = (props) => {
  const {
    children,
    record,
    id,
    className,
    quitEditMode,
    expand,
    initialValues,
    selectable,
    basePath,
    resource,
    save,
    saving,
    selected,
    submitOnEnter = true,
    undoable,
    onSuccess,
    onFailure,
    transform,
    mutationMode,
    ...rest
  } = props
  const classes = useStyles()

  // handle submit by enter
  const onKeyDown = (handleSubmit) => (event) => {
    if (event.key === 'Enter' && submitOnEnter) {
      event.preventDefault()
      event.stopPropagation()
      handleSubmit()
    }
  }

  const saveContext = useSaveContext()

  useEffect(() => {
    if (onFailure) {
      saveContext.setOnFailure(onFailure)
    }
    if (onSuccess) {
      saveContext.setOnSuccess(onSuccess)
    }
    if (transform) {
      saveContext.setTransform(transform)
    }
  }, [saveContext, onFailure, onSuccess, transform])

  return (
    <Form initialValues={{ ...initialValues, ...record }} onSubmit={save} {...rest}>
      {({ handleSubmit, invalid, dirty }) => (
        <TableRow className={className} key={id} onKeyDown={onKeyDown(handleSubmit)}>
          {React.Children.map(children, (field, index) => (
            <TableCell key={index} className={field.props.cellClassName} align={field.props.textAlign}>
              {cloneElement(field, {
                record,
                basePath: field.props.basePath || basePath,
                resource,
              })}
            </TableCell>
          ))}
          <TableCell className={classes.actionColumn}>
            <SaveRowButton
              dirty={dirty}
              handleSubmit={handleSubmit}
              invalid={invalid}
              quitEditMode={() => {}}
              saving={saving}
              undoable={undoable}
              mutationMode={mutationMode}
            />
          </TableCell>
        </TableRow>
      )}
    </Form>
  )
}

const useStyles = makeStyles(
  {
    actionColumn: {
      whiteSpace: 'nowrap',
      width: '5em',
      padding: '8px',
    },
  },

  {
    name: 'RaRowForm',
  },
)

export default RowForm
