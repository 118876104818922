import { IconButton, Portal, Tooltip } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/HelpOutlineRounded'
import { makeStyles } from '@material-ui/styles'
import { useTranslate } from 'ra-core'
import { useEffect, useRef } from 'react'
import { Layout, useSetLocale } from 'react-admin'

import env from '../config/env'
import { useTheme } from '../utils/hooks'

import CustomAppBar from './AppBar'
import { LANGUAGE_CODE_CACHE_KEY } from './LanguageSwitch'
import CustomMenu from './Menu'

const useStyles = makeStyles({
  layoutContent: (props) => ({
    [props.theme.breakpoints.up('sm')]: {
      paddingLeft: 5,
    },
  }),
  helpButton: {
    color: (props) => props.theme.palette.contrast,
    width: 48,
    height: 48,
    margin: 8,
  },
  helpButtonIcon: {
    width: 36,
    height: 36,
  },
})

export default (props) => {
  const helpRootEl = useRef(document.getElementById('help-root'))
  const translate = useTranslate()
  const theme = useTheme()
  const styles = useStyles({ theme })

  const setLocale = useSetLocale()
  useEffect(() => {
    const languageCode = window.localStorage.getItem(LANGUAGE_CODE_CACHE_KEY)
    if (languageCode) setLocale(languageCode)
  }, [setLocale])

  return (
    <>
      <Layout
        {...props}
        classes={{ content: styles.layoutContent }}
        theme={theme}
        appBar={CustomAppBar}
        menu={CustomMenu}
      />
      <Portal container={helpRootEl.current}>
        <Tooltip title={translate('mymove.dashboardUserGuide')}>
          <IconButton
            className={styles.helpButton}
            disableRipple
            onClick={() => window.open(env.DASHBOARD_USER_GUIDE_URL, '_blank')}
          >
            <HelpIcon className={styles.helpButtonIcon} />
          </IconButton>
        </Tooltip>
      </Portal>
    </>
  )
}
