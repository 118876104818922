import Icon from '@material-ui/icons/DirectionsCar'
import get from 'lodash/get'

import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'
import { VEHICLE_ACTIONS } from '../../config/vehicles'

export default {
  name: 'vehicles',
  icon: Icon,
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_LEASING_COMPANY]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'vehicle_id',
    defaultSort: { field: 'status', order: 'DESC' },
    defaultFilterValues: { active: true },
    // TODO later in sync with backend: modify maintenances name
    references: ['organisations', 'bookings', 'maintenances', 'damage-reports'],
    actions: VEHICLE_ACTIONS,
    badge: true,
    getName: (record) => {
      const brand = get(record, 'brand', '')
      const model = get(record, 'model', '')
      const designation = get(record, 'designation', '')
      const isEmpty = brand + model + designation === ''
      // Warning: empty space in string below is a non-breaking space (alt+space)
      // so an empty name still takes a text line height
      return isEmpty ? ' ' : `${brand} ${model} (${designation})`.trim()
    },
  },
}
