import { THEME_MODE_DARK, THEME_MODE_LIGHT } from '../config/theme'
import { isDarkMode } from '../utils/theme'

const getBestMode = () => (isDarkMode() ? THEME_MODE_DARK : THEME_MODE_LIGHT)

const initialState = {
  mode: getBestMode(),
}

const TOGGLE_THEME_MODE = 'TOGGLE_THEME_MODE'
export const toggleThemeMode = () => ({ type: TOGGLE_THEME_MODE })

const theme = (state = initialState, { type }) => {
  switch (type) {
    case TOGGLE_THEME_MODE:
      return {
        ...state,
        mode: state.mode === THEME_MODE_DARK ? THEME_MODE_LIGHT : THEME_MODE_DARK,
      }
    case 'RA/CLEAR_STATE':
      return {
        ...state,
        mode: getBestMode(),
      }
    default:
      return state
  }
}

export default theme
