import { Toolbar, SaveButton } from 'react-admin'

import { useCommonStyles } from '../config/theme'
import { CancelButton } from '../layouts/common/buttons'

import Spacer from './Spacer'

const BasicFormToolbar = ({ onCancel, children, ...props }) => {
  const classes = useCommonStyles()
  return (
    <Toolbar className={classes.toolbar} {...props}>
      {props.savebutton ? props.savebutton : <SaveButton />}
      <CancelButton onClick={onCancel} />
      <Spacer />
      {children}
    </Toolbar>
  )
}

export default BasicFormToolbar
