import { isEmpty } from 'lodash'

import { useShowStyles } from '../layouts/common/show'

import StaticText from './StaticText'

const UserNFCCardsField = ({ record: { nfc_cards: nfcCards } }) => {
  const showClasses = useShowStyles()
  if (isEmpty(nfcCards)) {
    return <StaticText>n/a</StaticText>
  }
  return (
    <ul className={showClasses.unorderedList}>
      {nfcCards.map(({ card_id: cardId, nfc_type: nfcType }) => (
        <li key={cardId} className={showClasses.unorderedListItem}>
          <p>
            {cardId} ({nfcType})
          </p>
        </li>
      ))}
    </ul>
  )
}

UserNFCCardsField.defaultProps = {
  addLabel: true,
  label: 'resources.users.fields.nfc_cards.name',
}

export default UserNFCCardsField
