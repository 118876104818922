import { Dialog, DialogTitle, ListItemText, MenuItem, MenuList, Select } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ChevronRight'
import SwitchAccountIcon from '@material-ui/icons/SwapHoriz'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import React, { forwardRef, useState } from 'react'
import { MenuItemLink } from 'react-admin'

import { useAccounts } from '../api/accountsProvider'
import { useCommonStyles } from '../config/theme'

export const AccountSwitchMenuItemLink = forwardRef((props, ref) => {
  const { accounts, currentAccount, setCurrentAccount } = useAccounts()

  const [accountsDialogOpen, setAccountsDialogOpen] = useState(false)
  const closeDialog = () => setAccountsDialogOpen(false)
  const translate = useTranslate()

  const switchAccount = (account) => {
    if (account.id === currentAccount.id) {
      closeDialog()
      return
    }
    setCurrentAccount(account)
  }

  return currentAccount ? (
    <>
      <MenuItemLink
        {...props}
        ref={ref}
        primaryText={translate('mymove.switchAccount')}
        leftIcon={<SwitchAccountIcon />}
        onClick={() => setAccountsDialogOpen(true)}
      />
      <Dialog open={accountsDialogOpen} onClose={closeDialog}>
        <DialogTitle>{translate('mymove.selectAccount')}</DialogTitle>
        <MenuList>
          {accounts.map((account) => {
            const { id, name } = account
            const isCurrent = id === currentAccount.id
            return (
              <MenuItem key={id} onClick={() => switchAccount(account)} selected={isCurrent}>
                <ListItemText primary={name} />
              </MenuItem>
            )
          })}
        </MenuList>
      </Dialog>
    </>
  ) : null
})

const AccountSwitch = () => {
  const commonClasses = useCommonStyles()
  const { accounts, currentAccount, setCurrentAccount } = useAccounts()
  const switchAccount = (event) => setCurrentAccount(event.target.value)

  const [isAccountSwitchExpanded, setIsAccountSwitchExpanded] = useState(false)
  const onAccountSwitchOpen = () => setIsAccountSwitchExpanded(true)
  const onAccountSwitchClose = () => setIsAccountSwitchExpanded(false)

  return currentAccount ? (
    <span className={commonClasses.textWithLargeIcons}>
      <Select
        value={currentAccount}
        onOpen={onAccountSwitchOpen}
        onClose={onAccountSwitchClose}
        onChange={switchAccount}
        className={classnames(
          commonClasses.commonSwitchRoot,
          isAccountSwitchExpanded ? commonClasses.commonSwitchExpandedBorder : null,
        )}
        classes={{
          select: commonClasses.commonSwitchSelect,
          icon: commonClasses.commonSwitchIcon,
        }}
      >
        {accounts.map((account) => {
          const { id, name } = account
          return (
            <MenuItem key={id} value={account}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
      <ArrowIcon />
    </span>
  ) : null
}

export default AccountSwitch
