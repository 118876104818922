import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'

import { useCommonStyles } from '../config/theme'
import { useShowStyles } from '../layouts/common/show'

const useStyles = makeStyles({
  unorderedList: {
    marginBottom: 10,
    paddingRight: 28,
    textAlign: 'justify',
  },
})

const DefinitionList = ({ items }) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <ul className={classnames(classes.unorderedList, showClasses.unorderedList)}>
      {items.map((i) => (
        <li key={i.role} className={showClasses.unorderedListItem}>
          <Typography
            component="span"
            variant="body2"
            className={classnames(commonClasses.inline, showClasses.boldText)}
            color="textPrimary"
          >
            {translate(i.role)}
          </Typography>{' '}
          <Typography component="span" variant="body2" className={commonClasses.inline} color="textPrimary">
            {translate(i.permission)}
          </Typography>
        </li>
      ))}
    </ul>
  )
}

DefinitionList.defaultProps = {
  items: [],
}

export default DefinitionList
