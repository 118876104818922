import { Typography, CardContent, Dialog } from '@material-ui/core'
import classnames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import {
  ImageField,
  ImageInput,
  TextInput,
  SimpleForm,
  FormDataConsumer,
  required,
  useRefresh,
  useNotify,
} from 'react-admin'

import { PAYOUT_API_BANK_ACCOUNTS_PATH } from '../config'
import { BANK_ACCOUNT_CREATE_SUCCESS_MESSAGE } from '../screens/addBankAccount'
import { usePayoutFormStyles } from '../styles'
import { CardTitle } from '../../common'
import { useShowStyles } from '../../common/show'
import { useApi } from '../../../api/apiProvider'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_DATE } from '../../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../../components/BasicFormToolbar'
import NonInput from '../../../components/NonInput'
import { useCommonStyles } from '../../../config/theme'
import { serializeFile } from '../../../utils'
import { getRoundedNow, parseDateAsDateTime } from '../../../utils/dates'
import { useSmallScreen } from '../../../utils/theme'
import { validateIBAN } from '../../../utils/validators'

const FirstExplanation = ({ isEmbedded = false }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const payoutFormClasses = usePayoutFormStyles({ isEmbedded })
  return (
    <Typography
      className={classnames(
        isEmbedded || isSmallScreen ? null : commonClasses.doubleInput,
        payoutFormClasses.formExplanation,
      )}
    >
      Please let us know the bank account in which you would like to receive funds.
    </Typography>
  )
}

const DisplayNameInput = ({ submitting }) => {
  const commonClasses = useCommonStyles()
  return (
    <TextInput
      className={classnames(commonClasses.commonInput, commonClasses.noMarginTop)}
      variant="outlined"
      source="displayName"
      label="Account holder name"
      validate={required()}
      disabled={submitting}
    />
  )
}

const AccountNumberInput = ({ submitting }) => {
  const commonClasses = useCommonStyles()
  return (
    <TextInput
      className={classnames(commonClasses.commonInput, commonClasses.noMarginTop)}
      variant="outlined"
      source="accountNumber"
      label="IBAN"
      validate={validateIBAN()}
      disabled={submitting}
    />
  )
}

const SecondExplanation = ({ sellerAccount, isEmbedded = false }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const payoutFormClasses = usePayoutFormStyles({ isEmbedded })
  return (
    <Typography
      component="div"
      className={classnames(
        isEmbedded || isSmallScreen ? null : commonClasses.doubleInput,
        payoutFormClasses.formExplanation,
      )}
    >
      The proof of bank account must show:
      <ul className={payoutFormClasses.unorderedList}>
        <li className={showClasses.unorderedListItem}>
          The legal business name of {sellerAccount?.legalEntity?.legalName};
        </li>
        <li className={showClasses.unorderedListItem}>The account number or IBAN;</li>
        <li className={showClasses.unorderedListItem}>The date of issuance, which must be less than 12 months ago;</li>
        <li className={showClasses.unorderedListItem}>
          The country where the bank account is located. For EU bank statements, we infer the country from the IBAN;
        </li>
        <li className={showClasses.unorderedListItem}>
          An indicator that the document was issued by a bank, such as the bank name, a bank logo or a bank-specific
          font.
        </li>
      </ul>
    </Typography>
  )
}

const BankStatementDocumentInput = ({ submitting }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  return (
    <ImageInput
      source="statement.data"
      label="Bank statement document"
      labelSingle="Drop or select your document here (PDF only)"
      className={classnames(
        isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput,
        commonClasses.noMarginTop,
      )}
      validate={required()}
      options={{ disabled: submitting }}
      accept=".pdf"
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  )
}

const BankStatementDateInput = ({ submitting }) => {
  const now = useRef(getRoundedNow())
  return (
    <AdvancedDateTimeInput
      variant="outlined"
      source="statement.issuedAt"
      label="Bank statement date"
      validate={required()}
      disabled={submitting}
      mode={DATETIME_INPUT_MODE_DATE}
      minDate={parseDateAsDateTime(now.current).minus({ years: 1 })}
      maxDate={now.current}
      required
    />
  )
}

const BankAccountInfoForm = ({ sellerAccount, submitting }) => {
  return (
    <>
      <FirstExplanation />
      <AccountNumberInput submitting={submitting} />
      <SecondExplanation sellerAccount={sellerAccount} />
      <BankStatementDocumentInput submitting={submitting} />
      <BankStatementDateInput submitting={submitting} />
    </>
  )
}

export const BankAccountInfoFormLayout = ({ sellerAccount, onClose, redirect }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const refresh = useRefresh()
  const notify = useNotify()

  const initialValues = {
    currency: 'EUR',
    accountType: 'IBAN',
  }

  const [addBankAccount, { loading: isAddingBankAccount }] = useApi(PAYOUT_API_BANK_ACCOUNTS_PATH, {
    method: 'POST',
    onSuccess: () => {
      notify(BANK_ACCOUNT_CREATE_SUCCESS_MESSAGE)
      refresh()
      onClose && onClose()
      redirect && redirect()
    },
    onFailure: () => notify('Failed to add bank account', 'warning'),
  })

  const onFormSubmit = useMemo(
    () =>
      async ({ statement, ...values }) => {
        const { data } = await serializeFile(statement.data.rawFile)
        addBankAccount({
          body: JSON.stringify({
            ...values,
            statement: {
              ...statement,
              issuedAt: statement.issuedAt.split('/').reverse().join('-'),
              data,
            },
          }),
        })
      },
    [addBankAccount],
  )

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="Add bank account" />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        initialValues={initialValues}
        variant="outlined"
        save={onFormSubmit}
        saving={isAddingBankAccount}
        mutationMode="pessimistic"
        className={showClasses.fieldContainerWrapper}
      >
        <NonInput formClassName={commonClasses.fullWidth}>
          <FirstExplanation isEmbedded={true} />
        </NonInput>
        <DisplayNameInput submitting={isAddingBankAccount} />
        <AccountNumberInput submitting={isAddingBankAccount} />
        <NonInput formClassName={classnames(commonClasses.fullWidth, isSmallScreen ? null : commonClasses.noMarginTop)}>
          <SecondExplanation sellerAccount={sellerAccount} isEmbedded={true} />
        </NonInput>
        <FormDataConsumer formClassName={commonClasses.fullWidth}>
          {({ formData, ...rest }) => <BankStatementDocumentInput {...rest} submitting={isAddingBankAccount} />}
        </FormDataConsumer>
        <FormDataConsumer formClassName={commonClasses.noMarginTop}>
          {({ formData, ...rest }) => <BankStatementDateInput {...rest} submitting={isAddingBankAccount} />}
        </FormDataConsumer>
      </SimpleForm>
    </>
  )
}

const BankAccountInfoFormLayoutController = ({ sellerAccount, onClose }) => {
  return <BankAccountInfoFormLayout sellerAccount={sellerAccount} onClose={onClose} />
}

export const CreateBankAccountInfoFormLayoutController = (props) => <BankAccountInfoFormLayoutController {...props} />

export const useCreateBankAccount = (sellerAccount) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (id) => setCreatePopupState({ isOpen: true, values: { id } })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateBankAccountInfoFormLayoutController
        initialValues={createPopupState.values}
        sellerAccount={sellerAccount}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export default BankAccountInfoForm
