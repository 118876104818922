import { Typography } from '@material-ui/core'
import classnames from 'classnames'
import { ImageField, ImageInput, required } from 'react-admin'

import { usePayoutFormStyles } from '../styles'
import { useShowStyles } from '../../common/show'
import { useCommonStyles } from '../../../config/theme'
import { useSmallScreen } from '../../../utils/theme'

const DocumentsForm = ({ submitting }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const payoutFormClasses = usePayoutFormStyles()

  return (
    <>
      <Typography
        component="div"
        className={classnames(isSmallScreen ? null : commonClasses.doubleInput, payoutFormClasses.formExplanation)}
      >
        Your company registration document can be either:
        <ul className={payoutFormClasses.unorderedList}>
          <li className={showClasses.unorderedListItem}>An extract from the chamber of commerce;</li>
          <li className={showClasses.unorderedListItem}>Articles of association or incorporation;</li>
          <li className={showClasses.unorderedListItem}>
            Documents must have been issued in the last 1 year. In case of incorporation documents older than 1 year,
            they must be accompanied by a statement that they are still correct and accurate.
          </li>
        </ul>
      </Typography>
      <ImageInput
        source="document"
        label="Official incorporation document"
        labelSingle="Drop or select your document here (PDF only)"
        className={classnames(
          isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput,
          commonClasses.noMarginTop,
        )}
        validate={required()}
        options={{ disabled: submitting }}
        accept=".pdf"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </>
  )
}

export default DocumentsForm
