import SvgIcon from '@material-ui/core/SvgIcon'
import { forwardRef } from 'react'

export const path =
  'M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M6 2C4.89 2 4 2.89 4 4V20C4 21.11 4.89 22 6 22H13.81C13.28 21.09 13 20.05 13 19C13 15.69 15.69 13 19 13C19.34 13 19.67 13.03 20 13.08V8L14 2M13 3.5L18.5 9H13Z'

const FileCheckedIcon = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <path d={path} />
  </SvgIcon>
))

export default FileCheckedIcon
