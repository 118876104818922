import React from 'react'
import { useHistory } from 'react-router-dom'
import { Edit, useNotify, useRefresh, useResourceContext } from 'react-admin'

export default ({ titleComponent, formLayoutComponent, transformValues, ...props }) => {
  const history = useHistory()
  const notify = useNotify()
  const refresh = useRefresh()
  const resource = useResourceContext()

  const onSuccess = () => {
    notify(`resources.${resource}.forms.update.success`)
    history.goBack()
    refresh()
  }

  const onFailure = (error) => notify(error.message, { type: 'warning' })

  return (
    <Edit
      {...props}
      title={titleComponent}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transformValues}
    >
      {formLayoutComponent}
    </Edit>
  )
}
