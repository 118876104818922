import { IconButton, ListItem, Tooltip, Typography } from '@material-ui/core'
import AccountIcon from '@material-ui/icons/AccountBox'
import ArrowIcon from '@material-ui/icons/ChevronRight'
import Brightness6Icon from '@material-ui/icons/Brightness6'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { AppBar, MenuItemLink, UserMenu } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'ra-core'

import { useDocumentTitle } from '../App'
import { useAccounts } from '../api/accountsProvider'
import { PAYOUT_SETUP_STATUS_FAILED, PAYOUT_SETUP_STATUS_IN_PROGRESS } from '../config/payout'
import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { THEME_MODE_LIGHT, useCommonStyles } from '../config/theme'
import { useResourcePermissions } from '../domain/permissions'
import accountConfig from '../layouts/account/config'
import { AccountShowPage } from '../layouts/account/show'
import { useShowStyles } from '../layouts/common/show'
import opsUsersConfig from '../layouts/opsUsers/config'
import payoutConfig, { PAYOUT_TITLE } from '../layouts/payout/config'
import { useGetPayoutSetupStatus } from '../layouts/payout/hooks'
import { PayoutSetupScreen } from '../layouts/payout/screens/setup'
import { PayoutShowScreen } from '../layouts/payout/screens/show'
import { toggleThemeMode } from '../reducers/theme'
import { useSmallScreen } from '../utils/theme'

import AccountSwitch, { AccountSwitchMenuItemLink } from './AccountSwitch'
import BankIcon from './icons/BankIcon'
import LanguageSwitch, { LanguageSwitchMenuItemLink } from './LanguageSwitch'

const useStyles = makeStyles((theme) => ({
  appBarTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  singleAccountName: {
    fontSize: 20,
    fontWeight: 500,
  },
  userMenuContainer: {
    marginLeft: theme.spacing(1),
  },
  userMenuItemLink: {
    color: theme.palette.type === THEME_MODE_LIGHT ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
  },
  userMenuListItem: {
    pointerEvents: 'none',
    paddingBottom: theme.spacing(2),
  },
}))

const CustomUserMenu = (props) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const classes = useStyles()

  const location = useSelector((state) => state.router.location)
  const url = `${location.pathname}${location.search}${location.hash}`

  const [hasReadForAccount] = useResourcePermissions(accountConfig.name, SYSTEM_PERMISSION_READ)
  const { accounts, currentAccount } = useAccounts()
  const hasAccountsInfo = !!currentAccount
  const hasSeveralAccounts = hasAccountsInfo && accounts.length > 1

  const [hasReadForPayout] = useResourcePermissions(payoutConfig.name, SYSTEM_PERMISSION_READ)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()

  const [hasReadForOpsUsers] = useResourcePermissions(opsUsersConfig.name, SYSTEM_PERMISSION_READ)

  const menuItemLinkCommonProps = {
    divider: isSmallScreen,
    tooltipProps: { disableHoverListener: true },
    className: classes.userMenuItemLink,
  }

  return (
    <div className={classes.userMenuContainer}>
      <UserMenu {...props}>
        {hasReadForAccount && hasAccountsInfo && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={AccountShowPage.path}
            primaryText={isSmallScreen ? currentAccount.name : translate('resources.accounts.management')}
            leftIcon={<AccountIcon />}
          />
        )}
        {isSmallScreen && !hasReadForAccount && hasAccountsInfo && (
          <ListItem className={classes.userMenuListItem} divider>
            {currentAccount.name}
          </ListItem>
        )}
        {isSmallScreen && hasSeveralAccounts && <AccountSwitchMenuItemLink {...menuItemLinkCommonProps} to={url} />}
        {hasReadForPayout && Boolean(payoutSetupStatus) && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={
              [PAYOUT_SETUP_STATUS_IN_PROGRESS, PAYOUT_SETUP_STATUS_FAILED].includes(payoutSetupStatus)
                ? PayoutSetupScreen.path
                : PayoutShowScreen.path
            }
            primaryText={PAYOUT_TITLE}
            leftIcon={<BankIcon />}
          />
        )}
        {hasReadForOpsUsers && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={`/${opsUsersConfig.name}`}
            primaryText={translate('resources.ops-users.name', 2)}
            leftIcon={<opsUsersConfig.icon />}
          />
        )}
        {isSmallScreen && <LanguageSwitchMenuItemLink {...menuItemLinkCommonProps} to={url} />}
      </UserMenu>
    </div>
  )
}

const CustomAppBar = (props) => {
  const classes = useStyles()
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const dispatch = useDispatch()
  const translate = useTranslate()

  const { accounts, currentAccount } = useAccounts()
  const hasAccountsInfo = !!currentAccount
  const hasSingleAccount = hasAccountsInfo && accounts.length === 1
  const hasSeveralAccounts = hasAccountsInfo && accounts.length > 1

  useDocumentTitle(() => {
    const titleNode = document.getElementById('react-admin-title')
    if (!currentAccount) {
      return null
    }
    return titleNode ? currentAccount.name + ' > ' + titleNode.textContent : currentAccount.name
  })

  return hasAccountsInfo ? (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      {isSmallScreen ? (
        <div className={showClasses.expanded} />
      ) : (
        <>
          {hasSingleAccount && (
            <span className={classnames(commonClasses.textWithLargeIcons, classes.singleAccountName)}>
              {currentAccount.name}
              <ArrowIcon />
            </span>
          )}
          {hasSeveralAccounts && <AccountSwitch />}
          <Typography
            id="react-admin-title"
            className={classnames(showClasses.expanded, classes.appBarTitle)}
            variant="h6"
            color="inherit"
          />
          <LanguageSwitch />
        </>
      )}
      <Tooltip title={translate('mymove.themeModeToggleToolTip')}>
        <IconButton color="inherit" onClick={() => dispatch(toggleThemeMode())}>
          <Brightness6Icon />
        </IconButton>
      </Tooltip>
    </AppBar>
  ) : null
}

export default CustomAppBar
