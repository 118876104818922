import { DeleteButton, CreateButton } from '../common/buttons'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import { useCreateVehicleUnavailability } from './form'
import config from './config'

export const DeleteVehicleUnavailabilityButton = (props) => (
  <DeleteButton
    {...props}
    mutationMode="pessimistic"
    confirmTitle="resources.maintenances.actions.delete.confirmTitle"
    successMessage="resources.maintenances.actions.delete.success"
  />
)

export const CreateVehicleUnavailabilityButton = ({ filterValues, disabledInputsSources }) => {
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const [openCreatePopup, createPopupDialog] = useCreateVehicleUnavailability({ disabledInputsSources })
  return hasCreate ? (
    <>
      <CreateButton
        basePath={'/' + config.name}
        label="resources.maintenances.actions.create.name"
        filterValues={filterValues}
        isFloatingOnSmallScreen={false}
        onClick={openCreatePopup}
      />
      {createPopupDialog}
    </>
  ) : null
}
