import { Box, CardContent, CardHeader, CircularProgress, TextField } from '@material-ui/core'
import classnames from 'classnames'
import { debounce, get } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FunctionField, ImageField, Labeled, Show, SimpleShowLayout, useGetOne, useNotify } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedNumberField from '../../components/AdvancedNumberField'
import AdvancedTextField from '../../components/AdvancedTextField'
import AdvancedUrlField from '../../components/AdvancedUrlField'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ShowButton from '../../components/ShowButton'
import UnitField from '../../components/UnitField'
import VehicleLockField from '../../components/VehicleLockField'
import VehicleMapField from '../../components/VehicleMapField'
import VehicleMileageField from '../../components/VehicleMileageField'
import VehicleOnTripField from '../../components/VehicleOnTripField'
import { ViewModeList } from '../../components/ViewModeToggle'
import { useApi } from '../../api/apiProvider'
import { DAMAGES_STATUS_VALIDATED } from '../../config/damages'
import {
  SYSTEM_PERMISSION_ACTIONS,
  SYSTEM_PERMISSION_GATEWAY_LINK,
  SYSTEM_PERMISSION_PATCH,
  SYSTEM_PERMISSION_POSITION,
} from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import {
  VEHICLE_ENERGY_TYPE_ELECTRIC,
  VEHICLE_ENERGY_TYPE_FUEL,
  VEHICLE_ENERGY_TYPES,
  VEHICLE_GATEWAYS,
  VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS,
  VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN,
  VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO,
  VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT,
  VEHICLE_SPECIAL_FIELDS,
  VEHICLE_TRANSMISSION_TYPES,
  VEHICLE_TYPE_CAR_BLUEPRINT_SETS,
  VEHICLE_TYPES,
} from '../../config/vehicles'
import { useResourcePermissions } from '../../domain/permissions'
import { isVehicleCar, isVehicleElectricCar, isVehicleFuelCar } from '../../domain/vehicles'
import { useSmallScreen } from '../../utils/theme'
import bookingsConfig from '../bookings/config'
import { BookingsListLayout } from '../bookings/list'
import { BookingShowLayout } from '../bookings/show'
import { CardTitle } from '../common'
import { ListReference } from '../common/list'
import {
  GenericCardTitle,
  ShowActions,
  ShowCard,
  ShowReference,
  ShowReferenceLinks,
  Title,
  useShowStyles,
} from '../common/show'
import damageReportsConfig from '../damageReports/config'
import { DamageReportsListLayout } from '../damageReports/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import vehicleDetailsConfig from '../vehicleDetails/config'
import vehicleUnavailabilitiesConfig from '../vehicleUnavailabilities/config'
import { VehicleUnavailabilitiesListLayout } from '../vehicleUnavailabilities/list'

import config from './config'

// We use 'Labeled' component here to be able to have custom label based on code
const VehicleSpecialField = ({ record, code }) => (
  <Labeled label={VEHICLE_SPECIAL_FIELDS[code]}>
    <FunctionField
      render={() => {
        const detail = record.details?.find((item) => item.code === code)
        if (code === VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT && record.parking_spot) {
          // Show both static and dynamic parking spots if present
          return `${detail?.value || ''} ${record.parking_spot}`.trim()
        }
        return detail?.value ?? 'n/a'
      }}
    />
  </Labeled>
)

// We create a component here to avoid unrecognized props errors
const NotesTextareaInput = ({ basePath, ...props }) => {
  const showClasses = useShowStyles()
  const translate = useTranslate()
  return (
    <TextField
      {...props}
      id="notes-textarea-input"
      className={showClasses.textareaInput}
      placeholder={translate('resources.vehicles.notesPlaceholder')}
      variant="outlined"
      multiline
    />
  )
}

export const VehicleShowLayout = ({
  title = 'resources.vehicles.show.titles.main',
  hasRedirect = false,
  excluded = [],
  record: originalRecord,
  ...originalProps
}) => {
  const { data: vehicleDetails } = useGetOne(vehicleDetailsConfig.name, originalRecord.id)
  const record = useMemo(
    () => ({ ...originalRecord, ...vehicleDetails }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalRecord), JSON.stringify(vehicleDetails)],
  )
  const props = useMemo(
    () => ({ ...originalProps, record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalProps), JSON.stringify(record)],
  )

  const notify = useNotify()
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const translate = useTranslate()

  const operationalStatusSectionProps = {
    data: record?.status_timeline,
    classes: { timelineContainer: showClasses.statusTimelineContainer },
  }

  const [hasPatch, hasActions, hasGatewayLink] = useResourcePermissions(
    props.resource,
    SYSTEM_PERMISSION_PATCH,
    SYSTEM_PERMISSION_ACTIONS,
    SYSTEM_PERMISSION_GATEWAY_LINK,
  )

  const [hasPositionForVehicles] = useResourcePermissions(config.name, SYSTEM_PERMISSION_POSITION)
  const [notes, setNotes] = useState(null)
  const [notesBeingSaved, setNotesBeingSaved] = useState(false)
  const debouncedSetNotes = useCallback(debounce(setNotes, 2000), []) // eslint-disable-line react-hooks/exhaustive-deps
  const [runSaveNotesQuery] = useApi(`/vehicles/${record.id}`, {
    method: 'PATCH',
    body: JSON.stringify({ notes }),
    onSuccess: () => {
      setNotesBeingSaved(false)
    },
    onFailure: (error) => {
      setNotesBeingSaved(false)
      notify(translate('resources.vehicles.show.couldNotSaveNotes', { errorMessage: error.message }), {
        type: 'warning',
      })
    },
  })

  useEffect(() => {
    if (vehicleDetails) {
      setNotes(vehicleDetails.notes)
    }
  }, [JSON.stringify(vehicleDetails), setNotes]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Don't save notes on initial render
    if (notes === null || !vehicleDetails || notes === vehicleDetails.notes) {
      return
    }
    runSaveNotesQuery()
  }, [notes, vehicleDetails?.notes]) // eslint-disable-line react-hooks/exhaustive-deps

  return record && vehicleDetails ? (
    <>
      <CardHeader
        title={<CardTitle text={title} />}
        action={<ShowButton basePath={props.basePath} id={record.id} hidden={!hasRedirect} />}
        className={commonClasses.titleContainer}
      />
      <div className={isSmallScreen ? null : showClasses.row}>
        <div className={showClasses.expanded}>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="identification" />
          </CardContent>
          <div className={isSmallScreen ? null : showClasses.row}>
            <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
              <ImageField source="picture" title="model" addLabel={false} classes={{ image: showClasses.smallImage }} />
            </SimpleShowLayout>
            <div className={showClasses.expanded}>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <AdvancedTextField source="type" map={VEHICLE_TYPES} />
                {isVehicleCar(record) && (
                  <AdvancedTextField source="blueprint_set" map={VEHICLE_TYPE_CAR_BLUEPRINT_SETS} />
                )}
                <AdvancedTextField source="brand" />
                <AdvancedTextField source="model" />
                <AdvancedTextField source="year" />
                <AdvancedTextField source="designation" />
                {isVehicleCar(record) && <AdvancedTextField source="identification_number" />}
              </SimpleShowLayout>
            </div>
          </div>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="status" />
          </CardContent>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <UnitField source="autonomy" />
            <VehicleMileageField />
            <VehicleLockField />
            <VehicleOnTripField />
          </SimpleShowLayout>
          {hasActions && (
            <ShowActions record={record} helperText={translate('resources.vehicles.actions.helperText')} />
          )}
          {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
          {isVehicleCar(record) && (
            <>
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <GenericCardTitle text="characteristics" />
              </CardContent>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <AdvancedTextField source="transmission_type" map={VEHICLE_TRANSMISSION_TYPES} />
                <AdvancedBooleanField source="keyless" trueIcon={null} falseIcon={null} />
                <AdvancedNumberField source="number_of_seats" />
                <UnitField source="volume" unit="m³" />
                <PriceField source="value" />
                <AdvancedTextField source="energy_type" map={VEHICLE_ENERGY_TYPES} />
                {record.energy_type === VEHICLE_ENERGY_TYPE_ELECTRIC && <UnitField source="maximum_autonomy" />}
                {record.energy_type === VEHICLE_ENERGY_TYPE_ELECTRIC && (
                  <VehicleSpecialField code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />
                )}
                {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && <UnitField source="tank_size" unit="l" />}
                {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && (
                  <UnitField source="average_consumption" unit="l/100km" />
                )}
                {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && (
                  <VehicleSpecialField code={VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO} />
                )}
              </SimpleShowLayout>
            </>
          )}
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="parameters" />
          </CardContent>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <OrganisationField />
            <HubField />
            <PeriodField startedOnSource="started_on" endedOnSource="ended_on" addTime />
            <AdvancedNumberField source="price_factor" />
            <PriceField source="security_deposit_amount" />
            {isVehicleCar(record) && (
              <PriceField source="price_per_km" label="resources.organisations.fields.price_per_km" />
            )}
            <VehicleSpecialField code={VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT} />
            {isVehicleElectricCar(record) && (
              <UnitField
                source="charging_buffer"
                unit="mymove.units.time.hours"
                formatValue={(v) => v / 60}
                shouldFormatUnit
              />
            )}
            {isVehicleFuelCar(record) && <VehicleSpecialField code={VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN} />}
            {!isVehicleCar(record) && <VehicleSpecialField code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />}
          </SimpleShowLayout>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="providerDetails" />
          </CardContent>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedBooleanField
              source="installed"
              trueIcon={null}
              falseIcon={null}
              trueLabel="resources.vehicles.enums.installed.true"
              falseLabel="resources.vehicles.enums.installed.false"
            />
            <AdvancedTextField source="gateway.code" map={VEHICLE_GATEWAYS} />
            <AdvancedTextField source="gateway.vehicle_id" />
            {hasGatewayLink && (
              <AdvancedUrlField source="gateway.link" text={translate('resources.vehicles.show.goToVehicleConsole')} />
            )}
          </SimpleShowLayout>
        </div>
        <Box
          width="100%"
          maxWidth={isSmallScreen ? null : 480}
          className={isSmallScreen ? null : commonClasses.borderLeft}
        >
          {!isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
          {hasPositionForVehicles && (
            <>
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <GenericCardTitle text="location" />
              </CardContent>
              <SimpleShowLayout record={record} className={classnames(commonClasses.borderTop, showClasses.map)}>
                <VehicleMapField />
              </SimpleShowLayout>
            </>
          )}
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <CardTitle
              size={17}
              text={
                <div className={commonClasses.flexRowCenterContainer}>
                  <div>{translate('resources.vehicles.fields.notes')}</div>
                  {hasPatch && notesBeingSaved && <CircularProgress size={18} style={{ marginLeft: 12 }} />}
                </div>
              }
            />
          </CardContent>
          {hasPatch ? (
            <SimpleShowLayout className={commonClasses.borderTop}>
              <NotesTextareaInput
                key={vehicleDetails.id}
                defaultValue={vehicleDetails.notes ?? ''}
                onChange={(event) => {
                  if (event.target.value === notes) {
                    setNotesBeingSaved(false)
                    debouncedSetNotes.cancel()
                  } else {
                    setNotesBeingSaved(true)
                    debouncedSetNotes(event.target.value)
                  }
                }}
              />
            </SimpleShowLayout>
          ) : (
            <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
              <AdvancedTextField
                source="notes"
                addLabel={false}
                fallback={translate('resources.vehicles.notesPlaceholder')}
                className={showClasses.textareaField}
              />
            </SimpleShowLayout>
          )}
        </Box>
      </div>
      <ShowReferenceLinks excluded={excluded} {...props} />
    </>
  ) : null
}

export const VehicleTitle = (props) => (
  <Title resourceConfig={config} {...props}>
    {props.details || config.options.getName}
  </Title>
)

const VehicleAside = (props) => {
  const translate = useTranslate()
  const { data: vehicleDetails } = useGetOne(vehicleDetailsConfig.name, props.record?.id, {
    enabled: Boolean(props.record?.id),
  })
  const [viewMode, setViewMode] = useState(ViewModeList)

  return props.record && vehicleDetails ? (
    <>
      {Boolean(vehicleDetails.current_booking_id) && (
        <ShowReference record={vehicleDetails} reference="bookings" source="current_booking_id">
          {(bookingProps) =>
            bookingProps?.record ? (
              <ShowReference record={bookingProps.record} reference="organisations" source="organisation_id">
                {(organisationProps) => (
                  <ShowCard>
                    <BookingShowLayout
                      title={translate('resources.common.show.currentBookingFor', {
                        organisationName: get(organisationProps, 'record.name', '...'),
                      })}
                      excluded={['organisations', 'hubs', 'vehicles']}
                      hasRedirect
                      {...bookingProps}
                    />
                  </ShowCard>
                )}
              </ShowReference>
            ) : null
          }
        </ShowReference>
      )}
      <ListReference
        reference={vehicleUnavailabilitiesConfig.name}
        target="vehicle_id"
        sort={vehicleUnavailabilitiesConfig.options.defaultSort}
        {...props}
      >
        <VehicleUnavailabilitiesListLayout
          createProps={{
            filterValues: {
              organisation_id: props.record.organisation_id,
              vehicle_id: props.record.id,
            },
            disabledInputsSources: ['organisation_id', 'vehicle_id'],
          }}
          excluded={['organisations', 'vehicles']}
        />
      </ListReference>
      <ListReference
        reference="bookings"
        target="vehicle_id"
        sort={bookingsConfig.options.defaultSort}
        filter={bookingsConfig.options.defaultFilterValues}
        showPagination={viewMode === ViewModeList}
        {...props}
      >
        <BookingsListLayout
          excluded={['vehicles']}
          disabledInputsSources={['organisation_id', 'hub_id', 'vehicle_id']}
          viewMode={viewMode}
          setViewMode={setViewMode}
          schedulerProps={{
            filter: { vehicle_id: props.record.id },
            relatedResource: 'users',
            groupFilter: { id: [props.record.id] },
            creationInitialValues: {
              organisation_id: props.record.organisation_id,
              hub_id: props.record.hub_id,
              vehicle_id: props.record.id,
            },
          }}
        />
      </ListReference>
      <ListReference
        reference="damage-reports"
        target="vehicle_id"
        sort={damageReportsConfig.options.defaultSort}
        filter={{ status: DAMAGES_STATUS_VALIDATED }}
        {...props}
      >
        <DamageReportsListLayout excluded={['organisations', 'hubs', 'vehicles']} />
      </ListReference>
    </>
  ) : null
}

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show {...props} classes={{ main: showClasses.main }} title={<VehicleTitle />} aside={<VehicleAside />}>
      <VehicleShowLayout />
    </Show>
  )
}
