import jsonExport from 'jsonexport/dist'
import { useEffect } from 'react'
import { Datagrid, downloadCSV, Filter, List } from 'react-admin'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import MobileAppVersionField from '../../components/MobileAppVersionField'
import StatusField from '../../components/StatusField'
import { getUserFullName } from '../../components/UserFullNameField'
import { CreateButton, EditButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'
import { useApi } from '../../api/apiProvider'
import { getStatusStyles } from '../../domain/statuses'
import { isUserDeactivated } from '../../domain/users'
import { formatDateTimeForExport } from '../../utils/dates'
import { useTheme } from '../../utils/hooks'

import { useCreateUser, useEditUser } from './form'
import { UserClosedFilter } from './filter'
import config from './config'

const UsersFilters = (props) => {
  const listClasses = useListStyles()
  return (
    <Filter variant="outlined" classes={{ form: listClasses.filterForm }} {...props}>
      <AdvancedSearchFilter alwaysOn />
      <UserClosedFilter alwaysOn />
    </Filter>
  )
}

const usersExporter = (records) => {
  const usersForExport = records.map((user) => {
    const dateFieldsToFormat = [
      'terms_acceptance_date',
      'marketing_terms_acceptance_date',
      'creation_date',
      'last_update_date',
      'account_closing_date',
    ]
    dateFieldsToFormat.forEach((field) => (user[field] = formatDateTimeForExport(user[field])))
    return user
  })

  jsonExport(
    usersForExport,
    {
      // order fields in the export
      headers: [
        'id',
        'first_name',
        'last_name',
        'phone_number',
        'email',
        'monitoring_email',
        'is_trusted',
        'address',
        'app_version',
        'terms_acceptance_date',
        'marketing_terms_acceptance_date',
        'creation_date',
        'last_update_date',
        'account_closing_date',
        'account_closing_reason',
        'account_closed_by',
        'account_closing_justification',
      ],
    },
    (err, csv) => downloadCSV(csv, 'users'),
  )
}

const userRowStyle = (record) => {
  const isDeactivated = isUserDeactivated(record)
  return {
    opacity: isDeactivated ? 0.4 : 1,
  }
}

export const UsersListLayout = ({ hasEdit, ...props }) => {
  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => fetchMeInfo(), [fetchMeInfo])

  const theme = useTheme()
  const listClasses = useListStyles()

  const [openEditPopup, editPopupDialog] = useEditUser()

  return meInfo ? (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          linkType="show"
          itemStyle={userRowStyle}
          icon={(record) => getUserFullName(record, { showInitials: true })}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={(record) => getUserFullName(record)}
          body={(record) => (
            <>
              <AdvancedTextField record={record} source="email" />
              <br />
              <AdvancedTextField record={record} source="phone_number" />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show" rowStyle={userRowStyle}>
          <StatusField />
          <AdvancedTextField source="first_name" />
          <AdvancedTextField source="last_name" />
          <AdvancedTextField source="email" />
          <AdvancedTextField source="phone_number" />
          <MobileAppVersionField mobileAppLatestVersion={meInfo.mobile_app_latest_version} />
          <DateTooltipField source="created_on" addTime />
          {hasEdit && <EditButton onClick={openEditPopup} />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  ) : null
}

export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateUser()
  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<UsersFilters />}
        filterDefaultValues={config.options.defaultFilterValues}
        exporter={usersExporter}
        actions={
          <ListActions hasExport>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <UsersListLayout hasEdit={props.hasEdit} />
      </List>
      {createPopupDialog}
    </>
  )
}
