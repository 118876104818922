import { useTranslate } from 'ra-core'

import { ORGANISATION_EMAIL_NOTIFICATIONS } from '../config/organisations'
import { useShowStyles } from '../layouts/common/show'

import StaticText from './StaticText'

const OrganisationEmailNotificationsField = ({ record }) => {
  const showClasses = useShowStyles()
  const translate = useTranslate()

  if (ORGANISATION_EMAIL_NOTIFICATIONS.every((n) => record[n.code] === false)) {
    return <StaticText>resources.organisations.emailNotifications.empty</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {ORGANISATION_EMAIL_NOTIFICATIONS.map((n) =>
        record[n.code] === true ? (
          <li key={n.code} className={showClasses.unorderedListItem}>
            <p>{translate(n.label)}</p>
          </li>
        ) : null,
      )}
    </ul>
  )
}

OrganisationEmailNotificationsField.defaultProps = {
  addLabel: true,
  label: 'resources.organisations.fields.has_email_notifications',
}

export default OrganisationEmailNotificationsField
