import Badge from '@material-ui/core/Badge'
import DefaultIcon from '@material-ui/icons/ViewList'
import { makeStyles } from '@material-ui/styles'
import { getResources, MenuItemLink, usePermissions } from 'react-admin'
import { useSelector } from 'react-redux'
import { useTranslate } from 'ra-core'

import { useAccounts } from '../api/accountsProvider'
import { SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU } from '../config/permissions'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from '../config/theme'
import { hasResourcePermission } from '../domain/permissions'

const useStyles = makeStyles({
  menuContainer: {
    // We set menu container width here to prevent menu items tooltips being too far to the right when sidebar is closed
    width: (props) => (props.sidebarIsOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH),
    marginTop: 12,
  },
  menuItemLink: {
    marginBottom: 5,
    overflow: 'visible',
  },
  menuItemIcon: {
    marginRight: 4,
  },
})

const CustomBadge = ({ resource, icon, counts }) => (
  <Badge badgeContent={counts[resource.name]} color="error" overlap="rectangular">
    {icon}
  </Badge>
)

const Menu = ({ onMenuClick }) => {
  const translate = useTranslate()
  const { currentAccount } = useAccounts()
  const sidebarIsOpen = useSelector((state) => state.admin.ui.sidebarOpen)
  const classes = useStyles({ sidebarIsOpen })

  const resources = useSelector(getResources)
  const { permissions } = usePermissions()
  const displayedResources = resources.filter((resource) =>
    hasResourcePermission(permissions, resource.name, SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU),
  )

  return (
    <div className={classes.menuContainer}>
      {displayedResources.map((resource) => {
        let icon = resource.icon ? <resource.icon /> : <DefaultIcon />
        if (currentAccount && resource.options?.badge) {
          // We wrap our custom icon into another component to swallow the non-supported props
          icon = <CustomBadge resource={resource} icon={icon} counts={currentAccount.counts} />
        }
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translate(`resources.${resource.name}.name`, 2)}
            leftIcon={icon}
            onClick={onMenuClick}
            sidebarIsOpen={sidebarIsOpen}
            classes={{ root: classes.menuItemLink, icon: classes.menuItemIcon }}
          />
        )
      })}
    </div>
  )
}

export default Menu
