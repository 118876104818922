import config from './config'
import List from './list'
import Create from './create'
import Edit from './edit'

export default {
  ...config,
  config,
  list: List,
  create: Create,
  edit: Edit,
}
