import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import { DateTime } from 'luxon'

import { parseLocalDate } from '../utils/dates'

const LocalDateField = ({ source, record, className, ...props }) => {
  const dateStr = get(record, source)
  const dt = parseLocalDate(dateStr)
  return (
    <Typography component="span" variant="body2" className={className}>
      {dateStr ? dt.isValid ? <span>{dt.toLocaleString(DateTime.DATE_SHORT)}</span> : dateStr : 'n/a'}
    </Typography>
  )
}

LocalDateField.defaultProps = {
  addLabel: true,
}

export default LocalDateField
