import { SelectInput, required } from 'react-admin'
import { pick } from 'lodash'

import { BOOKING_BILLING_TYPES } from '../config/bookings'

const DefaultBillingTypeSelectInput = ({ source, isRequired = true, billingTypesData, ...props }) => {
  const filteredBillingTypes = pick(BOOKING_BILLING_TYPES, billingTypesData.split('/'))
  const choices = Object.entries(filteredBillingTypes).map(([id, name]) => ({
    id,
    name,
  }))

  return <SelectInput {...props} source={source} choices={choices} validate={isRequired && required()} />
}

export default DefaultBillingTypeSelectInput
