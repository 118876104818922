import { ResourceContextProvider, SimpleShowLayout } from 'react-admin'
import { Box, CardHeader } from '@material-ui/core'
import classnames from 'classnames'

import { CardTitle } from '../common'
import { EditButton } from '../common/buttons'
import { ShowReferenceLinks, Title, useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import HubPickUpAndDropOffHoursField from '../../components/HubPickUpAndDropOffHoursField'
import HubsMapField from '../../components/HubsMapField'
import UnitField from '../../components/UnitField'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'

import { useEditHub } from './form'
import config from './config'
import { CreateHubButton } from './buttons'

const HubShowLayout = (props) => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  const [openEditPopup, editPopupDialog] = useEditHub()
  if (!props.record) return null
  const organisationId = props.record.organisation_id

  return (
    <ResourceContextProvider value="hubs">
      <CardHeader
        title={<CardTitle text="resources.hubs.show.titles.main" />}
        action={
          <div className={commonClasses.toolbar}>
            {hasEdit && (
              <EditButton
                record={props.record}
                basePath={'/' + config.name}
                label="resources.hubs.actions.update.name"
                onClick={openEditPopup}
              />
            )}
            <CreateHubButton organisationId={organisationId} disabledInputsSources={['organisation_id']} />
          </div>
        }
      />
      <div className={showClasses.row}>
        <div className={showClasses.expanded}>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField source="name" />
            <AdvancedTextField
              source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
              label="resources.hubs.fields.address"
            />
            <UnitField source="geofencing" unit="m" />
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <HubPickUpAndDropOffHoursField />
          </SimpleShowLayout>
        </div>
        <Box
          width="100%"
          maxWidth={isSmallScreen ? null : 480}
          className={classnames(commonClasses.borderTop, isSmallScreen ? null : commonClasses.borderLeft)}
        >
          <SimpleShowLayout className={showClasses.map}>
            <HubsMapField hubs={[props.record]} />
          </SimpleShowLayout>
        </Box>
      </div>
      <ShowReferenceLinks {...props} resource={config.name} filterValues={{ organisation_id: organisationId }} />
      {editPopupDialog}
    </ResourceContextProvider>
  )
}

export const HubTitle = (props) => (
  <Title resourceConfig={config} {...props}>
    {props.details || props.record.name}
  </Title>
)

export default HubShowLayout
