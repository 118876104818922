import { get, capitalize } from 'lodash'

export default {
  name: 'damage-blueprints',
  options: {
    referenceKey: 'blueprint_id',
    getName: (record) => {
      const name = get(record, 'name', '')
      // Warning: empty space in string below is a non-breaking space (alt+space)
      // so an empty name still takes a text line height
      return name === '' ? ' ' : capitalize(name)
    },
  },
}
