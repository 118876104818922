import { useGetList } from 'react-admin'

import { useAccounts } from '../../api/accountsProvider'
import hubsConfig from '../hubs/config'
import organisationsConfig from '../organisations/config'

export const useCurrentAccountSelectors = () => {
  const { currentAccount } = useAccounts()
  const currentAccountId = currentAccount?.id

  const organisations = useGetList(
    organisationsConfig.name,
    { page: 1, perPage: 1 },
    organisationsConfig.options.defaultSort,
    { ...organisationsConfig.options.defaultFilterValues, account_id: currentAccountId },
    { enabled: Boolean(currentAccountId) },
  )
  const hubs = useGetList(
    hubsConfig.name,
    { page: 1, perPage: 1 },
    hubsConfig.options.defaultSort,
    { account_id: currentAccountId },
    { enabled: Boolean(currentAccountId) },
  )

  const hasSingleOrganisation = organisations.total === 1
  const singleOrganisationId = hasSingleOrganisation ? organisations.ids[0] : null
  const hasSingleHub = hubs.total === 1
  const singleHubId = hasSingleHub ? hubs.ids[0] : null

  return { currentAccount, hasSingleOrganisation, singleOrganisationId, hasSingleHub, singleHubId }
}
