import { IconButton, Menu } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/styles'
import { Datagrid, SimpleShowLayout } from 'react-admin'
import classnames from 'classnames'
import { useState } from 'react'

import { getResourceByName } from '..'
import { EditButton } from '../common/buttons'
import { GenericListLayout, useListStyles } from '../common/list'
import { useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import HubPickUpAndDropOffHoursField from '../../components/HubPickUpAndDropOffHoursField'
import { ReferenceMenuItem } from '../../components/ReferenceLink'
import StaticText from '../../components/StaticText'
import UnitField from '../../components/UnitField'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'

import { useEditHub } from './form'
import config from './config'
import { CreateHubButton } from './buttons'

const useStyles = makeStyles((theme) => ({
  actionsToolbar: {
    float: 'right',
  },
  moreButton: {
    color: theme.palette.contrast,
    width: 36,
    height: 36,
    marginLeft: 6,
  },
}))

const HubPanel = (props) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  return (
    <SimpleShowLayout {...props} className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
      <HubPickUpAndDropOffHoursField />
    </SimpleShowLayout>
  )
}

const ActionsToolbar = ({ hasEdit, ...props }) => {
  const classes = useStyles()
  const [openEditPopup, editPopupDialog] = useEditHub()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <div className={classes.actionsToolbar}>
        {hasEdit && <EditButton {...props} onClick={openEditPopup} />}
        <IconButton className={classes.moreButton} onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Menu id={'menu-' + props.record.id} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {config.options.references
            .map((reference) => {
              const referenceConfig = getResourceByName(reference)
              return referenceConfig ? (
                <ReferenceMenuItem
                  key={referenceConfig.name}
                  reference={referenceConfig.name}
                  source={referenceConfig.options.referenceKey}
                  record={props.record}
                  resource={props.resource}
                  filterValues={{ organisation_id: props.record?.organisation_id }}
                />
              ) : null
            })
            .filter(Boolean)}
        </Menu>
      </div>
      {editPopupDialog}
    </>
  )
}

export const HubsListLayout = ({ organisationId, ...props }) => {
  const listClasses = useListStyles()

  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)

  return (
    <GenericListLayout
      action={<CreateHubButton organisationId={organisationId} disabledInputsSources={['organisation_id']} />}
      compactListLayout={
        <CompactList
          {...props}
          alignItems="flex-start"
          icon={<config.icon />}
          title={(record) => <AdvancedTextField record={record} source="name" />}
          body={(record) => (
            <>
              <StaticText>{'resources.hubs.fields.address'}: </StaticText>
              <AdvancedTextField record={record} source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`} />
              <br />
              <StaticText>{'resources.hubs.fields.geofencing'}: </StaticText>
              <UnitField record={record} source="geofencing" unit="m" />
            </>
          )}
          collapsibleBody={(record) => <HubPanel record={record} />}
          hasEditButtonInMenu={hasEdit}
          references={config.options.references}
          referencesFilterValues={{ organisation_id: organisationId }}
        />
      }
      regularListLayout={
        <Datagrid {...props} expand={<HubPanel />} classes={{ row: listClasses.row }}>
          <AdvancedTextField source="name" sortable={false} />
          <AdvancedTextField
            source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
            label="resources.hubs.fields.address"
            sortable={false}
          />
          <UnitField source="geofencing" unit="m" sortable={false} />
          <ActionsToolbar hasEdit={hasEdit} />
        </Datagrid>
      }
    />
  )
}
