import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { Title, TopToolbar } from 'react-admin'
import { useHistory } from 'react-router-dom'

import config, { PAYOUT_BASE_PATH, PAYOUT_TITLE } from '../config'
import PayoutSetupForm from '../forms/mainSetup'
import { useGetPayoutSetupStatus } from '../hooks'
import { usePayoutCommonStyles } from '../styles'
import BankIcon from '../../../components/icons/BankIcon'
import {
  PAYOUT_SETUP_STATUS_IN_PROGRESS,
  PAYOUT_SETUP_STATUS_COMPLETED,
  PAYOUT_SETUP_STATUS_FAILED,
} from '../../../config/payout'
import { SYSTEM_PERMISSION_CREATE } from '../../../config/permissions'
import { useResourcePermissions } from '../../../domain/permissions'

import { PayoutShowScreen } from './show'

const SetupFailedView = () => {
  const payoutCommonClasses = usePayoutCommonStyles()
  return (
    <div className={payoutCommonClasses.flexContainer}>
      <BankIcon className={payoutCommonClasses.largeBankIcon} variant="cross" />
      <Typography variant="h4" className={payoutCommonClasses.largeText}>
        Payout setup failed
      </Typography>
      <Typography variant="body2" className={payoutCommonClasses.smallText}>
        We have encountered a problem with your setup.
        <br />
        Please contact the MyMove team.
      </Typography>
    </div>
  )
}

export const PayoutSetupScreen = () => {
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()
  const history = useHistory()

  useEffect(() => {
    // Redirect to show screen if setup completed
    if (payoutSetupStatus === PAYOUT_SETUP_STATUS_COMPLETED) {
      history.push(PayoutShowScreen.path)
    }
  }, [payoutSetupStatus, history])

  return (
    <>
      <Title title={PAYOUT_TITLE} />
      <TopToolbar></TopToolbar>
      {hasCreate && payoutSetupStatus === PAYOUT_SETUP_STATUS_IN_PROGRESS && <PayoutSetupForm />}
      {hasCreate && payoutSetupStatus === PAYOUT_SETUP_STATUS_FAILED && <SetupFailedView />}
    </>
  )
}

PayoutSetupScreen.path = PAYOUT_BASE_PATH + '/setup'
