import { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { Button, ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { stringify } from 'query-string'
import { useTranslate } from 'ra-core'

import { getResourceByName } from '../layouts'

const useStyles = makeStyles((theme) => ({
  icon: { paddingRight: '0.25em' },
  menuIcon: { minWidth: theme.spacing(5) },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}))

const useReferenceLink = ({ reference, resource, record, source, filterValues }) => {
  const translate = useTranslate()
  const sourceResource = getResourceByName(resource)
  const referenceResource = getResourceByName(reference)
  const genericLabel = `resources.${referenceResource.name}.name`
  let label
  let to
  if (source && record[source]) {
    label = translate(genericLabel, 1)
    to = { pathname: '/' + referenceResource.name + (record ? '/' + record[source] + '/show' : '') }
  } else {
    label = translate(genericLabel, 2)
    to = {
      pathname: '/' + referenceResource.name,
      search: stringify({
        filter: JSON.stringify({
          ...referenceResource.options.defaultFilterValues,
          ...filterValues,
          [sourceResource.options.referenceKey]: record ? record.id : null,
        }),
      }),
    }
  }
  return { to, label, referenceResource }
}

const ReferenceLink = ({ reference, resource, record, source, filterValues, variant }) => {
  const { to, label, referenceResource } = useReferenceLink({ reference, resource, record, source, filterValues })
  const classes = useStyles()
  return (
    <Button
      color="primary"
      component={Link}
      to={to}
      className={classes.link}
      onClick={(e) => e.stopPropagation()}
      variant={variant}
    >
      {referenceResource.icon && <referenceResource.icon className={classes.icon} />}
      {label}
    </Button>
  )
}

export const ReferenceMenuItem = forwardRef(({ reference, resource, record, source, filterValues, ...props }, ref) => {
  const { to, label, referenceResource } = useReferenceLink({ reference, resource, record, source, filterValues })
  const classes = useStyles()
  return (
    <MenuItem component={Link} to={to} ref={ref} {...props}>
      <ListItemIcon className={classes.menuIcon}>
        {referenceResource.icon && <referenceResource.icon color="inherit" />}
      </ListItemIcon>
      <Typography color="inherit">{label}</Typography>
    </MenuItem>
  )
})

export default ReferenceLink
