import { makeStyles } from '@material-ui/styles'
import { isEmpty } from 'lodash'
import { ReferenceField, useGetList } from 'react-admin'

import { useShowStyles } from '../layouts/common/show'
import usersConfig from '../layouts/users/config'

import UserFullNameField from './UserFullNameField'
import StaticText from './StaticText'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

const BookingAdditionalDriversField = ({ record }) => {
  const { ids, data } = useGetList(
    'users',
    { page: 1, perPage: record.additional_driver_user_ids.length },
    { ...usersConfig.options.defaultSort },
    { id: record.additional_driver_user_ids },
  )

  const classes = useStyles()
  const showClasses = useShowStyles()

  if (isEmpty(ids)) {
    return <StaticText>n/a</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {ids.map((id) => (
        <li key={id} className={classes.unorderedListItem}>
          <ReferenceField record={data[id]} source="id" reference="users" link="show">
            <UserFullNameField />
          </ReferenceField>
        </li>
      ))}
    </ul>
  )
}

BookingAdditionalDriversField.defaultProps = {
  addLabel: true,
  label: 'resources.bookings.fields.additional_driver_user_ids',
}

export default BookingAdditionalDriversField
