import { Typography, CardHeader, IconButton, Card, CardContent, Button } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import PlusIcon from '@material-ui/icons/Add'
import { useCallback, useRef } from 'react'
import { TextInput, required } from 'react-admin'
import { FormSpy, useForm } from 'react-final-form'

import { usePayoutFormStyles } from '../styles'
import { CardTitle } from '../../common'
import AddressInputPayout from '../../../components/AddressInputPayout'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_DATE } from '../../../components/AdvancedDateTimeInput'
import CountryInput from '../../../components/CountryInput'
import { ADDRESS_FIELD_POSTAL_CODE, ADDRESS_FIELD_ROUTE, ADDRESS_FIELD_STREET_NUMBER } from '../../../config/addresses'
import { useCommonStyles } from '../../../config/theme'
import { getRoundedNow } from '../../../utils/dates'
import { useSmallScreen } from '../../../utils/theme'
import { validateAddress } from '../../../utils/validators'

const UltimateBeneficialOwnersForm = ({ submitting }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const payoutFormClasses = usePayoutFormStyles()
  const now = useRef(getRoundedNow())
  const form = useForm()

  const addUbo = useCallback(() => {
    form.change('legalEntity.ultimateBeneficialOwners', [
      ...(form.getState().values.legalEntity.ultimateBeneficialOwners ?? []),
      {},
    ])
  }, [form])
  const removeUboAtIndex = useCallback(
    (index) => {
      form.change(
        'legalEntity.ultimateBeneficialOwners',
        form.getState().values.legalEntity.ultimateBeneficialOwners?.filter((_, i) => i !== index) ?? [],
      )
    },
    [form],
  )

  return (
    <FormSpy>
      {({ values }) => {
        const uboAmount = values.legalEntity?.ultimateBeneficialOwners?.length
        return (
          <>
            <Typography component="div" className={payoutFormClasses.formExplanation}>
              <Typography variant="body2">
                Please indicate below the details of any individual with direct or indirect ownership or authority,
                through any contract, arrangement, understanding, relationship or otherwise, over 25% or more shares,
                voting rights, or other equity interests in your company.
              </Typography>
              <br />
              <Typography variant="body2">
                If your company does not have an owner, please indicate the details of any member of senior management
                or any other individual who exercises ultimate effective control over your company, its management, or
                strategic decisions that fundamentally affect the daily or regular affairs or business practices of your
                company.
              </Typography>
            </Typography>
            {Array.from(Array(uboAmount)).map((_, index) => (
              <Card key={index} className={payoutFormClasses.formGroup} variant="outlined">
                <CardHeader
                  action={
                    uboAmount > 1 &&
                    !values.legalEntity?.ultimateBeneficialOwners[index]?.code && (
                      <IconButton
                        title="Remove this UBO"
                        onClick={() => removeUboAtIndex(index)}
                        className={payoutFormClasses.removeCardButton}
                        disabled={submitting}
                      >
                        <ClearIcon />
                      </IconButton>
                    )
                  }
                  title={<CardTitle text={`Ultimate Beneficial Owner #${index + 1}`} />}
                  className={payoutFormClasses.cardHeader}
                />
                <CardContent>
                  <TextInput
                    className={commonClasses.commonInput}
                    variant="outlined"
                    source={`legalEntity.ultimateBeneficialOwners.${index}.firstName`}
                    label="First name"
                    validate={required()}
                    disabled={submitting}
                  />
                  <TextInput
                    className={commonClasses.commonInput}
                    variant="outlined"
                    source={`legalEntity.ultimateBeneficialOwners.${index}.lastName`}
                    label="Last name"
                    validate={required()}
                    disabled={submitting}
                  />
                  <TextInput
                    className={commonClasses.commonInput}
                    variant="outlined"
                    source={`legalEntity.ultimateBeneficialOwners.${index}.legalName`}
                    label="Full legal name"
                    validate={required()}
                    disabled={submitting}
                  />
                  <AdvancedDateTimeInput
                    variant="outlined"
                    source={`legalEntity.ultimateBeneficialOwners.${index}.dateOfBirth`}
                    label="Date of birth"
                    validate={required()}
                    disabled={submitting}
                    mode={DATETIME_INPUT_MODE_DATE}
                    maxDate={now.current}
                    required
                  />
                  <AddressInputPayout
                    variant="outlined"
                    source={`legalEntity.ultimateBeneficialOwners.${index}.placeOfBirth`}
                    label="Place of birth"
                    validate={validateAddress()}
                    excludedFields={[ADDRESS_FIELD_POSTAL_CODE, ADDRESS_FIELD_ROUTE, ADDRESS_FIELD_STREET_NUMBER]}
                  />
                  {/* By default, CountryInput component (underlying AdvancedAutocompleteInput component) takes full width.
                  To prevent this behaviour and display the 2 country inputs on the same line, we wrap them inside an inline container. */}
                  <div className={isSmallScreen ? null : payoutFormClasses.countryInputsContainer}>
                    <CountryInput
                      source={`legalEntity.ultimateBeneficialOwners.${index}.nationality`}
                      label="Nationality"
                      validate={required()}
                      disabled={submitting}
                      marginRight={isSmallScreen ? 0 : 32}
                    />
                    <CountryInput
                      source={`legalEntity.ultimateBeneficialOwners.${index}.address.country`}
                      label="Country of residence"
                      validate={required()}
                      disabled={submitting}
                    />
                  </div>
                </CardContent>
              </Card>
            ))}
            <Button
              onClick={() => addUbo()}
              startIcon={<PlusIcon />}
              disabled={submitting}
              variant="contained"
              color="primary"
            >
              Add 1 more UBO
            </Button>
          </>
        )
      }}
    </FormSpy>
  )
}

export default UltimateBeneficialOwnersForm
