import { getResourceSingleLabel } from '../common'

// Main config

const config = {
  name: 'payout',
  options: {
    label: 'Payout',
    singleLabel: 'Payout',
  },
}

export const PAYOUT_TITLE = `${getResourceSingleLabel(config)} configuration`

export const PAYOUT_BASE_PATH = '/' + config.name

export default config

// Payout API paths

export const PAYOUT_API_SELLER_ACCOUNT_PATH = '/payout/seller-account'
export const PAYOUT_API_SELLER_ACCOUNT_DOCUMENTS_PATH = '/payout/seller-account/documents'
export const PAYOUT_API_SETTINGS_PATH = '/payout/settings'
export const PAYOUT_API_BANK_ACCOUNTS_PATH = '/payout/bank-accounts'
export const PAYOUT_API_REPORTS_PATH = '/payout/reports'
