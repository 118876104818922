import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  Typography,
  withStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import flatten from 'flat'
import { pick, omit } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { Form } from 'react-final-form'

import config, {
  PAYOUT_API_SELLER_ACCOUNT_DOCUMENTS_PATH,
  PAYOUT_API_SELLER_ACCOUNT_PATH,
  PAYOUT_API_BANK_ACCOUNTS_PATH,
} from '../config'
import { useGetPayoutSellerAccount, useSendPayoutSellerAccountToReview } from '../hooks'
import { usePayoutCommonStyles, usePayoutFormStyles } from '../styles'
import { CardTitle, getResourceSingleLabel } from '../../common'
import { useApi } from '../../../api/apiProvider'
import ArrowRightCircleIcon from '../../../components/icons/ArrowRightCircleIcon'
import BankIcon from '../../../components/icons/BankIcon'
import { useCommonStyles } from '../../../config/theme'
import { serializeFile } from '../../../utils'

import UltimateBeneficialOwnersForm from './ubo'
import DocumentsForm from './documents'
import CompanyInfoForm from './companyInfo'
import BankAccountInfoForm from './bankAccountInfo'

const Connector = withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.divider,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector)

const PayoutSetupFormWizard = ({ children }) => {
  const commonClasses = useCommonStyles()
  const payoutCommonClasses = usePayoutCommonStyles()
  const payoutFormClasses = usePayoutFormStyles()

  const sellerAccount = useGetPayoutSellerAccount(['docfiles'])

  const [activeStepIndex, setActiveStepIndex] = useState(-1)

  const pages = React.Children.toArray(children)
  const activePage = pages[activeStepIndex]

  const nextIncompleteStepIndex =
    sellerAccount === undefined
      ? undefined
      : pages.findIndex((page) => {
          const values = flatten(page.props.parsedValues?.({ sellerAccount }))
          return !Object.values(values).every((val) => val !== undefined)
        })
  const isCompleted = nextIncompleteStepIndex === -1 || activeStepIndex >= pages.length

  useEffect(() => {
    if (nextIncompleteStepIndex >= 0 && activeStepIndex === -1) {
      const initialStepIndex = nextIncompleteStepIndex
      setActiveStepIndex(initialStepIndex > 0 ? initialStepIndex : 0)
    }
  }, [nextIncompleteStepIndex, activeStepIndex, setActiveStepIndex])

  const [sendPayoutSellerAccountToReview] = useSendPayoutSellerAccountToReview({
    legalEntity: omit(sellerAccount?.legalEntity, ['documents', 'kycStatus', 'ultimateBeneficialOwners']),
  })
  useEffect(() => {
    if (activeStepIndex > 0 && activeStepIndex === pages.length && Boolean(sellerAccount)) {
      sendPayoutSellerAccountToReview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepIndex, sendPayoutSellerAccountToReview, pages.length, Boolean(sellerAccount)])

  const handleBack = () => {
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex - 1)
  }

  const handleNext = () => {
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex + 1)
  }

  const handleSetStep = (step) => () => {
    setActiveStepIndex(step)
  }

  const initialValues = useMemo(
    () => {
      if (!sellerAccount || !activePage) return {}
      const parsedValues = activePage.props.parsedValues?.({ sellerAccount })
      const values = activePage.props.initialValues?.({ sellerAccount, parsedValues })
      return values
    },
    [sellerAccount, activeStepIndex], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validate = useCallback(
    (values) => {
      let errors = {}
      if (!activePage) {
        return errors
      }
      errors = activePage.props.validate?.(values) ?? {}
      if (Object.keys(errors).length > 0) {
        console.log({ errors })
      }
      return errors
    },
    [activeStepIndex], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const notify = useNotify()
  const refresh = useRefresh()
  const [sendForm, { loading: isSendingForm }] = useApi(activePage?.props.submit?.path, {
    method: activePage?.props.submit?.verb ?? 'POST',
    onSuccess: () => {
      refresh()
    },
    onFailure: (error) => {
      notify(`There was an error: ${error.body?.errors?.[0]?.message?.message ?? error.message}`, 'warning')
    },
  })

  const onSubmit = useCallback(
    async (values) => {
      const data = (await activePage.props.submit?.values?.(values)) ?? Promise.resolve({})
      const { error } = await sendForm({ body: JSON.stringify(data) })
      if (error) {
        return
      }
      // Move to next step
      handleNext()
    },
    [sendForm, activeStepIndex], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={payoutFormClasses.root}>
      <Stepper activeStep={activeStepIndex} alternativeLabel connector={<Connector />}>
        {pages.map(({ props: { label } }, index) => {
          const stepProps = {}
          if (isCompleted) {
            stepProps.completed = true
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                align="center"
                color="inherit"
                onClick={handleSetStep(index)}
                disabled={isSendingForm || isCompleted || index > activeStepIndex}
              >
                {label}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
      {activeStepIndex < 0 && !isCompleted && (
        <div className={payoutFormClasses.loadingContainer}>
          <CircularProgress color="inherit" size={50} />
        </div>
      )}
      {isCompleted && (
        <div className={payoutFormClasses.completedScreenContainer}>
          <BankIcon className={payoutCommonClasses.largeBankIcon} variant="checked" />
          <Typography variant="h4" className={payoutCommonClasses.largeText}>
            Seller account under review
          </Typography>
          <Typography variant="body2" className={payoutCommonClasses.smallText}>
            Your request to create a seller account has been initiated and is under review.
            <br />
            This process usually takes a few days.
          </Typography>
        </div>
      )}
      {!isCompleted && activeStepIndex >= 0 && activeStepIndex < pages.length && (
        <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
          {({ handleSubmit, submitting, dirty, valid, values }) => (
            <>
              <div className={classnames(payoutFormClasses.formWrapper)}>
                <div className={classnames(payoutFormClasses.formContainer)}>
                  {React.cloneElement(activePage, { sellerAccount, submitting })}
                </div>
              </div>
              <div className={classnames(payoutFormClasses.buttonsContainer, commonClasses.borderTop)}>
                <Button
                  disabled={activeStepIndex === 0 || submitting}
                  onClick={handleBack}
                  variant="outlined"
                  color="primary"
                >
                  Back
                </Button>
                <Button
                  onClick={() => {
                    if (!dirty && valid) {
                      handleNext()
                    } else {
                      if (!valid) {
                        notify('The form is not valid. Please check for errors', 'warning')
                      }
                      handleSubmit()
                    }
                  }}
                  endIcon={
                    submitting ? (
                      <CircularProgress size={17} color="inherit" style={{ marginLeft: 3 }} />
                    ) : (
                      <ArrowRightCircleIcon />
                    )
                  }
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                >
                  {activeStepIndex === pages.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </div>
  )
}

const PayoutSetupForm = () => {
  const commonClasses = useCommonStyles()
  return (
    <Card>
      <CardContent className={classnames(commonClasses.titleContainer, commonClasses.borderBottom)}>
        <CardTitle text={`${getResourceSingleLabel(config)} setup`} />
      </CardContent>
      <PayoutSetupFormWizard>
        <CompanyInfoForm
          label="Company info"
          parsedValues={({ sellerAccount }) => {
            return {
              legalEntity: {
                legalName: sellerAccount?.legalEntity?.legalName,
                legalForm: sellerAccount?.legalEntity?.legalForm,
                type: sellerAccount?.legalEntity?.type,
                registrationNumber: sellerAccount?.legalEntity?.registrationNumber,
                taxNumber: sellerAccount?.legalEntity?.taxNumber,
                address: sellerAccount?.legalEntity?.address,
                phone: sellerAccount?.legalEntity?.phone,
                email: sellerAccount?.legalEntity?.email,
              },
            }
          }}
          initialValues={({ parsedValues }) => {
            return { ...parsedValues }
          }}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          submit={{
            path: PAYOUT_API_SELLER_ACCOUNT_PATH,
            verb: 'PATCH',
            values: (values) => {
              return {
                ...values,
                legalEntity: {
                  ...values.legalEntity,
                  legalForm: 'organization',
                  type: 'PRIVATE_COMPANY',
                  address: omit(values.legalEntity.address, ['place_id', 'formatted_address']),
                },
              }
            },
          }}
        />
        <DocumentsForm
          label="Documents"
          parsedValues={({ sellerAccount }) => {
            const values = {
              document: undefined,
            }
            const doc = sellerAccount?.legalEntity?.documents?.[sellerAccount?.legalEntity?.documents?.length - 1]
            if (doc) {
              values.document = { src: 'data:image/png;base64,' + doc.data }
            }
            return values
          }}
          initialValues={({ parsedValues }) => {
            return {
              ...parsedValues,
            }
          }}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          submit={{
            path: PAYOUT_API_SELLER_ACCOUNT_DOCUMENTS_PATH,
            verb: 'POST',
            values: async ({ document, ...values }) => {
              const { data } = await serializeFile(document.rawFile)
              return {
                ...values,
                data,
              }
            },
          }}
        />
        <UltimateBeneficialOwnersForm
          label="Ultimate Beneficial Owners"
          parsedValues={({ sellerAccount }) => {
            return {
              legalEntity: {
                legalName: sellerAccount?.legalEntity?.legalName,
                legalForm: sellerAccount?.legalEntity?.legalForm,
                type: sellerAccount?.legalEntity?.type,
                registrationNumber: sellerAccount?.legalEntity?.registrationNumber,
                taxNumber: sellerAccount?.legalEntity?.taxNumber,
                address: sellerAccount?.legalEntity?.address,
                phone: sellerAccount?.legalEntity?.phone,
                email: sellerAccount?.legalEntity?.email,
                ultimateBeneficialOwners: sellerAccount?.legalEntity?.ultimateBeneficialOwners?.map((ubo) => ({
                  address: ubo.address,
                  code: ubo.code,
                  dateOfBirth: ubo.dateOfBirth,
                  firstName: ubo.firstName,
                  lastName: ubo.lastName,
                  legalName: ubo.legalName,
                  nationality: ubo.nationality,
                  placeOfBirth: ubo.placeOfBirth,
                  type: ubo.type,
                })),
              },
            }
          }}
          initialValues={({ parsedValues }) => {
            return {
              ...parsedValues,
              legalEntity: {
                ...parsedValues.legalEntity,
                ultimateBeneficialOwners: parsedValues.legalEntity?.ultimateBeneficialOwners ?? [{}],
              },
            }
          }}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          submit={{
            path: PAYOUT_API_SELLER_ACCOUNT_PATH,
            verb: 'PATCH',
            values: (values) => {
              return {
                ...values,
                legalEntity: {
                  ...values.legalEntity,
                  ultimateBeneficialOwners: values.legalEntity.ultimateBeneficialOwners.map((ubo) => {
                    return {
                      type: 'SHARE_HOLDER',
                      ...pick(ubo, [
                        'address',
                        'code',
                        'dateOfBirth',
                        'firstName',
                        'lastName',
                        'legalName',
                        'nationality',
                        'placeOfBirth',
                        'type',
                      ]),
                      address: pick(ubo.address, ['country']),
                      dateOfBirth: ubo.dateOfBirth.split('/').reverse().join('-'),
                      placeOfBirth: pick(ubo.placeOfBirth, ['city', 'country']),
                    }
                  }),
                },
              }
            },
          }}
        />
        <BankAccountInfoForm
          label="Bank account info"
          parsedValues={({ sellerAccount }) => {
            const values = {
              accountNumber: sellerAccount.externalAccounts?.[0]?.accountNumber,
              statement: {
                data: undefined,
                issuedAt: undefined,
              },
            }
            const statement = sellerAccount.externalAccounts?.[0]?.statement
            if (statement) {
              values.statement.data = { src: 'data:image/png;base64,' + statement.data }
              values.statement.issuedAt = statement.issuedAt
            }
            return values
          }}
          initialValues={({ sellerAccount, parsedValues }) => {
            const values = {
              ...parsedValues,
              displayName: sellerAccount?.legalEntity?.legalName,
              currency: 'EUR',
              accountType: 'IBAN',
              weight: 5000,
            }
            return values
          }}
          validate={(values) => {
            const errors = {}
            return errors
          }}
          submit={{
            path: PAYOUT_API_BANK_ACCOUNTS_PATH,
            verb: 'POST',
            values: async ({ statement, ...values }) => {
              const { data } = await serializeFile(statement.data.rawFile)
              return {
                ...values,
                statement: {
                  ...statement,
                  issuedAt: statement.issuedAt.split('/').reverse().join('-'),
                  data,
                },
              }
            },
          }}
        />
      </PayoutSetupFormWizard>
    </Card>
  )
}

export default PayoutSetupForm
