import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import { capitalize } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useAuthProvider, useCheckAuth, useSafeSetState, useTranslate } from 'ra-core'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { Notification, useLogin, useNotify, useQuery } from 'react-admin'
import { Field, Form } from 'react-final-form'
import { Link, useHistory, useLocation } from 'react-router-dom'

import GoogleIcon from '../../components/icons/GoogleIcon'
import MicrosoftIcon from '../../components/icons/MicrosoftIcon'
import { darkTheme, lightTheme, useCommonStyles } from '../../config/theme'
import { isDarkMode } from '../../utils/theme'
import { useDocumentTitle } from '../../App'

const useFormCardStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.background.default,
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' },
)

const FormCard = (props) => {
  const { title, children, shouldCheckAuth, ...rest } = props
  const containerRef = useRef()
  const classes = useFormCardStyles(props)

  return (
    <div className={classnames(classes.main)} {...rest} ref={containerRef}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {children}
      </Card>
      <Notification />
    </div>
  )
}

FormCard.propTypes = {
  children: PropTypes.node.isRequired,
}

const useFormStyles = makeStyles(
  (theme) => ({
    form: {
      paddingBottom: theme.spacing(1),
    },
    inputsContainer: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    ssoButton: {
      backgroundColor: '#F5F5F5',
      borderRadius: 4,
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
      color: 'black',
      '&:hover': {
        backgroundColor: '#C2C2C2',
      },
      '&:disabled': {
        boxShadow: 'none',
        color: '#8F8F8F',
        backgroundColor: '#595959',
      },
    },
    loadingIcon: {
      marginRight: theme.spacing(1),
    },
    footer: {
      justifyContent: 'center',
    },
  }),
  { name: 'RaLoginForm' },
)

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />

const LoginForm = ({ loginRedirectionPathName, ...props }) => {
  const [submittingProvider, setSubmittingProvider] = useSafeSetState('')
  const login = useLogin()
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useFormStyles(props)
  const commonClasses = useCommonStyles()

  const { data } = useQuery({ type: 'getAvailableLoginMethods' })
  const availableLoginMethods = data ? data.map((method) => method.toLowerCase()) : []

  const validate = (values) => {
    const errors = { username: undefined, password: undefined }
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values, loginType = 'password') => {
    setSubmittingProvider(loginType)

    login({ ...values, loginType }, `/redirect?path=${loginRedirectionPathName}`)
      .catch((error) => {
        notify(typeof error === 'string' ? error : error?.message || 'ra.auth.sign_in_error', {
          type: 'warning',
          messageArgs: {
            _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
          },
        })
      })
      .finally(() => {
        setSubmittingProvider('')
      })
  }

  const renderSSOButton = (provider, IconComponent) => (
    <CardActions className={classes.footer}>
      <Button
        disabled={!!submittingProvider}
        className={classnames(classes.button, classes.ssoButton)}
        onClick={() => submit({}, provider)}
        startIcon={submittingProvider === provider ? <CircularProgress size={18} thickness={2} /> : <IconComponent />}
      >
        {translate(`mymove.login.signInWith${capitalize(provider)}`)}
      </Button>
    </CardActions>
  )

  return availableLoginMethods ? (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          {availableLoginMethods.includes('password') && (
            <>
              <div className={classes.inputsContainer}>
                <div className={classes.input}>
                  <Field
                    id="username"
                    name="username"
                    component={Input}
                    label="Email"
                    disabled={!!submittingProvider}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    id="password"
                    name="password"
                    component={Input}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={!!submittingProvider}
                    autoComplete="current-password"
                  />
                </div>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!!submittingProvider}
                  className={classes.button}
                >
                  {submittingProvider === 'password' && (
                    <CircularProgress className={classes.loadingIcon} size={18} thickness={2} />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
              <CardActions className={classes.footer}>
                <Typography component="span" variant="body2" color="primary">
                  <Link
                    to={`${ResetPage.path}?email=${form.getState().values.username || ''}`}
                    className={commonClasses.link}
                  >
                    {translate('mymove.login.forgotPassword')}
                  </Link>
                </Typography>
              </CardActions>
            </>
          )}
          {availableLoginMethods.includes('google_com') && renderSSOButton('google', GoogleIcon)}
          {availableLoginMethods.includes('microsoft_com') && renderSSOButton('microsoft', MicrosoftIcon)}
          {availableLoginMethods.includes('saml') && renderSSOButton('saml', AccountCircleIcon)}
        </form>
      )}
    />
  ) : null
}

const ResetForm = (props) => {
  const [isLoading, setIsLoading] = useSafeSetState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const history = useHistory()
  const classes = useFormStyles(props)
  const commonClasses = useCommonStyles()
  const authProvider = useAuthProvider()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const validate = (values) => {
    const errors = { email: undefined }
    if (!values.email) {
      errors.email = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values) => {
    setIsLoading(true)
    authProvider.resetEmail(values.email).finally(() => {
      notify('A reset email was sent to ' + values.email)
      history.push(LoginPage.path)
      setIsLoading(false)
    })
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={{ email: params.get('email') }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <div className={classes.inputsContainer}>
            <div className={classes.input}>
              <Field autoFocus id="email" name="email" component={Input} label="Email" disabled={isLoading} />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" disabled={isLoading} className={classes.button}>
              {isLoading && <CircularProgress className={classes.loadingIcon} size={18} thickness={2} />}
              {translate('mymove.login.sendResetLink')}
            </Button>
          </CardActions>
          <CardActions className={classes.footer}>
            <Typography component="span" variant="body2" color="primary">
              <Link to={LoginPage.path} className={commonClasses.link}>
                {translate('mymove.login.iKnowMyPassword')}
              </Link>
            </Typography>
          </CardActions>
        </form>
      )}
    />
  )
}

ResetForm.propTypes = {
  redirectTo: PropTypes.string,
}

export const LoginPage = () => {
  const checkAuth = useCheckAuth()
  const history = useHistory()
  useDocumentTitle('Sign in')

  const [loginRedirectionPathName, setLoginRedirectionPathName] = useSafeSetState('/')
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page & store pathname for redirection when logging in
        const { state } = history.location
        if (state?.nextPathname) {
          setLoginRedirectionPathName(state.nextPathname)
        }
      })
  }, [checkAuth, history, setLoginRedirectionPathName])

  return (
    <ThemeProvider theme={isDarkMode() ? darkTheme : lightTheme}>
      <FormCard>
        <LoginForm loginRedirectionPathName={loginRedirectionPathName} />
      </FormCard>
    </ThemeProvider>
  )
}
LoginPage.path = '/login'

export const ResetPage = () => {
  useDocumentTitle('Reset password')
  return (
    <ThemeProvider theme={isDarkMode() ? darkTheme : lightTheme}>
      <FormCard>
        <ResetForm />
      </FormCard>
    </ThemeProvider>
  )
}
ResetPage.path = '/reset-password'

export const RedirectPage = () => {
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    window.location.replace(params.get('path'))
  }, [location])
  useDocumentTitle('Redirection...')
  return null
}
RedirectPage.path = '/redirect'

export default FormCard
