import GenericEditForm from '../common/edit'

import HubFormLayout, { transformValues } from './form'
import { HubTitle } from './show'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<HubTitle />}
      formLayoutComponent={<HubFormLayout />}
      transformValues={transformValues}
    />
  )
}
