import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import createDecorator from 'final-form-calculate'
import { get, pick } from 'lodash'
import { useMemo, useState } from 'react'
import {
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  RadioButtonGroupInput,
  required,
  ResourceContextProvider,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import { useApi } from '../../api/apiProvider'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_TIME } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import DefaultBillingTypeSelectInput from '../../components/DefaultBillingTypeSelectInput'
import {
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPES,
  BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET,
  BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD,
} from '../../config/bookings'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { hasBillingType, hasMobilityBudgetBillingType } from '../../utils'
import { validateEndTimeAfterStartTime } from '../../utils/dates'

import { ACCOUNT_BASE_PATH } from './config'

const validate = (values) => {
  const errors = {}
  if (values.has_mobility_budget) {
    errors.pro_hours_end = validateEndTimeAfterStartTime(values.pro_hours_start, values.pro_hours_end)
  }
  return errors
}

const validateBookingDefaultDuration = [
  required(),
  number('resources.accounts.forms.validation.booking_default_duration'),
  minValue(1, 'resources.accounts.forms.validation.booking_default_duration'),
  maxValue(24, 'resources.accounts.forms.validation.booking_default_duration'),
]

const validateBookingMaximumDuration = [
  number('resources.accounts.forms.validation.booking_maximum_duration'),
  minValue(1 / 24, 'resources.accounts.forms.validation.booking_maximum_duration'),
  maxValue(365, 'resources.accounts.forms.validation.booking_maximum_duration'),
]

const transformValues = ({ has_mobility_budget, billing_types_data, ...restValues }) =>
  restValues
    ? {
        ...restValues,
        billing_types: billing_types_data.split('/'),
        // Pro hours are not used when no mobility budget but for now backend needs values for these 2 fields
        pro_hours_start: has_mobility_budget ? restValues.pro_hours_start : '09:00:00',
        pro_hours_end: has_mobility_budget ? restValues.pro_hours_end : '18:00:00',
        booking_default_duration:
          restValues.booking_default_duration && !restValues.display_step_duration_slider
            ? restValues.booking_default_duration * 60
            : undefined,
        booking_maximum_duration: restValues.booking_maximum_duration
          ? restValues.booking_maximum_duration * 24 * 60
          : null,
        free_billing_type_requires_justification:
          restValues.free_billing_type_requires_justification &&
          hasBillingType(billing_types_data.split('/'), BOOKING_BILLING_TYPE_FREE),
        end_forgotten_bookings_after_1_hour:
          restValues.end_forgotten_bookings_after_1_hour &&
          hasBillingType(billing_types_data.split('/'), BOOKING_BILLING_TYPE_FREE),
      }
    : restValues

const createFormDecorator = () => {
  const updater = (value, field, values) => {
    const shouldResetToFirstChoice = field === 'has_mobility_budget'
    let hasMobilityBudget
    let billingTypesData
    let defaultBillingType

    hasMobilityBudget = values.has_mobility_budget
    const billingTypesChoices = hasMobilityBudget
      ? BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET
      : BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD
    billingTypesData = shouldResetToFirstChoice ? billingTypesChoices[0].id : values.billing_types_data
    const billingTypes = billingTypesData.split('/')
    defaultBillingType =
      shouldResetToFirstChoice || !billingTypes.includes(values.default_billing_type)
        ? billingTypes[0]
        : values.default_billing_type

    return {
      has_mobility_budget: hasMobilityBudget,
      billing_types_data: billingTypesData,
      default_billing_type: defaultBillingType,
    }
  }

  return createDecorator(
    {
      field: 'has_mobility_budget',
      updates: updater,
    },
    {
      field: 'billing_types_data',
      updates: updater,
    },
  )
}

const AccountFormLayout = ({ record, onClose, redirect }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()

  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const formProps = {
    record: pick(record, [
      'id',
      'slug',
      'name',
      'requires_payment',
      'billing_types',
      'default_billing_type',
      'free_billing_type_requires_justification',
      'end_forgotten_bookings_after_1_hour',
      'pro_hours_start',
      'pro_hours_end',
      'is_free_to_join',
      'display_step_duration_slider',
      'booking_default_duration',
      'booking_maximum_duration',
      'mobile_legal_url',
    ]),
  }

  formProps.record.billing_types_data = Object.keys(BOOKING_BILLING_TYPES)
    .filter((type) => formProps.record.billing_types.includes(type))
    .join('/')
  formProps.record.has_mobility_budget = hasMobilityBudgetBillingType(formProps.record.billing_types_data.split('/'))
  formProps.record.booking_default_duration = formProps.record.booking_default_duration
    ? formProps.record.booking_default_duration / 60
    : formProps.record.booking_default_duration
  formProps.record.booking_maximum_duration = formProps.record.booking_maximum_duration
    ? formProps.record.booking_maximum_duration / (24 * 60)
    : formProps.record.booking_maximum_duration

  const formDecorator = useMemo(() => createFormDecorator(), [])

  const [editAccount, { loading: isEditingAccount }] = useApi(ACCOUNT_BASE_PATH, {
    method: 'PUT',
    onSuccess: () => {
      notify('resources.accounts.forms.update.success')
      refresh()
      onClose && onClose()
      redirect && redirect()
    },
    onFailure: () => notify('resources.accounts.forms.update.failure', { type: 'warning' }),
  })

  const onFormSubmit = useMemo(
    () => (values) => editAccount({ body: JSON.stringify(transformValues(values)) }),
    [editAccount],
  )

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.accounts.forms.update.title" />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        validate={validate}
        decorators={[formDecorator]}
        save={onFormSubmit}
        saving={isEditingAccount}
        mutationMode="pessimistic"
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <TextInput source="name" label="resources.accounts.fields.name" validate={required()} />
        <FormDivider />
        <AdvancedSelectInput source="has_mobility_budget" />
        <AdvancedSelectInput source="requires_payment" />
        <FormDivider condition={(formData) => formData.has_mobility_budget !== undefined} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget !== undefined && (
              <RadioButtonGroupInput
                {...rest}
                row={false}
                source="billing_types_data"
                label={translate('resources.accounts.fields.billing_types', 2)}
                validate={required()}
                helperText={false}
                choices={
                  formData.has_mobility_budget
                    ? BOOKING_BILLING_TYPES_COMBINATIONS_MOBILITY_BUDGET
                    : BOOKING_BILLING_TYPES_COMBINATIONS_STANDARD
                }
                style={{ width: COMMON_INPUT_WIDTH, marginTop: -3 }}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget !== undefined && (
              <DefaultBillingTypeSelectInput
                {...rest}
                source="default_billing_type"
                label="resources.accounts.fields.default_billing_type"
                billingTypesData={formData.billing_types_data}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget !== undefined &&
            hasBillingType(formData.billing_types_data?.split('/'), BOOKING_BILLING_TYPE_FREE) && (
              <AdvancedSelectInput {...rest} source="free_billing_type_requires_justification" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget !== undefined &&
            hasBillingType(formData.billing_types_data?.split('/'), BOOKING_BILLING_TYPE_FREE) && (
              <AdvancedSelectInput {...rest} source="end_forgotten_bookings_after_1_hour" />
            )
          }
        </FormDataConsumer>
        <FormDivider condition={(formData) => formData.has_mobility_budget} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget && (
              <AdvancedDateTimeInput
                {...rest}
                validate={required()}
                required
                source="pro_hours_start"
                label="resources.accounts.fields.pro_hours_start"
                mode={DATETIME_INPUT_MODE_TIME}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_mobility_budget && (
              <AdvancedDateTimeInput
                {...rest}
                validate={required()}
                required
                source="pro_hours_end"
                label="resources.accounts.fields.pro_hours_end"
                mode={DATETIME_INPUT_MODE_TIME}
              />
            )
          }
        </FormDataConsumer>
        <FormDivider />
        <AdvancedSelectInput source="is_free_to_join" />
        <AdvancedSelectInput source="display_step_duration_slider" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.display_step_duration_slider && (
              <NumberInput
                {...rest}
                source="booking_default_duration"
                label="resources.accounts.fields.booking_default_duration"
                validate={validateBookingDefaultDuration}
                min={1}
                max={24}
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {translate('mymove.units.time.hours', get(formData, 'booking_default_duration') || 1)}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              {...rest}
              source="booking_maximum_duration"
              label="resources.accounts.fields.booking_maximum_duration"
              validate={validateBookingMaximumDuration}
              min={1}
              max={365}
              helperText="resources.accounts.forms.helperTexts.booking_maximum_duration"
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {translate('mymove.units.time.days', get(formData, 'booking_maximum_duration') || 1)}
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        </FormDataConsumer>
        <FormDivider />
        <TextInput
          source="mobile_legal_url"
          label="resources.accounts.fields.mobile_legal_url"
          type="url"
          validate={required()}
        />
      </SimpleForm>
    </>
  )
}

const AccountFormLayoutController = ({ account, onClose }) => (
  <ResourceContextProvider value="accounts">
    <AccountFormLayout record={account} onClose={onClose} />
  </ResourceContextProvider>
)

export const EditAccountFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <AccountFormLayoutController {...props} /> : <div />

export const useEditAccount = (account) => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditAccountFormLayoutController
        account={account}
        initialValues={editPopupState.values}
        onClose={handleEditPopupClose}
      />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default AccountFormLayout
