import { useEffect, useMemo } from 'react'
import {
  EmailField,
  ImageField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  useGetIdentity,
  useGetOne,
} from 'react-admin'
import { Box, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingEndChecksField from '../../components/BookingEndChecksField'
import HubsMapField from '../../components/HubsMapField'
import OrganisationEmailNotificationsField from '../../components/OrganisationEmailNotificationsField'
import OrganisationRegistrationLinkField from '../../components/OrganisationRegistrationLinkField'
import PressureGraphs from '../../components/PressureGraphs'
import PriceField from '../../components/PriceField'
import { useApi } from '../../api/apiProvider'
import { BOOKING_END_CHECK_TANK_FILLED_UP } from '../../config/bookingEndChecks'
import {
  OPS_USER_ROLE_LEASING_COMPANY,
  SYSTEM_PERMISSION_ACTIONS,
  SYSTEM_PERMISSION_READ,
} from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import hubsConfig from '../../layouts/hubs/config'
import { useSmallScreen } from '../../utils/theme'
import accountConfig, { ACCOUNT_BASE_PATH } from '../account/config'
import { CardTitle } from '../common'
import { EditButton } from '../common/buttons'
import { ListReference } from '../common/list'
import {
  GenericCardTitle,
  ShowActions,
  ShowCard,
  ShowReference,
  ShowReferenceLinks,
  Title,
  useShowStyles,
} from '../common/show'
import { useGetHubsListForOrganisation } from '../hubs/hooks'
import { HubsListLayout } from '../hubs/list'
import HubShowLayout from '../hubs/show'
import organisationDetailsConfig from '../organisationDetails/config'
import pricingStrategies from '../pricingStrategies'
import { PricingStrategiesListLayout } from '../pricingStrategies/list'

import config from './config'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    marginLeft: (props) => (props.isSmallScreen ? 0 : 20),
    marginTop: (props) => (props.isSmallScreen ? 10 : 24),
  },
  richTextContainer: {
    '& .MuiFormControl-root div': {
      maxHeight: 200,
      overflowY: 'scroll',
      paddingTop: 0,
      marginTop: theme.spacing(1),
    },
  },
}))

export const OrganisationShowLayout = ({ record: originalRecord, ...originalProps }) => {
  const [hasReadForAccount] = useResourcePermissions(accountConfig.name, SYSTEM_PERMISSION_READ)
  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  useEffect(() => {
    if (hasReadForAccount) fetchAccount()
  }, [hasReadForAccount, fetchAccount])

  const { data: organisationDetails } = useGetOne(organisationDetailsConfig.name, originalRecord.id)
  const record = useMemo(
    () => ({ ...originalRecord, ...organisationDetails }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalRecord), JSON.stringify(organisationDetails)],
  )
  const props = useMemo(
    () => ({ ...originalProps, record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalProps), JSON.stringify(record)],
  )

  const [hasActions] = useResourcePermissions(props.resource, SYSTEM_PERMISSION_ACTIONS)
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  return (!hasReadForAccount || Boolean(account)) && record && organisationDetails ? (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.organisations.show.titles.main" />
      </CardContent>
      <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
        <GenericCardTitle text="identification" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <ImageField source="picture" title="name" addLabel={false} classes={{ image: showClasses.smallImage }} />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedTextField source="name" />
        <EmailField source="contact_email" emptyText="n/a" />
      </SimpleShowLayout>
      <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
        <GenericCardTitle text="fleetUsage" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedBooleanField source="address_required" trueIcon={null} falseIcon={null} />
        <AdvancedBooleanField source="requires_face_check" trueIcon={null} falseIcon={null} />
        <AdvancedBooleanField source="requires_parking_spot" trueIcon={null} falseIcon={null} />
        <AdvancedBooleanField source="requires_damage_report_prompt" trueIcon={null} falseIcon={null} />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <BookingEndChecksField />
      </SimpleShowLayout>
      {record.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && (
        <>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="fines" />
          </CardContent>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedBooleanField
              source="missing_fuel_fine"
              label="resources.organisations.fields.missing_fuel_fine.name"
              trueIcon={null}
              falseIcon={null}
            />
            <PriceField source="missing_fuel_fine.operational_fees" />
            <PriceField source="missing_fuel_fine.fuel_fees" />
          </SimpleShowLayout>
        </>
      )}
      <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
        <GenericCardTitle text="notifications" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <OrganisationEmailNotificationsField />
      </SimpleShowLayout>
      <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
        <GenericCardTitle text="legalContent" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <RichTextField className={classes.richTextContainer} source="rental_terms_and_conditions" emptyText="n/a" />
      </SimpleShowLayout>
      {account?.is_free_to_join && (
        <>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="registrationInTheApp" />
          </CardContent>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <OrganisationRegistrationLinkField />
          </SimpleShowLayout>
        </>
      )}
      {hasActions && <ShowActions resource={props.resource} record={record} />}
      <ShowReferenceLinks {...props} />
    </>
  ) : null
}

export const OrganisationTitle = (props) => (
  <Title resourceConfig={config} {...props}>
    {props.details || props.record.name}
  </Title>
)

const OrganisationActions = ({ basePath, data, hasEdit }) => (
  <TopToolbar>
    {hasEdit && <EditButton basePath={basePath} record={data} label="resources.organisations.forms.update.title" />}
  </TopToolbar>
)

const OrganisationAside = (props) => {
  const { data: hubs, total: numberOfHubs } = useGetHubsListForOrganisation(props.record?.id)
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const showClasses = useShowStyles()
  const classes = useStyles({ isSmallScreen })

  return identity && props.record ? (
    <>
      {!!numberOfHubs && numberOfHubs === 1 && (
        <ShowCard>
          <HubShowLayout record={Object.values(hubs)[0]} />
        </ShowCard>
      )}
      {!!numberOfHubs && numberOfHubs > 1 && (
        <div className={showClasses.row}>
          <div className={showClasses.expanded}>
            <ListReference
              reference={hubsConfig.name}
              target="organisation_id"
              sort={hubsConfig.options.defaultSort}
              {...props}
            >
              <HubsListLayout organisationId={props.record?.id} />
            </ListReference>
          </div>
          <Box width="100%" maxWidth={isSmallScreen ? null : 480} className={classes.mapContainer}>
            <SimpleShowLayout className={showClasses.map}>
              <HubsMapField hubs={Object.values(hubs)} />
            </SimpleShowLayout>
          </Box>
        </div>
      )}
      <ListReference
        reference={pricingStrategies.config.name}
        target="organisation_id"
        sort={pricingStrategies.config.options.defaultSort}
        {...props}
      >
        <PricingStrategiesListLayout organisationId={props.record?.id} />
      </ListReference>
      {identity.role !== OPS_USER_ROLE_LEASING_COMPANY && (
        <ShowReference reference="organisations/pressure" source="id" record={props.record}>
          <PressureGraphs {...props} />
        </ShowReference>
      )}
    </>
  ) : null
}

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show
      {...props}
      classes={{ main: showClasses.main }}
      title={<OrganisationTitle />}
      actions={<OrganisationActions />}
      aside={<OrganisationAside />}
    >
      <OrganisationShowLayout />
    </Show>
  )
}
