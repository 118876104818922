import { Typography } from '@material-ui/core'
import jsonExport from 'jsonexport/dist'
import { Datagrid, downloadCSV, Filter, List } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UnitField from '../../components/UnitField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleLockField from '../../components/VehicleLockField'
import { getVehicleName } from '../../components/VehicleNameField'
import { useCommonStyles } from '../../config/theme'
import { getStatusStyles } from '../../domain/statuses'
import { isVehicleInstalled } from '../../domain/vehicles'
import { formatDateTimeForExport } from '../../utils/dates'
import { useTheme } from '../../utils/hooks'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CreateButton, EditButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'
import HubField from '../hubs/field'
import HubsFilter from '../hubs/filter'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'

import { useCreateVehicle, useEditVehicle } from './form'
import { VehicleActiveFilter, VehicleLockFilter, VehicleTypeFilter } from './filter'
import config from './config'

const VehiclesFilters = (props) => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const listClasses = useListStyles()
  return (
    <Filter variant="outlined" classes={{ form: listClasses.filterForm }} {...props}>
      <AdvancedSearchFilter alwaysOn />
      {!hasSingleOrganisation && <OrganisationsFilter alwaysOn />}
      {((!hasSingleOrganisation && props.filterValues.organisation_id) || (hasSingleOrganisation && !hasSingleHub)) && (
        <HubsFilter alwaysOn />
      )}
      <VehicleTypeFilter alwaysOn />
      <VehicleActiveFilter alwaysOn />
      <VehicleLockFilter alwaysOn />
    </Filter>
  )
}

const vehiclesExporter = (records, fetchRelatedRecords) => {
  let vehicles = []
  fetchRelatedRecords(records, 'organisation_id', 'organisations')
    .then((organisations) => {
      vehicles = records.map((record) =>
        record.organisation_id && organisations[record.organisation_id]
          ? {
              ...record,
              organisation_name: organisations[record.organisation_id].name,
            }
          : record,
      )
    })
    .then(() =>
      fetchRelatedRecords(records, 'hub_id', 'hubs').then((hubs) => {
        vehicles = vehicles.map((vehicle) =>
          vehicle.hub_id && hubs[vehicle.hub_id] ? { ...vehicle, hub_name: hubs[vehicle.hub_id].name } : vehicle,
        )
      }),
    )
    .then(() => {
      const autonomyHeader = 'autonomy (km)'
      const vehiclesForExport = vehicles.map((vehicle) => {
        // Remove non-used fields
        const {
          organisation_id,
          hub_id,
          started_on,
          ended_on,
          status,
          designation,
          autonomy,
          created_on,
          updated_on,
          ...vehicleForExport
        } = vehicle
        // Add new fields (date formatting & header change)
        vehicleForExport.start_date = formatDateTimeForExport(started_on)
        vehicleForExport.end_date = formatDateTimeForExport(ended_on)
        vehicleForExport.plate = designation
        vehicleForExport[autonomyHeader] = autonomy
        vehicleForExport.creation_date = formatDateTimeForExport(created_on)
        vehicleForExport.last_update_date = formatDateTimeForExport(updated_on)
        return vehicleForExport
      })

      jsonExport(
        vehiclesForExport,
        {
          // order fields in the export
          headers: [
            'id',
            'organisation_name',
            'hub_name',
            'start_date',
            'end_date',
            'type',
            'brand',
            'model',
            'plate',
            'picture',
            'installed',
            'locked',
            autonomyHeader,
            'creation_date',
            'last_update_date',
          ],
        },
        (err, csv) => downloadCSV(csv, 'vehicles'),
      )
    })
}

const vehicleRowStyle = (record) => {
  const isInstalled = isVehicleInstalled(record)
  return {
    opacity: isInstalled ? 1 : 0.4,
  }
}

export const VehiclesListLayout = ({ title = config.options.label, compact = false, hasEdit, ...props }) => {
  const translate = useTranslate()
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const [openEditPopup, editPopupDialog] = useEditVehicle()

  const theme = useTheme()
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          linkType="show"
          itemStyle={vehicleRowStyle}
          icon={<VehicleIconField />}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={(record) => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                {getVehicleName(record)}
              </Typography>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                {' • '}
                {record.designation}
              </Typography>
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{`${translate('resources.organisations.name', 1)}: `}</StaticText>
              <ReferenceValue
                record={record}
                reference="organisations"
                source="organisation_id"
                target="name"
                fallback="n/a"
              />
              <br />
              <StaticText>{`${translate('resources.hubs.name', 1)}: `}</StaticText>
              <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" fallback="n/a" />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show" rowStyle={vehicleRowStyle}>
          <StatusField />
          {!hasSingleOrganisation && (
            <OrganisationField sortable={false} label={translate('resources.organisations.name', 1)} />
          )}
          {!hasSingleHub && <HubField sortable={false} />}
          <VehicleIconField color="textSecondary" />
          <AdvancedTextField source="brand" />
          <AdvancedTextField source="model" />
          <AdvancedTextField source="designation" />
          <UnitField source="autonomy" />
          <VehicleLockField />
          {hasEdit && <EditButton onClick={openEditPopup} />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  )
}

export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateVehicle()
  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<VehiclesFilters />}
        filterDefaultValues={config.options.defaultFilterValues}
        exporter={vehiclesExporter}
        actions={
          <ListActions hasExport>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <VehiclesListLayout hasEdit={props.hasEdit} />
      </List>
      {createPopupDialog}
    </>
  )
}
