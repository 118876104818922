import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { DatePicker, DateTimePicker, TimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { InputHelperText, useInput } from 'react-admin'

import { useCommonStyles } from '../config/theme'
import {
  formatTime,
  getISOFromDateTime,
  parseDateAsDateTime,
  parseLocalDate,
  parseTimeAsDateTime,
} from '../utils/dates'
import { useSmallScreen } from '../utils/theme'

export const DATETIME_INPUT_MODE_DATE = 'date'
export const DATETIME_INPUT_MODE_TIME = 'time'
export const DATETIME_INPUT_MODE_DATETIME = 'datetime'

const useStyles = makeStyles((theme) => ({
  clearButton: {
    color: theme.palette.contrast,
    width: 24,
    height: 24,
    padding: 0,
  },
  clearIcon: {
    width: 16,
    height: 16,
  },
}))

const AdvancedDateTimeInput = ({
  variant,
  label,
  minDate,
  maxDate,
  minDateMessage,
  maxDateMessage,
  required,
  disabled,
  helperText,
  className,
  mode = DATETIME_INPUT_MODE_DATETIME,
  ...props
}) => {
  const {
    meta: { submitting, error, touched },
    input: { onChange, value, ...inputProps },
  } = useInput(props)

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const hasError = Boolean(error) && touched
  const translate = useTranslate()

  const handleClick = (event) => {
    event.stopPropagation()
    onChange(null)
  }

  const additionalInputProps = {
    label,
    required,
    minDate,
    maxDate,
    minDateMessage,
    maxDateMessage,
    inputVariant: variant,
    className: classnames(commonClasses.commonInput, className),
  }

  const commonProps = {
    ...inputProps,
    ...additionalInputProps,
    margin: 'dense',
    variant: isSmallScreen ? 'dialog' : 'inline',
    ampm: false,
    disabled: submitting || disabled,
    autoOk: true,
    error: hasError,
    helperText: <InputHelperText touched={touched} error={error} helperText={helperText} />,
    label: translate(label || `resources.${props.resource}.fields.${props.source}`),
  }

  const commonDatePickerProps = {
    clearable: isSmallScreen ? !!value : null, // add clear button in dialog (on small screen only) if value not null
    InputProps: {
      endAdornment: value ? (
        <IconButton
          className={classes.clearButton}
          title={translate('mymove.clearValue')}
          disableRipple
          onClick={handleClick}
        >
          <ClearIcon className={classes.clearIcon} />
        </IconButton>
      ) : null,
    },
  }

  return (
    <>
      {mode === DATETIME_INPUT_MODE_DATE && (
        <DatePicker
          {...commonProps}
          {...commonDatePickerProps}
          format="dd/MM/yyyy"
          value={value ? parseLocalDate(value) : null}
          onChange={(dt) => onChange(dt ? dt.toISODate() : null)} // clear value if no datetime
        />
      )}
      {mode === DATETIME_INPUT_MODE_TIME && (
        <TimePicker
          {...commonProps}
          format="HH:mm"
          views={['hours', 'minutes']}
          value={value ? parseTimeAsDateTime(value) : null}
          onChange={(dt) => onChange(formatTime(dt))}
        />
      )}
      {mode === DATETIME_INPUT_MODE_DATETIME && (
        <DateTimePicker
          {...commonProps}
          {...commonDatePickerProps}
          format="dd/MM/yyyy HH:mm"
          value={value ? parseDateAsDateTime(value) : null}
          onChange={(dt) => onChange(dt ? getISOFromDateTime(dt) : null)} // clear value if no datetime
        />
      )}
    </>
  )
}

export default AdvancedDateTimeInput
