import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get, pick } from 'lodash'
import { useState } from 'react'
import {
  ArrayInput,
  CREATE,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  RadioButtonGroupInput,
  required,
  ResourceContextProvider,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useTranslate } from 'ra-core'

import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import AddressInputMain from '../../components/AddressInputMain'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_TIME } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import {
  HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
  HUB_ADDRESS_FILLING_METHOD_MANUAL,
  HUB_ADDRESS_FILLING_METHODS,
  PLACE_DETAILS_FIELD_FORMATTED_ADDRESS,
} from '../../config/addresses'
import {
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES,
} from '../../config/hubs'
import { useCommonStyles } from '../../config/theme'
import {
  formatHubPickUpAndDropOffHoursWeekDayParams,
  getHubPickUpAndDropOffHoursWeekDayParams,
} from '../../domain/hubs'
import { isDisabled } from '../../utils'
import { getWeekDayFromIndex, validateEndTimeAfterStartTime } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { getMissingFieldsFromObject, validateAddress } from '../../utils/validators'
import { NonInputTitle } from '../common/forms'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'

import config from './config'

export const useHubsFormStyles = makeStyles({
  simpleFormIteratorLabel: {
    width: 90,
  },
})

export const hubPickUpAndDropOffHoursTypeChoices = Object.entries(HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES).map(
  ([k, v]) => ({
    id: k,
    name: v,
  }),
)

export const validateLatitude = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(-90, 'mymove.validation.coordinates.latitude.notInRange'),
  maxValue(90, 'mymove.validation.coordinates.latitude.notInRange'),
]

export const validateLongitude = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(-180, 'mymove.validation.coordinates.longitude.notInRange'),
  maxValue(180, 'mymove.validation.coordinates.longitude.notInRange'),
]

export const validateGeofencing = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const transformValues = ({
  address_filling_method,
  address,
  has_pick_up_and_drop_off_hours,
  pick_up_and_drop_off_hours,
  ...restValues
}) => {
  if (address_filling_method === HUB_ADDRESS_FILLING_METHOD_MANUAL) {
    // We only want the formatted_address key in this case
    Object.keys(address).forEach((key) => {
      if (key !== PLACE_DETAILS_FIELD_FORMATTED_ADDRESS) {
        address[key] = null
      }
    })
  }
  return restValues
    ? {
        ...restValues,
        address,
        pick_up_and_drop_off_hours: has_pick_up_and_drop_off_hours
          ? pick_up_and_drop_off_hours.map((weekDay) => formatHubPickUpAndDropOffHoursWeekDayParams(weekDay))
          : null,
      }
    : restValues
}

export const HubAddressFillingMethodInput = ({ source, addressSource, positionSource }) => {
  const { change } = useForm()
  return (
    <RadioButtonGroupInput
      row={false}
      label={false}
      source={source}
      validate={required()}
      choices={HUB_ADDRESS_FILLING_METHODS}
      helperText="resources.hubs.forms.addressFillingMethod.helperText"
      style={{ marginTop: -12 }}
      onChange={(newValue) => {
        if (newValue === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE) {
          change(addressSource, null)
          change(positionSource, null)
        }
      }}
    />
  )
}

export const HubAddressInput = ({ target }) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const sourcePrefix = target ? `${target}.` : ''
  const label = translate('resources.hubs.fields.address')

  return (
    <FormDataConsumer formClassName={commonClasses.fullWidth}>
      {({ formData, ...rest }) =>
        (formData[target] ?? formData).address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE ? (
          <AddressInputMain
            {...rest}
            source={`${sourcePrefix}address`}
            label={label}
            latLngSources={[`${sourcePrefix}position.latitude`, `${sourcePrefix}position.longitude`]}
            validate={validateAddress()}
          />
        ) : (
          <TextInput
            {...rest}
            variant="outlined"
            source={`${sourcePrefix}address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
            label={label}
            validate={required()}
            helperText="resources.hubs.forms.helperTexts.manual_address"
            className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
          />
        )
      }
    </FormDataConsumer>
  )
}

const HubFormLayout = ({ type = UPDATE, disabledInputsSources = [], onClose, ...props }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useHubsFormStyles()
  const translate = useTranslate()

  const title = `resources.hubs.forms.${type.toLowerCase()}.title`

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'organisation_id',
    'name',
    'address',
    'position',
    'geofencing',
    'pick_up_and_drop_off_hours',
  ])
  formProps.record.position = pick(formProps.record.position, ['longitude', 'latitude'])

  let initialValues = {}
  if (type === CREATE) {
    initialValues.address_filling_method = HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE
    initialValues.has_pick_up_and_drop_off_hours = false
    initialValues.pick_up_and_drop_off_hours = Array.from({ length: 7 }, () => ({
      type: HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
    }))
  } else {
    // We consider that address had been filled manually if the address object is missing some fields
    const numberOfAddressMissingFields = getMissingFieldsFromObject(formProps.record.address).length
    initialValues.address_filling_method =
      numberOfAddressMissingFields > 0 ? HUB_ADDRESS_FILLING_METHOD_MANUAL : HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE
    // We convert hours data received from the backend if not null
    initialValues.has_pick_up_and_drop_off_hours = Boolean(formProps.record.pick_up_and_drop_off_hours)
    formProps.record.pick_up_and_drop_off_hours = Boolean(formProps.record.pick_up_and_drop_off_hours)
      ? formProps.record.pick_up_and_drop_off_hours.map((weekDay) =>
          getHubPickUpAndDropOffHoursWeekDayParams(weekDay.start_time, weekDay.end_time),
        )
      : Array.from({ length: 7 }, () => ({
          type: HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
        }))
  }

  return (
    <ResourceContextProvider value="hubs">
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>

      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <NonInputTitle text="identification" />

        <FormDivider />

        <OrganisationReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <TextInput source="name" validate={required()} />

        <FormDivider />

        <NonInputTitle text="geolocation" />

        <FormDivider />

        <HubAddressFillingMethodInput
          formClassName={commonClasses.fullWidth}
          source="address_filling_method"
          addressSource="address"
          positionSource="position"
        />
        <HubAddressInput />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              {...rest}
              source="position.latitude"
              validate={validateLatitude}
              step={0.1}
              min={-90}
              max={90}
              disabled={formData.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
              options={{
                InputProps: {
                  endAdornment: <InputAdornment position="end">°</InputAdornment>,
                },
              }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              {...rest}
              source="position.longitude"
              validate={validateLongitude}
              step={0.1}
              min={-180}
              max={180}
              disabled={formData.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
              options={{
                InputProps: {
                  endAdornment: <InputAdornment position="end">°</InputAdornment>,
                },
              }}
            />
          )}
        </FormDataConsumer>

        <FormDivider />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              {...rest}
              source="geofencing"
              validate={validateGeofencing}
              min={0}
              helperText="resources.hubs.forms.helperTexts.geofencing"
              formClassName={commonClasses.fullWidth}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {translate('mymove.units.distance.meters', get(formData, 'geofencing') || 1)}
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        </FormDataConsumer>

        <FormDivider />

        <NonInputTitle text="schedules" />

        <FormDivider />

        <AdvancedBooleanInput
          source="has_pick_up_and_drop_off_hours"
          className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
        />

        <FormDivider condition={(formData) => formData.has_pick_up_and_drop_off_hours} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.has_pick_up_and_drop_off_hours && (
              <ArrayInput
                {...rest}
                label={false}
                source="pick_up_and_drop_off_hours"
                className={commonClasses.noMarginTop}
              >
                <SimpleFormIterator
                  disableAdd
                  disableRemove
                  disableReordering
                  getItemLabel={(index) => translate(getWeekDayFromIndex(index))}
                  classes={{
                    root: commonClasses.noMarginTop,
                    index: classes.simpleFormIteratorLabel,
                  }}
                >
                  <SelectInput
                    source="type"
                    label="resources.hubs.pickUpAndDropOffHours.openingTypes.name"
                    choices={hubPickUpAndDropOffHoursTypeChoices}
                    validate={required()}
                    optionText="name"
                    optionValue="id"
                  />
                  <FormDataConsumer>
                    {({ scopedFormData, getSource, ...rest }) =>
                      scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                        <AdvancedDateTimeInput
                          {...rest}
                          required
                          validate={required()}
                          source={getSource('start_time')}
                          label="resources.hubs.pickUpAndDropOffHours.startTime"
                          mode={DATETIME_INPUT_MODE_TIME}
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ scopedFormData, getSource, ...rest }) =>
                      scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                        <AdvancedDateTimeInput
                          {...rest}
                          required
                          validate={[
                            required(),
                            (value) => validateEndTimeAfterStartTime(scopedFormData.start_time, value),
                          ]}
                          disabled={!Boolean(scopedFormData.start_time)}
                          source={getSource('end_time')}
                          label="resources.hubs.pickUpAndDropOffHours.endTime"
                          mode={DATETIME_INPUT_MODE_TIME}
                        />
                      )
                    }
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </ResourceContextProvider>
  )
}

const HubFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  disabledInputsSources,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    disabledInputsSources,
    onClose,
  }

  return <HubFormLayout {...controllerProps} />
}

export const EditHubFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <HubFormLayoutController {...props} /> : <div />

export const CreateHubFormLayoutController = (props) => <HubFormLayoutController {...props} />

export const useCreateHub = (disabledInputsSources) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateHubFormLayoutController
        initialValues={createPopupState.values}
        disabledInputsSources={disabledInputsSources}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditHub = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditHubFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default HubFormLayout
