import { DeleteButton } from '../common/buttons'

export const DeleteOpsUserButton = (props) => (
  <DeleteButton
    {...props}
    mutationMode="pessimistic"
    confirmTitle="resources.ops-users.actions.delete.confirmTitle"
    successMessage="resources.ops-users.actions.delete.success"
  />
)
