import { cloneElement, Fragment, useState } from 'react'
import { linkToRecord, MenuItemLink, usePermissions } from 'react-admin'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Badge,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import MoreIcon from '@material-ui/icons/MoreVert'

import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { useCommonStyles } from '../config/theme'
import { hasResourcePermission } from '../domain/permissions'
import { getResourceByName } from '../layouts'

import { ReferenceMenuItem } from './ReferenceLink'

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
  },
  item: {
    flexWrap: 'wrap',
  },
  iconDotBadge: {
    backgroundColor: (props) => props.iconBadgeColor,
    borderRadius: 5,
    width: 10,
    height: 10,
  },
  menuItemLink: {
    color: theme.palette.text.primary,
  },
}))

const getRecordValue = (record, source, fallback) => {
  if (!record || !source) {
    return ''
  }
  if (typeof source === 'string') {
    return record[source] || fallback
  }
  if (typeof source === 'function') {
    return source(record) || fallback
  }
  return cloneElement(source, { record })
}

const recordLink = ({ basePath, record, linkType }) => {
  if (!linkType) {
    return null
  }
  return linkToRecord(basePath, record.id, linkType)
}

const CompactListItem = ({
  title,
  icon,
  iconColor,
  iconBadgeColor,
  image,
  body,
  bodyFallback,
  references,
  referencesFilterValues,
  record,
  basePath,
  resource,
  linkType,
  style,
  alignItems,
  collapsibleBody,
  hasEditButtonInMenu = false,
}) => {
  const { permissions } = usePermissions()
  const filteredReferences = (references || []).filter((r) =>
    hasResourcePermission(permissions, r, SYSTEM_PERMISSION_READ),
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const titleValue = getRecordValue(record, title)
  const iconEl = getRecordValue(record, icon)
  const iconColorValue = getRecordValue(record, iconColor)
  const iconBadgeColorValue = getRecordValue(record, iconBadgeColor)
  const imageSrc = getRecordValue(record, image)
  const bodyValue = getRecordValue(record, body, bodyFallback)

  const classes = useStyles({ iconBadgeColor: iconBadgeColorValue })
  const commonClasses = useCommonStyles()
  let colorClass
  if (iconColorValue) {
    colorClass = commonClasses[iconColorValue]
  }

  const [isCollapsibleOpen, setCollapsibleOpen] = useState(false)

  const hasLink = !!linkType

  return (
    <ListItem
      button={hasLink || !!collapsibleBody}
      onClick={!!collapsibleBody ? () => setCollapsibleOpen(!isCollapsibleOpen) : null}
      component={hasLink ? Link : undefined}
      to={recordLink({ basePath, record, linkType })}
      style={style}
      alignItems={alignItems}
      classes={{ root: classes.item }}
    >
      {(imageSrc || iconEl) && (
        <ListItemAvatar>
          {imageSrc ? (
            <Avatar className={commonClasses.whiteLogo} variant="square" alt={titleValue} src={imageSrc} />
          ) : (
            <Badge
              variant="dot"
              overlap="circular"
              invisible={!iconBadgeColorValue}
              classes={{ dot: classes.iconDotBadge }}
            >
              <Avatar className={colorClass}>{iconEl}</Avatar>
            </Badge>
          )}
        </ListItemAvatar>
      )}
      <ListItemText primary={titleValue} secondary={bodyValue} />
      {collapsibleBody && <Collapse in={isCollapsibleOpen}>{collapsibleBody(record)}</Collapse>}
      {(hasEditButtonInMenu || filteredReferences.length > 0) && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="more"
            aria-controls={'menu-' + record.id}
            aria-haspopup="true"
            className={commonClasses.contrastColor}
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
          <Menu id={'menu-' + record.id} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
            {hasEditButtonInMenu && (
              <MenuItemLink
                to={recordLink({ basePath, record, linkType: 'edit' })}
                className={classes.menuItemLink}
                leftIcon={<EditIcon />}
                primaryText="Edit"
              />
            )}
            {filteredReferences
              .map((reference) => {
                const referenceConfig = getResourceByName(reference)
                return referenceConfig ? (
                  <ReferenceMenuItem
                    key={referenceConfig.name}
                    reference={referenceConfig.name}
                    source={referenceConfig.options.referenceKey}
                    record={record}
                    resource={resource}
                    filterValues={referencesFilterValues}
                  />
                ) : null
              })
              .filter(Boolean)}
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

const CompactList = ({ ids, data, inset, Item = CompactListItem, itemStyle, ...props }) => {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      <Divider component="li" />
      {ids.map((id, index) => {
        const record = data[id]
        const itemProps = { record, ...props }
        return (
          <Fragment key={'item-' + id}>
            <Item {...itemProps} style={itemStyle ? itemStyle(record, index) : null} />
            {index < ids.length - 1 && <Divider variant={inset ? 'inset' : undefined} component="li" />}
          </Fragment>
        )
      })}
      {ids.length > 0 && <Divider component="li" />}
    </List>
  )
}

CompactList.defaultProps = {
  data: {},
  ids: [],
}

export default CompactList
