import React, { cloneElement } from 'react'
import { Link, useRecordContext } from 'react-admin'

const AdvancedReferenceField = ({ source, sourceName, reference, attributePrefix, link = true, children }) => {
  const record = useRecordContext()
  if (!record) return null

  if (!children && !sourceName) {
    throw new Error('AdvancedReferenceField requires either a children or a sourceName prop.')
  }

  const entityId = record[source]

  // Stop event propagation to prevent row click action
  const handleClick = (event) => {
    event.stopPropagation()
  }

  attributePrefix += '_'

  const newRecord = Object.entries(record).reduce((acc, [key, value]) => {
    if (key.startsWith(attributePrefix)) {
      const newKey = key.replace(attributePrefix, '')
      acc[newKey] = value
    }
    return acc
  }, {})

  const linkPath = `/${reference}/${entityId}/show`

  const content = children ? cloneElement(children, { record: newRecord }) : record[sourceName] ?? 'n/a'

  return link ? (
    <Link to={linkPath} onClick={handleClick}>
      {content}
    </Link>
  ) : (
    content
  )
}

export default AdvancedReferenceField
