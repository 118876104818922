import { Typography, Tooltip } from '@material-ui/core'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'

import { VEHICLE_TYPES_ICONS, VEHICLE_TYPES } from '../config/vehicles'

const VehicleIconField = ({ record, source, color }) => {
  const translate = useTranslate()
  const tooltip = VEHICLE_TYPES[get(record, source)]
  const icon = VEHICLE_TYPES_ICONS[get(record, source)]
  return icon ? (
    <Typography component="span" variant="body2" color={color}>
      <Tooltip title={translate(tooltip)}>
        <span>
          <icon.type />
        </span>
      </Tooltip>
    </Typography>
  ) : null
}

VehicleIconField.defaultProps = {
  addLabel: true,
  source: 'type',
}

export default VehicleIconField
