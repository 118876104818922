import * as React from 'react'
import { useRedirect } from 'react-admin'
import { IconButton } from '@material-ui/core'
import RedirectIcon from '@material-ui/icons/LaunchRounded'

import { useCommonStyles } from '../config/theme'

const ShowButton = ({ basePath, id, hidden }) => {
  const commonClasses = useCommonStyles()
  const redirect = useRedirect()
  if (!id || hidden) {
    return null
  }
  return (
    <IconButton className={commonClasses.primaryColor} onClick={() => redirect('show', basePath, id)}>
      <RedirectIcon />
    </IconButton>
  )
}

export default ShowButton
