import { Tooltip, Typography } from '@material-ui/core'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'

const AdvancedTextField = ({ record, source, map, tooltip = '', fallback = 'n/a', multiline = false, className }) => {
  const translate = useTranslate()
  let value = get(record, source)
  value = (map ? map[value] : value) || fallback
  if (multiline) {
    value = value.split('\\n').join('\n')
  }
  return (
    <Typography component="span" variant="body2" className={className}>
      <Tooltip title={tooltip}>
        <span style={multiline ? { whiteSpace: 'pre-wrap' } : null}>{translate(value, { _: value })}</span>
      </Tooltip>
    </Typography>
  )
}

AdvancedTextField.defaultProps = {
  addLabel: true,
}

export default AdvancedTextField
