import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useState } from 'react'
import { Confirm, useNotify, useResourceContext } from 'react-admin'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'ra-core'

import { useApi } from '../api/apiProvider'
import { ERROR_MESSAGES, getErrorDataFromError } from '../config/errors'
import { getResourceByName } from '../layouts'
import { pollRefresh } from '../sagas/actions'
import { useSmallScreen } from '../utils/theme'

const useStyles = makeStyles({
  icon: {
    marginRight: '0.35em',
  },
})

const funcOrValue = (object, ...args) => (typeof object === 'function' ? object(...args) : object)

const ActionButton = ({ record, action, children, onSuccess, onFailure, ...props }) => {
  const classes = useStyles()
  const isSmallScreen = useSmallScreen()
  const resource = useResourceContext()
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()

  const resourceConfig = getResourceByName(resource)
  const actionConfig = resourceConfig.options.actions?.[action]
  const actionTranslationBase = `resources.${resource}.actions.${actionConfig?.name}`
  const confirm = funcOrValue(actionConfig?.confirm, { record })
  const Icon = actionConfig?.icon

  const [isPristine, setIsPristine] = useState(true)
  const [confirmData, setConfirmData] = useState({})
  const [error, setError] = useState(null)

  const update = (data) => {
    const newState = { ...confirmData, ...data }
    setConfirmData(newState)
    setError(confirm?.validate?.(newState))
  }

  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const handleClick = () => {
    if (confirm) {
      setConfirmData({})
      setIsPristine(true)
      setConfirmOpen(true)
    } else {
      runQuery()
    }
  }
  const handleClose = () => setConfirmOpen(false)
  const handleConfirm = () => {
    setIsPristine(false)
    if (confirm.validate) {
      const error = confirm.validate(confirmData)
      if (error) {
        setError(error)
        return
      }
    }
    runQuery()
  }

  const [runQuery, { loading }] = useApi(`/${resource}/${record.id}/actions`, {
    method: 'POST',
    body: JSON.stringify({ action, ...confirmData }),
    onSuccess: (response) => {
      setConfirmOpen(false)
      notify(`${actionTranslationBase}.success`)
      if (onSuccess) onSuccess(response)
      if (actionConfig?.refresh) dispatch(pollRefresh())
    },
    onFailure: (error) => {
      const { code, message } = getErrorDataFromError(error)
      const notification = ERROR_MESSAGES[code]
        ? translate(ERROR_MESSAGES[code])
        : translate('mymove.errors.errorWithMessage', { message })
      notify(notification, { type: 'warning' })
      if (onFailure) onFailure(error)
    },
  })

  const actionLabel = `${actionTranslationBase}.` + (isSmallScreen ? 'shortLabel' : 'label')

  return (
    <>
      <Button {...props} color="primary" variant="contained" onClick={handleClick} disabled={loading}>
        {Icon && <Icon className={classes.icon} />}
        {translate(actionLabel)}
      </Button>
      {confirm && (
        <Confirm
          isOpen={isConfirmOpen}
          loading={loading}
          title={actionConfig?.label}
          content={
            <>
              {confirm.content?.({ update, state: confirmData, isPristine, error })}
              <Typography>{' '}</Typography>
            </>
          }
          confirm={confirm.confirmLabel}
          cancel={confirm.cancelLabel}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default ActionButton
