import { CardContent, Dialog, InputAdornment, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { get, isEmpty, pick } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useGetOne,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useTranslate } from 'ra-core'

import bookingDetailsConfig from '../bookingDetails/config'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import PriceField from '../../components/PriceField'
import { BOOKING_TRANSACTION_ALL_TYPES, BOOKING_TRANSACTION_FORM_TYPES } from '../../config/bookingTransactions'
import env from '../../config/env'
import { useCommonStyles } from '../../config/theme'
import {
  isBookingTransactionTypeRefund,
  isBookingTransactionTypeReleasePreAuth,
} from '../../domain/bookingTransactions'
import { formatDateTime } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'

import { useGetTransactionsListForBooking } from './hooks'
import config from './config'

const useStyles = makeStyles({
  paAvailableAmountContainer: {
    marginLeft: 20 + 'px !important',
  },
})

export const transformValues = (values) => ({
  ...values,
  charge_transaction_id: isBookingTransactionTypeRefund(values) ? values.charge_transaction_id : null,
  amount: isBookingTransactionTypeReleasePreAuth(values) ? 0 : values.amount,
})

const AmountNumberInput = ({ maxRefundableAmount, ...props }) => {
  const source = 'amount'
  const { change } = useForm()
  const { values } = useFormState()

  const validateAmount = [
    required(),
    number('mymove.validation.number.invalid'),
    minValue(1 * 100, 'mymove.validation.number.positive'),
    isBookingTransactionTypeRefund(values)
      ? maxValue(maxRefundableAmount, 'resources.booking-transactions.forms.validation.amount.greaterThanCharge')
      : false,
  ]

  useEffect(() => {
    if (isBookingTransactionTypeRefund(values)) {
      change(source, maxRefundableAmount)
    } else {
      change(source, null)
    }
  }, [change, source, values.type, maxRefundableAmount]) // eslint-disable-line react-hooks/exhaustive-deps

  return Boolean(values.type) && !isBookingTransactionTypeReleasePreAuth(values) ? (
    <NumberInput
      {...props}
      source={source}
      validate={validateAmount}
      disabled={isBookingTransactionTypeRefund(values) && !Boolean(values.charge_transaction_id)}
      format={(v) => (v ? v / 100 : null)}
      parse={(v) => v * 100}
      min={1}
      max={isBookingTransactionTypeRefund(values) ? maxRefundableAmount / 100 : null}
      options={{
        InputProps: { endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> },
      }}
    />
  ) : null
}

const BookingTransactionFormLayout = ({ onClose, ...props }) => {
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, props.record?.booking_id, {
    enabled: Boolean(props.record?.booking_id),
  })
  const { data: bookingTransactions } = useGetTransactionsListForBooking(props.record?.booking_id)

  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])

  const bookingTransactionTypeChoices = useMemo(
    () =>
      Object.entries(BOOKING_TRANSACTION_FORM_TYPES).map(([k, v]) => ({
        id: k,
        name: v,
        disabled: !bookingDetails?.allowed_transaction_actions.includes(k),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(bookingDetails?.allowed_transaction_actions)],
  )

  const bookingRefundableTransactionsChoices = useMemo(
    () =>
      Object.entries(bookingTransactions)
        .filter(([k, v]) => v.refundable_amount > 0)
        .map(([k, v]) => ({
          id: k,
          name: `${translate(BOOKING_TRANSACTION_ALL_TYPES[v.type])} - ${formatDateTime(v.created_on)}`,
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(bookingTransactions)],
  )

  if (
    !Boolean(props.record?.booking_id) ||
    isEmpty(bookingDetails?.allowed_transaction_actions) ||
    isEmpty(bookingTransactions)
  ) {
    return null
  }

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.booking-transactions.forms.create.pageTitle" />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <SelectInput
          source="type"
          choices={bookingTransactionTypeChoices}
          optionText="name"
          optionValue="id"
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isBookingTransactionTypeRefund(formData) && (
              <SelectInput
                {...rest}
                source="charge_transaction_id"
                choices={bookingRefundableTransactionsChoices}
                optionText="name"
                optionValue="id"
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AmountNumberInput
              {...rest}
              maxRefundableAmount={
                Boolean(formData.charge_transaction_id)
                  ? bookingTransactions[formData.charge_transaction_id].refundable_amount
                  : null
              }
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer
          formClassName={classnames(
            commonClasses.fullWidth,
            commonClasses.noMarginTop,
            classes.paAvailableAmountContainer,
          )}
        >
          {({ formData }) =>
            isBookingTransactionTypeReleasePreAuth(formData) && (
              <Typography variant="body2" component="span">
                {translate('resources.booking-transactions.forms.releasedAmountText')}
                <PriceField record={bookingDetails} source="pa_available_amount" addLabel={false} />
              </Typography>
            )
          }
        </FormDataConsumer>
        <FormDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <TextInput
              {...rest}
              source="justification"
              validate={Boolean(formData.type) && !isBookingTransactionTypeReleasePreAuth(formData) ? required() : null}
              className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </>
  )
}

const BookingTransactionFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')

  let controllerProps = useCreateController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.create.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    onClose,
  }

  return <BookingTransactionFormLayout {...controllerProps} />
}

export const CreateBookingTransactionFormLayoutController = (props) => (
  <BookingTransactionFormLayoutController {...props} />
)

export const useCreateBookingTransaction = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateBookingTransactionFormLayoutController
        initialValues={createPopupState.values}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export default BookingTransactionFormLayout
