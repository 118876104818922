import { ImageField, ReferenceField, Show, SimpleShowLayout } from 'react-admin'
import { Box, CardContent } from '@material-ui/core'
import classnames from 'classnames'

import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import ReferenceValue from '../../components/ReferenceValue'
import UserFullNameField from '../../components/UserFullNameField'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'
import { CardTitle } from '../common'
import { ShowReferenceLinks, Title, useShowStyles } from '../common/show'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import vehiclesConfig from '../vehicles/config'
import VehicleField from '../vehicles/field'

import MarkerField from './field'
import config from './config'

export const DamageReportShowLayout = ({ excluded = [], ...props }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.damage-reports.show.titles.main" />
      </CardContent>
      <div className={isSmallScreen ? null : showClasses.row}>
        <div className={showClasses.expanded}>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <OrganisationField />
            <HubField />
            <VehicleField />
            {hasReadForUsers && (
              <ReferenceField source="reporter_id" reference="users" link="show">
                <UserFullNameField />
              </ReferenceField>
            )}
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField
              source="category"
              fallback="resources.damage-reports.enums.category.none"
              map={DAMAGES_CATEGORIES}
            />
            <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
            <DateTooltipField source="created_on" addTime />
            <DateTooltipField source="updated_on" addTime />
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField source="note" fallback="resources.damage-reports.show.note.fallback" multiline />
          </SimpleShowLayout>
        </div>
        {(props.record.blueprint_id || props.record.picture) && (
          <Box
            width="100%"
            maxWidth={isSmallScreen ? null : 480}
            className={isSmallScreen ? null : commonClasses.borderLeft}
          >
            {props.record.blueprint_id && (
              <SimpleShowLayout
                {...props}
                className={classnames(showClasses.fieldContainer, isSmallScreen ? null : commonClasses.center)}
              >
                <MarkerField containerWidth={356} markerSize={28} />
              </SimpleShowLayout>
            )}
            {props.record.picture && (
              <SimpleShowLayout
                {...props}
                className={classnames(showClasses.fieldContainer, isSmallScreen ? null : commonClasses.center)}
              >
                <ImageField source="picture" addLabel={false} classes={{ image: showClasses.largeImage }} />
              </SimpleShowLayout>
            )}
          </Box>
        )}
      </div>
      <ShowReferenceLinks excluded={excluded} {...props} />
    </>
  )
}

const DamageReportDetailsTitle = ({ damageReport }) => {
  const commonClasses = useCommonStyles()
  return (
    <>
      <ReferenceValue
        record={damageReport}
        reference="organisations"
        source="organisation_id"
        target="name"
        className={commonClasses.inheritedFont}
      />
      {' • '}
      <ReferenceValue
        record={damageReport}
        reference="vehicles"
        source="vehicle_id"
        target={vehiclesConfig.options.getName}
        className={commonClasses.inheritedFont}
      />
    </>
  )
}

export const DamageReportTitle = (props) => {
  const damageReport = props.record
  const details = props.details || <DamageReportDetailsTitle damageReport={damageReport} />
  return (
    <Title resourceConfig={config} {...props}>
      {details}
    </Title>
  )
}

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show {...props} classes={{ main: showClasses.main }} title={<DamageReportTitle />}>
      <DamageReportShowLayout />
    </Show>
  )
}
