import countries from '../config/countries.json'

import AdvancedAutocompleteInput from './AdvancedAutocompleteInput'

const CountryInput = (props) => {
  const choices = countries.map(({ code, name }) => ({ id: code, name }))
  return (
    <AdvancedAutocompleteInput
      {...props}
      choices={choices}
      getOptionLabel={(record) => record.name}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  )
}

export default CountryInput
