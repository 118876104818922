import { useGetMany, useVersion } from 'react-admin'
import get from 'lodash/get'

const ReferenceValueBase = ({ children, classes, loadingText, ...props }) => {
  const version = useVersion()
  const { data, loading, loaded } = useGetMany(props.reference, [props.id], { version })
  const record = data ? data[0] : null
  const childrenProps = {
    ...props,
    resource: props.reference,
    basePath: '/' + props.reference,
    record,
    loading,
    loaded,
    version,
  }
  return record ? children(childrenProps) : loadingText
}

const ReferenceValue = ({ reference, source, record, target, loadingText = '...', fallback = null, id }) => {
  if (!record) {
    return loadingText
  }
  const finalId = id || get(record, source)
  return finalId ? (
    <ReferenceValueBase reference={reference} id={finalId} loadingText={loadingText}>
      {({ record: referenceRecord, loaded }) => {
        if (!loaded) {
          return loadingText
        }
        return typeof target === 'function' ? target(referenceRecord) : get(referenceRecord, target, fallback)
      }}
    </ReferenceValueBase>
  ) : (
    fallback
  )
}

export default ReferenceValue
