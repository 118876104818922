import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGetMany, useDataProvider, useNotify, linkToRecord } from 'react-admin'
import { renderToString } from 'react-dom/server'
import { get, keyBy, uniq } from 'lodash'
import classnames from 'classnames'
import { CardHeader, Avatar, Box, ButtonGroup, Button } from '@material-ui/core'
import PlusIcon from '@material-ui/icons/Add'
import PreviousIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'
import MinusIcon from '@material-ui/icons/Remove'

import { useAccounts } from '../api/accountsProvider'
import { useApi } from '../api/apiProvider'
import { BOOKING_BILLING_TYPES_ICONS } from '../config/bookings'
import { SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../config/permissions'
import { STATUS_CODES, STATUS_SEVERITY_CRITICAL } from '../config/statuses'
import { useCommonStyles } from '../config/theme'
import {
  VEHICLE_UNAVAILABILITY_TYPES,
  VEHICLE_UNAVAILABILITY_TYPES_COLORS,
  VEHICLE_UNAVAILABILITY_TYPE_OTHER,
} from '../config/vehicleUnavailabilities'
import { isBookingEditable } from '../domain/bookings'
import { useResourcePermissions } from '../domain/permissions'
import { getResourceByName } from '../layouts'
import bookingsConfig from '../layouts/bookings/config'
import vehicleUnavailabilitiesConfig from '../layouts/vehicleUnavailabilities/config'
import { toSentence } from '../utils'
import {
  getDateBoundsWithOffsets,
  formatDateTime,
  getMaxDate,
  getMinDate,
  getTodayDateBounds,
  getWeekDateBounds,
  parseDateAsISO,
} from '../utils/dates'
import { getRandomColorFromString } from '../utils/theme'
import { defaultPresets, defaultPresetName } from '../vendor/bryntum'
import BryntumScheduler from '../vendor/bryntum/BryntumScheduler'

import { getBookingConsumedIcon } from './BookingConsumedField'

const getFilterFromDates = ({ startDate, endDate }) => ({
  timeline_start: startDate,
  timeline_end: endDate,
})

const getDatesFromEvent = (event) => {
  const isBooking = event.resource === bookingsConfig.name
  const startDate = new Date(isBooking ? event.effective_started_on : event.started_on)
  const endDate = new Date(isBooking ? event.effective_ended_on : event.ended_on)
  return { startDate, endDate }
}

const Scheduler = (props) => {
  const scheduler = useRef()
  const notify = useNotify()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const commonClasses = useCommonStyles()
  const { currentAccount } = useAccounts()

  const [hasReadForBookings, hasEditForBookings] = useResourcePermissions(
    bookingsConfig.name,
    SYSTEM_PERMISSION_READ,
    SYSTEM_PERMISSION_UPDATE,
  )
  const [hasReadForVehicleUnavailabilities, hasEditForVehicleUnavailabilities] = useResourcePermissions(
    vehicleUnavailabilitiesConfig.name,
    SYSTEM_PERMISSION_READ,
    SYSTEM_PERMISSION_UPDATE,
  )

  const [defaultDates] = useState(getDateBoundsWithOffsets({ days: -14 }, { days: 14 }))
  const [defaultStartDate, defaultEndDate] = defaultDates
  const [dates, setDates] = useState({ startDate: defaultStartDate, endDate: defaultEndDate })
  const minStartDate = useRef(defaultStartDate)
  const maxEndDate = useRef(defaultEndDate)
  useEffect(() => {
    minStartDate.current = getMinDate(minStartDate.current, dates.startDate)
    maxEndDate.current = getMaxDate(maxEndDate.current, dates.endDate)
  }, [dates])

  const relatedConfig = getResourceByName(props.relatedResource)
  const groupConfig = getResourceByName(props.groupResource)

  let [fetchBookings, { data: bookings }] = useApi(`/bookings`, {
    method: 'GET',
    params: {
      filter: {
        ...props.filter,
        cancelled: false,
        ...getFilterFromDates(dates),
      },
      pagination: { page: 1, perPage: 99999 },
    },
  })
  bookings = bookings || []

  let [fetchVehicleUnavailabilities, { data: vehicleUnavailabilities }] = useApi(
    '/' + vehicleUnavailabilitiesConfig.name,
    {
      method: 'GET',
      params: {
        filter: {
          ...props.filter,
          ...getFilterFromDates(dates),
        },
        pagination: { page: 1, perPage: 99999 },
      },
    },
  )
  vehicleUnavailabilities = vehicleUnavailabilities || []

  let [fetchGroups, { data: rawGroups }] = useApi(`/` + props.groupResource, {
    method: 'GET',
    params: {
      filter: props.groupFilter,
      pagination: { page: 1, perPage: 99999 },
    },
  })

  const groups = useMemo(() => {
    return (rawGroups || []).map((i) => ({
      ...i,
      imageUrl: i.picture,
      name: groupConfig.options.getName(i),
      category: translate(get(props.groupCategories, get(i, props.groupCategoryKey), get(i, props.groupCategoryKey))),
    }))
  }, [rawGroups, translate]) // eslint-disable-line react-hooks/exhaustive-deps

  const resourceTimeRanges = useMemo(() => {
    const ranges = []
    groups.forEach((g) => {
      if (g.status?.severity === STATUS_SEVERITY_CRITICAL) {
        const codes = g.status.codes.map((c) => `"${translate(STATUS_CODES[c]).toLowerCase()}"`)
        const name =
          scheduler.current.schedulerEngine.zoomLevel > 1
            ? `${translate('resources.vehicles.criticalOperationalStatus')} ${toSentence(codes)}`
            : ''
        ranges.push({
          duration: 7,
          name,
          resourceId: g.id,
          startDate: new Date(),
          style:
            'justify-content: flex-start; align-items: center; color: #1F1F1F; font-size: 16px; padding: 0.75em; font-weight: bold; background: repeating-linear-gradient(315deg, #F5BBB7, #F5BBB7 5px, #E8EDEC 5px, #E8EDEC 10px);',
        })
      }
      if (g.ended_on) {
        ranges.push({
          duration: 9999,
          name: translate('resources.vehicles.outOfCurrentLease'),
          resourceId: g.id,
          startDate: new Date(g.ended_on),
          style:
            'justify-content: flex-start; align-items: center; color: #474747; font-size: 16px; padding: 0.75em; font-weight: bold; background: repeating-linear-gradient(315deg, #F0F0F0, #F0F0F0 5px, #D6D6D6 5px, #D6D6D6 10px);',
        })
      }
    })
    return ranges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, scheduler?.current?.schedulerEngine?.zoomLevel, translate])

  const groupCacheRef = useRef({})
  useEffect(() => {
    groupCacheRef.current = { ...groupCacheRef.current, ...keyBy(groups, 'id') }
  }, [groups]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasReadForBookings) {
      fetchBookings()
    }
    if (hasReadForVehicleUnavailabilities && !props.filter.user_id) {
      fetchVehicleUnavailabilities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(dates), // eslint-disable-line react-hooks/exhaustive-deps
    hasReadForBookings,
    hasReadForVehicleUnavailabilities,
    fetchBookings,
    fetchVehicleUnavailabilities,
    props.version,
  ])

  useEffect(() => {
    if (props.groupResource) {
      fetchGroups()
    }
  }, [JSON.stringify(props.groupFilter), fetchGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  const [hasReadForRelatedResource] = useResourcePermissions(props.relatedResource, SYSTEM_PERMISSION_READ)
  const relatedIds = useMemo(() => uniq(bookings.map((b) => b[relatedConfig.options.referenceKey])), [bookings]) // eslint-disable-line react-hooks/exhaustive-deps
  let { data: relatedItems } = useGetMany(props.relatedResource, relatedIds, { enabled: hasReadForRelatedResource })
  const relatedCacheRef = useRef({})
  const relatedCache = useMemo(() => {
    relatedCacheRef.current = {
      ...relatedCacheRef.current,
      ...keyBy(relatedItems, 'id'),
    }
    return relatedCacheRef.current
  }, [relatedItems])

  const schedule = useMemo(
    () =>
      [
        ...bookings.map((b) => ({ ...b, resource: bookingsConfig.name })),
        ...vehicleUnavailabilities.map((u) => ({ ...u, resource: vehicleUnavailabilitiesConfig.name })),
      ].map((event) => {
        const refKey = event[relatedConfig.options.referenceKey]
        const isBooking = event.resource === bookingsConfig.name
        const { startDate, endDate } = getDatesFromEvent(event)

        const name = isBooking
          ? relatedConfig.options.getName(relatedCache[refKey]).trim() || translate('resources.bookings.name', 1)
          : event.type === VEHICLE_UNAVAILABILITY_TYPE_OTHER
          ? translate('resources.maintenances.otherUnavailability')
          : translate(VEHICLE_UNAVAILABILITY_TYPES[event.type])
        const eventColor = isBooking
          ? getRandomColorFromString(refKey)
          : VEHICLE_UNAVAILABILITY_TYPES_COLORS[event.type]
        const icons = isBooking
          ? [get(BOOKING_BILLING_TYPES_ICONS, event.billing_type), getBookingConsumedIcon(event)]
          : [vehicleUnavailabilitiesConfig.icon]

        const item = {
          ...event,
          name,
          startDate,
          endDate,
          eventColor,
          link: linkToRecord(event.resource, event.id, 'show'),
          icons,
        }

        if (props.groupResource) {
          item.resourceId = event[groupConfig.options.referenceKey]
        }

        return item
      }),
    [JSON.stringify({ bookings, vehicleUnavailabilities, relatedCache })], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onNavigate = useCallback((startDate, endDate) => setDates({ startDate, endDate }), [setDates])

  const canEditResource = useCallback(
    (data) => {
      if (data.resource === bookingsConfig.name) {
        return hasEditForBookings && isBookingEditable(data)
      }
      if (data.resource === vehicleUnavailabilitiesConfig.name) {
        return hasEditForVehicleUnavailabilities
      }
      return false
    },
    [hasEditForBookings, hasEditForVehicleUnavailabilities],
  )

  // We use a ref here to retrieve the updated value for the currentAccount object
  const onSeeEventItemPress = useRef()
  onSeeEventItemPress.current = (eventRecord) => {
    if (!currentAccount) return
    const { resource, id } = eventRecord.data
    const link = linkToRecord(resource, id, 'show')
    window.open('/' + currentAccount.slug + '/' + link, '_blank')
  }

  // We use a ref here to retrieve the updated value for the activeLeases array
  const eventDragCreateValidatorFunc = useRef()
  eventDragCreateValidatorFunc.current = (resourceRecord, startDate, endDate) => {
    // Prevent creating anything before the vehicle is available
    if (startDate < new Date(resourceRecord.data.started_on)) {
      return false
    }
    // Prevent creating anything after the vehicle becomes unavailable
    if (resourceRecord.data.ended_on && startDate > new Date(resourceRecord.data.ended_on)) {
      return false
    }
    // Prevent creating anything ending after the vehicle becomes unavailable
    if (resourceRecord.data.ended_on && endDate > new Date(resourceRecord.data.ended_on)) {
      return false
    }
    // Prevent creating anything in the past
    if (startDate < Date.now()) {
      return false
    }
    return true
  }

  const features = useMemo(() => {
    const feats = {
      regionResize: false,
      timeRanges: { showCurrentTimeLine: true },
      resourceTimeRanges: true,
      stripe: true,
      sort: 'name',

      eventTooltip: {
        template: (data) => {
          const resourceRecord = get(groupCacheRef.current, data.eventRecord.get(groupConfig.options.referenceKey))
          const isBooking = data.eventRecord.get('resource') === bookingsConfig.name
          const icons = data.eventRecord.get('icons')
          return renderToString(
            <>
              <div className="b-sch-event-tooltip-icon">
                {icons.map((Icon) => (
                  <Icon key={Icon.displayName} />
                ))}
              </div>
              <div className="b-sch-event-tooltip-header">
                <div className="b-sch-event-tooltip-title">{data.eventRecord.get('name')}</div>
                {resourceRecord && <div className="b-sch-event-tooltip-subtitle">{resourceRecord.name}</div>}
              </div>
              <dl>
                {isBooking && (
                  <>
                    <dt>{translate('mymove.scheduler.scheduledOn')}</dt>
                    <dd>
                      {formatDateTime(data.eventRecord.get('start_scheduled_on'))} →{' '}
                      {formatDateTime(data.eventRecord.get('end_scheduled_on'))}
                    </dd>
                  </>
                )}
                <dt>{translate(isBooking ? 'mymove.scheduler.usedOn' : 'mymove.scheduler.period')}</dt>
                <dd>
                  {formatDateTime(data.eventRecord.get('started_on'))} →{' '}
                  {formatDateTime(data.eventRecord.get('ended_on'))}
                </dd>
              </dl>
            </>,
          )
        },
      },

      eventContextMenu: {
        items: { deleteEvent: false, unassignEvent: false },
        processItems: ({ eventRecord, items }) => {
          // "Edit" & "See" buttons when right-clicking on an event
          const { resource } = eventRecord.data
          const itemName = translate(`resources.${resource}.name`, 1).toLowerCase()
          const canEdit = canEditResource(eventRecord.data)
          const canRead =
            (resource === bookingsConfig.name && hasReadForBookings) ||
            (resource === vehicleUnavailabilitiesConfig.name && hasReadForVehicleUnavailabilities)
          if (canEdit) {
            items.editEvent.text = translate('mymove.scheduler.edit') + ' ' + itemName
          } else {
            items.editEvent = false
          }
          items.seeEvent = canRead
            ? {
                text: translate('mymove.scheduler.see') + ' ' + itemName,
                icon: 'b-fa b-fa-share-square',
                onItem: ({ eventRecord }) => onSeeEventItemPress.current(eventRecord),
              }
            : false
        },
      },

      eventDrag: {
        showExactDropPosition: true,
        constrainDragToResource: false,
        validatorFn: ({ startDate, record }) => {
          const { resource } = record.data
          // Prevent moving a booking to the past
          if (resource === bookingsConfig.name && startDate < Date.now()) {
            return false
          }
          return true
        },
      },

      eventResize: {
        showExactResizePosition: true,
        validatorFn: ({ startDate, endDate, eventRecord, edge }) => {
          const { resource, startDate: originalStartDate } = eventRecord.data

          if (resource === bookingsConfig.name) {
            // Prevent resizing a finished booking
            if (endDate < Date.now()) {
              return false
            }
            // Prevent resizing a current booking start date
            if (edge === 'left' && originalStartDate < Date.now()) {
              return false
            }
            // Prevent resizing a future booking into the past
            if (edge === 'left' && startDate < Date.now()) {
              return false
            }
          }
          return true
        },
      },

      eventDragCreate: {
        validatorFn: ({ resourceRecord, startDate, endDate }) =>
          eventDragCreateValidatorFunc.current(resourceRecord, startDate, endDate),
      },

      scheduleContextMenu: { items: { addEvent: false } },
    }

    if (props.groupCategories) {
      feats.group = 'category'
    }

    return feats
  }, [hasReadForBookings, hasEditForBookings, hasReadForVehicleUnavailabilities, hasEditForVehicleUnavailabilities]) // eslint-disable-line react-hooks/exhaustive-deps

  const listeners = useMemo(() => {
    return {
      timeAxisChange: ({ config: { startDate, endDate } }) => {
        onNavigate(startDate, endDate)
      },
      beforeEventDrag: ({ eventRecord }) => {
        const { data, startDate } = eventRecord
        const isBooking = data.resource === bookingsConfig.name
        if (!canEditResource(data)) {
          return false
        }
        // Prevent moving a booking in the past
        if (isBooking && startDate < Date.now()) {
          return false
        }
        return true
      },
      beforeEventResize: ({ eventRecord }) => {
        const { data, endDate } = eventRecord
        if (!canEditResource(data)) {
          return false
        }
        if (data.resource === bookingsConfig.name && endDate < Date.now()) {
          return false
        }
        return true
      },
      beforeEventDropFinalize: async ({ context }) => {
        context.async = true
        const result = window.confirm(translate('mymove.scheduler.moveElementConfirmation'))
        context.finalize(result)
      },
      beforeEventEdit: ({ eventRecord, resourceRecord }) => {
        const { isPhantom, startDate, endDate } = eventRecord
        if (isPhantom) {
          if (startDate < Date.now()) {
            return false
          }
          props.onBookingCreate({
            vehicle_id: props.groupResource === 'vehicles' ? resourceRecord.get('id') : null,
            start_scheduled_on: startDate,
            end_scheduled_on: endDate,
          })
        } else {
          const { resource, id } = eventRecord.data
          if (resource === bookingsConfig.name && canEditResource(eventRecord.data)) {
            props.onBookingEdit(id)
          } else if (resource === vehicleUnavailabilitiesConfig.name && canEditResource(eventRecord.data)) {
            props.onVehicleUnavailabilityEdit(id)
          }
        }
        return false
      },
    }
  }, [hasEditForBookings, hasEditForVehicleUnavailabilities]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() => {
    return [
      {
        text: 'Category',
        field: 'category',
        hidden: true,
      },
      {
        type: 'resourceInfo',
        showImage: true,
        text: groupConfig.options.singleLabel,
        field: 'name',
        width: 240,
        autoScaleThreshold: 0,
        renderer: (data) => {
          return renderToString(
            <CardHeader
              style={{ padding: 0 }}
              avatar={
                data.record.picture ? (
                  <Box width={60} height={60} style={{ position: 'relative' }}>
                    <img
                      className={classnames(commonClasses.absoluteFill, commonClasses.objectContain)}
                      alt={data.record.name}
                      src={data.record.picture}
                    />
                  </Box>
                ) : (
                  <Avatar alt={data.record.name}>{(data.record.name || ' ').charAt(0)}</Avatar>
                )
              }
              title={
                typeof props.groupNameKey === 'function'
                  ? props.groupNameKey(data.record)
                  : data.record.get(props.groupNameKey)
              }
              subheader={
                typeof props.groupAccessoryNameKey === 'function'
                  ? props.groupAccessoryNameKey(data.record)
                  : data.record.get(props.groupAccessoryNameKey)
              }
            />,
          )
        },
      },
    ]
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const eventStore = useMemo(() => {
    return {
      onUpdate: ({ record, changes }) => {
        const { resourceId, startDate, endDate } = changes
        if (!Boolean(resourceId) && !Boolean(startDate) && !Boolean(endDate)) {
          return true
        }
        const { resource } = record.data
        if (resource === bookingsConfig.name) {
          const { id, organisation_id, hub_id, vehicle_id, billing_type, start_scheduled_on, end_scheduled_on } =
            record.data
          const updatedValues = {
            organisation_id,
            hub_id,
            vehicle_id: Boolean(resourceId) ? resourceId.value : vehicle_id,
            billing_type,
            start_scheduled_on: Boolean(startDate) ? parseDateAsISO(startDate.value) : start_scheduled_on,
            end_scheduled_on: Boolean(endDate) ? parseDateAsISO(endDate.value) : end_scheduled_on,
          }
          const rollbackValues = {}
          if (Boolean(resourceId)) {
            rollbackValues.vehicle_id = resourceId.oldValue
            rollbackValues.resourceId = resourceId.oldValue
          }
          if (Boolean(startDate)) {
            rollbackValues.start_scheduled_on = parseDateAsISO(startDate.oldValue)
            rollbackValues.startDate = startDate.oldValue
          }
          if (Boolean(endDate)) {
            rollbackValues.end_scheduled_on = parseDateAsISO(endDate.oldValue)
            rollbackValues.endDate = endDate.oldValue
          }
          record.set(updatedValues)
          dataProvider.update(bookingsConfig.name, { id, data: updatedValues }).catch((error) => {
            notify(error.message, { type: 'warning' })
            record.set(rollbackValues)
          })
        } else if (resource === vehicleUnavailabilitiesConfig.name) {
          const { id, organisation_id, hub_id, vehicle_id, started_on, ended_on } = record.data
          const updatedValues = {
            organisation_id,
            hub_id,
            vehicle_id: Boolean(resourceId) ? resourceId.value : vehicle_id,
            started_on: Boolean(startDate) ? parseDateAsISO(startDate.value) : started_on,
            ended_on: Boolean(endDate) ? parseDateAsISO(endDate.value) : ended_on,
          }
          const rollbackValues = {}
          if (Boolean(resourceId)) {
            rollbackValues.vehicle_id = resourceId.oldValue
            rollbackValues.resourceId = resourceId.oldValue
          }
          if (Boolean(startDate)) {
            rollbackValues.started_on = parseDateAsISO(startDate.oldValue)
            rollbackValues.startDate = startDate.oldValue
          }
          if (Boolean(endDate)) {
            rollbackValues.ended_on = parseDateAsISO(endDate.oldValue)
            rollbackValues.endDate = endDate.oldValue
          }
          record.set(updatedValues)
          dataProvider.update(vehicleUnavailabilitiesConfig.name, { id, data: updatedValues }).catch((error) => {
            notify(error.message, { type: 'warning' })
            record.set(rollbackValues)
          })
        }
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Fix bug where permissions are not yet loaded, causing the scheduler to initially render with wrong settings
  const permissions = [
    hasReadForBookings,
    hasEditForBookings,
    hasReadForVehicleUnavailabilities,
    hasEditForVehicleUnavailabilities,
  ]
  if (permissions.some((p) => p === undefined)) {
    return null
  }

  return (
    <div>
      <Box className={commonClasses.borderBottom} flex={1} display="flex" justifyContent="flex-end">
        <Box p={2} display="flex">
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomTo({
                    preset: 'hourAndDayCustom',
                    startDate: getTodayDateBounds()[0],
                    endDate: getTodayDateBounds()[1],
                  })
                }}
              >
                {translate('mymove.dates.today')}
              </Button>
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomToSpan({
                    startDate: getWeekDateBounds()[0],
                    endDate: getWeekDateBounds()[1],
                  })
                }}
              >
                {translate('mymove.dates.week')}
              </Button>
              <Button
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomTo({
                    preset: 'weekAndMonthCustom',
                  })
                }}
              >
                {translate('mymove.dates.month')}
              </Button>
            </ButtonGroup>
          </Box>
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button
                disabled={
                  scheduler.current &&
                  scheduler.current.schedulerEngine.zoomLevel <= scheduler.current.schedulerEngine.minZoomLevel
                }
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomOut()
                }}
              >
                <MinusIcon />
              </Button>
              <Button
                disabled={
                  scheduler.current &&
                  scheduler.current.schedulerEngine.zoomLevel >= scheduler.current.schedulerEngine.maxZoomLevel
                }
                onClick={() => {
                  scheduler.current.schedulerEngine.zoomIn()
                }}
              >
                <PlusIcon />
              </Button>
            </ButtonGroup>
          </Box>
          <Box ml={1}>
            <ButtonGroup color="primary" size="small">
              <Button onClick={() => scheduler.current.schedulerEngine.shiftPrevious()}>
                <PreviousIcon />
              </Button>
              <Button onClick={() => scheduler.current.schedulerEngine.shiftNext()}>
                <NextIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <BryntumScheduler
        ref={scheduler}
        autoHeight
        rowHeight={60}
        presets={defaultPresets}
        viewPreset={defaultPresetName}
        useInitialAnimation={false}
        allowOverlap={false}
        snap
        eventRenderer={({ eventRecord }) => ({
          headerText: eventRecord.name,
          footerText: formatDateTime(eventRecord.startDate) + ' → ' + formatDateTime(eventRecord.endDate),
        })}
        eventBodyTemplate={(data) => `
          <section>
            <div className="b-sch-event-header">${data.headerText}</div>
            <div className="b-sch-event-footer">${data.footerText}</div>
          </section>
        `}
        resources={groups}
        resourceTimeRanges={resourceTimeRanges}
        events={schedule}
        features={features}
        columns={columns}
        listeners={listeners}
        eventStore={eventStore}
      />
    </div>
  )
}

export default Scheduler
