import { Datagrid, Filter, List } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import { getVehicleName } from '../../components/VehicleNameField'
import { SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CreateButton, EditButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'
import VehicleField from '../vehicles/field'
import VehiclesFilter from '../vehicles/filter'

import { useCreateVehicleUnavailability, useEditVehicleUnavailability } from './form'
import { CreateVehicleUnavailabilityButton, DeleteVehicleUnavailabilityButton } from './buttons'
import config from './config'

const VehicleUnavailabilitiesFilters = (props) => {
  const { hasSingleOrganisation } = useCurrentAccountSelectors()
  const listClasses = useListStyles()
  return (
    <Filter variant="outlined" classes={{ form: listClasses.filterForm }} {...props}>
      {!hasSingleOrganisation && <OrganisationsFilter alwaysOn />}
      <VehiclesFilter alwaysOn />
    </Filter>
  )
}

export const VehicleUnavailabilitiesListLayout = ({ excluded = [], hasEdit, hasDelete, createProps, ...props }) => {
  const { hasSingleOrganisation } = useCurrentAccountSelectors()
  const [openEditPopup, editPopupDialog] = useEditVehicleUnavailability()

  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()

  return (
    <GenericListLayout
      action={
        <div className={commonClasses.toolbar}>
          {createProps && <CreateVehicleUnavailabilityButton {...createProps} />}
        </div>
      }
      compactListLayout={
        <CompactList
          {...props}
          alignItems="flex-start"
          linkType="show"
          icon={<config.icon />}
          title={(record) => (
            <ReferenceValue
              record={record}
              reference="vehicles"
              source="vehicle_id"
              target={(v) => (v ? getVehicleName(v) : '')}
            />
          )}
          body={(record) => (
            <>
              <StaticText>{'resources.maintenances.fields.type'}: </StaticText>
              <AdvancedTextField record={record} source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
              <br />
              <PeriodField record={record} startedOnSource="started_on" endedOnSource="ended_on" addTime />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show">
          {isAllowed(excluded, 'organisations') && !hasSingleOrganisation && <OrganisationField sortable={false} />}
          {isAllowed(excluded, 'vehicles') && <VehicleField />}
          <AdvancedTextField source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
          <DateTooltipField source="started_on" addTime />
          <DateTooltipField source="ended_on" addTime />
          {hasEdit && <EditButton onClick={openEditPopup} />}
          {hasDelete && <DeleteVehicleUnavailabilityButton />}
        </Datagrid>
      }
      editPopupDialog={editPopupDialog}
    />
  )
}

export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateVehicleUnavailability({
    defaultValues: ['organisation_id', 'vehicle_id'],
  })
  const [hasDelete] = useResourcePermissions(props.resource, SYSTEM_PERMISSION_DELETE)

  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<VehicleUnavailabilitiesFilters />}
        actions={
          <ListActions>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <VehicleUnavailabilitiesListLayout hasEdit={props.hasEdit} hasDelete={hasDelete} />
      </List>
      {createPopupDialog}
    </>
  )
}
