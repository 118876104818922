import LuxonUtils from '@date-io/luxon'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createBrowserHistory as createHistory } from 'history'
import createSimpleRestProvider from 'ra-data-simple-rest'
import * as React from 'react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Admin, Resource, RouteWithoutLayout } from 'react-admin'
import { Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import createAccountsProvider, { AccountsProvider } from './api/accountsProvider'
import createApiClient from './api/apiClient'
import createApiProvider, { ApiProvider } from './api/apiProvider'
import createAuthProvider from './api/authProvider'
import createDataProvider from './api/dataProvider'
import firebaseClient from './api/firebaseClient'
import createLocalApiClient from './api/localApiClient'
import createLocalAuthProvider from './api/localAuthProvider'
import createPermissionsProvider from './api/permissionsProvider'
import Layout from './components/Layout'
import NotFound from './components/NotFound'
import env from './config/env'
import i18nProvider from './config/i18nProvider'
import './config/overrides'
import {
  SYSTEM_PERMISSION_CREATE,
  SYSTEM_PERMISSION_READ,
  SYSTEM_PERMISSION_UPDATE,
  UI_PERMISSION_MENU,
} from './config/permissions'
import { hasResourcePermission } from './domain/permissions'
import { list as resources } from './layouts'
import { AccountEditPage } from './layouts/account/edit'
import { AccountShowPage } from './layouts/account/show'
import { LoginPage, RedirectPage, ResetPage } from './layouts/common/login'
import { PayoutBankAccountCreateScreen } from './layouts/payout/screens/addBankAccount'
import { PayoutMarketplaceModelEditScreen } from './layouts/payout/screens/editMarketplaceModel'
import { PayoutSetupScreen } from './layouts/payout/screens/setup'
import { PayoutShowScreen } from './layouts/payout/screens/show'
import keyboardReducer from './reducers/keyboard'
import themeReducer from './reducers/theme'
import rootSaga from './sagas/root'
import './vendor'

Sentry.init({ dsn: env.SENTRY_DSN, integrations: [], environment: env.ENV_NAME })

const baseTitle = document.title
export const useDocumentTitle = (funcOrString, displayBaseTitle = false) =>
  useLayoutEffect(() => {
    const documentTitle = typeof funcOrString === 'function' ? funcOrString() : funcOrString
    document.title = (documentTitle ?? '') + (displayBaseTitle ? ' - ' + baseTitle : '')
  })

const extractSlugFromPath = (path) => path.split('/')?.[1]

const getResourceWithPermissions = (resource, permissions) => {
  const resourceName = resource.name.split('/')[0]
  const hasPermission = (permission) => hasResourcePermission(permissions, resourceName, permission)
  if (!hasPermission(SYSTEM_PERMISSION_READ)) {
    return null
  }
  const { list, show, create, edit, ...rest } = resource
  const props = {
    ...rest,
    list: (hasPermission(SYSTEM_PERMISSION_READ) ? list : null) || null,
    show: (hasPermission(SYSTEM_PERMISSION_READ) ? show : null) || null,
    create: (hasPermission(SYSTEM_PERMISSION_CREATE) ? create : null) || null,
    edit: (hasPermission(SYSTEM_PERMISSION_UPDATE) ? edit : null) || null,
  }
  return <Resource {...props} />
}

const initialPath = window.location.pathname

const customRoutes = [
  <RouteWithoutLayout exact path={ResetPage.path} component={ResetPage} />,
  <RouteWithoutLayout exact path={RedirectPage.path} component={RedirectPage} />,
  <Route exact path={AccountShowPage.path} component={AccountShowPage} />,
  <Route exact path={AccountEditPage.path} component={AccountEditPage} />,
  <Route exact path={PayoutSetupScreen.path} component={PayoutSetupScreen} />,
  <Route exact path={PayoutShowScreen.path} component={PayoutShowScreen} />,
  <Route exact path={PayoutMarketplaceModelEditScreen.path} component={PayoutMarketplaceModelEditScreen} />,
  <Route exact path={PayoutBankAccountCreateScreen.path} component={PayoutBankAccountCreateScreen} />,
]

const App = () => {
  const [state, setState] = useState(null)

  useEffect(() => {
    async function start() {
      const isLocalDev = !!env.LOCAL_TOKEN_OVERRIDE
      const baseApiClient = isLocalDev ? createLocalApiClient() : createApiClient(firebaseClient)
      const accountsProvider = createAccountsProvider(env.API_URL, baseApiClient)
      const apiClient = accountsProvider.client

      const restDataProvider = createSimpleRestProvider(env.API_URL, apiClient)
      const apiProvider = createApiProvider(env.API_URL, apiClient)
      const dataProvider = createDataProvider(restDataProvider, apiProvider)

      let history
      let currentAccount = await accountsProvider.getCurrentAccount()
      if (currentAccount) {
        const initialSlug = extractSlugFromPath(initialPath)
        if (initialSlug !== currentAccount.slug) {
          // slug is different from current account (could be empty on first run)
          const slugAccount = accountsProvider.findAccountBySlug(initialSlug)
          if (slugAccount) {
            // we found a valid account with the specified slug
            // set current account as the newly found account
            accountsProvider.setCurrentAccount(slugAccount)
            currentAccount = slugAccount
          } else {
            // no account with specified slug, use slug from current account
            if (initialSlug !== '' && initialSlug !== 'login' && initialSlug !== 'reset-password') {
              // a slug was present & different than base login URLs, but invalid
              window.alert("You don't have access to this account")
            }
            accountsProvider.navigateToCurrentAccount()
            return
          }
        }
        history = createHistory({ basename: currentAccount.slug })
      } else {
        history = createHistory()
      }

      const trackPath = (path) => {
        const resource = resources.find((r) => '/' + r.name === path)
        const featureName = resource?.options.label
        if (featureName) {
          window.ska?.((skalin) => {
            skalin.feature({ name: featureName })
          })
        }
      }
      trackPath(history.location.pathname)
      history.listen((loc) => trackPath(loc.pathname))

      const permissionsProvider = createPermissionsProvider(apiProvider)
      const authProvider = isLocalDev
        ? createLocalAuthProvider(accountsProvider, permissionsProvider)
        : createAuthProvider(firebaseClient, accountsProvider, baseApiClient, permissionsProvider, history, [
            ResetPage.path,
          ])

      // Find the first resource with menu permission (if connected) and redirect to it if we are on the root path
      if (currentAccount) {
        const permissions = await permissionsProvider.get()
        const firstResource = resources.find((r) => hasResourcePermission(permissions, r.name, UI_PERMISSION_MENU))
        if (firstResource && history.location.pathname === '/') {
          history.push('/' + firstResource.name)
        }
      }

      setState({ history, apiProvider, accountsProvider, authProvider, dataProvider, i18nProvider })
    }
    start()
  }, [setState])

  if (!state) {
    return null
  }

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <ApiProvider value={state.apiProvider}>
        <AccountsProvider value={state.accountsProvider}>
          <Admin
            authProvider={state.authProvider}
            catchAll={NotFound}
            customReducers={{ keyboard: keyboardReducer, theme: themeReducer }}
            customRoutes={customRoutes}
            customSagas={[rootSaga]}
            dataProvider={state.dataProvider}
            disableTelemetry
            history={state.history}
            initialState={{ admin: { ui: { sidebarOpen: false, viewVersion: 0 } } }}
            i18nProvider={state.i18nProvider}
            layout={Layout}
            loginPage={LoginPage}
          >
            {(permissions) => {
              console.log('permissions', permissions)
              return resources.map((r) => getResourceWithPermissions(r, permissions))
            }}
          </Admin>
        </AccountsProvider>
      </ApiProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
