import { get } from 'lodash'
import { useGetOne } from 'react-admin'

import { Title } from '../common/show'
import usersConfig from '../users/config'
import vehiclesConfig from '../vehicles/config'
import PeriodField from '../../components/PeriodField'
import ReferenceValue from '../../components/ReferenceValue'
import UserFullNameField from '../../components/UserFullNameField'
import { useCommonStyles } from '../../config/theme'

import config from './config'

const BookingTitlePeriodField = ({ record }) => {
  const commonClasses = useCommonStyles()
  return (
    <PeriodField
      record={record}
      className={commonClasses.inheritedFont}
      startedOnSource="start_scheduled_on"
      endedOnSource="end_scheduled_on"
      addTime
    />
  )
}

export const BookingTitleWithUser = (props) => {
  const userId = get(props.record, 'user_id')
  const { data: user } = useGetOne(usersConfig.name, userId, { enabled: Boolean(userId) })
  const commonClasses = useCommonStyles()
  return (
    <Title resourceConfig={config} {...props}>
      {props.details ? (
        props.details
      ) : (
        <>
          <UserFullNameField record={user} className={commonClasses.inheritedFont} />
          {' • '}
          <BookingTitlePeriodField record={props.record} />
        </>
      )}
    </Title>
  )
}

export const BookingTitleWithVehicle = (props) => {
  const commonClasses = useCommonStyles()
  return (
    <Title resourceConfig={config} {...props}>
      <ReferenceValue
        record={props.record}
        reference={vehiclesConfig.name}
        source={vehiclesConfig.options.referenceKey}
        target={vehiclesConfig.options.getName}
        className={commonClasses.inheritedFont}
      />
      {' • '}
      <BookingTitlePeriodField record={props.record} />
    </Title>
  )
}
