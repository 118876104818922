import { Box, Button, CardContent, Dialog, Grid, Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import classnames from 'classnames'
import jsonExport from 'jsonexport/dist'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Datagrid, downloadCSV, Filter, List, Pagination } from 'react-admin'

import AdvancedReferenceField from '../../components/AdvancedReferenceField'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingConsumedField from '../../components/BookingConsumedField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ReferenceValue from '../../components/ReferenceValue'
import Scheduler from '../../components/Scheduler'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UserFullNameField, { getUserFullName } from '../../components/UserFullNameField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleNameField from '../../components/VehicleNameField'
import ViewModeToggle, { ViewModeList, ViewModeTimeline } from '../../components/ViewModeToggle'
import env from '../../config/env'
import { BOOKING_BILLING_TYPES, BOOKING_ERA_CURRENT, BOOKING_ERAS } from '../../config/bookings'
import { SYSTEM_PERMISSION_CREATE, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { darkTheme, useCommonStyles } from '../../config/theme'
import { VEHICLE_TYPES } from '../../config/vehicles'
import { wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { getStatusStyles } from '../../domain/statuses'
import { formatPrice, isAllowed } from '../../utils'
import { formatDateTimeForExport, formatDuration } from '../../utils/dates'
import { useTheme } from '../../utils/hooks'
import { useSmallScreen } from '../../utils/theme'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CreateButton } from '../common/buttons'
import { FORM_TYPE_FILTER } from '../common/forms'
import { ListActions, ListCardTitle, useListStyles } from '../common/list'
import HubsFilter from '../hubs/filter'
import OrganisationField from '../organisations/field'
import OrganisationsFilter from '../organisations/filter'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehiclesFilter from '../vehicles/filter'
import vehicleUnavailabilitiesConfig from '../vehicleUnavailabilities/config'
import { useCreateVehicleUnavailability, useEditVehicleUnavailability } from '../vehicleUnavailabilities/form'

import { EditBookingButton } from './buttons'
import config from './config'
import { BookingCancelledFilter, BookingDatesFilter, BookingPeriodFilter } from './filter'
import { useCreateBooking, useEditBooking } from './form'

const BookingsFilters = ({ viewMode, ...props }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const listClasses = useListStyles()
  return (
    <Filter variant="outlined" classes={{ form: listClasses.filterForm }} {...props}>
      {!hasSingleOrganisation && <OrganisationsFilter alwaysOn />}
      {((!hasSingleOrganisation && props.filterValues.organisation_id) || (hasSingleOrganisation && !hasSingleHub)) && (
        <HubsFilter alwaysOn />
      )}
      {hasReadForUsers && <UserReferenceInput alwaysOn formType={FORM_TYPE_FILTER} validate={null} width={227} />}
      {viewMode === ViewModeList && <VehiclesFilter alwaysOn />}
      {viewMode === ViewModeList && <BookingCancelledFilter alwaysOn />}
      <BookingPeriodFilter alwaysOn />
      <BookingDatesFilter alwaysOn />
    </Filter>
  )
}

const bookingsExporter = (records) => {
  const employerAmountHeader = `employer_amount (${env.CURRENCY_CODE})`
  const finalPriceHeader = `final_price (${env.CURRENCY_CODE})`
  const fuelDeltaHeader = 'fuel_delta (L)'
  const initialPriceHeader = `initial_price (${env.CURRENCY_CODE})`

  const bookingsForExport = records.map((booking) => {
    // Remove fields to rename
    const { employer_amount, final_price, fuel_delta, initial_price, ...bookingForExport } = booking

    // Add renamed fields (price formatting, date formatting & header change)
    bookingForExport[employerAmountHeader] = formatPrice(employer_amount)
    bookingForExport[finalPriceHeader] = formatPrice(final_price)
    bookingForExport[fuelDeltaHeader] = fuel_delta
    bookingForExport[initialPriceHeader] = formatPrice(initial_price)

    bookingForExport.time_driven = formatDuration(Math.round(booking.time_driven / 60))

    const dateFieldsToFormat = [
      'scheduled_start_date',
      'start_date',
      'scheduled_end_date',
      'end_date',
      'cancellation_date',
      'creation_date',
      'last_update_date',
    ]
    dateFieldsToFormat.forEach((field) => (bookingForExport[field] = formatDateTimeForExport(booking[field])))

    return bookingForExport
  })

  jsonExport(
    bookingsForExport,
    {
      // order fields in the export
      headers: [
        'id',
        'organisation_name',
        'hub_name',
        'user',
        'additional_drivers',
        'vehicle_type',
        'vehicle_model',
        'vehicle_plate',
        'billing_type',
        'payment_required',
        'payment_status',
        initialPriceHeader,
        'voucher',
        finalPriceHeader,
        employerAmountHeader,
        'justification',
        'user_rating',
        'period',
        'number_of_trips',
        fuelDeltaHeader,
        'time_driven',
        'number_of_km_travelled',
      ],
    },
    (err, csv) => downloadCSV(csv, 'bookings'),
  )
}

const bookingRowStyle = (record) => {
  const wasCancelled = wasBookingCancelled(record)
  const isCurrent = !wasCancelled && get(record, 'era') === BOOKING_ERA_CURRENT
  return {
    opacity: wasCancelled ? 0.4 : 1,
    backgroundColor: isCurrent ? alpha(darkTheme.palette.secondary.main, 0.2) : 'none',
  }
}

export const BookingsListLayout = ({
  excluded = [],
  isFullList = false,
  hasEdit,
  disabledInputsSources = [],
  viewMode = ViewModeList,
  setViewMode,
  schedulerProps,
  ...props
}) => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [hasCreateForBookings] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const [hasCreateForVehicleUnavailabilities] = useResourcePermissions(
    vehicleUnavailabilitiesConfig.name,
    SYSTEM_PERMISSION_CREATE,
  )

  const theme = useTheme()
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const translate = useTranslate()

  const organisationId = get(props.filterValues, 'organisation_id')
  const hubId = get(props.filterValues, 'hub_id')
  const userId = get(props.filterValues, 'user_id')
  const vehicleId = get(props.filterValues, 'vehicle_id')
  const isFilteredByOrganisation = Boolean(organisationId)
  const isFilteredByHub = Boolean(hubId)
  const isFilteredByUser = Boolean(userId)
  const isFilteredByVehicle = Boolean(vehicleId)
  const isSchedulerEnabled = Boolean(schedulerProps) || (isFullList && (hasSingleHub || isFilteredByHub))

  useEffect(() => {
    if (!isSchedulerEnabled) {
      setViewMode?.(ViewModeList)
    }
  }, [isSchedulerEnabled, setViewMode])

  const [openCreateBookingPopup, createBookingPopupDialog] = useCreateBooking({ disabledInputsSources })
  const [openCreateVehicleUnavailabilityPopup, createVehicleUnavailabilityPopupDialog] = useCreateVehicleUnavailability(
    { disabledInputsSources },
  )

  const [bookingOrVehicleUnavailabilityChoicePopupState, setBookingOrVehicleUnavailabilityChoicePopupState] = useState({
    isOpen: false,
    values: {},
  })
  const handleBookingOrVehicleUnavailabilityChoicePopupClose = () =>
    setBookingOrVehicleUnavailabilityChoicePopupState({ isOpen: false, values: {} })

  const handleOpenCreateVehicleUnavailabilityPopup = () => {
    handleBookingOrVehicleUnavailabilityChoicePopupClose()
    openCreateVehicleUnavailabilityPopup?.({
      ...(Boolean(schedulerProps) && schedulerProps.creationInitialValues),
      ...props.filterValues,
      ...bookingOrVehicleUnavailabilityChoicePopupState.values,
      started_on: bookingOrVehicleUnavailabilityChoicePopupState.values.start_scheduled_on,
      ended_on: bookingOrVehicleUnavailabilityChoicePopupState.values.end_scheduled_on,
    })
  }
  const handleOpenCreateBookingPopup = (values) => {
    handleBookingOrVehicleUnavailabilityChoicePopupClose()
    openCreateBookingPopup?.({
      ...(Boolean(schedulerProps) && schedulerProps.creationInitialValues),
      ...props.filterValues,
      ...bookingOrVehicleUnavailabilityChoicePopupState.values,
      ...values,
    })
  }

  // We use a ref here to allow the update of the filter values when onBookingCreate func (in finalSchedulerProps) is called
  const onBookingOrVehicleUnavailabilityCreateInScheduler = useRef()
  onBookingOrVehicleUnavailabilityCreateInScheduler.current = (values) => {
    if (schedulerProps?.vehicleUnavailabilityCreationDisabled) {
      handleOpenCreateBookingPopup(values)
    } else {
      setBookingOrVehicleUnavailabilityChoicePopupState({ isOpen: true, values })
    }
  }

  const [openEditBookingPopup, editBookingPopupDialog] = useEditBooking()
  const [openEditVehicleUnavailabilityPopup, editVehicleUnavailabilityPopupDialog] = useEditVehicleUnavailability()

  const finalSchedulerProps = {
    relatedResource: 'users',
    filter: props.filterValues,
    groupResource: 'vehicles',
    groupFilter: { organisation_id: organisationId, hub_id: hubId, active: true },
    groupNameKey: (record) => record.brand + ' ' + record.model,
    groupAccessoryNameKey: 'designation',
    groupCategories: VEHICLE_TYPES,
    groupCategoryKey: 'type',
    version: props.version,
    onBookingEdit: openEditBookingPopup,
    onVehicleUnavailabilityEdit: openEditVehicleUnavailabilityPopup,
    onBookingCreate: (values) => onBookingOrVehicleUnavailabilityCreateInScheduler.current(values),
    ...schedulerProps,
  }

  const shouldDisplayUsers = hasReadForUsers && isAllowed(excluded, 'users') && !isFilteredByUser
  const shouldDisplayVehiclesAttributes = isAllowed(excluded, 'vehicles') && !isFilteredByVehicle

  return (
    <Fragment key={viewMode === ViewModeTimeline ? undefined : props.version}>
      <CardContent className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}>
        <ListCardTitle />
        {isSchedulerEnabled && <ViewModeToggle mode={viewMode} onChange={(newViewMode) => setViewMode(newViewMode)} />}
      </CardContent>

      {isSchedulerEnabled && viewMode === ViewModeTimeline && <Scheduler {...finalSchedulerProps} />}

      {(!isSchedulerEnabled || viewMode === ViewModeList) &&
        (isSmallScreen ? (
          <CompactList
            {...props}
            alignItems="flex-start"
            linkType="show"
            itemStyle={bookingRowStyle}
            icon={
              <ReferenceValue
                reference="vehicles"
                source="vehicle_id"
                target={(v) => <VehicleIconField record={v} />}
              />
            }
            iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
            title={(record) => (
              <>
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                  <ReferenceValue record={record} reference="organisations" source="organisation_id" target="name" />
                </Typography>
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                  {' • '}
                  {shouldDisplayUsers ? (
                    <ReferenceValue
                      record={record}
                      reference="users"
                      source="user_id"
                      target={(u) => getUserFullName(u)}
                    />
                  ) : (
                    <ReferenceValue
                      record={record}
                      reference="vehicles"
                      source="vehicle_id"
                      loadingText=""
                      target={vehiclesConfig.options.getName}
                    />
                  )}
                </Typography>
              </>
            )}
            body={(record) => (
              <>
                <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
                <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" />
                <br />
                {shouldDisplayUsers && isAllowed(excluded, 'vehicles') && (
                  <>
                    <StaticText>{translate('resources.vehicles.name', 1)}: </StaticText>
                    <ReferenceValue
                      record={record}
                      reference="vehicles"
                      source="vehicle_id"
                      target={vehiclesConfig.options.getName}
                    />
                    <br />
                  </>
                )}
                <StaticText>{'resources.bookings.fields.era'}: </StaticText>
                <AdvancedTextField record={record} source="era" map={BOOKING_ERAS} />
                <br />
                <PeriodField
                  record={record}
                  startedOnSource="effective_started_on"
                  endedOnSource="effective_ended_on"
                  addTime
                />
                <br />
                <StaticText>{'resources.bookings.fields.price'}: </StaticText>
                <PriceField record={record} source="amount" />
              </>
            )}
            references={config.options.references}
          />
        ) : (
          <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show" rowStyle={bookingRowStyle}>
            <StatusField />
            {!hasSingleOrganisation && !isFilteredByOrganisation && <OrganisationField />}
            {!hasSingleHub && !isFilteredByHub && (
              <AdvancedReferenceField source="hub_id" reference="hubs" sourceName="hub_name" link={false} />
            )}
            {shouldDisplayUsers && (
              <AdvancedReferenceField reference="users" source="user_id" attributePrefix="user">
                <UserFullNameField showFirstNameInitial={isFullList} />
              </AdvancedReferenceField>
            )}
            {shouldDisplayVehiclesAttributes &&
              [
                <VehicleIconField
                  record={props.record}
                  source="vehicle_type"
                  color="textSecondary"
                  label="resources.vehicles.fields.type"
                  sortable={false}
                />,
                <AdvancedReferenceField
                  reference="vehicles"
                  source="vehicle_id"
                  attributePrefix="vehicle"
                  link={false}
                  label="resources.vehicles.fields.model"
                >
                  <VehicleNameField />
                </AdvancedReferenceField>,
                <AdvancedReferenceField
                  source="vehicle_id"
                  sortBy="vehicle.designation"
                  reference="vehicles"
                  label="resources.vehicles.fields.designation"
                  sourceName="vehicle_designation"
                />,
              ].map((field, index) => ({ ...field, key: index }))}
            <AdvancedTextField source="billing_type" map={BOOKING_BILLING_TYPES} />
            <PriceField source="amount" />
            <BookingConsumedField />
            <AdvancedTextField source="era" map={BOOKING_ERAS} />
            <DateTooltipField source="effective_started_on" addTime />
            <DateTooltipField source="effective_ended_on" addTime />
            {hasEdit && <EditBookingButton onClick={openEditBookingPopup} />}
          </Datagrid>
        ))}

      {createBookingPopupDialog}
      {editBookingPopupDialog}
      {createVehicleUnavailabilityPopupDialog}
      {editVehicleUnavailabilityPopupDialog}
      {(hasCreateForBookings || hasCreateForVehicleUnavailabilities) && (
        <Dialog
          open={bookingOrVehicleUnavailabilityChoicePopupState.isOpen}
          onClose={handleBookingOrVehicleUnavailabilityChoicePopupClose}
        >
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              {hasCreateForBookings && (
                <Grid item>
                  <Button fullWidth variant="outlined" onClick={handleOpenCreateBookingPopup}>
                    {translate('resources.bookings.forms.create.pageTitle')}
                  </Button>
                </Grid>
              )}
              {hasCreateForVehicleUnavailabilities && (
                <Grid item>
                  <Button fullWidth variant="outlined" onClick={handleOpenCreateVehicleUnavailabilityPopup}>
                    {translate('resources.maintenances.forms.create.pageTitle')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Dialog>
      )}
    </Fragment>
  )
}

export default (props) => {
  const [viewMode, setViewMode] = useState(ViewModeList)
  const [openCreateBookingPopup, createBookingPopupDialog] = useCreateBooking({
    defaultValues: ['organisation_id', 'hub_id', 'user_id', 'vehicle_id'],
  })

  return (
    <>
      <List
        sort={config.options.defaultSort}
        filters={<BookingsFilters viewMode={viewMode} />}
        filterDefaultValues={config.options.defaultFilterValues}
        pagination={viewMode === ViewModeList ? <Pagination /> : false}
        exporter={bookingsExporter}
        actions={
          <ListActions hasExport>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && (
                <CreateButton basePath={basePath} onClick={openCreateBookingPopup} filterValues={filterValues} />
              )
            }
          </ListActions>
        }
        {...props}
      >
        <BookingsListLayout isFullList hasEdit={props.hasEdit} viewMode={viewMode} setViewMode={setViewMode} />
      </List>
      {createBookingPopupDialog}
    </>
  )
}
