import { FormControl, FormHelperText, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  textJustify: {
    textAlign: 'justify',
  },
  unorderedListPreText: {
    marginTop: theme.spacing(2),
  },
  unorderedList: {
    paddingLeft: 20,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  unorderedListItem: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
}))

const ArchiveOrganisationExplanationText = ({ organisationName }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <Typography className={classes.textJustify}>
        {translate('resources.organisations.actions.archive.helperText1')}
      </Typography>
      <Typography className={classnames(classes.textJustify, classes.unorderedListPreText)}>
        {translate('resources.organisations.actions.archive.helperText2')}
      </Typography>
      <ul className={classnames(classes.textJustify, classes.unorderedList)}>
        <li className={classes.unorderedListItem}>
          {translate('resources.organisations.actions.archive.helperText3')}
        </li>
        <li className={classes.unorderedListItem}>
          {translate('resources.organisations.actions.archive.helperText4')}
        </li>
      </ul>
      <Typography className={classes.textJustify}>
        {translate('resources.organisations.actions.archive.helperText5', { organisationName: organisationName })}
      </Typography>
    </>
  )
}

const ArchiveOrganisationFormControl = ({ update, isPristine, error }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <FormControl className={classes.formControl}>
      <TextField
        name="organisation_name"
        label={translate('resources.organisations.fields.name')}
        required
        fullWidth
        size="small"
        variant="outlined"
        onChange={(event) => update({ organisation_name: event.target.value })}
      />
      <FormHelperText error>{!isPristine && translate(error?.organisation_name)}</FormHelperText>
    </FormControl>
  )
}

export const archiveOrganisationConfirm = ({ record }) => ({
  validate: (state) => {
    if (!state.organisation_name || state.organisation_name !== record.name) {
      return { organisation_name: 'resources.organisations.actions.archive.validate' }
    }
    return null
  },
  content: (contentProps) => (
    <>
      <ArchiveOrganisationExplanationText organisationName={record.name} />
      <ArchiveOrganisationFormControl {...contentProps} />
    </>
  ),
  confirmLabel: 'resources.organisations.actions.archive.confirmLabel',
})
