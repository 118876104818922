import { CardContent, Dialog } from '@material-ui/core'
import { get, pick } from 'lodash'
import { useState } from 'react'
import {
  CREATE,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import BasicFormToolbar from '../../components/BasicFormToolbar'
import DefinitionList from '../../components/DefinitionList'
import {
  OPS_USER_ROLES_AND_PERMISSIONS,
  OPS_USER_ROLES_NAMES,
  SYSTEM_PERMISSION_DELETE,
} from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { validateEmail } from '../../utils/validators'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import OrganisationReferenceInput from '../organisations/input'

import config from './config'
import { DeleteOpsUserButton } from './buttons'

const opsUserRoleChoices = Object.entries(OPS_USER_ROLES_NAMES).map(([k, v]) => ({ id: k, name: v }))

export const OpsUserFormToolbar = ({ type, hasDelete, ...props }) => (
  <BasicFormToolbar {...props}>
    {type === UPDATE && hasDelete && <DeleteOpsUserButton variant="outlined" />}
  </BasicFormToolbar>
)

const OpsUserFormLayout = ({ type = UPDATE, onClose, hasDelete, ...props }) => {
  const { identity } = useGetIdentity()
  const currentUserId = identity?.id
  const currentUserRestrictedToOrganisationId = identity?.restricted_to_organisation_id
  const translate = useTranslate()

  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const title = `resources.ops-users.forms.${type.toLowerCase()}.title`

  if (!identity) {
    return null
  }

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'first_name',
    'last_name',
    'email',
    'role',
    'restricted_to_organisation_id',
  ])
  formProps.initialValues =
    type === UPDATE ? {} : { restricted_to_organisation_id: currentUserRestrictedToOrganisationId }

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={
          <OpsUserFormToolbar
            onCancel={onClose}
            type={type}
            hasDelete={hasDelete && formProps.record.id !== currentUserId}
          />
        }
        variant="outlined"
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <TextInput source="first_name" validate={required()} />
        <TextInput source="last_name" validate={required()} />
        <TextInput source="email" validate={validateEmail()} type="email" />
        <OrganisationReferenceInput
          source="restricted_to_organisation_id"
          validate={null}
          disabled={!!currentUserRestrictedToOrganisationId}
        />
        <FormDivider />
        <SelectInput
          source="role"
          choices={opsUserRoleChoices}
          optionText="name"
          optionValue="id"
          validate={required()}
          helperText={type === UPDATE ? translate('resources.ops-users.forms.helperTexts.role') : null}
          formClassName={commonClasses.fullWidth}
        />
        <DefinitionList items={OPS_USER_ROLES_AND_PERMISSIONS} />
      </SimpleForm>
    </>
  )
}

const OpsUserFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
  })

  const [hasDelete] = useResourcePermissions(resource, SYSTEM_PERMISSION_DELETE)

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
    hasDelete,
  }

  return <OpsUserFormLayout {...controllerProps} />
}

export const EditOpsUserFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <OpsUserFormLayoutController {...props} /> : <div />

export const CreateOpsUserFormLayoutController = (props) => <OpsUserFormLayoutController {...props} />

export const useCreateOpsUser = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateOpsUserFormLayoutController initialValues={createPopupState.values} onClose={handleCreatePopupClose} />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditOpsUser = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditOpsUserFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default OpsUserFormLayout
