import GenericCreateForm from '../common/create'

import VehicleUnavailabilityFormLayout from './form'
import { VehicleUnavailabilityTitle } from './show'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<VehicleUnavailabilityTitle />}
      formLayoutComponent={<VehicleUnavailabilityFormLayout />}
    />
  )
}
