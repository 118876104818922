import { BooleanInput, useResourceContext } from 'react-admin'

import { useHelperText } from '../layouts/common/forms'

const AdvancedBooleanInput = ({ label, helperText, ...props }) => {
  const resource = useResourceContext()
  const helperTextFromSource = useHelperText(props.source)
  return (
    <BooleanInput
      {...props}
      label={label ?? `resources.${resource}.fields.${props.source}`}
      helperText={helperText === undefined ? helperTextFromSource : helperText}
    />
  )
}

export default AdvancedBooleanInput
