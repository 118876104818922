import UserTitle from '../../components/UserTitle'
import GenericCreateForm from '../common/create'

import OpsUserFormLayout from './form'
import config from './config'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<UserTitle resourceConfig={config} />}
      formLayoutComponent={<OpsUserFormLayout />}
    />
  )
}
