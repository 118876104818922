import get from 'lodash/get'

import { useCommonStyles } from '../config/theme'

import AdvancedTextField from './AdvancedTextField'

const AdvancedUrlField = ({ record = {}, text, ...props }) => {
  const commonClasses = useCommonStyles()
  const value = get(record, props.source)
  return value ? (
    <a href={value} className={commonClasses.link} target="_blank" rel="noopener noreferrer">
      <AdvancedTextField {...props} record={{ ...record, [props.source]: text || value }} />
    </a>
  ) : (
    <AdvancedTextField {...props} />
  )
}

AdvancedUrlField.defaultProps = {
  addLabel: true,
}

export default AdvancedUrlField
