import { useGetList } from 'react-admin'

import config from './config'

export const useGetHubsListForOrganisation = (organisationId) =>
  useGetList(
    config.name,
    { page: 1, perPage: 100 },
    config.options.defaultSort,
    { organisation_id: organisationId },
    { enabled: Boolean(organisationId) },
  )
