import { useGetList } from 'react-admin'

import config from './config'

export const useGetTransactionsListForBooking = (bookingId) =>
  useGetList(
    config.name,
    { page: 1, perPage: 100 },
    config.options.defaultSort,
    { booking_id: bookingId },
    { enabled: Boolean(bookingId) },
  )
