import { get, isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'

import { useShowStyles } from '../layouts/common/show'
import { BOOKING_END_CHECKS } from '../config/bookingEndChecks'

import StaticText from './StaticText'

const BookingEndChecksField = ({ record }) => {
  const showClasses = useShowStyles()
  const recordChecks = get(record, 'booking_end_checks', [])
  const translate = useTranslate()

  if (isEmpty(recordChecks)) {
    return <StaticText>resources.organisations.enums.bookingEndChecks.empty</StaticText>
  }

  return (
    <ul className={showClasses.unorderedList}>
      {recordChecks.map((recordCheck) => {
        const check = BOOKING_END_CHECKS[recordCheck]
        return (
          <li key={check} className={showClasses.unorderedListItem}>
            <p>{translate(check)}</p>
          </li>
        )
      })}
    </ul>
  )
}

BookingEndChecksField.defaultProps = {
  addLabel: true,
  label: 'resources.organisations.enums.bookingEndChecks.name',
}

export default BookingEndChecksField
