import UserTitle from '../../components/UserTitle'
import GenericEditForm from '../common/edit'

import OpsUserFormLayout from './form'
import config from './config'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<UserTitle resourceConfig={config} />}
      formLayoutComponent={<OpsUserFormLayout />}
    />
  )
}
