import { CardContent, Dialog } from '@material-ui/core'
import { get, pick } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  CREATE,
  FormDataConsumer,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  UPDATE,
  useDataProvider,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import damageBlueprintsConfig from '../damageBlueprints/config'
import HubReferenceInput from '../hubs/input'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehicleDetailsConfig from '../vehicleDetails/config'
import VehicleReferenceInput from '../vehicles/input'

import MarkerInput from './input'
import config from './config'

const damageStatusChoices = Object.entries(DAMAGES_STATUSES).map(([k, v]) => ({ id: k, name: v }))
const damageCategoryChoices = Object.entries(DAMAGES_CATEGORIES).map(([k, v]) => ({ id: k, name: v }))

const SaveButtonWithMarker = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()
  const handleClick = useCallback(() => {
    form.change('marker_x_ratio', props.marker.xRatio)
    form.change('marker_y_ratio', props.marker.yRatio)
    handleSubmitWithRedirect('show')
  }, [form, props.marker, handleSubmitWithRedirect])

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

const BlueprintReferenceInput = ({ formType, ...props }) => {
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const [blueprintSet, setBlueprintSet] = useState(null)
  useEffect(() => {
    dataProvider
      .getOne(vehicleDetailsConfig.name, { id: values.vehicle_id })
      .then(({ data }) => setBlueprintSet(data.blueprint_set))
  }, [dataProvider, values.vehicle_id])

  return (
    <AdvancedReferenceInput
      {...props}
      reference={damageBlueprintsConfig.name}
      source={damageBlueprintsConfig.options.referenceKey}
      getOptionLabel={damageBlueprintsConfig.options.getName}
      disabled={!Boolean(values.vehicle_id) || formType === UPDATE}
      filter={{ set: blueprintSet }}
    />
  )
}

const DamageReportFormLayout = ({ type = UPDATE, onClose, ...props }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const title = `resources.damage-reports.forms.${type.toLowerCase()}.title`

  const [marker, setMarker] = useState({ xRatio: props.record.marker_x_ratio, yRatio: props.record.marker_y_ratio })

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'organisation_id',
    'hub_id',
    'vehicle_id',
    'reporter_id',
    'blueprint_id',
    'created_on',
    'status',
    'category',
    'picture',
    'note',
    'marker_x_ratio',
    'marker_y_ratio',
  ])

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} savebutton={<SaveButtonWithMarker marker={marker} />} />}
        variant="outlined"
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <OrganisationReferenceInput disabled={type === UPDATE} />
        <HubReferenceInput disabled={type === UPDATE} />
        <VehicleReferenceInput formType={type} disabled={type === UPDATE} />
        {hasReadForUsers && <UserReferenceInput source="reporter_id" disabled={type === UPDATE} />}
        <BlueprintReferenceInput formType={type} />
        <FormDivider condition={(formData) => Boolean(formData.blueprint_id)} />
        <FormDataConsumer>
          {({ formData }) =>
            Boolean(formData.blueprint_id) && (
              <MarkerInput report={formData} onNewMarker={(marker) => setMarker(marker)} />
            )
          }
        </FormDataConsumer>
        <FormDivider />
        <SelectInput
          choices={damageStatusChoices}
          source="status"
          optionText="name"
          optionValue="id"
          validate={required()}
        />
        <SelectInput
          choices={damageCategoryChoices}
          source="category"
          optionText="name"
          optionValue="id"
          validate={required()}
        />
        <FormDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <TextInput
              {...rest}
              multiline
              source="note"
              label="Description"
              validate={Boolean(formData.blueprint_id) ? null : required()}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </>
  )
}

const DamageReportFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : null

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
  })

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
  }

  return <DamageReportFormLayout {...controllerProps} />
}

export const EditDamageReportFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <DamageReportFormLayoutController {...props} /> : <div />

export const useEditDamageReport = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditDamageReportFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default DamageReportFormLayout
