import { orange, teal, grey } from '@material-ui/core/colors'
import { createTheme, makeStyles, alpha } from '@material-ui/core/styles'

export const SIDEBAR_OPEN_WIDTH = 244
export const SIDEBAR_CLOSED_WIDTH = 58

export const COMMON_INPUT_WIDTH = 256

const base = {
  sidebar: {
    width: SIDEBAR_OPEN_WIDTH,
    closedWidth: SIDEBAR_CLOSED_WIDTH,
  },
  props: {
    MuiSelect: {
      variant: 'outlined',
      // Moves the menu below the select input
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      },
    },
  },
  overrides: {
    MuiCard: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiCardHeader: {
      root: {
        height: 40,
      },
      action: {
        alignSelf: 'center',
        marginTop: '0px !important',
        marginRight: '0px !important',
      },
    },
    MuiTableCell: {
      root: {
        '& .MuiLinearProgress-root': {
          // Prevent loading bars in ReferenceFields from
          // making containing table cells grow
          width: '100%',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        // Make icons in menu items inherit their parent color
        // so they're highlighted when active
        color: 'inherit',
      },
    },
    // Don't display timeline left sections
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          display: 'none',
        },
      },
    },
    RaLink: {
      link: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    RaSingleFieldList: {
      root: {
        '& > a': {
          textDecoration: 'none !important',
        },
      },
    },
    RaChipField: {
      chip: {
        marginLeft: 0,
      },
    },
    RaAutocompleteSuggestionList: {
      // Make suggestions list visible over modal dialogs
      suggestionsContainer: {
        zIndex: 1400,
      },
    },
    RaAutocompleteSuggestionItem: {
      // Set suggestions text as regular, matching letters as bold
      suggestionText: {
        fontWeight: 400,
      },
      highlightedSuggestionText: {
        fontWeight: 700,
      },
    },
    RaConfirm: {
      confirmWarning: {
        border: '1px solid',
        padding: '5px 15px',
      },
    },
    RaFilterForm: {
      form: {
        alignItems: 'center',
        marginTop: 0,
      },
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center',
      },
    },
    RaSimpleFormIterator: {
      root: {
        minWidth: COMMON_INPUT_WIDTH,
      },
      line: {
        '&:not(:last-child)': {
          marginBottom: 16,
        },
      },
      action: {
        paddingTop: 11,
        '&:not(:first-child) > .MuiButton-root': {
          minWidth: 'auto',
          '& > .MuiButton-label': {
            width: 24,
            overflow: 'hidden',
            justifyContent: 'flex-start',
          },
        },
      },
    },
    RaLoadingIndicator: {
      loader: {
        margin: 15,
      },
    },
    RaCheckboxGroupInput: {
      root: {
        '& .MuiFormGroup-root > .MuiFormControlLabel-root': {
          height: 40,
        },
      },
    },
    RaRadioButtonGroupInput: {
      label: {
        marginBottom: 6,
      },
    },
  },
}

export const THEME_MODE_LIGHT = 'light'
export const lightTheme = createTheme({
  ...base,
  palette: {
    type: THEME_MODE_LIGHT,
    primary: teal,
    secondary: { main: '#25333f' },
    success: { main: '#2CA428', faded: '#4FD34A' },
    warning: orange,
    error: { main: '#F44336', faded: '#F76E64' },
    contrast: grey[600],
  },
})

export const THEME_MODE_DARK = 'dark'
export const darkTheme = createTheme({
  ...base,
  palette: {
    type: THEME_MODE_DARK,
    primary: { main: 'hsl(174, 80%, 45%)', contrastText: '#000' },
    secondary: { main: 'hsl(174, 50%, 45%)', contrastText: '#000' },
    success: { main: '#2CA428', faded: '#4FD34A' },
    warning: orange,
    error: { main: '#F44336', faded: '#F65C51' },
    contrast: 'white',
  },
  overrides: {
    ...base.overrides,
    RaToolbar: {
      toolbar: {
        backgroundColor: '#333',
      },
    },
  },
})

export const THEMES = {
  [THEME_MODE_LIGHT]: lightTheme,
  [THEME_MODE_DARK]: darkTheme,
}

export const useCommonStyles = makeStyles((theme) => {
  const { breakpoints, palette, spacing } = theme
  const isLightMode = palette.type === THEME_MODE_LIGHT

  return {
    inheritedFont: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
    textWithLargeIcons: {
      whiteSpace: 'nowrap',
      '& svg': {
        verticalAlign: 'middle',
        padding: '0 0.2rem',
      },
    },
    textWithSmallIcons: {
      whiteSpace: 'nowrap',
      '& svg': {
        verticalAlign: 'middle',
        fontSize: '1rem',
        padding: '0 0.2rem',
      },
    },
    titleContainer: {
      [breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 40,
      },
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    solidBorder: {
      borderStyle: 'solid',
    },
    dashedBorder: {
      borderStyle: 'dashed',
    },
    contrastColor: {
      color: palette.contrast,
    },
    borderTop: {
      borderTop: `solid 1px ${palette.divider}`,
    },
    borderLeft: {
      borderLeft: `solid 1px ${palette.divider}`,
    },
    borderBottom: {
      borderBottom: `solid 1px ${palette.divider}`,
    },
    borderRight: {
      borderRight: `solid 1px ${palette.divider}`,
    },
    borderless: {
      border: 'none !important',
    },
    fullWidth: {
      width: '100% !important',
    },
    halfWidth: {
      width: '50% !important',
    },
    noMarginTop: {
      marginTop: 0 + 'px !important',
    },
    marginTopOnFirstChild: {
      '&:first-child:not(:last-child)': {
        marginTop: theme.spacing(1.5),
      },
    },
    noMarginBottomOnPenultimateChild: {
      '&:nth-last-child(2)': {
        marginBottom: 0 + 'px !important',
      },
    },
    inline: {
      display: 'inline',
    },
    absoluteFill: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    objectContain: {
      objectFit: 'contain',
    },
    displayNone: {
      display: 'none',
    },
    flexRowCenterContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    flexForm: {
      display: 'flex',
      '& > .ra-input': {
        marginLeft: 32,
        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
    spacer: {
      flexGrow: 1,
    },
    toolbar: {
      '& > button + button': {
        marginLeft: spacing(1),
      },
    },
    whiteLogo: {
      padding: spacing(0.5),
      margin: -spacing(0.5),
      backgroundColor: 'white',
    },
    primaryColor: {
      color: palette.primary.main,
    },
    link: {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    deleteButton: {
      color: palette.error.main,
      border: `1px solid ${alpha(palette.error.main, 0.5)} !important`,
      '&:hover': {
        border: `1px solid ${palette.error.main} !important`,
      },
    },
    commonInput: {
      width: COMMON_INPUT_WIDTH + 'px !important',
    },
    doubleInput: {
      width: COMMON_INPUT_WIDTH * 2 + spacing(4) + 'px !important',
    },
    smallDot: {
      marginRight: theme.spacing(1),
      borderRadius: 5,
      height: 10,
      width: 10,
    },
    commonSwitchRoot: {
      minWidth: 100,
      height: 36,
      color: 'inherit',
      fontSize: 18,
      fontWeight: 500,
      border: '2px solid',
      borderRadius: 6,
      borderColor: isLightMode ? palette.grey[500] : 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        borderColor: isLightMode ? palette.grey[300] : 'rgba(0, 0, 0, 0.87)',
        transition: 'border-color 0.5s ease',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // Remove background border (white when not focused & green when focused)
        borderStyle: 'none',
      },
    },
    commonSwitchSelect: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    commonSwitchIcon: {
      color: 'inherit',
      position: 'absolute',
      right: 3,
    },
    commonSwitchExpandedBorder: {
      borderColor: isLightMode ? palette.primary.main : 'rgba(25, 230, 209, 0.8)',
    },
  }
})

export const THEME_LINEAR_GRADIENT_GREEN = 'green'
export const THEME_LINEAR_GRADIENT_YELLOW = 'yellow'
export const THEME_LINEAR_GRADIENT_ORANGE = 'orange'
export const THEME_LINEAR_GRADIENT_RED = 'red'

export const useLinearGradientCommonStyles = makeStyles((theme) => ({
  [THEME_LINEAR_GRADIENT_GREEN]: {
    backgroundImage: `linear-gradient(${theme.palette.success.faded},${theme.palette.success.main})`,
  },
  [THEME_LINEAR_GRADIENT_YELLOW]: {
    backgroundImage: `linear-gradient(${theme.palette.warning[300]},${theme.palette.warning[400]})`,
  },
  [THEME_LINEAR_GRADIENT_ORANGE]: {
    backgroundImage: `linear-gradient(${theme.palette.warning[500]},${theme.palette.warning[700]})`,
  },
  [THEME_LINEAR_GRADIENT_RED]: {
    backgroundImage: `linear-gradient(${theme.palette.error.faded},${theme.palette.error.main})`,
  },
}))

export default lightTheme
