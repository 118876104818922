import { Pagination, List, Confirm } from 'react-admin'

import { PAGINATION_PER_PAGE, PAGINATION_PER_PAGE_CHOICES } from './pagination'

Pagination.defaultProps = {
  ...List.defaultProps,
  rowsPerPageOptions: PAGINATION_PER_PAGE_CHOICES,
}

List.defaultProps = {
  ...List.defaultProps,
  perPage: PAGINATION_PER_PAGE,
  exporter: false,
  bulkActionButtons: false,
}

Confirm.defaultProps = {
  ...Confirm.defaultProps,
  CancelIcon: () => null,
  ConfirmIcon: () => null,
  confirmColor: 'warning',
}
