import Icon from '@material-ui/icons/EventNote'

import { BOOKING_ACTIONS } from '../../config/bookings'
import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'

export default {
  name: 'bookings',
  icon: Icon,
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'booking_id',
    defaultSort: { field: 'effective_started_on', order: 'DESC' },
    defaultFilterValues: { cancelled: false },
    references: ['organisations', 'users', 'vehicles'],
    actions: BOOKING_ACTIONS,
    badge: true,
  },
}
