import GenericEditForm from '../common/edit'

import DamageReportFormLayout from './form'
import { DamageReportTitle } from './show'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<DamageReportTitle />}
      formLayoutComponent={<DamageReportFormLayout />}
    />
  )
}
