import { cloneElement } from 'react'
import {
  Button as RaButton,
  EditButton as EditButtonBase,
  CreateButton as CreateButtonBase,
  DeleteButton as DeleteButtonBase,
  useNotify,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { useTranslate } from 'ra-core'
import { Button as MuiButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PlusIcon from '@material-ui/icons/Add'
import { stringify } from 'query-string'
import classnames from 'classnames'

import { useCommonStyles } from '../../config/theme'
import { useKeyboard } from '../../utils/hooks'
import { useSmallScreen } from '../../utils/theme'

export const DeleteButton = ({ condition, variant, successMessage, mutationMode, ...props }) => {
  const classes = useCommonStyles()
  const notify = useNotify()

  if (!props.record || (typeof condition === 'function' && !condition(props.record))) {
    return null
  }

  if (variant === 'outlined') {
    props = {
      ...props,
      className: classes.deleteButton,
      variant: 'outlined',
      size: 'medium',
    }
  }

  const undoable = mutationMode === 'undoable'
  const onSuccess = () => {
    notify(successMessage, { type: 'info', undoable })
  }

  return <DeleteButtonBase {...props} mutationMode={mutationMode} onSuccess={onSuccess} />
}

DeleteButton.defaultProps = {
  mutationMode: 'undoable',
}

export const EditButton = ({ onClick, condition, ...props }) => {
  const buttonProps = { ...props }
  const keyboard = useKeyboard()
  const isSmallScreen = useSmallScreen()

  if (!props.record || (typeof condition === 'function' && !condition(props.record))) {
    return null
  }

  // Allow custom click handlers but not if pressing meta/shift key,
  // which show a user intent to open a new tab/window
  if (onClick && !isSmallScreen && !keyboard.metaKey && !keyboard.shiftKey) {
    buttonProps.onClick = (event) => {
      event.stopPropagation()
      onClick(props.record.id)
    }
    // Disable default link behavior
    buttonProps.to = null
    buttonProps.component = undefined
  }

  return <EditButtonBase {...buttonProps} />
}

export const CreateButton = ({ onClick, filterValues, isFloatingOnSmallScreen = true, ...props }) => {
  const buttonProps = { ...props }
  const keyboard = useKeyboard()
  const isSmallScreen = useSmallScreen()
  const source = JSON.stringify(filterValues)

  // Allow custom click handlers but not if pressing meta/shift key,
  // which show a user intent to open a new tab/window
  if (onClick && !isSmallScreen && !keyboard.metaKey && !keyboard.shiftKey) {
    const values = { ...filterValues }
    buttonProps.onClick = (event) => {
      event.stopPropagation()
      onClick(values)
    }
    // Disable default link behavior
    buttonProps.to = null
    buttonProps.component = undefined
    buttonProps.basePath = 'onClick_' + source // only way to trigger a render in CreateButton (won't be used)
  } else {
    buttonProps.to = {
      pathname: `${buttonProps.basePath}/create`,
      search: stringify({ source }),
    }
    buttonProps.component = Link
    buttonProps.basePath = 'link_' + source // only way to trigger a render in CreateButton (won't be used)
  }

  return isFloatingOnSmallScreen ? (
    <CreateButtonBase {...buttonProps} />
  ) : (
    <RaButton {...buttonProps}>
      <PlusIcon />
    </RaButton>
  )
}

const useCancelStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'RaCancelButton' },
)

const goBack = () => window.history.go(-1)

export const CancelButton = ({
  className,
  classes: classesOverride = {},
  invalid,
  label = 'ra.action.cancel',
  pristine,
  redirect,
  saving,
  submitOnEnter,
  variant = 'outlined',
  icon,
  onClick = goBack,
  handleSubmitWithRedirect,
  ...rest
}) => {
  const classes = useCancelStyles({ classes: classesOverride })
  const translate = useTranslate()

  // We handle the click event through mousedown because of an issue when
  // the button is not as the same place when mouseup occurs, preventing the click
  // event to fire.
  // It can happen when some errors appear under inputs, pushing the button
  // towards the window bottom.
  const handleMouseDown = (event) => {
    if (saving) {
      event.preventDefault()
    } else if (typeof onClick === 'function') {
      event.preventDefault()
      onClick()
    }
  }

  // As we handle the "click" through the mousedown event, we have to make sure we cancel
  // the default click in case the issue mentionned above does not occur.
  // Otherwise, this would trigger a standard HTML submit, not the final-form one.
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const displayedLabel = label && translate(label, { _: label })

  const sanitizeRestProps = ({ basePath, handleSubmit, record, resource, undoable, onSave, ...rest }) => rest

  return (
    <MuiButton
      className={classnames(classes.button, className)}
      variant={variant}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      color={saving ? 'default' : 'primary'}
      disabled={saving}
      aria-label={displayedLabel}
      {...sanitizeRestProps(rest)}
    >
      {icon &&
        cloneElement(icon, {
          className: classnames(classes.leftIcon, classes.icon),
        })}
      {displayedLabel}
    </MuiButton>
  )
}
