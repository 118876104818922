import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { FunctionField } from 'react-admin'
import { Typography } from '@material-ui/core'

import { useCommonStyles } from '../config/theme'

const UnitField = ({ children, unit, formatValue, shouldFormatUnit, addEmptySpace, ...props }) => {
  const translate = useTranslate()
  const commonClasses = useCommonStyles()
  return (
    <FunctionField
      {...props}
      render={(record) => {
        const value = get(record, props.source)
        if (!value && value !== 0) {
          return 'n/a'
        }
        return (
          <div className={commonClasses.flexRowCenterContainer}>
            <Typography variant="body2">
              {formatValue(value)}
              {addEmptySpace ? ' ' : ''}
              {shouldFormatUnit ? translate(unit, formatValue(value) || 1) : unit}
            </Typography>
            {children}
          </div>
        )
      }}
    />
  )
}

UnitField.defaultProps = {
  addLabel: true,
  unit: 'km',
  formatValue: (v) => v,
  shouldFormatUnit: false,
  addEmptySpace: true,
}

export default UnitField
