import { FormDataConsumer } from 'react-admin'
import { Divider, Typography } from '@material-ui/core'
import { useTranslate } from 'ra-core'

export const getResourceSingleLabel = (resourceConfig) =>
  resourceConfig?.options?.singleLabel ? resourceConfig.options.singleLabel : 'Resource'

export const CardTitle = ({ text, size, args = {} }) => {
  const translate = useTranslate()
  return (
    <Typography variant="h6" style={{ fontSize: size }}>
      {translate(text, args)}
    </Typography>
  )
}

export const FormDivider = ({ condition }) => (
  <FormDataConsumer>{({ formData }) => (!condition || condition(formData) ? <Divider /> : null)}</FormDataConsumer>
)
FormDivider.defaultProps = {
  source: 'divider',
}
