import { Box, CardContent } from '@material-ui/core'
import classnames from 'classnames'
import { get } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { EmailField, Show, SimpleShowLayout, useDataProvider, useGetIdentity, useGetOne } from 'react-admin'

import AdvancedArrayField from '../../components/AdvancedArrayField'
import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import FieldWrapper from '../../components/FieldWrapper'
import MobileAppVersionField from '../../components/MobileAppVersionField'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PriceField from '../../components/PriceField'
import UserDocumentStatusField from '../../components/UserDocumentStatusField'
import UserNFCCardsField from '../../components/UserNFCCardsField'
import UserPaymentMethodsField from '../../components/UserPaymentMethodsField'
import UserTitle from '../../components/UserTitle'
import { ViewModeList } from '../../components/ViewModeToggle'
import { ACCOUNT_BASE_PATH } from '../account/config'
import bookingsConfig from '../bookings/config'
import { BookingsListLayout } from '../bookings/list'
import { BookingShowLayout } from '../bookings/show'
import { CardTitle } from '../common'
import { ListReference } from '../common/list'
import {
  GenericCardTitle,
  ShowActions,
  ShowCard,
  ShowReference,
  ShowReferenceLinks,
  useShowStyles,
} from '../common/show'
import paymentActionsConfig from '../paymentActions/config'
import PaymentActionsListLayout from '../paymentActions/list'
import userDetailsConfig from '../userDetails/config'
import { VehicleShowLayout } from '../vehicles/show'
import { useApi } from '../../api/apiProvider'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPES } from '../../config/bookings'
import { OPS_USER_ROLE_OWNER, SYSTEM_PERMISSION_ACTIONS } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { USER_ACCOUNTS_CLOSED_BY, USER_ACCOUNTS_CLOSING_REASONS, USER_ROLES } from '../../config/users'
import { useResourcePermissions } from '../../domain/permissions'
import { hasBillingType, hasMobilityBudgetBillingType } from '../../utils'
import { useSmallScreen } from '../../utils/theme'

import config from './config'

const UserShowLayout = ({ record: { id: userId }, ...originalProps }) => {
  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => fetchAccount(), [fetchAccount])
  useEffect(() => fetchMeInfo(), [fetchMeInfo])

  const dataProvider = useDataProvider()
  const [user, setUser] = useState()
  const [userDetails, setUserDetails] = useState()
  useEffect(() => {
    if (!Boolean(userId)) {
      return
    }
    dataProvider
      .getOne(config.name, { id: userId })
      .then(({ data }) => setUser(data))
      .then(() => dataProvider.getOne(userDetailsConfig.name, { id: userId }).then(({ data }) => setUserDetails(data)))
  }, [dataProvider, userId])

  const record = useMemo(
    () => ({ ...user, ...userDetails }),
    [JSON.stringify(user), JSON.stringify(userDetails)], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const props = useMemo(
    () => ({ ...originalProps, record }),
    [JSON.stringify(originalProps), JSON.stringify(record)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const translate = useTranslate()
  const [hasActions] = useResourcePermissions(props.resource, SYSTEM_PERMISSION_ACTIONS)
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const operationalStatusSectionProps = {
    data: record?.status_timeline,
    classes: { timelineContainer: showClasses.statusTimelineContainer },
  }

  const getCommonAccountFieldProps = useMemo(
    () => (isFieldOverriden) => ({
      record: account,
      tooltip: translate('resources.users.show.accountSettings'),
      className: classnames(showClasses.italicHintText, isFieldOverriden ? showClasses.disabledText : null),
    }),
    [account?.id, translate], // eslint-disable-line react-hooks/exhaustive-deps
  )
  const commonUserOverrideFieldProps = {
    className: showClasses.overrideText,
    tooltip: translate('resources.users.show.userSettings'),
  }

  const accountHasBillingTypeFree = hasBillingType(account?.billing_types, BOOKING_BILLING_TYPE_FREE)
  const accountHasMobilityBudget = hasMobilityBudgetBillingType(account?.billing_types)
  const userHasBillingOverride = Boolean(record?.billing_types_override)
  const userHasBillingTypeFreeInOverride = hasBillingType(record?.billing_types_override, BOOKING_BILLING_TYPE_FREE)
  const userHasMobilityBudgetInOverride = hasMobilityBudgetBillingType(record?.billing_types_override)
  const shouldDisplayMobilityBudgetSection =
    identity?.role === OPS_USER_ROLE_OWNER &&
    ((!userHasBillingOverride && accountHasMobilityBudget) || userHasMobilityBudgetInOverride)

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.users.show.titles.main" />
      </CardContent>
      {user && userDetails && (
        <>
          <div className={isSmallScreen ? null : showClasses.row}>
            <div className={showClasses.expanded}>
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <GenericCardTitle text="information" />
              </CardContent>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <AdvancedTextField source="first_name" />
                <AdvancedTextField source="last_name" />
                <EmailField source="email" emptyText="n/a" />
                <AdvancedTextField source="phone_number" />
                <AdvancedTextField
                  source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
                  label="resources.users.fields.address"
                />
                <MobileAppVersionField mobileAppLatestVersion={meInfo?.mobile_app_latest_version} />
                <DateTooltipField source="created_on" addTime />
              </SimpleShowLayout>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <AdvancedTextField source="role" map={USER_ROLES} />
                <UserDocumentStatusField isTrusted={record.is_trusted} />
                <AdvancedTextField source="monitoring_email" />
              </SimpleShowLayout>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <UserNFCCardsField />
              </SimpleShowLayout>
              {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
              {Boolean(record.company_legal_info) && (
                <>
                  <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                    <GenericCardTitle text="professionalAccount" />
                  </CardContent>
                  <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                    <AdvancedTextField source="company_legal_info.name" />
                    <AdvancedTextField source="company_legal_info.vat_number" />
                    <AdvancedTextField
                      source="company_legal_info.address.formatted_address"
                      label="resources.users.fields.company_legal_info.address"
                    />
                  </SimpleShowLayout>
                </>
              )}
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <GenericCardTitle text="paymentInformation" />
              </CardContent>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <UserPaymentMethodsField />
                <PriceField source="balance" />
              </SimpleShowLayout>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <FieldWrapper source="billing_types_override">
                  {(fieldProps) => {
                    const isOverriden = get(fieldProps.record, fieldProps.source, null) !== null
                    const commonFieldProps = { ...fieldProps, map: BOOKING_BILLING_TYPES }
                    return (
                      <>
                        <AdvancedArrayField
                          {...commonFieldProps}
                          {...getCommonAccountFieldProps(isOverriden)}
                          source="billing_types"
                        />
                        {'\xa0\xa0'}
                        {isOverriden && <AdvancedArrayField {...commonFieldProps} {...commonUserOverrideFieldProps} />}
                      </>
                    )
                  }}
                </FieldWrapper>
                <FieldWrapper source="default_billing_type_override">
                  {(fieldProps) => {
                    const isOverriden = get(fieldProps.record, fieldProps.source, null) !== null
                    const commonFieldProps = { ...fieldProps, map: BOOKING_BILLING_TYPES }
                    return (
                      <>
                        <AdvancedTextField
                          {...commonFieldProps}
                          {...getCommonAccountFieldProps(isOverriden)}
                          source="default_billing_type"
                        />
                        {'\xa0\xa0'}
                        {isOverriden && <AdvancedTextField {...commonFieldProps} {...commonUserOverrideFieldProps} />}
                      </>
                    )
                  }}
                </FieldWrapper>
                {accountHasBillingTypeFree || userHasBillingTypeFreeInOverride ? (
                  <FieldWrapper source="free_billing_type_requires_justification_override">
                    {(fieldProps) => {
                      const isOverriden = get(fieldProps.record, fieldProps.source, null) !== null
                      const commonFieldProps = {
                        ...fieldProps,
                        trueIcon: null,
                        falseIcon: null,
                      }
                      return (
                        <>
                          <AdvancedBooleanField
                            {...commonFieldProps}
                            {...getCommonAccountFieldProps(isOverriden)}
                            source="free_billing_type_requires_justification"
                            overrideLabel={accountHasBillingTypeFree ? null : 'n/a'}
                          />
                          {'\xa0\xa0'}
                          {isOverriden && (
                            <AdvancedBooleanField
                              {...commonFieldProps}
                              {...commonUserOverrideFieldProps}
                              overrideLabel={userHasBillingTypeFreeInOverride ? null : 'n/a'}
                            />
                          )}
                        </>
                      )
                    }}
                  </FieldWrapper>
                ) : null}
                <FieldWrapper source="requires_payment_override">
                  {(fieldProps) => {
                    const isOverriden = get(fieldProps.record, fieldProps.source, null) !== null
                    const commonFieldProps = {
                      ...fieldProps,
                      trueIcon: null,
                      falseIcon: null,
                    }
                    return (
                      <>
                        <AdvancedBooleanField
                          {...commonFieldProps}
                          {...getCommonAccountFieldProps(isOverriden)}
                          source="requires_payment"
                        />
                        {'\xa0\xa0'}
                        {isOverriden && (
                          <AdvancedBooleanField {...commonFieldProps} {...commonUserOverrideFieldProps} />
                        )}
                      </>
                    )
                  }}
                </FieldWrapper>
              </SimpleShowLayout>
              {shouldDisplayMobilityBudgetSection && (
                <>
                  <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                    <GenericCardTitle text="mobilityBudgetIntegration" />
                  </CardContent>
                  <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                    <AdvancedTextField source="mbrella_id" />
                    <AdvancedTextField source="skipr_id" />
                  </SimpleShowLayout>
                </>
              )}
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <GenericCardTitle text="accountStatus" />
              </CardContent>
              <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
                <DateTooltipField source="user_account.closed_on" addTime />
                <AdvancedTextField source="user_account.closing_reason" map={USER_ACCOUNTS_CLOSING_REASONS} />
                <AdvancedTextField source="user_account.closed_by" map={USER_ACCOUNTS_CLOSED_BY} />
                <AdvancedTextField source="user_account.closing_justification" multiline />
              </SimpleShowLayout>
              {hasActions && <ShowActions resource="users" record={record} />}
            </div>
            {!isSmallScreen && (
              <Box width="100%" maxWidth={400} className={commonClasses.borderLeft}>
                <OperationalStatusSection {...operationalStatusSectionProps} />
              </Box>
            )}
          </div>
          <ShowReferenceLinks {...props} />
        </>
      )}
    </>
  )
}

const UserAside = (props) => {
  const translate = useTranslate()
  const { data: userDetails } = useGetOne(userDetailsConfig.name, props.record?.id, {
    enabled: Boolean(props.record?.id),
  })
  const [viewMode, setViewMode] = useState(ViewModeList)

  return props.record && userDetails ? (
    <>
      {Boolean(userDetails.current_booking_id) && (
        <ShowReference record={userDetails} reference="bookings" source="current_booking_id">
          {(bookingProps) =>
            bookingProps?.record ? (
              <ShowReference record={bookingProps.record} reference="organisations" source="organisation_id">
                {(organisationProps) => (
                  <>
                    <ShowCard>
                      <BookingShowLayout
                        title={translate('resources.common.show.currentBookingFor', {
                          organisationName: get(organisationProps, 'record.name', '...'),
                        })}
                        excluded={['users', 'vehicles']}
                        shouldDisplayVehicleReferenceLink
                        hasRedirect
                        {...bookingProps}
                      />
                    </ShowCard>
                    <ShowReference record={bookingProps.record} reference="vehicles" source="vehicle_id">
                      <VehicleShowLayout
                        title={translate('resources.users.show.currentVehicleFor', {
                          organisationName: get(organisationProps, 'record.name', '...'),
                        })}
                        excluded={['organisations']}
                        hasRedirect
                      />
                    </ShowReference>
                  </>
                )}
              </ShowReference>
            ) : null
          }
        </ShowReference>
      )}
      <ListReference
        reference="bookings"
        target="user_id"
        sort={bookingsConfig.options.defaultSort}
        filter={bookingsConfig.options.defaultFilterValues}
        showPagination={viewMode === ViewModeList}
        {...props}
      >
        <BookingsListLayout
          excluded={['users']}
          disabledInputsSources={['user_id']}
          viewMode={viewMode}
          setViewMode={setViewMode}
          schedulerProps={{
            filter: { user_id: props.record.id },
            relatedResource: 'vehicles',
            groupResource: 'users',
            groupNameKey: config.options.getName,
            groupAccessoryNameKey: null,
            groupFilter: { id: [props.record.id] },
            groupCategories: null,
            vehicleUnavailabilityCreationDisabled: true,
            creationInitialValues: { user_id: props.record.id },
          }}
        />
      </ListReference>
      <ListReference
        reference="payment-actions"
        target="user_id"
        sort={paymentActionsConfig.options.defaultSort}
        {...props}
      >
        <PaymentActionsListLayout />
      </ListReference>
    </>
  ) : null
}

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show {...props} classes={{ main: showClasses.main }} title={<UserTitle />} aside={<UserAside />}>
      <UserShowLayout />
    </Show>
  )
}
