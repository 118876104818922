import { makeStyles } from '@material-ui/styles'
import { FilterLiveSearch } from 'react-admin'

import { useSmallScreen } from '../utils/theme'

const useStyles = makeStyles({
  filter: {
    marginTop: (props) => (props.isSmallScreen ? 8 : 0),
    marginBottom: (props) => (props.isSmallScreen ? 8 : 0),
  },
})

const AdvancedSearchFilter = (props) => {
  const isSmallScreen = useSmallScreen()
  const classes = useStyles({ isSmallScreen })
  return <FilterLiveSearch {...props} className={classes.filter} />
}

AdvancedSearchFilter.defaultProps = { source: 'search_designation' }

export default AdvancedSearchFilter
