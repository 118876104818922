import Icon from '@material-ui/icons/Healing'

import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'
import { DAMAGES_STATUS_REPORTED } from '../../config/damages'

export default {
  name: 'damage-reports',
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_LEASING_COMPANY]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    defaultSort: { field: 'created_on', order: 'DESC' },
    defaultFilterValues: { status: DAMAGES_STATUS_REPORTED },
    references: ['organisations', 'vehicles'],
    badge: true,
  },
  icon: Icon,
}
