import { get } from 'lodash'
import { ArrayField, Datagrid, FunctionField } from 'react-admin'

import { HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM, HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES } from '../config/hubs'
import { getHubPickUpAndDropOffHoursWeekDayParams } from '../domain/hubs'
import { getWeekDayFromIndex } from '../utils/dates'

import AdvancedTextField from './AdvancedTextField'
import PeriodField from './PeriodField'
import StaticText from './StaticText'

const HubPickUpAndDropOffHoursField = ({ record, ...props }) => {
  const value = get(record, props.source)

  if (!Boolean(value)) {
    return <StaticText>resources.hubs.pickUpAndDropOffHours.always</StaticText>
  }

  // For each element of the array (i.e. each week day), we add an ID to prevent a React warning (fixed in later versions of RA)
  const newRecord = {
    ...record,
    [props.source]: value.map((weekDay, index) => ({
      id: index,
      label: getWeekDayFromIndex(index),
      ...getHubPickUpAndDropOffHoursWeekDayParams(weekDay.start_time, weekDay.end_time),
    })),
  }

  return (
    <ArrayField {...props} record={newRecord}>
      <Datagrid>
        <AdvancedTextField source="label" label="mymove.dates.day" />
        <FunctionField
          label="resources.hubs.pickUpAndDropOffHours.openingTypes.name"
          render={(record) =>
            record.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM ? (
              <PeriodField record={record} startedOnSource="start_time" endedOnSource="end_time" />
            ) : (
              <AdvancedTextField record={record} source="type" map={HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES} />
            )
          }
        />
      </Datagrid>
    </ArrayField>
  )
}

HubPickUpAndDropOffHoursField.defaultProps = {
  addLabel: true,
  source: 'pick_up_and_drop_off_hours',
  label: 'resources.hubs.fields.pick_up_and_drop_off_hours',
}

export default HubPickUpAndDropOffHoursField
