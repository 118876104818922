import { Datagrid, ImageField, List, usePermissions } from 'react-admin'
import { Box, Grid } from '@material-ui/core'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import FieldWrapper from '../../components/FieldWrapper'
import ReferenceLink from '../../components/ReferenceLink'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { hasResourcePermission } from '../../domain/permissions'
import { CreateButton } from '../common/buttons'
import { GenericListLayout, ListActions, useListStyles } from '../common/list'

import { useCreateOrganisation } from './form'
import config from './config'

export const OrganisationsListLayout = (props) => {
  const { permissions } = usePermissions()
  const listClasses = useListStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          {...props}
          inset
          linkType="show"
          image="picture"
          title="name"
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid {...props} classes={{ row: listClasses.row }} rowClick="show">
          <FieldWrapper source="name">
            {(fieldProps) => (
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <Box
                    width={36}
                    height={36}
                    p={0.5}
                    m={-0.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="white"
                  >
                    <ImageField
                      {...fieldProps}
                      classes={{ image: listClasses.thumbnail }}
                      source="picture"
                      label=""
                      sortable={false}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <AdvancedTextField {...fieldProps} source="name" />
                </Grid>
              </Grid>
            )}
          </FieldWrapper>
          <FieldWrapper>
            {(fieldProps) => (
              <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                {config.options.references
                  .filter((r) => hasResourcePermission(permissions, r, SYSTEM_PERMISSION_READ))
                  .map((reference) => (
                    <Grid key={reference} item>
                      <ReferenceLink {...fieldProps} reference={reference} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </FieldWrapper>
        </Datagrid>
      }
    />
  )
}

export default (props) => {
  const [openCreatePopup, createPopupDialog] = useCreateOrganisation()

  return (
    <>
      <List
        sort={config.options.defaultSort}
        filter={config.options.defaultFilterValues}
        actions={
          <ListActions>
            {({ hasCreate, basePath, filterValues }) =>
              hasCreate && <CreateButton basePath={basePath} onClick={openCreatePopup} filterValues={filterValues} />
            }
          </ListActions>
        }
        {...props}
      >
        <OrganisationsListLayout />
      </List>
      {createPopupDialog}
    </>
  )
}
