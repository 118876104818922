import { ReferenceField } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'

import config from './config'

const HubField = ({ variant, ...props }) => (
  <ReferenceField {...props}>
    <AdvancedTextField source="name" />
  </ReferenceField>
)

HubField.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  addLabel: true,
  link: false,
}

export default HubField
