import { useEffect } from 'react'
import { ResourceContextProvider, ShowButton, Title, TopToolbar, useRedirect } from 'react-admin'
import { Card } from '@material-ui/core'
import { useTranslate } from 'ra-core'

import { useApi } from '../../api/apiProvider'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import { AccountShowPage } from './show'
import AccountFormLayout from './form'
import config, { ACCOUNT_BASE_PATH } from './config'

export const AccountEditPage = () => {
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  const redirect = useRedirect()
  const translate = useTranslate()

  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  useEffect(() => {
    if (hasEdit) fetchAccount()
  }, [hasEdit, fetchAccount])

  return account ? (
    <ResourceContextProvider value="accounts">
      <Title title={translate('resources.accounts.name', 1)} />
      <TopToolbar>
        <ShowButton to={AccountShowPage.path} />
      </TopToolbar>
      <Card>
        <AccountFormLayout record={account} redirect={() => redirect(AccountShowPage.path)} />
      </Card>
    </ResourceContextProvider>
  ) : (
    <Title title={translate('resources.accounts.name', 1)} />
  )
}

AccountEditPage.path = `${ACCOUNT_BASE_PATH}/edit`
