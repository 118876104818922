import { required } from 'react-admin'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'

import config from './config'

const OrganisationReferenceInput = (props) => <AdvancedReferenceInput {...props} />

OrganisationReferenceInput.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  sort: config.options.defaultSort,
  filter: config.options.defaultFilterValues,
  validate: required(),
}

export default OrganisationReferenceInput
