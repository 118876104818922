import { memo, useCallback } from 'react'
import { CardContent } from '@material-ui/core'
import { Internationalization } from '@syncfusion/ej2-base'
import {
  ChartComponent,
  DateTime,
  Inject,
  Legend,
  ScrollBar,
  SeriesCollectionDirective,
  SeriesDirective,
  StepAreaSeries,
  StepLineSeries,
  StripLine,
  Tooltip,
  Zoom,
} from '@syncfusion/ej2-react-charts'
import classnames from 'classnames'
import { useTranslate } from 'ra-core'

import { useCommonStyles } from '../config/theme'
import { VEHICLE_TYPES } from '../config/vehicles'
import { CardTitle } from '../layouts/common'
import { useListStyles } from '../layouts/common/list'
import { getMaxDate, getMinDate, getTwoHoursDateBounds, getTwoMonthsDateBounds } from '../utils/dates'

const intl = new Internationalization()

const PressureGraph = memo(({ code, title, data }) => {
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const translate = useTranslate()
  const tooltipRender = useCallback((args) => {
    args.text = intl.formatDate(new Date(args.point.x), { format: 'dd/MM/y HH:mm z' })
  }, [])

  if (data.total.length === 0) {
    return null
  }

  const [nowMinusOneHour, nowPlusOneHour] = getTwoHoursDateBounds()
  const [nowMinusOneMonth, nowPlusOneMonth] = getTwoMonthsDateBounds()

  const dates = data.total.concat(data.used).map((e) => e.date)
  const earliestDate = getMinDate(...dates)
  const latestDate = getMaxDate(...dates)

  // We first compute the interval available with data (total + used) received, which represents a zoom factor equal to 1
  // We then compute the interval desired by default (2 months here)
  // We finally compute the zoom factor w/ these two intervals to display the desired interval by default
  const dataInterval = latestDate - earliestDate
  const desiredDefaultInterval = nowPlusOneMonth - nowMinusOneMonth
  const zoomFactor = desiredDefaultInterval > dataInterval ? 1 : desiredDefaultInterval / dataInterval

  let zoomPosition
  if (latestDate - nowMinusOneMonth <= 0) {
    // If available data are earlier than the current date, we display by default the latest 2 months
    zoomPosition = 1
  } else if (nowPlusOneMonth - earliestDate <= 0) {
    // If available data are later than the current date, we display by default the earliest 2 months
    zoomPosition = 0
  } else {
    // Otherwise, we display by default 1 month before and 1 month after the current date
    zoomPosition = (nowMinusOneMonth - earliestDate) / dataInterval
  }

  return (
    <>
      <CardContent className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}>
        <CardTitle
          text="resources.organisations.pressureGraph"
          args={{ vehicleType: translate(title).toLowerCase() }}
        />
      </CardContent>
      <CardContent>
        <ChartComponent
          id={`chart-${code}`}
          style={{ textAlign: 'center' }}
          primaryXAxis={{
            intervalType: 'Days',
            majorGridLines: { width: 0 },
            valueType: 'DateTime',
            edgeLabelPlacement: 'Shift',
            zoomFactor,
            zoomPosition,
            stripLines: [
              {
                start: nowMinusOneHour,
                end: nowPlusOneHour,
                color: 'red',
                text: 'Now',
                textStyle: { size: 15, color: 'red' },
                verticalAlignment: 'Start',
                horizontalAlignment: 'End',
              },
            ],
          }}
          primaryYAxis={{
            lineStyle: { width: 0 },
            interval: 1,
            majorTickLines: { width: 0 },
            labelFormat: '{value}',
          }}
          width="100%"
          chartArea={{ border: { width: 0 } }}
          tooltipRender={tooltipRender}
          tooltip={{ enable: true }}
          zoomSettings={{
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true,
            mode: 'X',
            enableScrollbar: true,
          }}
        >
          <Inject services={[StepAreaSeries, StepLineSeries, Legend, Tooltip, DateTime, Zoom, ScrollBar, StripLine]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={data.total}
              xName="date"
              yName="counter"
              name="Total"
              width={2}
              type="StepLine"
              marker={{ visible: false }}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={data.used}
              xName="date"
              yName="booking_counter"
              name="Used"
              width={2}
              type="StepArea"
              marker={{ visible: false }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </CardContent>
    </>
  )
})

const PressureGraphs = (props) =>
  props.record
    ? Object.entries(VEHICLE_TYPES).map(([code, title]) => (
        <PressureGraph key={code} code={code} title={title} data={props.record[code]} />
      ))
    : null

export default PressureGraphs
