import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import classnames from 'classnames'
import { TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import { useInput, InputHelperText } from 'react-admin'

import { useCommonStyles } from '../config/theme'

const useStyles = makeStyles({
  autocomplete: {
    marginRight: (props) => props.marginRight ?? 0,
  },
})

const AdvancedAutocompleteInput = ({
  label,
  choices,
  excludedIds = [],
  getOptionLabel,
  getOptionSelected,
  disabled,
  helperText,
  marginRight,
  ...props
}) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles({ marginRight })
  const {
    meta: { submitting, error, touched },
    input: { onChange, value, ...inputProps },
    isRequired,
  } = useInput(props)
  const hasError = Boolean(error) && touched

  // Logic for displaying loading indicator & message when dropdown is open & no options yet
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0

  useEffect(() => {
    let active = true
    if (!loading) return
    // Set options if dropdown is open & no options yet
    if (active) setOptions(choices.filter((c) => !excludedIds.includes(c.id)))
    return () => (active = false)
  }, [loading, choices.map((c) => c?.id).join(',')]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // We disable scrolling on the main page when dropdown is open (same behaviour as react-admin's SelectInput)
    if (open) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    // We reset options when dropdown is closed
    // Used to update the list when the field is linked to another field (e.g. organisation)
    if (!open) setOptions([])
  }, [open])

  const onAutocompleteChange = (e, newValue, reason) => {
    onChange(newValue ? newValue.id : null)

    // Open dropdown on clear (only for required field)
    if (reason === 'clear' && isRequired) setOpen(true)
  }

  const renderInput = (params) => {
    const textFieldProps = {
      label,
      variant: 'outlined',
      required: isRequired,
      error: hasError,
      helperText: helperText ?? <InputHelperText touched={touched} error={error} />,
    }
    return (
      <TextField
        {...params}
        {...textFieldProps}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {/* Display loading indicator inside input */}
              {loading ? <CircularProgress size={20} style={{ marginLeft: 10 }} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )
  }

  const renderOption = (option, { inputValue }) => {
    // Highlight part of the text that matches input text
    const matches = match(getOptionLabel(option), inputValue)
    const parts = parse(getOptionLabel(option), matches)
    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    )
  }

  return (
    <Autocomplete
      {...inputProps}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading} // display loading message in dropdown
      options={options}
      value={value && choices.length > 0 ? choices.find((choice) => choice.id === value) : null}
      onChange={onAutocompleteChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      disabled={submitting || disabled}
      disableClearable={loading}
      autoHighlight
      size="small"
      className={classnames(commonClasses.commonInput, classes.autocomplete)}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default AdvancedAutocompleteInput
