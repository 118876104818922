import {
  Typography,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import PlusIcon from '@material-ui/icons/Add'
import classnames from 'classnames'
import countries from 'i18n-iso-countries'
import { get, isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import {
  FunctionField,
  Loading,
  SimpleShowLayout,
  Title,
  TopToolbar,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useHistory } from 'react-router-dom'

import config, { PAYOUT_TITLE, PAYOUT_BASE_PATH, PAYOUT_API_BANK_ACCOUNTS_PATH } from '../config'
import { useCreateBankAccount } from '../forms/bankAccountInfo'
import { useEditMarketplaceModel } from '../forms/marketplaceModel'
import {
  useCreatePayoutSellerAccount,
  useGetPayoutSetupStatus,
  useGetPayoutSellerAccount,
  useGetPayoutSettings,
  useGetPayoutReports,
} from '../hooks'
import { usePayoutCommonStyles, usePayoutShowStyles, usePayoutIconColorStyles } from '../styles'
import { CardTitle } from '../../common'
import { EditButton } from '../../common/buttons'
import { useShowStyles } from '../../common/show'
import { useApi } from '../../../api/apiProvider'
import AdvancedTextField from '../../../components/AdvancedTextField'
import BankIcon from '../../../components/icons/BankIcon'
import DefaultIcon from '../../../components/icons/DefaultIcon'
import SetDefaultIcon from '../../../components/icons/SetDefaultIcon'
import UnitField from '../../../components/UnitField'
import env from '../../../config/env'
import {
  PAYOUT_SETUP_STATUS_NOT_STARTED,
  PAYOUT_SETUP_STATUS_IN_PROGRESS,
  PAYOUT_SETUP_STATUS_COMPLETED,
  PAYOUT_SETUP_STATUS_FAILED,
  PAYOUT_BANK_ACCOUNT_STATUS_PASSED,
  PAYOUT_BANK_ACCOUNT_STATUSES_LABELS,
  PAYOUT_BANK_ACCOUNT_STATUSES_ICONS,
  PAYOUT_BANK_ACCOUNT_STATUSES_COLORS,
  PAYOUT_REPORT_STATUS_GENERATED,
  PAYOUT_REPORT_STATUSES_LABELS,
  PAYOUT_REPORT_STATUSES_ICONS,
  PAYOUT_REPORT_STATUSES_COLORS,
} from '../../../config/payout'
import { SYSTEM_PERMISSION_CREATE, SYSTEM_PERMISSION_PATCH, SYSTEM_PERMISSION_READ } from '../../../config/permissions'
import { useCommonStyles } from '../../../config/theme'
import { useResourcePermissions } from '../../../domain/permissions'
import { formatDate, formatDateTime, parseDateAsDateTime } from '../../../utils/dates'
import { useSmallScreen } from '../../../utils/theme'

import { PayoutSetupScreen } from './setup'
import { PayoutMarketplaceModelEditScreen } from './editMarketplaceModel'
import { PayoutBankAccountCreateScreen } from './addBankAccount'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const PaymentFeesPerTransactionField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      const fixedCommission = get(record, 'mymove_fixed_commission', 0)
      const percentageCommission = get(record, 'mymove_percentage_commission', 0)
      const hasFixedCommission = fixedCommission > 0
      const hasPercentageCommission = percentageCommission > 0
      return hasFixedCommission || hasPercentageCommission
        ? (hasFixedCommission ? `${env.CURRENCY_SYMBOL}${(fixedCommission / 100).toFixed(2)}` : '') +
            (hasFixedCommission && hasPercentageCommission ? ' + ' : '') +
            (hasPercentageCommission ? `${percentageCommission}%` : '')
        : 'n/a'
    }}
  />
)
PaymentFeesPerTransactionField.defaultProps = {
  label: 'Payment fees per transaction',
  addLabel: true,
}

const LegalEntityAddressField = (props) => {
  const commonClasses = useCommonStyles()
  return (
    <FunctionField
      {...props}
      className={commonClasses.fullWidth}
      render={(record, source) => {
        const address = get(record, source)
        return `${address?.street} ${address?.houseNumber}, ${address?.zipcode} ${address?.city}, ${countries.getName(
          address?.country,
          'en',
        )}`
      }}
    />
  )
}
LegalEntityAddressField.defaultProps = {
  source: 'legalEntity.address',
  label: 'Address',
  addLabel: true,
}

const SetupNotStartedView = () => {
  const [createSellerAccount, { loading: isCreating }] = useCreatePayoutSellerAccount()
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const payoutCommonClasses = usePayoutCommonStyles()

  return (
    <div className={payoutCommonClasses.flexContainer}>
      <BankIcon className={payoutCommonClasses.largeBankIcon} />
      <Typography variant="h4" className={payoutCommonClasses.largeText}>
        Payout setup not started yet
      </Typography>
      <Typography variant="body2" className={payoutCommonClasses.smallText}>
        If you want to receive the revenue from your bookings on your bank account, please set up your account first.
      </Typography>
      {hasCreate && (
        <Button
          color="primary"
          variant="contained"
          component="a"
          disabled={isCreating}
          onClick={() => createSellerAccount()}
        >
          {isCreating ? (
            <CircularProgress size={18} color="inherit" style={{ marginRight: 12 }} />
          ) : (
            <PlusIcon style={{ marginRight: '0.35em' }} />
          )}
          Start setup
        </Button>
      )}
    </div>
  )
}

const SetupCompletedView = () => {
  const refresh = useRefresh()
  const notify = useNotify()

  const sellerAccount = useGetPayoutSellerAccount()
  const settings = useGetPayoutSettings()
  const reports = useGetPayoutReports()

  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const payoutCommonClasses = usePayoutCommonStyles()
  const payoutShowClasses = usePayoutShowStyles({ isSmallScreen })
  const payoutIconColorClasses = usePayoutIconColorStyles()

  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_PATCH)
  const [openEditModelPopup, editModelPopupDialog] = useEditMarketplaceModel(settings)
  const [openCreateBankAccountPopup, createBankAccountPopupDialog] = useCreateBankAccount(sellerAccount)

  const bankAccountsMinWeight = Boolean(sellerAccount?.externalAccounts)
    ? Math.min(...sellerAccount.externalAccounts.map((account) => account.weight))
    : null

  const [editBankAccount, { loading: isEditingBankAccount }] = useApi(PAYOUT_API_BANK_ACCOUNTS_PATH, {
    method: 'PATCH',
  })

  const setAsPrimaryBankAccount = useCallback(
    ({ code, accountType, accountNumber, extRef, displayName, description, currency }) =>
      editBankAccount({
        path: `${PAYOUT_API_BANK_ACCOUNTS_PATH}/${code}`,
        body: JSON.stringify({
          accountType,
          accountNumber,
          extRef,
          displayName,
          description,
          currency,
          weight: bankAccountsMinWeight - 1,
        }),
        onSuccess: () => {
          notify('Primary bank account successfully updated')
          refresh()
        },
        onFailure: () => notify('Failed to update primary bank account', 'warning'),
      }),
    [editBankAccount, bankAccountsMinWeight, notify, refresh],
  )

  if (!sellerAccount || !settings || !identity) {
    return <Loading className={payoutCommonClasses.loadingContainer} />
  }

  const currentUserRestrictedToOrganisationId = identity.restricted_to_organisation_id

  return (
    <>
      <Card className={payoutShowClasses.card}>
        <CardHeader title={<CardTitle text={PAYOUT_TITLE} />} className={commonClasses.titleContainer} />
      </Card>
      <Grid container direction={isSmallScreen ? 'column-reverse' : 'row'} spacing={1}>
        <Grid item md={8}>
          <Card className={payoutShowClasses.card}>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Marketplace model" size={16} />
              <CardActions className={payoutShowClasses.actions}>
                {hasEdit && !Boolean(currentUserRestrictedToOrganisationId) && (
                  <EditButton
                    record={settings}
                    label="Edit model"
                    disabled={isEditingBankAccount}
                    onClick={openEditModelPopup}
                    to={PayoutMarketplaceModelEditScreen.path}
                  />
                )}
              </CardActions>
            </CardContent>
            <SimpleShowLayout record={settings} className={showClasses.fieldContainer}>
              <UnitField unit="%" source="marketplace_percentage" label="Marketplace commission" addEmptySpace={false}>
                {isSmallScreen ? null : (
                  <Tooltip title="Does not apply to extra charges" placement="right">
                    <InfoIcon className={payoutShowClasses.infoIcon} />
                  </Tooltip>
                )}
              </UnitField>
              <PaymentFeesPerTransactionField />
            </SimpleShowLayout>
          </Card>
          <Card className={payoutShowClasses.card}>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Bank accounts" size={16} />
              <CardActions className={payoutShowClasses.actions}>
                {hasEdit && (
                  <EditButton
                    record={sellerAccount}
                    icon={<PlusIcon />}
                    label="Add a bank account"
                    disabled={isEditingBankAccount}
                    onClick={openCreateBankAccountPopup}
                    to={PayoutBankAccountCreateScreen.path}
                  />
                )}
              </CardActions>
            </CardContent>
            <CardContent className={commonClasses.borderTop}>
              {isEmpty(sellerAccount.externalAccounts) ? (
                <Typography variant="body2">No bank account</Typography>
              ) : (
                sellerAccount.externalAccounts
                  .sort((a, b) => a.weight - b.weight)
                  .map((account, index) => {
                    const isPrimary = index === 0
                    const accountStatus = account.kycStatus.toUpperCase()
                    const isAccepted = accountStatus === PAYOUT_BANK_ACCOUNT_STATUS_PASSED
                    const StatusIcon = PAYOUT_BANK_ACCOUNT_STATUSES_ICONS[accountStatus]
                    return (
                      <div
                        key={account.code}
                        className={classnames(
                          commonClasses.flexRowCenterContainer,
                          isPrimary ? commonClasses.solidBorder : commonClasses.dashedBorder,
                          payoutShowClasses.bankAccountContainer,
                          payoutShowClasses.listItemContainer,
                        )}
                      >
                        <div className={classnames(commonClasses.flexRowCenterContainer, commonClasses.halfWidth)}>
                          <Tooltip title={PAYOUT_BANK_ACCOUNT_STATUSES_LABELS[accountStatus]} placement="right">
                            <StatusIcon
                              className={classnames(
                                payoutShowClasses.statusIcon,
                                payoutIconColorClasses[PAYOUT_BANK_ACCOUNT_STATUSES_COLORS[accountStatus]],
                              )}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {account.accountNumber
                              .replace(/[^\dA-Z]/g, '')
                              .replace(/(.{4})/g, '$1 ')
                              .trim()}
                          </Typography>
                        </div>
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            commonClasses.halfWidth,
                            payoutShowClasses.listItemRightContainer,
                          )}
                        >
                          <Typography variant="body2" className={payoutShowClasses.bankAccountDisplayName}>
                            {account.displayName}
                          </Typography>
                          {isPrimary ? (
                            <div className={commonClasses.flexRowCenterContainer}>
                              <DefaultIcon className={payoutShowClasses.defaultIcon} />
                              {isSmallScreen ? null : (
                                <Typography className={payoutShowClasses.primaryAccountText}>Primary</Typography>
                              )}
                            </div>
                          ) : (
                            <Button
                              className={payoutShowClasses.setDefaultButton}
                              disabled={!hasEdit || !isAccepted || isEditingBankAccount}
                              onClick={() => setAsPrimaryBankAccount(account)}
                              variant="text"
                              color="primary"
                              size="small"
                            >
                              <SetDefaultIcon className={payoutShowClasses.setDefaultIcon} />
                              {isSmallScreen ? null : 'Set as primary'}
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })
              )}
            </CardContent>
          </Card>
          <Card>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Reports" size={16} />
            </CardContent>
            <CardContent className={commonClasses.borderTop}>
              {isEmpty(reports) ? (
                <Typography variant="body2">No reports</Typography>
              ) : (
                reports
                  .sort((a, b) => parseDateAsDateTime(b.createdAt) - parseDateAsDateTime(a.createdAt))
                  .map((report) => {
                    const reportStatus = report.status.toUpperCase()
                    const isGenerated = reportStatus === PAYOUT_REPORT_STATUS_GENERATED
                    const StatusIcon = PAYOUT_REPORT_STATUSES_ICONS[reportStatus]
                    return (
                      <div
                        key={report.id}
                        className={classnames(
                          commonClasses.flexRowCenterContainer,
                          commonClasses.solidBorder,
                          payoutShowClasses.listItemContainer,
                        )}
                      >
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            isSmallScreen ? commonClasses.spacer : commonClasses.halfWidth,
                          )}
                        >
                          <Tooltip title={PAYOUT_REPORT_STATUSES_LABELS[reportStatus]} placement="right">
                            <StatusIcon
                              className={classnames(
                                payoutShowClasses.statusIcon,
                                payoutIconColorClasses[PAYOUT_REPORT_STATUSES_COLORS[reportStatus]],
                              )}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {`New splits until ${
                              Boolean(report.toDate) && report.toDate !== 'null' ? formatDate(report.toDate) : 'n/a'
                            }`}
                          </Typography>
                        </div>
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            isSmallScreen ? null : commonClasses.halfWidth,
                            payoutShowClasses.listItemRightContainer,
                          )}
                        >
                          <Typography variant="body2">
                            {isSmallScreen
                              ? null
                              : Boolean(report.createdAt) && report.createdAt !== 'null'
                              ? `Created on ${formatDateTime(report.createdAt)}`
                              : 'No creation date'}
                          </Typography>
                          <Tooltip title="Download report" placement="left">
                            <IconButton
                              className={commonClasses.primaryColor}
                              disabled={!isGenerated || report.url === 'null' || isEditingBankAccount}
                              href={report.url}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Company info" size={16} />
            </CardContent>
            <SimpleShowLayout record={sellerAccount} className={showClasses.fieldContainer}>
              <AdvancedTextField
                source="legalEntity.legalName"
                label="Legal name"
                className={commonClasses.fullWidth}
              />
              <AdvancedTextField
                source="legalEntity.registrationNumber"
                label="Registration number"
                className={commonClasses.fullWidth}
              />
              <AdvancedTextField
                source="legalEntity.taxNumber"
                label="VAT number"
                className={commonClasses.fullWidth}
              />
              <LegalEntityAddressField />
              <AdvancedTextField source="legalEntity.phone" label="Phone number" className={commonClasses.fullWidth} />
              <AdvancedTextField source="legalEntity.email" label="Email address" className={commonClasses.fullWidth} />
            </SimpleShowLayout>
          </Card>
        </Grid>
      </Grid>
      {editModelPopupDialog}
      {createBankAccountPopupDialog}
    </>
  )
}

export const PayoutShowScreen = () => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()
  const isSmallScreen = useSmallScreen()
  const history = useHistory()

  useEffect(() => {
    // Redirect to setup screen if setup in progress or if setup failed
    if ([PAYOUT_SETUP_STATUS_IN_PROGRESS, PAYOUT_SETUP_STATUS_FAILED].includes(payoutSetupStatus)) {
      history.push(PayoutSetupScreen.path)
    }
  }, [payoutSetupStatus, history])

  return (
    <>
      <Title title={PAYOUT_TITLE} />
      {!isSmallScreen && <TopToolbar></TopToolbar>}
      {hasRead && payoutSetupStatus === PAYOUT_SETUP_STATUS_NOT_STARTED && <SetupNotStartedView />}
      {hasRead && payoutSetupStatus === PAYOUT_SETUP_STATUS_COMPLETED && <SetupCompletedView />}
    </>
  )
}

PayoutShowScreen.path = PAYOUT_BASE_PATH
