import { Show, SimpleShowLayout, useReference } from 'react-admin'
import { CardContent } from '@material-ui/core'
import get from 'lodash/get'

import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import VehicleNameField from '../../components/VehicleNameField'
import { useCommonStyles } from '../../config/theme'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { CardTitle } from '../common'
import { ShowReference, ShowReferenceLinks, Title, useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'
import { VehicleShowLayout } from '../vehicles/show'

import config from './config'

export const VehicleUnavailabilityShowLayout = (props) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.maintenances.show.titles.main" />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <OrganisationField />
        <AdvancedTextField source="type" map={VEHICLE_UNAVAILABILITY_TYPES} />
        <PeriodField
          startedOnSource="started_on"
          endedOnSource="ended_on"
          label="resources.maintenances.fields.period"
          addTime
        />
        <DateTooltipField source="created_on" addTime />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedTextField source="description" fallback="resources.maintenances.show.description.fallback" multiline />
      </SimpleShowLayout>
      <ShowReferenceLinks {...props} />
    </>
  )
}

const VehicleUnavailabilityDetailsTitle = ({ vehicleUnavailability, vehicle }) => {
  const commonClasses = useCommonStyles()
  return (
    <>
      <VehicleNameField record={vehicle} className={commonClasses.inheritedFont} />
      {' • '}
      <PeriodField
        record={vehicleUnavailability}
        className={commonClasses.inheritedFont}
        startedOnSource="started_on"
        endedOnSource="ended_on"
        addTime
      />
    </>
  )
}

export const VehicleUnavailabilityTitle = (props) => {
  const vehicleUnavailability = props.record
  const { referenceRecord: vehicle } = useReference({
    reference: 'vehicles',
    id: get(vehicleUnavailability, 'vehicle_id'),
  })
  const details = props.details || (
    <VehicleUnavailabilityDetailsTitle vehicleUnavailability={vehicleUnavailability} vehicle={vehicle} />
  )
  return (
    <Title resourceConfig={config} {...props}>
      {details}
    </Title>
  )
}

const VehicleUnavailabilityAside = (props) =>
  props.record ? (
    <ShowReference reference="vehicles" source="vehicle_id" {...props}>
      <VehicleShowLayout excluded={['organisations']} hasRedirect />
    </ShowReference>
  ) : null

export default (props) => {
  const showClasses = useShowStyles()
  return (
    <Show
      {...props}
      classes={{ main: showClasses.main }}
      title={<VehicleUnavailabilityTitle />}
      aside={<VehicleUnavailabilityAside />}
    >
      <VehicleUnavailabilityShowLayout />
    </Show>
  )
}
