import ArrowIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { SelectInput } from 'react-admin'
import { useFormState } from 'react-final-form'

import { useListStyles } from '../common/list'
import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import { BOOKING_ERAS } from '../../config/bookings'
import { useCommonStyles } from '../../config/theme'
import { parseDateAsDateTime } from '../../utils/dates'

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

const bookingPeriodChoices = Object.entries(BOOKING_ERAS).map(([k, v]) => ({ id: k, name: v }))
export const BookingPeriodFilter = (props) => (
  <SelectInput {...props} choices={bookingPeriodChoices} optionText="name" optionValue="id" allowEmpty />
)
BookingPeriodFilter.defaultProps = {
  source: 'era',
  label: 'resources.bookings.filters.era.name',
}

export const BookingDatesFilter = ({ label, source, ...props }) => {
  const { values } = useFormState()
  const commonClasses = useCommonStyles()
  const listClasses = useListStyles()
  const classes = useStyles()
  return (
    <>
      <AdvancedDateTimeInput
        {...props}
        source={source[0]}
        helperText={false}
        maxDate={values[source[1]] ? parseDateAsDateTime(values[source[1]]) : undefined}
        className={listClasses.commonFilterInput}
      />
      <ArrowIcon className={classnames(commonClasses.contrastColor, classes.arrowIcon)} />
      <AdvancedDateTimeInput
        {...props}
        source={source[1]}
        helperText={false}
        minDate={values[source[0]] ? parseDateAsDateTime(values[source[0]]) : undefined}
        className={listClasses.commonFilterInput}
      />
    </>
  )
}
BookingDatesFilter.defaultProps = {
  source: ['timeline_start', 'timeline_end'],
  label: 'Dates',
}

const bookingCancelledChoices = [
  { value: true, name: 'resources.bookings.filters.cancelled.values.true' },
  { value: false, name: 'resources.bookings.filters.cancelled.values.false' },
]
export const BookingCancelledFilter = (props) => (
  <SelectInput {...props} choices={bookingCancelledChoices} optionText="name" optionValue="value" allowEmpty />
)
BookingCancelledFilter.defaultProps = {
  source: 'cancelled',
  label: 'resources.bookings.filters.cancelled.name',
}
