import { NumberField } from 'react-admin'
import get from 'lodash/get'

const AdvancedNumberField = ({ record = {}, fallback = 'n/a', ...props }) => {
  const value = get(record, props.source) || fallback
  return <NumberField {...props} record={{ ...record, [props.source]: value }} />
}

AdvancedNumberField.defaultProps = {
  addLabel: true,
}

export default AdvancedNumberField
