import { Button, CardContent, Dialog } from '@material-ui/core'
import PlusIcon from '@material-ui/icons/AddCircleOutline'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { get, pick } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useRef, useState } from 'react'
import {
  ArrayInput,
  CREATE,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  UPDATE,
  useCreateController,
  useDataProvider,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import { useApi } from '../../api/apiProvider'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import BookingJustificationInput from '../../components/BookingJustificationInput'
import {
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPE_PAID,
  BOOKING_BILLING_TYPES,
  BOOKING_ERA_UPCOMING,
} from '../../config/bookings'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { isBookingPaid } from '../../domain/bookings'
import { hasOnlyBillingType, isDisabled } from '../../utils'
import { getDateWithThirtyMinutesOffset, getRoundedNow, validateEndDateAfterStartDate } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { ACCOUNT_BASE_PATH } from '../account/config'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import hubsConfig from '../hubs/config'
import HubReferenceInput from '../hubs/input'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'
import vouchersConfig from '../vouchers/config'
import VoucherReferenceInput from '../vouchers/input'

import config from './config'
import { useGetBookingsJustifications } from './hooks'

const useStyles = makeStyles({
  billingTypeContainer: {
    height: 76,
  },
})

const getBillingTypeChoices = (billingTypes) => {
  const orderedBillingTypes = Object.keys(BOOKING_BILLING_TYPES).filter((type) => billingTypes.includes(type))
  const filteredBillingTypes = pick(BOOKING_BILLING_TYPES, orderedBillingTypes)
  const billingTypeChoices = Object.entries(filteredBillingTypes).map(([id, name]) => ({ id, name }))
  return billingTypeChoices
}

const validate = (values) => {
  const errors = {}
  errors.end_scheduled_on = validateEndDateAfterStartDate(values.start_scheduled_on, values.end_scheduled_on, 30, 1)
  return errors
}

export const transformValues = ({
  billing_settings,
  billing_type,
  make_booking_free,
  has_voucher,
  additional_driver_user_ids,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        billing_type: make_booking_free ? BOOKING_BILLING_TYPE_FREE : billing_type,
        additional_driver_user_ids: additional_driver_user_ids
          ? additional_driver_user_ids.map(({ user_id }) => user_id)
          : [],
      }
    : restValues

const BillingSettingsInjector = ({ account, formType }) => {
  const {
    values: { user_id: userId },
  } = useFormState()
  const { change } = useForm()
  const dataProvider = useDataProvider()

  const accountBillingTypes = get(account, 'billing_types', [])
  const accountDefaultBillingType = get(account, 'default_billing_type', null)
  const accountFreeBillingTypeRequiresJustification = get(account, 'free_billing_type_requires_justification', false)

  const [billingTypes, setBillingTypes] = useState(accountBillingTypes)
  const [defaultBillingType, setDefaultBillingType] = useState(accountDefaultBillingType)
  const [freeBillingTypeRequiresJustification, setFreeBillingTypeRequiresJustification] = useState(
    accountFreeBillingTypeRequiresJustification,
  )

  useEffect(() => {
    if (!Boolean(userId)) {
      // If we clear the user input or we change the org input, we want the account-related values
      setBillingTypes(accountBillingTypes)
      setDefaultBillingType(accountDefaultBillingType)
      setFreeBillingTypeRequiresJustification(accountFreeBillingTypeRequiresJustification)
      return
    }

    dataProvider.getOne('users/details', { id: userId }).then(({ data }) => {
      if (data?.billing_types_override) {
        // Billing settings override on the user --> user-related values
        setBillingTypes(data.billing_types_override)
        setDefaultBillingType(data.default_billing_type_override)
        setFreeBillingTypeRequiresJustification(data.free_billing_type_requires_justification_override)
      } else {
        // No billing settings override on the user --> account-related values
        setBillingTypes(accountBillingTypes)
        setDefaultBillingType(accountDefaultBillingType)
        setFreeBillingTypeRequiresJustification(accountFreeBillingTypeRequiresJustification)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataProvider,
    JSON.stringify(accountBillingTypes), // eslint-disable-line react-hooks/exhaustive-deps
    accountDefaultBillingType,
    accountFreeBillingTypeRequiresJustification,
    userId,
  ])

  useEffect(() => {
    change('billing_settings', {
      billing_types: billingTypes,
      default_billing_type: defaultBillingType,
      free_billing_type_requires_justification: freeBillingTypeRequiresJustification,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, JSON.stringify(billingTypes), defaultBillingType, freeBillingTypeRequiresJustification])

  useEffect(() => {
    if (formType === CREATE) {
      change('billing_type', defaultBillingType)
    }
  }, [change, formType, defaultBillingType])

  return null
}

const VoucherFormSection = ({ formType, ...props }) => {
  const { change } = useForm()
  const { values } = useFormState()

  const onToggle = (event) => {
    change('has_voucher', event.target.checked)
    if (!event.target.checked) {
      change(vouchersConfig.options.referenceKey, null)
    }
  }

  useEffect(() => {
    if (formType === CREATE && values.make_booking_free) {
      change('has_voucher', false)
      change(vouchersConfig.options.referenceKey, null)
    }
  }, [change, formType, values.make_booking_free])

  return (
    <>
      <AdvancedBooleanInput
        source="has_voucher"
        helperText={values.has_voucher ? false : 'resources.bookings.forms.helperTexts.voucher_id'}
        disabled={values.make_booking_free}
        options={{ onChange: onToggle }}
      />
      {values.has_voucher && (
        <VoucherReferenceInput {...props} label="resources.vouchers.fields.code" disabled={values.make_booking_free} />
      )}
    </>
  )
}

const AdditionalDriversAddButton = (props) => {
  const translate = useTranslate()
  return (
    <Button color="primary" size="small" startIcon={<PlusIcon />} {...props}>
      {translate('resources.bookings.forms.giveAccessToAnAdditionalUser')}
    </Button>
  )
}

const AdditionalDriversArrayInput = (props) => {
  const translate = useTranslate()
  const maxAmountOfDrivers = 19
  const commonClasses = useCommonStyles()
  const {
    values: { user_id: userId, additional_driver_user_ids: additionalDriverUserIds },
  } = useFormState()
  const excludedDriverUserIds = additionalDriverUserIds?.map((element) => element?.user_id) || []
  const excludedUserIds = [userId, ...excludedDriverUserIds].filter(Boolean)
  return (
    <ArrayInput {...props} source="additional_driver_user_ids" className={commonClasses.noMarginTop}>
      <SimpleFormIterator
        disableReordering
        addButton={<AdditionalDriversAddButton />}
        disableAdd={additionalDriverUserIds?.length >= maxAmountOfDrivers}
        TransitionProps={{ enter: false, exit: false }}
        classes={{
          index: commonClasses.displayNone,
          line: classnames(
            commonClasses.marginTopOnFirstChild,
            additionalDriverUserIds?.length >= maxAmountOfDrivers
              ? null
              : commonClasses.noMarginBottomOnPenultimateChild,
          ),
        }}
      >
        <UserReferenceInput label={translate('resources.bookings.fields.user_id')} excludedIds={excludedUserIds} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const BookingFormLayout = ({ type = UPDATE, disabledInputsSources = [], onClose, ...props }) => {
  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  const { hasSingleOrganisation, singleOrganisationId, hasSingleHub, singleHubId } = useCurrentAccountSelectors()
  useEffect(() => fetchAccount(), [fetchAccount])

  const bookingsJustifications = useGetBookingsJustifications()

  const title = `resources.bookings.forms.${type.toLowerCase()}.title`
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  const now = useRef(getRoundedNow())
  const end = useRef(getDateWithThirtyMinutesOffset())

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'organisation_id',
    'hub_id',
    'user_id',
    'vehicle_id',
    'era',
    'start_scheduled_on',
    'end_scheduled_on',
    'billing_type',
    'justification',
    'voucher_id',
    'additional_driver_user_ids',
  ])
  formProps.record.additional_driver_user_ids = formProps.record.additional_driver_user_ids?.map((id) => ({
    user_id: id,
  }))

  let initialValues = {}
  if (type === CREATE) {
    if (hasSingleOrganisation) {
      initialValues.organisation_id = singleOrganisationId
    }
    if (hasSingleHub) {
      initialValues.hub_id = singleHubId
    }
    initialValues.start_scheduled_on = now.current
    initialValues.end_scheduled_on = end.current
    initialValues.make_booking_free = false
    initialValues.has_voucher = false
  } else {
    initialValues.has_voucher = Boolean(formProps.record.voucher_id)
  }

  return Boolean(account) && Boolean(bookingsJustifications) ? (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        validate={validate}
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <OrganisationReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <HubReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, hubsConfig.options.referenceKey)}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <VehicleReferenceInput
              {...rest}
              formType={type}
              disabled={
                (type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING) ||
                isDisabled(disabledInputsSources, vehiclesConfig.options.referenceKey)
              }
            />
          )}
        </FormDataConsumer>
        <UserReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, usersConfig.options.referenceKey)}
        />
        <FormDivider />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AdvancedDateTimeInput
              {...rest}
              validate={required()}
              required
              source="start_scheduled_on"
              minDate={now.current}
              disabled={type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING}
            />
          )}
        </FormDataConsumer>
        <AdvancedDateTimeInput validate={required()} required source="end_scheduled_on" minDate={now.current} />
        <FormDivider />
        <BillingSettingsInjector account={account} formType={type} />
        <FormDataConsumer formClassName={classes.billingTypeContainer}>
          {({ formData, ...rest }) =>
            Boolean(formData.billing_settings) && (
              <RadioButtonGroupInput
                {...rest}
                source="billing_type"
                validate={required()}
                choices={getBillingTypeChoices(formData.billing_settings.billing_types)}
                style={{ width: COMMON_INPUT_WIDTH, marginTop: -3 }}
                helperText={false}
              />
            )
          }
        </FormDataConsumer>
        <BookingJustificationInput source="justification" bookingsJustifications={bookingsJustifications} />
        <FormDivider
          condition={(formData) =>
            type === CREATE &&
            Boolean(formData.billing_settings) &&
            hasOnlyBillingType(formData.billing_settings.billing_types, BOOKING_BILLING_TYPE_PAID)
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            type === CREATE &&
            Boolean(formData.billing_settings) &&
            hasOnlyBillingType(formData.billing_settings.billing_types, BOOKING_BILLING_TYPE_PAID) && (
              <AdvancedBooleanInput
                {...rest}
                source="make_booking_free"
                className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
              />
            )
          }
        </FormDataConsumer>
        <FormDivider condition={(formData) => isBookingPaid(formData)} />
        <FormDataConsumer>
          {({ formData, ...rest }) => isBookingPaid(formData) && <VoucherFormSection {...rest} formType={type} />}
        </FormDataConsumer>
        <FormDivider />
        <AdditionalDriversArrayInput />
      </SimpleForm>
    </>
  ) : null
}

const BookingFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  disabledInputsSources,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    disabledInputsSources,
    onClose,
  }

  return <BookingFormLayout {...controllerProps} />
}

export const EditBookingFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <BookingFormLayoutController {...props} /> : <div />

export const CreateBookingFormLayoutController = (props) => <BookingFormLayoutController {...props} />

export const useCreateBooking = ({ defaultValues = [], disabledInputsSources } = { defaultValues: [] }) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => {
    // We don't pick the vehicle_id if org or hub is not in the values
    const filteredDefaultValues = Array.from(defaultValues)
    if (!values.organisation_id || !values.hub_id) {
      filteredDefaultValues.splice(filteredDefaultValues.indexOf('vehicle_id'), 1)
    }
    setCreatePopupState({
      isOpen: true,
      values: filteredDefaultValues?.length > 0 ? pick(values, filteredDefaultValues) : values,
    })
  }
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateBookingFormLayoutController
        initialValues={createPopupState.values}
        disabledInputsSources={disabledInputsSources}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditBooking = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditBookingFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default BookingFormLayout
