import polyglotI18nProvider from 'ra-i18n-polyglot'
import { resolveBrowserLocale } from 'react-admin'

import en from './locales/en'
import fr from './locales/fr'
import nl from './locales/nl'

const translations = { en, fr, nl }

export default polyglotI18nProvider((locale) => translations[locale], resolveBrowserLocale('en'), {
  allowMissing: true,
  onMissingKey: (key, _, __) => key,
})
