import SvgIcon from '@material-ui/core/SvgIcon'

export const path =
  'M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C17.3 12 16.6 12.1 15.9 12.4L18.1 10.5L13.7 10.1L12 6.1L10.3 10.1L5.9 10.5L9.2 13.4L8.2 17.7L12 15.4L12.5 15.7C12.3 16.2 12.1 16.8 12.1 17.3L5.8 21M17.8 21.2L15 18.2L16.2 17L17.8 18.6L21.4 15L22.6 16.4L17.8 21.2'

const SetDefaultIcon = (props) => (
  <SvgIcon {...props}>
    <path d={path} />
  </SvgIcon>
)

export default SetDefaultIcon
