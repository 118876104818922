import Icon from '@material-ui/icons/AttachMoney'

export default {
  name: 'pricing-strategies',
  options: {
    defaultSort: { field: 'start_date', order: 'DESC' },
    referenceKey: 'pricing_strategy_id',
    references: [],
  },
  icon: Icon,
}
