import { ShowReference } from '../layouts/common/show'

import AdvancedBooleanField from './AdvancedBooleanField'

const VehicleOnTripField = ({ addText, ...props }) => (
  <ShowReference reference="vehicles/details" source="id" {...props}>
    {(vehicleDetailsProps) => (
      <AdvancedBooleanField
        record={vehicleDetailsProps.record}
        source="current_booking_id"
        trueIcon={null}
        falseIcon={null}
      />
    )}
  </ShowReference>
)

VehicleOnTripField.defaultProps = {
  addLabel: true,
  label: 'resources.vehicles.fields.on_trip',
}

export default VehicleOnTripField
