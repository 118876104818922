import { ReferenceField, FunctionField } from 'react-admin'

import config from './config'

const OpsUserField = ({ variant, ...props }) => (
  <ReferenceField {...props}>
    <FunctionField render={(record) => config.options.getName(record)} />
  </ReferenceField>
)

OpsUserField.defaultProps = {
  reference: config.name,
  source: config.options.referenceKey,
  addLabel: true,
  emptyText: 'n/a',
  link: false,
}

export default OpsUserField
