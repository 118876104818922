import Icon from '../../components/icons/HubIcon'

export default {
  name: 'hubs',
  options: {
    defaultSort: { field: 'name', order: 'ASC' },
    referenceKey: 'hub_id',
    references: ['vehicles', 'bookings', 'damage-reports'],
  },
  icon: Icon,
}
