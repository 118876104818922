import { Box, CardContent, CardHeader } from '@material-ui/core'
import classnames from 'classnames'
import { get, pull } from 'lodash'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { ReferenceField, ResourceContextProvider, Show, SimpleShowLayout, TopToolbar, useGetOne } from 'react-admin'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingAdditionalDriversField from '../../components/BookingAdditionalDriversField'
import BookingJustificationField from '../../components/BookingJustificationField'
import BookingRatingField from '../../components/BookingRatingField'
import DateTooltipField from '../../components/DateTooltipField'
import FieldWrapper from '../../components/FieldWrapper'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ShowButton from '../../components/ShowButton'
import UnitField from '../../components/UnitField'
import UserFullNameField from '../../components/UserFullNameField'
import VehicleInteriorRatingField from '../../components/VehicleInteriorRatingField'
import { BOOKING_BILLING_TYPES, BOOKING_ERAS, BOOKING_PAYMENT_STATUSES } from '../../config/bookings'
import { SYSTEM_PERMISSION_ACTIONS, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { isBookingPaid, isBookingPast, wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { formatDuration } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import bookingDetailsConfig from '../bookingDetails/config'
import bookingTransactionsConfig from '../bookingTransactions/config'
import BookingTransactionsListLayout from '../bookingTransactions/list'
import { CardTitle } from '../common'
import { ListReference } from '../common/list'
import { GenericCardTitle, ShowActions, ShowReference, ShowReferenceLinks, useShowStyles } from '../common/show'
import { DamageCheckShowLayout } from '../damageChecks/show'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import { VehicleShowLayout } from '../vehicles/show'
import vouchersConfig from '../vouchers/config'
import VoucherField from '../vouchers/field'

import { EditBookingButton } from './buttons'
import config from './config'
import { useGetBookingsJustifications } from './hooks'
import { BookingTitleWithUser, BookingTitleWithVehicle } from './titles'

export const BookingShowLayout = ({
  title = 'resources.bookings.show.titles.main',
  hasRedirect = false,
  excluded = [],
  shouldDisplayVehicleReferenceLink = false,
  record: originalRecord,
  ...originalProps
}) => {
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, originalRecord.id)
  const record = useMemo(
    () => ({ ...originalRecord, ...bookingDetails }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalRecord), JSON.stringify(bookingDetails)],
  )
  const props = useMemo(
    () => ({ ...originalProps, record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(originalProps), JSON.stringify(record)],
  )

  const bookingsJustifications = useGetBookingsJustifications()
  const [hasActions] = useResourcePermissions(props.resource, SYSTEM_PERMISSION_ACTIONS)
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [hasReadForVouchers] = useResourcePermissions(vouchersConfig.name, SYSTEM_PERMISSION_READ)
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const shouldDisplayOrganisation = isAllowed(excluded, 'organisations')
  const shouldDisplayHub = isAllowed(excluded, 'hubs')

  const operationalStatusSectionProps = {
    data: record?.status_timeline,
    classes: { timelineContainer: showClasses.statusTimelineContainer },
  }

  return Boolean(record) && Boolean(bookingDetails) && Boolean(bookingsJustifications) ? (
    <ResourceContextProvider value="bookings">
      <CardHeader
        title={<CardTitle text={title} />}
        action={<ShowButton basePath={props.basePath} id={record.id} hidden={!hasRedirect} />}
        className={commonClasses.titleContainer}
      />
      <div className={isSmallScreen ? null : showClasses.row}>
        <div className={showClasses.expanded}>
          <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
            <GenericCardTitle text="bookingInformation" />
          </CardContent>
          {(shouldDisplayOrganisation || shouldDisplayHub || hasReadForUsers) && (
            <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
              {shouldDisplayOrganisation && <OrganisationField />}
              {shouldDisplayHub && <HubField />}
              {hasReadForUsers && isAllowed(excluded, 'users') && (
                <ReferenceField source="user_id" reference="users" link="show">
                  <UserFullNameField />
                </ReferenceField>
              )}
              {hasReadForUsers && <BookingAdditionalDriversField />}
            </SimpleShowLayout>
          )}
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField source="era" map={BOOKING_ERAS} />
            <PeriodField
              startedOnSource="start_scheduled_on"
              endedOnSource="end_scheduled_on"
              label="resources.bookings.fields.scheduled_period"
              addTime
            />
            <PeriodField
              startedOnSource="started_on"
              endedOnSource="ended_on"
              label="resources.bookings.fields.used_period"
              addTime
            />
            <DateTooltipField source="cancelled_on" addTime />
            <DateTooltipField source="created_on" addTime />
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <VehicleInteriorRatingField />
            <BookingRatingField source="user_rating" />
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField source="number_of_trips" />
            <UnitField source="fuel_delta" unit="l" />
            <UnitField source="time_driven" formatValue={(v) => formatDuration(Math.round(v / 60))} unit="" />
            <UnitField source="number_of_km_travelled" unit="km" />
          </SimpleShowLayout>
          <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
            <AdvancedTextField source="billing_type" map={BOOKING_BILLING_TYPES} />
            <BookingJustificationField
              addLabel
              bookingsJustifications={bookingsJustifications}
              source="justification"
            />
            {isBookingPast(record) && !wasBookingCancelled(record) && record.voucher_id ? (
              <FieldWrapper label="Price">
                {(fieldProps) => (
                  <>
                    <PriceField
                      {...fieldProps}
                      source="initial_amount"
                      className={classnames(showClasses.italicHintText, showClasses.disabledText)}
                    />
                    {'\xa0\xa0'}
                    <PriceField {...fieldProps} source="amount" className={showClasses.overrideText} />
                  </>
                )}
              </FieldWrapper>
            ) : (
              <PriceField source="amount" />
            )}
            {hasReadForVouchers && <VoucherField />}
            {record.payment && [
              <AdvancedBooleanField
                key="payment_required"
                source="payment.required"
                trueIcon={null}
                falseIcon={null}
              />,
              record.payment.status && [
                <AdvancedTextField key="payment_status" source="payment.status" map={BOOKING_PAYMENT_STATUSES} />,
                record.payment.refusal_reason && (
                  <AdvancedTextField key="payment_refusal_reason" source="payment.refusal_reason" />
                ),
              ],
            ]}
          </SimpleShowLayout>
          {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
          {hasActions && <ShowActions record={record} />}
        </div>
        {!isSmallScreen && (
          <Box width="100%" maxWidth={400} className={commonClasses.borderLeft}>
            <OperationalStatusSection {...operationalStatusSectionProps} />
          </Box>
        )}
      </div>
      <ShowReferenceLinks
        excluded={shouldDisplayVehicleReferenceLink ? pull(Array.from(excluded), 'vehicles') : excluded}
        {...props}
      />
    </ResourceContextProvider>
  ) : null
}

const BookingActions = ({ basePath, data, hasEdit }) => (
  <TopToolbar>{hasEdit && <EditBookingButton basePath={basePath} record={data} />}</TopToolbar>
)

const BookingAside = (props) => {
  const translate = useTranslate()
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, props.record?.id, {
    enabled: Boolean(props.record?.id),
  })
  const { data: organisation } = useGetOne('organisations', props.record?.organisation_id, {
    enabled: Boolean(props.record?.organisation_id),
  })
  const organisationRequiresDamageReportPrompt = get(organisation, 'requires_damage_report_prompt', false)

  if (!props.record || !bookingDetails || !organisation) {
    return null
  }
  return (
    <>
      {isBookingPaid(props.record) && Boolean(bookingDetails.payment?.required) && (
        <ListReference
          reference={bookingTransactionsConfig.name}
          target={config.options.referenceKey}
          sort={bookingTransactionsConfig.options.defaultSort}
          {...props}
        >
          <BookingTransactionsListLayout bookingId={props.record.id} />
        </ListReference>
      )}
      <ShowReference reference="vehicles" source="vehicle_id" {...props}>
        <VehicleShowLayout excluded={['organisations']} hasRedirect />
      </ShowReference>
      {isBookingPast(props.record) && !wasBookingCancelled(props.record) && organisationRequiresDamageReportPrompt && (
        <ShowReference
          reference="damage-checks"
          source="id"
          errorTitle={translate('resources.damage-checks.name', 1)}
          errorText={translate('resources.damage-checks.missingForBooking')}
          {...props}
        >
          <DamageCheckShowLayout />
        </ShowReference>
      )}
    </>
  )
}

export default (props) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const showClasses = useShowStyles()
  return (
    <Show
      {...props}
      classes={{ main: showClasses.main }}
      title={hasReadForUsers ? <BookingTitleWithUser /> : <BookingTitleWithVehicle />}
      actions={<BookingActions />}
      aside={<BookingAside />}
    >
      <BookingShowLayout excluded={['vehicles']} shouldDisplayVehicleReferenceLink />
    </Show>
  )
}
