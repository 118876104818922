import { FORM_TYPE_FILTER } from '../common/forms'

import config from './config'
import OrganisationReferenceInput from './input'

const OrganisationsFilter = (props) => (
  <OrganisationReferenceInput {...props} formType={FORM_TYPE_FILTER} validate={null} width={227} />
)

OrganisationsFilter.defaultProps = { source: config.options.referenceKey }

export default OrganisationsFilter
