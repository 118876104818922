import { Card } from '@material-ui/core'
import { Title, TopToolbar, ShowButton, useRedirect } from 'react-admin'

import config, { PAYOUT_BASE_PATH, PAYOUT_TITLE } from '../config'
import { BankAccountInfoFormLayout } from '../forms/bankAccountInfo'
import { useGetPayoutSellerAccount, useGetPayoutSetupStatus } from '../hooks'
import { PAYOUT_SETUP_STATUS_COMPLETED } from '../../../config/payout'
import { SYSTEM_PERMISSION_PATCH } from '../../../config/permissions'
import { useResourcePermissions } from '../../../domain/permissions'

import { PayoutShowScreen } from './show'

export const BANK_ACCOUNT_CREATE_SUCCESS_MESSAGE = 'Bank account successfully added'

export const PayoutBankAccountCreateScreen = () => {
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_PATCH)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()
  const sellerAccount = useGetPayoutSellerAccount()
  const redirect = useRedirect()

  return hasEdit && payoutSetupStatus === PAYOUT_SETUP_STATUS_COMPLETED ? (
    <>
      <Title title={PAYOUT_TITLE} />
      <TopToolbar>
        <ShowButton to={PayoutShowScreen.path} />
      </TopToolbar>
      <Card>
        <BankAccountInfoFormLayout sellerAccount={sellerAccount} redirect={() => redirect(PayoutShowScreen.path)} />
      </Card>
    </>
  ) : (
    <Title title={PAYOUT_TITLE} />
  )
}

PayoutBankAccountCreateScreen.path = `${PAYOUT_BASE_PATH}/add-bank-account`
