import GenericEditForm from '../common/edit'

import VoucherFormLayout, { transformValues } from './form'
import { VoucherTitle } from './show'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<VoucherTitle />}
      formLayoutComponent={<VoucherFormLayout />}
      transformValues={transformValues}
    />
  )
}
