import { Card, CardContent } from '@material-ui/core'
import { useEffect } from 'react'
import { ResourceContextProvider, SimpleShowLayout, Title, TopToolbar, useVersion } from 'react-admin'
import { useTranslate } from 'ra-core'

import { CardTitle } from '../common'
import { EditButton } from '../common/buttons'
import { useShowStyles } from '../common/show'
import { useApi } from '../../api/apiProvider'
import AdvancedArrayField from '../../components/AdvancedArrayField'
import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import PeriodField from '../../components/PeriodField'
import UnitField from '../../components/UnitField'
import { BOOKING_BILLING_TYPE_AUTO, BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPES } from '../../config/bookings'
import { SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { hasBillingType } from '../../utils'

import { useEditAccount } from './form'
import { AccountEditPage } from './edit'
import config, { ACCOUNT_BASE_PATH } from './config'

export const AccountShowPage = () => {
  const translate = useTranslate()
  const version = useVersion()
  const [fetchAccount, { data: account }] = useApi(ACCOUNT_BASE_PATH, { method: 'GET' })
  useEffect(() => fetchAccount(), [fetchAccount, version])

  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)
  const [openEditPopup, editPopupDialog] = useEditAccount(account)

  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()

  const commonBooleanFieldProps = {
    trueIcon: null,
    falseIcon: null,
  }

  return account ? (
    <ResourceContextProvider value="accounts">
      <Title title={translate('resources.accounts.name', 1)} />
      <TopToolbar>
        {hasEdit && <EditButton record={account} onClick={openEditPopup} to={AccountEditPage.path} />}
      </TopToolbar>
      <Card>
        <CardContent className={commonClasses.titleContainer}>
          <CardTitle text="resources.accounts.show.titles.main" />
        </CardContent>
        <SimpleShowLayout record={account} className={showClasses.fieldContainer}>
          <AdvancedTextField source="name" label="resources.accounts.fields.name" />
          {hasBillingType(account.billing_types, BOOKING_BILLING_TYPE_AUTO) && (
            <PeriodField
              startedOnSource="pro_hours_start"
              endedOnSource="pro_hours_end"
              label="resources.accounts.fields.workingHours"
            />
          )}
        </SimpleShowLayout>
        <SimpleShowLayout record={account} className={showClasses.fieldContainer}>
          <AdvancedArrayField
            source="billing_types"
            map={BOOKING_BILLING_TYPES}
            addLabel={true}
            label={translate('resources.accounts.fields.billing_types', 2)}
          />
          <AdvancedTextField
            source="default_billing_type"
            map={BOOKING_BILLING_TYPES}
            label="resources.accounts.fields.default_billing_type"
          />
          {hasBillingType(account.billing_types, BOOKING_BILLING_TYPE_FREE) && (
            <AdvancedBooleanField
              {...commonBooleanFieldProps}
              source="free_billing_type_requires_justification"
              label="resources.accounts.fields.free_billing_type_requires_justification"
            />
          )}
          {hasBillingType(account.billing_types, BOOKING_BILLING_TYPE_FREE) && (
            <AdvancedBooleanField
              {...commonBooleanFieldProps}
              source="end_forgotten_bookings_after_1_hour"
              label="resources.accounts.fields.end_forgotten_bookings_after_1_hour"
            />
          )}
          <AdvancedBooleanField
            {...commonBooleanFieldProps}
            source="requires_payment"
            label="resources.accounts.fields.requires_payment"
          />
        </SimpleShowLayout>
        <SimpleShowLayout record={account} className={showClasses.fieldContainer}>
          <AdvancedBooleanField
            {...commonBooleanFieldProps}
            source="is_free_to_join"
            label="resources.accounts.fields.is_free_to_join"
          />
          <AdvancedBooleanField
            {...commonBooleanFieldProps}
            source="display_step_duration_slider"
            label="resources.accounts.fields.display_step_duration_slider"
          />
          {!account.display_step_duration_slider && (
            <UnitField
              source="booking_default_duration"
              label="resources.accounts.fields.booking_default_duration"
              unit="mymove.units.time.hours"
              formatValue={(v) => v / 60}
              shouldFormatUnit
            />
          )}
          <UnitField
            source="booking_maximum_duration"
            label="resources.accounts.fields.booking_maximum_duration"
            unit="mymove.units.time.days"
            formatValue={(v) => v / (24 * 60)}
            shouldFormatUnit
          />
        </SimpleShowLayout>
      </Card>
      {editPopupDialog}
    </ResourceContextProvider>
  ) : (
    <Title title={translate('resources.accounts.name', 1)} />
  )
}

AccountShowPage.path = ACCOUNT_BASE_PATH
