import { Typography, Tooltip } from '@material-ui/core'
import get from 'lodash/get'
import { DateTime } from 'luxon'

import { TIME_REGEX, parseDateAsDateTime } from '../utils/dates'

const DateTooltipField = ({ source, record, addTime, relative, className }) => {
  const dateStr = get(record, source)
  const dt = parseDateAsDateTime(dateStr)
  return (
    <Typography component="span" variant="body2" className={className}>
      {dateStr ? (
        dt.isValid ? (
          relative ? (
            <Tooltip title={dt.toLocaleString(DateTime.DATETIME_SHORT)}>
              <span>{dt.toRelative()}</span>
            </Tooltip>
          ) : addTime ? (
            <span>{dt.toLocaleString(DateTime.DATETIME_SHORT)}</span>
          ) : (
            <Tooltip title={dt.toLocaleString(DateTime.DATETIME_SHORT)}>
              <span>{dt.toLocaleString(DateTime.DATE_SHORT)}</span>
            </Tooltip>
          )
        ) : TIME_REGEX.test(dateStr) ? (
          // We remove seconds if "HH:mm:ss" format
          dateStr.replace(TIME_REGEX, '$1:$2')
        ) : (
          dateStr
        )
      ) : (
        'n/a'
      )}
    </Typography>
  )
}

DateTooltipField.defaultProps = {
  addLabel: true,
  addTime: false,
  relative: false,
}

export default DateTooltipField
