import get from 'lodash/get'
import ParkingIcon from '@material-ui/icons/LocalParking'

import AdvancedBooleanField from './AdvancedBooleanField'
import RoadIcon from './icons/RoadIcon'

export const getBookingConsumedIcon = (record) => (Boolean(get(record, 'started_on')) ? RoadIcon : ParkingIcon)

const BookingConsumedField = ({ addText, ...props }) => (
  <AdvancedBooleanField
    source="started_on"
    trueIcon={RoadIcon}
    falseIcon={ParkingIcon}
    trueTooltip="resources.bookings.enums.consumed.true"
    falseTooltip="resources.bookings.enums.consumed.false"
    color="textSecondary"
    trueLabel=""
    falseLabel=""
    {...props}
  />
)

BookingConsumedField.defaultProps = {
  addLabel: true,
  sortBy: 'started_on',
  label: 'resources.bookings.fields.consumed',
}

export default BookingConsumedField
