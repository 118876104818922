import get from 'lodash/get'

import {
  BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA,
  BOOKING_TRANSACTION_TYPE_REFUND,
  BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION,
  BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE,
} from '../config/bookingTransactions'

export const isBookingTransactionTypeChargeExtra = (record) =>
  get(record, 'type') === BOOKING_TRANSACTION_TYPE_CHARGE_EXTRA

export const isBookingTransactionTypeRefund = (record) => get(record, 'type') === BOOKING_TRANSACTION_TYPE_REFUND

export const isBookingTransactionTypeReleasePreAuth = (record) =>
  get(record, 'type') === BOOKING_TRANSACTION_TYPE_RELEASE_PRE_AUTHORIZATION

export const isBookingTransactionTypeChargeBookingPrice = (record) =>
  get(record, 'type') === BOOKING_TRANSACTION_TYPE_CHARGE_BOOKING_PRICE
