import VehicleOffIcon from '../../components/icons/VehicleOffIcon'
import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'

export default {
  // TODO later in sync with backend: modify maintenances name & referenceKey
  name: 'maintenances',
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_LEASING_COMPANY]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'maintenance_id',
    defaultSort: { field: 'started_on', order: 'DESC' },
    references: ['organisations', 'vehicles'],
  },
  icon: VehicleOffIcon,
}
