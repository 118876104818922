import GenericCreateForm from '../common/create'

import HubFormLayout, { transformValues } from './form'
import { HubTitle } from './show'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<HubTitle />}
      formLayoutComponent={<HubFormLayout />}
      transformValues={transformValues}
    />
  )
}
