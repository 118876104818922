import GenericCreateForm from '../common/create'

import PricingStrategyFormLayout from './form'
import { PricingStrategyTitle } from './show'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<PricingStrategyTitle />}
      formLayoutComponent={<PricingStrategyFormLayout />}
    />
  )
}
