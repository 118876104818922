import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import classnames from 'classnames'
import { get, pick } from 'lodash'
import { useTranslate } from 'ra-core'
import { useRef, useState } from 'react'
import {
  ArrayInput,
  CREATE,
  FormDataConsumer,
  minValue,
  number,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin'

import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_DATE } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import StaticText from '../../components/StaticText'
import env from '../../config/env'
import { useCommonStyles } from '../../config/theme'
import { isDisabled } from '../../utils'
import { getRoundedNow } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'

import config from './config'
import { PricingStrategyChart } from './show'

const increasing = (name, message) => (value, values, meta) => {
  let { steps } = values
  const stepIndex = parseInt(meta.name.match(/^steps\[([0-9]+)\]./i)?.[1])

  if (steps && !isNaN(stepIndex)) {
    steps = steps
      .filter(Boolean) // remove empty steps
      .map((s, index) => ({ ...s, index })) // keep original array index
      .sort((a, b) => a.duration - b.duration) // sort by duration
    let maxValue = 0
    for (const step of steps) {
      if (step[name] < maxValue) {
        if (step.index === stepIndex) {
          return message
        }
      } else {
        maxValue = step[name]
      }
    }
  }
  return undefined
}

export const validateDuration = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const validatePrice = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
  increasing('amount', 'resources.pricing-strategies.forms.validation.amount.increasing'),
]

const validateMileage = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
  increasing('mileage', 'resources.pricing-strategies.forms.validation.mileage.increasing'),
]

const validateSteps = [
  required(),
  (value) => (value?.length < 3 ? 'resources.pricing-strategies.forms.validation.steps.minimum' : undefined),
]

const PricingStrategyFormLayout = ({ type = UPDATE, disabledInputsSources = [], onClose, ...props }) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const title = `resources.pricing-strategies.forms.${type.toLowerCase()}.title`

  const now = useRef(getRoundedNow())

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
  ])
  formProps.record = pick(formProps.record, ['id', 'organisation_id', 'name', 'start_date', 'steps'])

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        className={classnames(showClasses.fieldContainerWrapper)}
        {...formProps}
      >
        <OrganisationReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <TextInput source="name" validate={required()} />
        <AdvancedDateTimeInput
          validate={required()}
          required
          source="start_date"
          mode={DATETIME_INPUT_MODE_DATE}
          minDate={now.current}
        />
        <FormDivider />
        <ArrayInput
          source="steps"
          label="resources.pricing-strategies.fields.steps.name"
          className={commonClasses.noMarginTop}
          validate={validateSteps}
        >
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            classes={{
              index: commonClasses.displayNone,
              form: commonClasses.flexForm,
              line: commonClasses.noMarginBottomOnPenultimateChild,
            }}
          >
            <NumberInput
              source="duration"
              label="resources.pricing-strategies.fields.steps.duration"
              validate={validateDuration}
              step={1}
              min={0}
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">{translate('mymove.units.time.minutes', 2)}</InputAdornment>
                  ),
                },
              }}
            />
            <NumberInput
              source="amount"
              label="resources.pricing-strategies.fields.steps.amount"
              validate={validatePrice}
              format={(v) => (v ? v / 100 : null)}
              parse={(v) => v * 100}
              step={1}
              min={0}
              options={{
                InputProps: {
                  endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment>,
                },
              }}
            />
            <NumberInput
              source="mileage"
              label="resources.pricing-strategies.fields.steps.mileage"
              min={0}
              options={{
                InputProps: {
                  endAdornment: <InputAdornment position="end">km</InputAdornment>,
                },
              }}
              validate={validateMileage}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <FormDivider />
        <StaticText>
          <em>{translate('resources.pricing-strategies.forms.helperTexts.first')}</em>
        </StaticText>
        <StaticText>
          <em>{translate('resources.pricing-strategies.forms.helperTexts.second')}</em>
        </StaticText>
        <FormDivider />
        {!isSmallScreen && (
          <FormDataConsumer>
            {({ formData }) => (
              <div style={{ minWidth: 640 }}>
                <PricingStrategyChart record={formData} />
              </div>
            )}
          </FormDataConsumer>
        )}
      </SimpleForm>
    </>
  )
}

const PricingStrategyFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  disabledInputsSources,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
  })

  controllerProps = {
    ...controllerProps,
    type,
    disabledInputsSources,
    onClose,
  }

  return <PricingStrategyFormLayout {...controllerProps} />
}

export const EditPricingStrategyFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <PricingStrategyFormLayoutController {...props} /> : <div />

export const CreatePricingStrategyFormLayoutController = (props) => <PricingStrategyFormLayoutController {...props} />

export const useCreatePricingStrategy = (disabledInputsSources) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose} maxWidth="lg">
      <CreatePricingStrategyFormLayoutController
        initialValues={createPopupState.values}
        disabledInputsSources={disabledInputsSources}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditPricingStrategy = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose} maxWidth="lg">
      <EditPricingStrategyFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default PricingStrategyFormLayout
