import { CardContent, Dialog, InputAdornment, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { get, pick } from 'lodash'
import { DateTime } from 'luxon'
import RichTextInput from 'ra-input-rich-text'
import { useEffect, useState } from 'react'
import {
  ArrayInput,
  CheckboxGroupInput,
  CREATE,
  FormDataConsumer,
  ImageField,
  ImageInput,
  minValue,
  number,
  NumberInput,
  required,
  ResourceContextProvider,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import { useApi } from '../../api/apiProvider'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_TIME } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import NonInput from '../../components/NonInput'
import { HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE } from '../../config/addresses'
import env from '../../config/env'
import {
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
} from '../../config/hubs'
import {
  ORGANISATION_DEFAULT_RENTAL_TCS_DISPLAY_NAME,
  ORGANISATION_DEFAULT_RENTAL_TCS_EMAIL_ADDRESS,
  ORGANISATION_DEFAULT_RENTAL_TCS_LEGAL_NAME,
  ORGANISATION_DEFAULT_RENTAL_TCS_START_DATE,
  ORGANISATION_EMAIL_NOTIFICATION_BOOKING_INTERACTION,
  ORGANISATION_EMAIL_NOTIFICATION_CRITICAL_ISSUE,
  ORGANISATION_EMAIL_NOTIFICATION_VEHICLE_RETURNED,
  ORGANISATION_EMAIL_NOTIFICATIONS,
} from '../../config/organisations'
import { OPS_USER_ROLE_OWNER } from '../../config/permissions'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { getWeekDayFromIndex, validateEndTimeAfterStartTime } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { validateEmail } from '../../utils/validators'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import {
  HubAddressFillingMethodInput,
  HubAddressInput,
  hubPickUpAndDropOffHoursTypeChoices,
  transformValues as transformHubValues,
  useHubsFormStyles,
  validateGeofencing,
  validateLatitude,
  validateLongitude,
} from '../hubs/form'
import { BOOKING_END_CHECK_TANK_FILLED_UP, BOOKING_END_CHECKS } from '../../config/bookingEndChecks'
import { NonInputTitle } from '../common/forms'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'

import config from './config'

const useStyles = makeStyles((theme) => ({
  checksInput: {
    marginLeft: theme.spacing(2),
    marginTop: 0,
  },
  notificationInputContainer: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  additionalHelperText: {
    color: theme.palette.text.secondary,
  },
  '@global': {
    '.ra-rich-text-input .ql-editor': {
      minWidth: COMMON_INPUT_WIDTH * 2 + theme.spacing(4) + 'px !important',
      maxHeight: '400px',
      overflowY: 'scroll',
    },
  },
}))

export const transformValues = ({
  form_type,
  legal_name,
  requires_booking_end_checks,
  requires_missing_fuel_fine,
  has_email_notifications,
  default_rental_terms_and_conditions,
  rental_terms_and_conditions,
  hub,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        booking_end_checks: requires_booking_end_checks ? restValues.booking_end_checks : [],
        missing_fuel_fine:
          restValues.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && requires_missing_fuel_fine
            ? restValues.missing_fuel_fine
            : null,
        ...ORGANISATION_EMAIL_NOTIFICATIONS.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.code]: has_email_notifications ? restValues[curr.code] ?? false : false,
          }),
          {},
        ),
        rental_terms_and_conditions:
          form_type === CREATE
            ? default_rental_terms_and_conditions
                ?.replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_LEGAL_NAME, legal_name)
                .replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_DISPLAY_NAME, restValues.name)
                .replaceAll(
                  ORGANISATION_DEFAULT_RENTAL_TCS_START_DATE,
                  DateTime.now().toLocaleString(DateTime.DATE_SHORT),
                )
                .replaceAll(ORGANISATION_DEFAULT_RENTAL_TCS_EMAIL_ADDRESS, restValues.contact_email)
            : rental_terms_and_conditions,
        hub: Boolean(hub) ? transformHubValues(hub) : null,
      }
    : restValues

export const validateFees = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const OrganisationFormLayout = ({ type = UPDATE, onClose, ...props }) => {
  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const hubsFormClasses = useHubsFormStyles()
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const translate = useTranslate()

  const [fetchMeInfo, { data: meInfo }] = useApi('/me')
  useEffect(() => fetchMeInfo(), [fetchMeInfo])

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'name',
    'picture',
    'contact_email',
    'address_required',
    'requires_face_check',
    'requires_damage_report_prompt',
    'requires_parking_spot',
    'booking_end_checks',
    'missing_fuel_fine',
    ORGANISATION_EMAIL_NOTIFICATION_BOOKING_INTERACTION,
    ORGANISATION_EMAIL_NOTIFICATION_CRITICAL_ISSUE,
    ORGANISATION_EMAIL_NOTIFICATION_VEHICLE_RETURNED,
    'rental_terms_and_conditions',
  ])

  const bookingEndChecksChoices = Object.entries(BOOKING_END_CHECKS).map(([k, v]) => ({ id: k, name: v }))

  let initialValues = { form_type: type }
  if (type === CREATE) {
    initialValues.default_rental_terms_and_conditions = meInfo?.default_rental_terms_and_conditions
    initialValues.hub = {
      address_filling_method: HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
      has_pick_up_and_drop_off_hours: false,
      pick_up_and_drop_off_hours: Array.from({ length: 7 }, () => ({
        type: HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
      })),
    }
  } else {
    initialValues.picture_data = formProps.record.picture ? { src: formProps.record.picture } : null
    initialValues.requires_booking_end_checks = formProps.record.booking_end_checks.length > 0
    initialValues.requires_missing_fuel_fine = !!formProps.record.missing_fuel_fine
    initialValues.has_email_notifications = ORGANISATION_EMAIL_NOTIFICATIONS.some(
      (n) => formProps.record[n.code] === true,
    )
  }

  const shouldDisplayRentalTermsAndConditions = identity?.role === OPS_USER_ROLE_OWNER && type === UPDATE

  return identity && meInfo ? (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={`resources.organisations.forms.${type.toLowerCase()}.title`} />
      </CardContent>

      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <NonInputTitle text="identification" />

        <FormDivider />

        <ImageInput
          className={commonClasses.commonInput}
          source="picture_data"
          labelSingle="mymove.dropOrSelectYourFileHere"
          accept="image/*"
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDivider />

        <TextInput source="name" validate={required()} />
        {type === CREATE && <TextInput source="legal_name" validate={required()} />}
        <TextInput
          type="email"
          source="contact_email"
          validate={validateEmail()}
          helperText="resources.organisations.forms.helperTexts.contact_email"
        />

        <FormDivider />

        <NonInputTitle text="fleetUsage" />

        <FormDivider />

        <AdvancedSelectInput source="address_required" />
        <AdvancedSelectInput source="requires_face_check" />
        <AdvancedSelectInput source="requires_parking_spot" />
        <AdvancedSelectInput source="requires_damage_report_prompt" />

        <FormDivider />

        <AdvancedSelectInput source="requires_booking_end_checks" formClassName={commonClasses.fullWidth} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.requires_booking_end_checks ? (
              <CheckboxGroupInput
                {...rest}
                source="booking_end_checks"
                choices={bookingEndChecksChoices}
                optionValue="id"
                optionText="name"
                validate={required('resources.organisations.forms.validations.booking_end_checks.required')}
                helperText="resources.organisations.forms.helperTexts.booking_end_checks"
                row={false}
                classes={{
                  root: classnames(commonClasses.fullWidth, classes.checksInput),
                  label: commonClasses.displayNone,
                }}
              />
            ) : null
          }
        </FormDataConsumer>

        <FormDivider
          condition={(formData) => formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP)}
        />
        <NonInputTitle
          text="fines"
          condition={(formData) => formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP)}
        />
        <FormDivider
          condition={(formData) => formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP)}
        />

        <FormDataConsumer formClassName={commonClasses.fullWidth}>
          {({ formData, ...rest }) =>
            formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) && (
              <AdvancedBooleanInput
                {...rest}
                helperText={false}
                source="requires_missing_fuel_fine"
                className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) &&
            formData.requires_missing_fuel_fine && (
              <NumberInput
                {...rest}
                source="missing_fuel_fine.operational_fees"
                validate={validateFees}
                format={(v) => (v ? v / 100 : null)}
                parse={(v) => v * 100}
                step={0.01}
                min={0}
                options={{
                  InputProps: { endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> },
                }}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.booking_end_checks?.includes(BOOKING_END_CHECK_TANK_FILLED_UP) &&
            formData.requires_missing_fuel_fine && (
              <NumberInput
                {...rest}
                source="missing_fuel_fine.fuel_fees"
                validate={validateFees}
                format={(v) => (v ? v / 100 : null)}
                parse={(v) => v * 100}
                step={0.01}
                min={0}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL + '/L'}</InputAdornment>,
                  },
                }}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider />
        <NonInputTitle text="notifications" />
        <FormDivider />

        <AdvancedSelectInput source="has_email_notifications" formClassName={commonClasses.fullWidth} />
        <FormDataConsumer>
          {({ formData }) =>
            formData.has_email_notifications &&
            ORGANISATION_EMAIL_NOTIFICATIONS.map(({ code, recommended, ...rest }) => (
              <div key={code} className={classes.notificationInputContainer}>
                <AdvancedBooleanInput
                  {...rest}
                  source={code}
                  className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
                />
                {recommended && (
                  <Typography variant="caption" display="block" className={classes.additionalHelperText}>
                    {translate('resources.organisations.emailNotifications.recommended')}
                  </Typography>
                )}
              </div>
            ))
          }
        </FormDataConsumer>

        <FormDivider condition={() => shouldDisplayRentalTermsAndConditions} />
        <NonInputTitle text="legalContent" condition={() => shouldDisplayRentalTermsAndConditions} />
        <FormDivider condition={() => shouldDisplayRentalTermsAndConditions} />

        {shouldDisplayRentalTermsAndConditions && (
          <RichTextInput source="rental_terms_and_conditions" validate={required()} helperText={false} />
        )}

        {type === CREATE &&
          [
            <FormDivider />,

            <NonInput>
              <Typography variant="h6">{translate('resources.organisations.forms.create.firstHubCreation')}</Typography>
            </NonInput>,

            <FormDivider />,

            <ResourceContextProvider value="hubs">
              <NonInputTitle text="identification" />
            </ResourceContextProvider>,

            <FormDivider />,

            <TextInput source="hub.name" label="resources.hubs.fields.name" validate={required()} />,

            <FormDivider />,

            <ResourceContextProvider value="hubs">
              <NonInputTitle text="geolocation" />
            </ResourceContextProvider>,

            <FormDivider />,

            <HubAddressFillingMethodInput
              formClassName={commonClasses.fullWidth}
              source="hub.address_filling_method"
              addressSource="hub.address"
              positionSource="hub.position"
            />,
            <HubAddressInput target="hub" />,
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <NumberInput
                  {...rest}
                  source="hub.position.latitude"
                  label="resources.hubs.fields.position.latitude"
                  validate={validateLatitude}
                  step={0.1}
                  min={-90}
                  max={90}
                  disabled={formData.hub.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">°</InputAdornment>,
                    },
                  }}
                />
              )}
            </FormDataConsumer>,
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <NumberInput
                  {...rest}
                  source="hub.position.longitude"
                  label="resources.hubs.fields.position.longitude"
                  validate={validateLongitude}
                  step={0.1}
                  min={-180}
                  max={180}
                  disabled={formData.hub.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
                  options={{
                    InputProps: {
                      endAdornment: <InputAdornment position="end">°</InputAdornment>,
                    },
                  }}
                />
              )}
            </FormDataConsumer>,

            <FormDivider />,

            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <NumberInput
                  {...rest}
                  source="hub.geofencing"
                  label="resources.hubs.fields.geofencing"
                  validate={validateGeofencing}
                  min={0}
                  helperText="resources.hubs.forms.helperTexts.geofencing"
                  formClassName={commonClasses.fullWidth}
                  options={{
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {translate('mymove.units.distance.meters', get(formData, 'hub.geofencing') || 1)}
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            </FormDataConsumer>,

            <FormDivider />,

            <ResourceContextProvider value="hubs">
              <NonInputTitle text="schedules" />
            </ResourceContextProvider>,

            <FormDivider />,

            <AdvancedBooleanInput
              source="hub.has_pick_up_and_drop_off_hours"
              label="resources.hubs.fields.has_pick_up_and_drop_off_hours"
              helperText="resources.hubs.forms.helperTexts.has_pick_up_and_drop_off_hours"
              className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
            />,

            <FormDivider condition={(formData) => formData.hub.has_pick_up_and_drop_off_hours} />,

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.hub.has_pick_up_and_drop_off_hours && (
                  <ArrayInput
                    {...rest}
                    label={false}
                    source="hub.pick_up_and_drop_off_hours"
                    className={commonClasses.noMarginTop}
                  >
                    <SimpleFormIterator
                      disableAdd
                      disableRemove
                      disableReordering
                      getItemLabel={(index) => translate(getWeekDayFromIndex(index))}
                      classes={{
                        root: commonClasses.noMarginTop,
                        index: hubsFormClasses.simpleFormIteratorLabel,
                      }}
                    >
                      <SelectInput
                        source="type"
                        label="resources.hubs.pickUpAndDropOffHours.openingTypes.name"
                        choices={hubPickUpAndDropOffHoursTypeChoices}
                        validate={required()}
                        optionText="name"
                        optionValue="id"
                      />
                      <FormDataConsumer>
                        {({ scopedFormData, getSource, ...rest }) =>
                          scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                            <AdvancedDateTimeInput
                              {...rest}
                              required
                              validate={required()}
                              source={getSource('start_time')}
                              label="resources.hubs.pickUpAndDropOffHours.startTime"
                              mode={DATETIME_INPUT_MODE_TIME}
                            />
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ scopedFormData, getSource, ...rest }) =>
                          scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                            <AdvancedDateTimeInput
                              {...rest}
                              required
                              validate={[
                                required(),
                                (value) => validateEndTimeAfterStartTime(scopedFormData.start_time, value),
                              ]}
                              disabled={!Boolean(scopedFormData.start_time)}
                              source={getSource('end_time')}
                              label="resources.hubs.pickUpAndDropOffHours.endTime"
                              mode={DATETIME_INPUT_MODE_TIME}
                            />
                          )
                        }
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              }
            </FormDataConsumer>,
          ].map((e, i) => ({ ...e, key: i }))}
      </SimpleForm>
    </>
  ) : null
}

const OrganisationFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
  }

  return <OrganisationFormLayout {...controllerProps} />
}

export const EditOrganisationFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <OrganisationFormLayoutController {...props} /> : <div />

export const CreateOrganisationFormLayoutController = (props) => <OrganisationFormLayoutController {...props} />

export const useCreateOrganisation = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateOrganisationFormLayoutController
        initialValues={createPopupState.values}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditOrganisation = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditOrganisationFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default OrganisationFormLayout
