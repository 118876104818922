import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import { pick } from 'lodash'
import { useMemo, useState } from 'react'
import { NumberInput, SimpleForm, required, useNotify, useRefresh, number, minValue, maxValue } from 'react-admin'

import { PAYOUT_API_SETTINGS_PATH } from '../config'
import { MARKETPLACE_MODEL_UPDATE_SUCCESS_MESSAGE } from '../screens/editMarketplaceModel'
import { CardTitle } from '../../common'
import { useShowStyles } from '../../common/show'
import { useApi } from '../../../api/apiProvider'
import BasicFormToolbar from '../../../components/BasicFormToolbar'
import { useCommonStyles } from '../../../config/theme'

const validateMarketplacePercentage = [
  required(),
  number('Marketplace commission must be a valid number'),
  minValue(0, 'Marketplace commission must be between 0-100 %'),
  maxValue(100, 'Marketplace commission must be between 0-100 %'),
]

const MarketplaceModelFormLayout = ({ record, onClose, redirect }) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const refresh = useRefresh()
  const notify = useNotify()

  const formProps = {
    record: pick(record, ['marketplace_percentage']),
  }

  const [editMarketplaceModel, { loading: isEditingMarketplaceModel }] = useApi(PAYOUT_API_SETTINGS_PATH, {
    method: 'PATCH',
    onSuccess: () => {
      notify(MARKETPLACE_MODEL_UPDATE_SUCCESS_MESSAGE)
      refresh()
      onClose && onClose()
      redirect && redirect()
    },
    onFailure: () => notify('Failed to edit marketplace model', 'warning'),
  })

  const onFormSubmit = useMemo(
    () => (values) => editMarketplaceModel({ body: JSON.stringify(values) }),
    [editMarketplaceModel],
  )

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="Marketplace model edition" />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        save={onFormSubmit}
        saving={isEditingMarketplaceModel}
        mutationMode="pessimistic"
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <NumberInput
          source="marketplace_percentage"
          label="Marketplace commission"
          validate={validateMarketplacePercentage}
          helperText="Does not apply to extra charges"
          max={100}
          min={0}
          options={{
            InputProps: { endAdornment: <InputAdornment position="end">%</InputAdornment> },
          }}
        />
      </SimpleForm>
    </>
  )
}

const MarketplaceModelFormLayoutController = ({ settings, onClose }) => (
  <MarketplaceModelFormLayout record={settings} onClose={onClose} />
)

export const EditMarketplaceModelFormLayoutController = (props) => <MarketplaceModelFormLayoutController {...props} />

export const useEditMarketplaceModel = (settings) => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditMarketplaceModelFormLayoutController
        settings={settings}
        initialValues={editPopupState.values}
        onClose={handleEditPopupClose}
      />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default MarketplaceModelFormLayout
