import GenericCreateForm from '../common/create'

import OrganisationFormLayout, { transformValues } from './form'
import { OrganisationTitle } from './show'

export default (props) => {
  return (
    <GenericCreateForm
      {...props}
      titleComponent={<OrganisationTitle />}
      formLayoutComponent={<OrganisationFormLayout />}
      transformValues={transformValues}
    />
  )
}
