import { omit } from 'lodash'
import { useCallback, useContext, createContext } from 'react'
import { fetchEnd, fetchStart, fetchUtils, useRefresh, useSafeSetState } from 'react-admin'
import { useDispatch } from 'react-redux'

const ApiContext = createContext()

export const ApiProvider = (props) => <ApiContext.Provider {...props} />

/**
 * Usage:
 *
 * const ActionButton = ({ resource, record, action, label }) => {
 *   const notify = useNotify()
 *   const [runQuery, { data, loading, loaded, error }] = useApi(`/${resource}/${record.id}/actions`, {
 *     method: 'POST',
 *     body: JSON.stringify({ action }),
 *     onSuccess: data => notify('Success!'),
 *     onFailure: error => notify('Error:' + error.message),
 *     refresh: true,
 *   })
 *
 *   return (
 *     <Button color="primary" variant="contained" onClick={() => runQuery(optionsOverride)} disabled={loading}>
 *       {label}
 *     </Button>
 *   )
 * }
 *  */

export const useApi = (path, { onSuccess, onFailure, refresh, ...options } = {}) => {
  const api = useContext(ApiContext)
  const dispatch = useDispatch()
  const triggerRefresh = useRefresh()
  const [state, setState] = useSafeSetState({
    data: undefined,
    error: undefined,
    loading: false,
    loaded: false,
  })

  const callback = useCallback(
    ({ path: pathOverride, onSuccess: onSuccessOverride, onFailure: onFailureOverride, ...optionsOverride } = {}) => {
      setState((prevState) => ({ ...prevState, loading: true }))
      dispatch(fetchStart()) // start the global loading indicator

      let url = pathOverride ?? path
      const { params, ...opts } = options
      if (params) {
        const query = omit(params, ['filter', 'sort', 'pagination'])
        if (params.filter) {
          query.filter = JSON.stringify(params.filter)
        }
        if (params.sort) {
          const { field, order } = params.sort
          query.sort = JSON.stringify([field, order])
        }
        if (params.pagination) {
          const { page, perPage } = params.pagination
          query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1])
        }
        url = `${url}?${fetchUtils.queryParameters(query)}`
      }

      return api(url, { ...opts, ...optionsOverride })
        .then((response) => {
          setState({
            data: response.json,
            error: null,
            loading: false,
            loaded: true,
          })
          const onSuccessFunc = onSuccessOverride ?? onSuccess
          if (onSuccessFunc) onSuccessFunc(response)
          if (refresh) triggerRefresh()
          return { result: response.json }
        })
        .catch((error) => {
          setState({
            data: null,
            error,
            loading: false,
            loaded: false,
          })
          const onFailureFunc = onFailureOverride ?? onFailure
          if (onFailureFunc) onFailureFunc(error)
          return { error }
        })
        .finally(() => {
          dispatch(fetchEnd()) // stop the global loading indicator
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, dispatch, path, JSON.stringify(options), api, onSuccess, onFailure],
  )

  return [callback, state]
}

export default (baseUrl, apiClient) => (path, options) => apiClient(`${baseUrl}${path}`, options)
