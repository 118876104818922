import React from 'react'
import { TextInput } from 'react-admin'

import { useHelperText } from '../layouts/common/forms'

const AdvancedTextInput = ({ defaultValue = null, ...props }) => {
  const helperText = useHelperText(props.source)

  return <TextInput defaultValue={defaultValue} helperText={helperText} {...props} />
}

export default AdvancedTextInput
