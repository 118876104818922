import { Title } from '../layouts/common/show'
import usersConfig from '../layouts/users/config'

import { getUserFullName } from './UserFullNameField'

const UserTitle = ({ details, ...props }) => <Title {...props}>{details || getUserFullName}</Title>

UserTitle.defaultProps = {
  resourceConfig: usersConfig,
}

export default UserTitle
