import { CREATE, Create, CreateActions, useNotify, useRefresh, useResourceContext } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { cloneElement } from 'react'
import { useTranslate } from 'ra-core'

export default ({ titleComponent, formLayoutComponent, transformValues, ...props }) => {
  const history = useHistory()
  const notify = useNotify()
  const refresh = useRefresh()
  const resource = useResourceContext()
  const translate = useTranslate()

  const onSuccess = () => {
    notify(`resources.${resource}.forms.create.success`)
    history.goBack()
    refresh()
  }

  const onFailure = (error) => notify(error.message, { type: 'warning' })

  const createTitleComponent = cloneElement(titleComponent, {
    details: translate(`resources.${resource}.forms.create.pageTitle`),
  })
  const createFormLayoutComponent = cloneElement(formLayoutComponent, { type: CREATE })

  return (
    <Create
      {...props}
      title={createTitleComponent}
      actions={<CreateActions />}
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transformValues}
    >
      {createFormLayoutComponent}
    </Create>
  )
}
