import { Card } from '@material-ui/core'
import { Title, TopToolbar, ShowButton, useGetIdentity, useRedirect } from 'react-admin'

import config, { PAYOUT_BASE_PATH, PAYOUT_TITLE } from '../config'
import MarketplaceModelFormLayout from '../forms/marketplaceModel'
import { useGetPayoutSettings, useGetPayoutSetupStatus } from '../hooks'
import { PAYOUT_SETUP_STATUS_COMPLETED } from '../../../config/payout'
import { SYSTEM_PERMISSION_PATCH } from '../../../config/permissions'
import { useResourcePermissions } from '../../../domain/permissions'

import { PayoutShowScreen } from './show'

export const MARKETPLACE_MODEL_UPDATE_SUCCESS_MESSAGE = 'Marketplace model successfully updated'

export const PayoutMarketplaceModelEditScreen = () => {
  const [hasEdit] = useResourcePermissions(config.name, SYSTEM_PERMISSION_PATCH)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()
  const settings = useGetPayoutSettings()
  const { identity } = useGetIdentity()
  const redirect = useRedirect()

  const currentUserRestrictedToOrganisationId = identity?.restricted_to_organisation_id

  return hasEdit &&
    payoutSetupStatus === PAYOUT_SETUP_STATUS_COMPLETED &&
    !Boolean(currentUserRestrictedToOrganisationId) &&
    settings ? (
    <>
      <Title title={PAYOUT_TITLE} />
      <TopToolbar>
        <ShowButton to={PayoutShowScreen.path} />
      </TopToolbar>
      <Card>
        <MarketplaceModelFormLayout record={settings} redirect={() => redirect(PayoutShowScreen.path)} />
      </Card>
    </>
  ) : (
    <Title title={PAYOUT_TITLE} />
  )
}

PayoutMarketplaceModelEditScreen.path = `${PAYOUT_BASE_PATH}/edit-marketplace-model`
