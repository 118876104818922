import { Box } from '@material-ui/core'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ListIcon from '@material-ui/icons/List'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

export const ViewModeTimeline = 'timeline'
export const ViewModeList = 'list'

const ViewModeToggle = ({ mode, onChange }) => {
  return (
    <Box flex={1} display="flex" justifyContent="flex-end">
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={(event, value) => {
          value && onChange(value)
        }}
        size="small"
      >
        <ToggleButton value={ViewModeList}>
          <ListIcon />
        </ToggleButton>
        <ToggleButton value={ViewModeTimeline}>
          <EventNoteIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default ViewModeToggle
