import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Children, cloneElement, useMemo } from 'react'
import {
  ExportButton,
  Pagination,
  ReferenceManyField,
  sanitizeListRestProps,
  TopToolbar,
  useResourceContext,
} from 'react-admin'
import { CardHeader } from '@material-ui/core'
import classnames from 'classnames'

import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { useCommonStyles } from '../../config/theme'
import { useSmallScreen } from '../../utils/theme'

import { CardTitle } from './index'

export const useListStyles = makeStyles((theme) => ({
  main: {
    flexDirection: 'column',
  },
  card: {
    marginTop: theme.spacing(3),
  },
  filterForm: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  titleContainer: {
    [theme.breakpoints.up('md')]: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
  row: {
    height: 43,
  },
  thumbnail: {
    display: 'block',
    width: '100% !important',
    margin: '0 !important',
  },
  commonFilterInput: {
    width: 227 + 'px !important',
  },
}))

// We create this component to prevent passing addLabel prop to DOM element
const ListReferencePagination = () => <Pagination />

export const ListReference = ({ children, classes: classesOverride = {}, showPagination = true, ...props }) => {
  const classes = useListStyles({ classes: classesOverride })
  const [hasRead] = useResourcePermissions(props.reference, SYSTEM_PERMISSION_READ)
  return hasRead ? (
    <Card className={classes.card}>
      <ReferenceManyField {...props} perPage={10} pagination={showPagination ? <ListReferencePagination /> : null}>
        {children}
      </ReferenceManyField>
    </Card>
  ) : null
}

export const ListActions = (props) => {
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    hasExport,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    total,
    children,
    ...rest
  } = props

  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {typeof children === 'function'
          ? children(props)
          : Children.map(children, (child) => cloneElement(child, props))}
        {hasExport && (
          <ExportButton
            resource={`${resource}/export`}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={10000}
          />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total, children], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
ListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hasExport: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
  onCreate: PropTypes.func,
}
ListActions.defaultProps = {
  hasExport: false,
  selectedIds: [],
  onUnselectItems: () => null,
}

export const ListCardTitle = () => {
  const resource = useResourceContext()
  return <CardTitle text={`resources.${resource}.name`} args={2} />
}

export const GenericListLayout = ({ action = null, compactListLayout, regularListLayout, editPopupDialog = null }) => {
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <CardHeader
        title={<ListCardTitle />}
        className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}
        action={action}
      />
      {isSmallScreen ? compactListLayout : regularListLayout}
      {editPopupDialog}
    </>
  )
}
