const initialState = {}

const KEYBOARD_UPDATE = 'KEYBOARD_UPDATE'
export const updateKeyboard = (keys) => ({ type: KEYBOARD_UPDATE, payload: keys })

const keyboard = (state = initialState, { type, payload }) => {
  switch (type) {
    case KEYBOARD_UPDATE:
      return payload
    default:
      return state
  }
}

export default keyboard
