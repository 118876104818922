import { isEmpty } from 'lodash'
import { useGetOne } from 'react-admin'

import bookingDetailsConfig from '../bookingDetails/config'
import { CreateButton } from '../common/buttons'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import { useGetTransactionsListForBooking } from './hooks'
import { useCreateBookingTransaction } from './form'
import config from './config'

export const CreateBookingTransactionButton = ({ bookingId }) => {
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, bookingId, {
    enabled: Boolean(bookingId),
  })
  const { data: bookingTransactions } = useGetTransactionsListForBooking(bookingId)
  const [openCreatePopup, createPopupDialog] = useCreateBookingTransaction()

  return hasCreate && !isEmpty(bookingDetails?.allowed_transaction_actions) && !isEmpty(bookingTransactions) ? (
    <>
      <CreateButton
        basePath={'/' + config.name}
        label="resources.booking-transactions.actions.create.name"
        filterValues={{ booking_id: bookingId }}
        isFloatingOnSmallScreen={false}
        onClick={openCreatePopup}
      />
      {createPopupDialog}
    </>
  ) : null
}
