import { SimpleShowLayout } from 'react-admin'
import { CardContent, Grid, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import AdvancedTextField from '../../components/AdvancedTextField'
import { useCommonStyles } from '../../config/theme'
import { useShowStyles } from '../common/show'
import { CardTitle } from '../common'

// We create a component here to avoid unrecognized props errors
const PicturesShowLayout = ({ pictures }) =>
  !isEmpty(pictures) ? (
    <Grid container direction="row" alignItems="center" spacing={2}>
      {Object.values(pictures).map((value) => (
        <Grid key={value} item sm={12} md={6} lg={3}>
          <a href={value} target="_blank" rel="noopener noreferrer">
            <img src={value} alt={value} width="100%" height="100%" style={{ borderRadius: 3 }} />
          </a>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography component="span" variant="body2">
      No pictures provided
    </Typography>
  )

export const DamageCheckShowLayout = (props) => {
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text="resources.damage-checks.name" args={1} />
      </CardContent>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <PicturesShowLayout pictures={props.record.pictures} />
      </SimpleShowLayout>
      <SimpleShowLayout {...props} className={showClasses.fieldContainer}>
        <AdvancedTextField
          source="description"
          fallback="resources.damage-checks.show.description.fallback"
          multiline
        />
      </SimpleShowLayout>
    </>
  )
}
