import { Box, CardContent, Dialog, Typography } from '@material-ui/core'
import FalseIcon from '@material-ui/icons/Clear'
import TrueIcon from '@material-ui/icons/Done'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { get, pick } from 'lodash'
import { cloneElement, useCallback, useEffect, useRef, useState } from 'react'
import {
  Confirm,
  CREATE,
  FormDataConsumer,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  UPDATE,
  useCreateController,
  useEditController,
  useListContext,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import { useFormState } from 'react-final-form'

import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import RowForm from '../../components/AdvancedRowForm'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import PeriodField from '../../components/PeriodField'
import { SYSTEM_PERMISSION_DELETE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { useResourcePermissions } from '../../domain/permissions'
import { isDisabled } from '../../utils'
import {
  getDateWithThirtyMinutesOffset,
  getRoundedNow,
  parseDateAsDateTime,
  parseDateAsISO,
  validateEndDateAfterStartDate,
} from '../../utils/dates'
import { isUuid4 } from '../../utils/validators'
import { useCurrentAccountSelectors } from '../account/hooks'
import bookingsConfig from '../bookings/config'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'
import VehicleField from '../vehicles/field'

import config from './config'
import { DeleteVehicleUnavailabilityButton } from './buttons'

const vehicleUnavailabilityTypeChoices = Object.entries(VEHICLE_UNAVAILABILITY_TYPES).map(([k, v]) => ({
  id: k,
  name: v,
}))

const SaveButtonWithConflictingBookings = ({ handleSubmitWithRedirect, numberOfConflictingBookings, ...props }) => {
  const isFormValid = !props.invalid
  const shouldDisplayConfirmPopup = isFormValid && numberOfConflictingBookings > 0

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const confirm = useCallback(() => handleSubmitWithRedirect('show'), [handleSubmitWithRedirect])
  const handleClick = useCallback(() => {
    shouldDisplayConfirmPopup ? setIsConfirmOpen(true) : confirm()
  }, [confirm, shouldDisplayConfirmPopup])

  return (
    <>
      <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
      {shouldDisplayConfirmPopup && (
        <Confirm
          isOpen={isConfirmOpen}
          loading={props.saving}
          title="resources.maintenances.conflictingBookings.true.confirm.title"
          content="resources.maintenances.conflictingBookings.true.confirm.content"
          confirm="resources.maintenances.conflictingBookings.true.confirm.confirmLabel"
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={confirm}
        />
      )}
    </>
  )
}

const VehicleUnavailabilityFormToolbar = ({ hasDelete, numberOfConflictingBookings, type, ...props }) => (
  <BasicFormToolbar
    {...props}
    savebutton={<SaveButtonWithConflictingBookings numberOfConflictingBookings={numberOfConflictingBookings} />}
  >
    {type === UPDATE && hasDelete && <DeleteVehicleUnavailabilityButton variant="outlined" />}
  </BasicFormToolbar>
)

const validate = (values) => {
  const errors = {}
  errors.ended_on = validateEndDateAfterStartDate(values.started_on, values.ended_on)
  return errors
}

const useStyles = makeStyles({
  cell: {
    padding: '8px 0px 8px 16px',
  },
  editableCell: {
    padding: '16px 0px 0px 16px',
  },
  icon: {
    verticalAlign: 'bottom',
    marginRight: '0.25em',
  },
  conflictingBookings: {
    margin: '0px !important',
  },
})

const ConflictingBookingsReferenceManyField = ({ setNumberOfConflictingBookings }) => {
  const { values } = useFormState()
  return values.started_on && values.ended_on && values[vehiclesConfig.options.referenceKey] ? (
    <ReferenceManyField
      filter={{
        ...bookingsConfig.options.defaultFilterValues,
        timeline_start: parseDateAsISO(values.started_on),
        timeline_end: parseDateAsISO(values.ended_on),
      }}
      perPage={10}
      record={{ id: values[vehiclesConfig.options.referenceKey] }}
      reference={bookingsConfig.name}
      sort={{ ...bookingsConfig.options.defaultSort, order: 'ASC' }}
      target={vehiclesConfig.options.referenceKey}
    >
      <DatagridWithTitle setNumberOfConflictingBookings={setNumberOfConflictingBookings}>
        <EditableDatagrid editForm={<EditOverlappingBookingForm />} initialRowMode="edit" noDelete>
          <PeriodField startedOnSource="start_scheduled_on" endedOnSource="end_scheduled_on" addTime />
          <VehicleField sortable={false} />
        </EditableDatagrid>
      </DatagridWithTitle>
    </ReferenceManyField>
  ) : null
}

const VehicleUnavailabilityFormLayout = ({
  type = UPDATE,
  disabledInputsSources = [],
  onClose,
  hasDelete,
  ...props
}) => {
  const { hasSingleOrganisation, singleOrganisationId } = useCurrentAccountSelectors()

  const [numberOfConflictingBookings, setNumberOfConflictingBookings] = useState(0)

  const now = useRef(getRoundedNow())
  const end = useRef(getDateWithThirtyMinutesOffset())

  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const classes = useStyles()

  const title = `resources.maintenances.forms.${type.toLowerCase()}.title`

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'organisation_id',
    'vehicle_id',
    'type',
    'description',
    'started_on',
    'ended_on',
  ])

  let initialValues = {}
  if (type === CREATE) {
    initialValues.started_on = now.current
    initialValues.ended_on = end.current
    if (hasSingleOrganisation) {
      initialValues.organisation_id = singleOrganisationId
    }
  }

  return (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={
          <VehicleUnavailabilityFormToolbar
            hasDelete={hasDelete}
            numberOfConflictingBookings={numberOfConflictingBookings}
            onCancel={onClose}
            type={type}
          />
        }
        variant="outlined"
        validate={validate}
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <OrganisationReferenceInput
          disabled={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <VehicleReferenceInput
          linkedToHub={false}
          formType={type}
          disabled={type === UPDATE || isDisabled(disabledInputsSources, vehiclesConfig.options.referenceKey)}
        />
        <FormDivider />
        <SelectInput
          choices={vehicleUnavailabilityTypeChoices}
          source="type"
          optionText="name"
          optionValue="id"
          validate={required()}
          disabled={type === UPDATE}
        />
        <TextInput multiline source="description" />
        <FormDivider />
        <AdvancedDateTimeInput validate={required()} required source="started_on" minDate={now.current} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AdvancedDateTimeInput
              {...rest}
              validate={required()}
              required
              source="ended_on"
              minDate={formData.started_on ? parseDateAsDateTime(formData.started_on) : now.current}
            />
          )}
        </FormDataConsumer>
        <FormDivider
          condition={(formData) =>
            formData.started_on && formData.ended_on && formData[vehiclesConfig.options.referenceKey]
          }
        />
        <ConflictingBookingsReferenceManyField
          formClassName={classes.conflictingBookings}
          setNumberOfConflictingBookings={setNumberOfConflictingBookings}
        />
      </SimpleForm>
    </>
  )
}

const EditOverlappingBookingForm = (props) => {
  const record = { ...props.record, vehicle_id: null }
  const { refetch } = useListContext()
  const notify = useNotify()
  const classes = useStyles()

  return (
    <RowForm
      {...props}
      onSuccess={refetch}
      record={record}
      save={(values) =>
        isUuid4(values.booking_vehicle_id)
          ? props.save(values)
          : notify('resources.maintenances.conflictingBookings.true.noReallocationActionMessage')
      }
      transform={({ booking_vehicle_id, ...values }) =>
        values ? { ...values, vehicle_id: booking_vehicle_id } : values
      }
      undoable={false}
    >
      <PeriodField
        startedOnSource="start_scheduled_on"
        endedOnSource="end_scheduled_on"
        addTime
        cellClassName={classes.cell}
      />
      <FormDataConsumer cellClassName={classes.editableCell}>
        {({ formData }) => (
          <VehicleReferenceInput
            allowEmpty
            emptyText="resources.maintenances.conflictingBookings.true.noReallocation"
            filter={{
              available_from: formData.start_scheduled_on,
              available_to: formData.end_scheduled_on,
              user_id: formData.user_id,
              hub_id: formData.hub_id,
            }}
            helperText="resources.maintenances.conflictingBookings.true.vehicleInputHelperText"
            label={false}
            perPage={50}
            source="booking_vehicle_id"
            validate={null}
            variant="outlined"
          />
        )}
      </FormDataConsumer>
    </RowForm>
  )
}

const DatagridWithTitle = ({ children, setNumberOfConflictingBookings, ...props }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const translate = useTranslate()

  useEffect(() => {
    if (typeof props.total === 'number') setNumberOfConflictingBookings(props.total)
  }, [props.total, setNumberOfConflictingBookings])

  return (
    <>
      <Box p="16px 10px">
        {props.total > 0 ? (
          <>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <FalseIcon className={classes.icon} color="error" />
              {translate('resources.maintenances.conflictingBookings.true.title')}
            </Typography>
            <Typography variant="body2" style={{ margin: '13px 13px 0px 30px' }}>
              {translate('resources.maintenances.conflictingBookings.true.helperText')}
            </Typography>
          </>
        ) : (
          <Typography variant="body2">
            <TrueIcon className={classnames(commonClasses.primaryColor, classes.icon)} />
            {translate('resources.maintenances.conflictingBookings.false.title')}
          </Typography>
        )}
      </Box>
      {cloneElement(children, props)}
    </>
  )
}

const VehicleUnavailabilityFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  disabledInputsSources,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
  })

  const [hasDelete] = useResourcePermissions(resource, SYSTEM_PERMISSION_DELETE)

  controllerProps = {
    ...controllerProps,
    type,
    disabledInputsSources,
    onClose,
    hasDelete,
  }

  return <VehicleUnavailabilityFormLayout {...controllerProps} />
}

export const EditVehicleUnavailabilityFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <VehicleUnavailabilityFormLayoutController {...props} /> : <div />

export const CreateVehicleUnavailabilityFormLayoutController = (props) => (
  <VehicleUnavailabilityFormLayoutController {...props} />
)

export const useCreateVehicleUnavailability = (
  { defaultValues = [], disabledInputsSources } = { defaultValues: [] },
) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => {
    // We don't pick the vehicle_id if org is not in the values
    const filteredDefaultValues = Array.from(defaultValues)
    if (!values.organisation_id) {
      filteredDefaultValues.splice(filteredDefaultValues.indexOf('vehicle_id'), 1)
    }
    setCreatePopupState({
      isOpen: true,
      values: filteredDefaultValues?.length > 0 ? pick(values, filteredDefaultValues) : values,
    })
  }
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateVehicleUnavailabilityFormLayoutController
        initialValues={createPopupState.values}
        disabledInputsSources={disabledInputsSources}
        onClose={handleCreatePopupClose}
      />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditVehicleUnavailability = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditVehicleUnavailabilityFormLayoutController
        initialValues={editPopupState.values}
        onClose={handleEditPopupClose}
      />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default VehicleUnavailabilityFormLayout
