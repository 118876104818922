import { CardContent, Dialog, InputAdornment } from '@material-ui/core'
import { get, isEmpty, pick } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import {
  CREATE,
  FormDataConsumer,
  ImageField,
  ImageInput,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  UPDATE,
  useCreateController,
  useDataProvider,
  useEditController,
  useGetOne,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useTranslate } from 'ra-core'

import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import env from '../../config/env'
import { useCommonStyles } from '../../config/theme'
import {
  VEHICLE_ENERGY_TYPES,
  VEHICLE_GATEWAYS,
  VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS,
  VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN,
  VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO,
  VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT,
  VEHICLE_SPECIAL_FIELDS,
  VEHICLE_TRANSMISSION_TYPES,
  VEHICLE_TYPE_CAR_BLUEPRINT_SETS,
  VEHICLE_TYPES,
} from '../../config/vehicles'
import { isVehicleCar, isVehicleElectricCar, isVehicleFuelCar } from '../../domain/vehicles'
import { getRoundedNow, parseDateAsDateTime, validateEndDateAfterStartDate } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { validateVIN } from '../../utils/validators'
import { useCurrentAccountSelectors } from '../account/hooks'
import { CardTitle, FormDivider } from '../common'
import { useShowStyles } from '../common/show'
import HubReferenceInput from '../hubs/input'
import OrganisationReferenceInput from '../organisations/input'
import { NonInputTitle } from '../common/forms'

import config from './config'

const vehicleTypeChoices = Object.entries(VEHICLE_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleBlueprintSetChoices = Object.entries(VEHICLE_TYPE_CAR_BLUEPRINT_SETS).map(([k, v]) => ({ id: k, name: v }))
const vehicleEnergyTypeChoices = Object.entries(VEHICLE_ENERGY_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleTransmissionTypeChoices = Object.entries(VEHICLE_TRANSMISSION_TYPES).map(([k, v]) => ({ id: k, name: v }))
const vehicleGatewayCodeChoices = Object.entries(VEHICLE_GATEWAYS).map(([k, v]) => ({ id: k, name: v }))

export const validate = (values) => {
  const errors = {}
  errors.ended_on = validateEndDateAfterStartDate(values.started_on, values.ended_on)
  return errors
}

export const validateValue = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateNumberOfSeats = [
  number('mymove.validation.number.invalid'),
  minValue(1, 'mymove.validation.number.positive'),
]

const validateVolume = [number('mymove.validation.number.invalid'), minValue(1, 'mymove.validation.number.positive')]

const validateMaximumAutonomy = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateTankSize = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validateAverageConsumption = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const validatePriceFactor = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const validateSecurityDepositAmount = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const validateChargingBuffer = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'resources.vehicles.forms.validation.charging_buffer.notInRange'),
  maxValue(24, 'resources.vehicles.forms.validation.charging_buffer.notInRange'),
]

export const validatePricePerKm = [
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const VehicleSpecialFieldInput = ({ code, ...props }) => (
  <TextInput {...props} source={'details.' + code} label={VEHICLE_SPECIAL_FIELDS[code]} />
)

export const PricePerKmNumberInput = ({ organisationId, source, type, ...props }) => {
  const { change } = useForm()

  const { data: organisation } = useGetOne('organisations', organisationId, { enabled: Boolean(organisationId) })
  const organisationPricePerKm = get(organisation, 'price_per_km', null)
  const [pricePerKm, setPricePerKm] = useState(organisationPricePerKm)

  useEffect(() => {
    setPricePerKm(organisationPricePerKm)
  }, [organisationId, organisationPricePerKm])

  useEffect(() => {
    if (type === CREATE) {
      change(source, pricePerKm)
    }
  }, [change, type, pricePerKm, source])

  return (
    <NumberInput
      source={source}
      validate={validatePricePerKm}
      format={(v) => (v ? v / 100 : null)}
      parse={(v) => v * 100}
      step={0.01}
      min={0}
      options={{
        InputProps: { endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> },
      }}
      label={`resources.organisations.fields.${source}`}
      {...props}
    />
  )
}

const getFieldCollectionFromObject = (obj) =>
  obj
    ? Object.entries(obj)
        .map(([k, v]) => ({ code: k, value: v }))
        .filter((i) => i.value !== null) // remove items with no value
    : undefined

const getFieldObjectFromCollection = (coll) =>
  coll ? coll.reduce((acc, field) => ({ ...acc, [field.code]: field.value }), {}) : undefined

export const transformValues = (values) =>
  values
    ? {
        ...values,
        information_document_data:
          values.information_document_data && isVehicleCar(values) ? values.information_document_data : null,
        charging_buffer: values.charging_buffer && isVehicleElectricCar(values) ? values.charging_buffer * 60 : 0,
        price_per_km: values.price_per_km && isVehicleCar(values) ? values.price_per_km : 0,
        security_deposit_amount: values.security_deposit_amount ? values.security_deposit_amount : 0,
        details: getFieldCollectionFromObject(values.details),
      }
    : values

const VehicleFormLayout = ({ type = UPDATE, onClose, ...props }) => {
  const translate = useTranslate()
  const { hasSingleOrganisation, singleOrganisationId, hasSingleHub, singleHubId } = useCurrentAccountSelectors()

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const title = `resources.vehicles.forms.${type.toLowerCase()}.title`

  const now = useRef(getRoundedNow())

  const dataProvider = useDataProvider()
  const [vehicleDetails, setVehicleDetails] = useState({})

  useEffect(() => {
    if (!Boolean(props.record.id)) return
    const timeout = setTimeout(() => {
      dataProvider
        .getOne('vehicles/details', { id: props.record.id })
        .then(({ data }) => {
          setVehicleDetails(data)
        })
        .catch(() => {
          onClose && onClose()
        })
    }, 50)
    return () => clearTimeout(timeout)
  }, [props.record.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const formProps = pick(props, [
    'basePath',
    'record',
    'redirect',
    'resource',
    'save',
    'saving',
    'version',
    'mutationMode',
    'onSuccess',
    'onFailure',
    'transform',
  ])
  formProps.record = pick(formProps.record, [
    'id',
    'type',
    'brand',
    'model',
    'designation',
    'picture',
    'hub_id',
    'organisation_id',
    'started_on',
    'ended_on',
  ])

  let initialValues = {}
  if (type === CREATE) {
    initialValues.price_factor = 1
    initialValues.started_on = now.current
    if (hasSingleOrganisation) {
      initialValues.organisation_id = singleOrganisationId
    }
    if (hasSingleHub) {
      initialValues.hub_id = singleHubId
    }
  } else {
    formProps.record.picture_data = formProps.record.picture ? { src: formProps.record.picture } : null
    const hasDetails = Object.keys(vehicleDetails).length > 0
    if (hasDetails) {
      formProps.record = {
        ...formProps.record,
        ...pick(vehicleDetails, [
          'average_consumption',
          'blueprint_set',
          'energy_type',
          'identification_number',
          'information_document',
          'keyless',
          'maximum_autonomy',
          'number_of_seats',
          'price_factor',
          'price_per_km',
          'security_deposit_amount',
          'tank_size',
          'transmission_type',
          'value',
          'volume',
          'year',
        ]),
      }
      formProps.record.information_document_data = formProps.record.information_document
        ? {
            src: formProps.record.information_document,
            title: translate('resources.vehicles.fields.information_document_data'),
          }
        : null
      formProps.record.details = getFieldObjectFromCollection(vehicleDetails.details)
      formProps.record.charging_buffer = vehicleDetails.charging_buffer ? vehicleDetails.charging_buffer / 60 : null
      formProps.record.gateway = pick(vehicleDetails.gateway, ['code', 'vehicle_id'])
    }
  }

  return type === CREATE || (type === UPDATE && !isEmpty(vehicleDetails)) ? (
    <>
      <CardContent className={commonClasses.titleContainer}>
        <CardTitle text={title} />
      </CardContent>
      <SimpleForm
        toolbar={<BasicFormToolbar onCancel={onClose} />}
        variant="outlined"
        validate={validate}
        initialValues={initialValues}
        className={showClasses.fieldContainerWrapper}
        {...formProps}
      >
        <NonInputTitle text="identification" />

        <FormDivider />

        <ImageInput
          className={commonClasses.commonInput}
          source="picture_data"
          labelSingle={translate('mymove.dropOrSelectYourWebpFileHere')}
          accept="image/webp"
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDivider />

        <SelectInput
          choices={vehicleTypeChoices}
          source="type"
          optionText="name"
          optionValue="id"
          validate={required()}
          disabled={type === UPDATE}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <SelectInput
                {...rest}
                choices={vehicleBlueprintSetChoices}
                source="blueprint_set"
                optionText="name"
                optionValue="id"
                validate={required()}
                disabled={type === UPDATE}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <TextInput source="brand" validate={required()} />

        <TextInput source="model" validate={required()} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && <NumberInput {...rest} source="year" validate={required()} min={2000} />
          }
        </FormDataConsumer>

        <TextInput source="designation" validate={required()} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && <TextInput {...rest} source="identification_number" validate={validateVIN()} />
          }
        </FormDataConsumer>

        <FormDivider condition={(formData) => isVehicleCar(formData)} />

        <NonInputTitle text="characteristics" condition={(formData) => isVehicleCar(formData)} />

        <FormDivider condition={(formData) => isVehicleCar(formData)} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <SelectInput
                {...rest}
                choices={vehicleTransmissionTypeChoices}
                source="transmission_type"
                optionText="name"
                optionValue="id"
                validate={required()}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => isVehicleCar(formData) && <AdvancedSelectInput {...rest} source="keyless" />}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <NumberInput {...rest} source="number_of_seats" min={1} validate={validateNumberOfSeats} />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <NumberInput
                {...rest}
                source="volume"
                min={1}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                  },
                }}
                validate={validateVolume}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <NumberInput
                {...rest}
                source="value"
                validate={validateValue}
                format={(v) => (v ? v / 100 : null)}
                parse={(v) => v * 100}
                step={10}
                min={0}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment>,
                  },
                }}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider condition={(formData) => isVehicleCar(formData)} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <SelectInput
                {...rest}
                choices={vehicleEnergyTypeChoices}
                source="energy_type"
                optionText="name"
                optionValue="id"
                validate={required()}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleElectricCar(formData) && (
              <NumberInput
                {...rest}
                source="maximum_autonomy"
                min={0}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">km</InputAdornment>,
                  },
                }}
                validate={validateMaximumAutonomy}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleElectricCar(formData) && (
              <VehicleSpecialFieldInput {...rest} code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleFuelCar(formData) && (
              <NumberInput
                {...rest}
                source="tank_size"
                min={0}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">l</InputAdornment>,
                  },
                }}
                validate={validateTankSize}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleFuelCar(formData) && (
              <NumberInput
                {...rest}
                source="average_consumption"
                step={0.1}
                min={0}
                options={{
                  InputProps: {
                    endAdornment: <InputAdornment position="end">l/100km</InputAdornment>,
                  },
                }}
                validate={validateAverageConsumption}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleFuelCar(formData) && (
              <VehicleSpecialFieldInput {...rest} code={VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO} />
            )
          }
        </FormDataConsumer>

        <FormDivider condition={(formData) => isVehicleCar(formData)} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <ImageInput
                {...rest}
                accept=".pdf"
                source="information_document_data"
                labelSingle={translate('mymove.dropOrSelectYourPDFDocumentHere')}
                className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <NonInputTitle text="parameters" />

        <FormDivider />

        <OrganisationReferenceInput disabled={type === UPDATE} />

        <HubReferenceInput />

        <FormDivider />

        <AdvancedDateTimeInput
          source="started_on"
          validate={required()}
          required
          label={translate('resources.vehicles.fields.started_on')}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AdvancedDateTimeInput
              {...rest}
              source="ended_on"
              label={translate('resources.vehicles.fields.ended_on')}
              minDate={formData.started_on ? parseDateAsDateTime(formData.started_on) : undefined}
            />
          )}
        </FormDataConsumer>

        <FormDivider />

        <NumberInput source="price_factor" step={0.1} min={0} validate={validatePriceFactor} />

        <NumberInput
          source="security_deposit_amount"
          validate={validateSecurityDepositAmount}
          format={(v) => (v ? v / 100 : null)}
          parse={(v) => v * 100}
          step={10}
          min={0}
          options={{
            InputProps: {
              endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment>,
            },
          }}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleCar(formData) && (
              <PricePerKmNumberInput
                {...rest}
                organisationId={formData.organisation_id}
                source="price_per_km"
                type={type}
              />
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <VehicleSpecialFieldInput code={VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT} />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleElectricCar(formData) && (
              <NumberInput
                {...rest}
                source="charging_buffer"
                validate={validateChargingBuffer}
                min={0}
                max={24}
                helperText="resources.vehicles.forms.helperTexts.charging_buffer"
                options={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {translate('mymove.units.time.hours', get(formData, 'charging_buffer') || 1)}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            isVehicleFuelCar(formData) && (
              <VehicleSpecialFieldInput {...rest} code={VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN} />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            Boolean(formData.type) &&
            !isVehicleCar(formData) && (
              <VehicleSpecialFieldInput {...rest} code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS} />
            )
          }
        </FormDataConsumer>

        <FormDivider />

        <NonInputTitle text="providerDetails" />

        <FormDivider />

        <SelectInput
          choices={vehicleGatewayCodeChoices}
          source="gateway.code"
          optionText="name"
          optionValue="id"
          validate={required()}
          disabled={type === UPDATE}
        />

        <TextInput source="gateway.vehicle_id" disabled={type === UPDATE && !!formProps.record?.gateway?.vehicle_id} />
      </SimpleForm>
    </>
  ) : null
}

const VehicleFormLayoutController = ({
  basePath = '/' + config.name,
  resource = config.name,
  initialValues,
  onClose,
}) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const id = get(initialValues, 'id')
  const type = Boolean(id) ? UPDATE : CREATE

  const useController = type === UPDATE ? useEditController : useCreateController

  let controllerProps = useController({
    basePath,
    resource,
    id,
    record: initialValues,
    mutationMode: 'pessimistic',
    onSuccess: () => {
      notify(`resources.${resource}.forms.${type.toLowerCase()}.success`)
      refresh()
      onClose && onClose()
    },
    onFailure: (error) => notify(error.message, { type: 'warning' }),
    transform: transformValues,
  })

  controllerProps = {
    ...controllerProps,
    type,
    onClose,
  }

  return <VehicleFormLayout {...controllerProps} />
}

export const EditVehicleFormLayoutController = (props) =>
  get(props, 'initialValues.id') ? <VehicleFormLayoutController {...props} /> : <div />

export const CreateVehicleFormLayoutController = (props) => <VehicleFormLayoutController {...props} />

export const useCreateVehicle = () => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => {
    setCreatePopupState({ isOpen: true, values })
  }
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={createPopupState.isOpen} onClose={handleCreatePopupClose}>
      <CreateVehicleFormLayoutController initialValues={createPopupState.values} onClose={handleCreatePopupClose} />
    </Dialog>
  )
  return [handleCreatePopupOpen, dialog]
}

export const useEditVehicle = () => {
  const [editPopupState, setEditPopupState] = useState({ isOpen: false, values: {} })
  const handleEditPopupOpen = (id) => setEditPopupState({ isOpen: true, values: { id } })
  const handleEditPopupClose = () => setEditPopupState({ isOpen: false, values: {} })
  const dialog = (
    <Dialog open={editPopupState.isOpen} onClose={handleEditPopupClose}>
      <EditVehicleFormLayoutController initialValues={editPopupState.values} onClose={handleEditPopupClose} />
    </Dialog>
  )
  return [handleEditPopupOpen, dialog]
}

export default VehicleFormLayout
