import { CreateButton } from '../common/buttons'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'

import { useCreateHub } from './form'
import config from './config'

export const CreateHubButton = ({ organisationId, disabledInputsSources }) => {
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const [openCreatePopup, createPopupDialog] = useCreateHub(disabledInputsSources)
  return hasCreate ? (
    <>
      <CreateButton
        basePath={'/' + config.name}
        label="resources.hubs.actions.create.name"
        filterValues={{ organisation_id: organisationId }}
        isFloatingOnSmallScreen={false}
        onClick={openCreatePopup}
      />
      {createPopupDialog}
    </>
  ) : null
}
