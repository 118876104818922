import GenericEditForm from '../common/edit'

import VehicleUnavailabilityFormLayout from './form'
import { VehicleUnavailabilityTitle } from './show'

export default (props) => {
  return (
    <GenericEditForm
      {...props}
      titleComponent={<VehicleUnavailabilityTitle />}
      formLayoutComponent={<VehicleUnavailabilityFormLayout />}
    />
  )
}
