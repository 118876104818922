import { NumberField, useLocale } from 'react-admin'
import get from 'lodash/get'

import env from '../config/env'

import AdvancedTextField from './AdvancedTextField'
import StaticText from './StaticText'

const PriceField = ({ record, format, ...props }) => {
  const locale = useLocale()
  let formattedRecord = record
  const value = get(record, props.source)
  if (!record || (!value && value !== 0)) {
    return <AdvancedTextField {...props} />
  }
  if (format) {
    formattedRecord = {
      ...record,
      [props.source]: format(value),
    }
  }
  return (
    <>
      <StaticText>{value >= 0 ? '' : '–'}</StaticText>
      <NumberField {...props} locales={locale} record={formattedRecord} />
    </>
  )
}

PriceField.defaultProps = {
  addLabel: true,
  textAlign: 'right',
  options: { style: 'currency', currency: env.CURRENCY_CODE },
  format: (v) => Math.abs(v / 100),
}

export default PriceField
